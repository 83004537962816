<template>
    <div class="page-vue-good-table scrollable only-y">
        <page-header />
        <datatable :path="'notes'" :source="'tools/notes'" :canCreate="true" />
    </div>
</template>

<script>
    import Datatable from "../../../components/Datatable";
    import PageHeader from "../../../components/PageHeader";

    export default {
        components: {PageHeader, Datatable}
    }
</script>