<template>
    <div>
        <el-form-item label="SEO Başlık">
            <el-input v-model="container.meta_title" placeholder="Meta Title"/>
        </el-form-item>

        <el-form-item label="SEO Link" class="is-error">
            <el-input v-model="container.slug" placeholder="Link" :disabled="linkStatus">
                <el-button slot="append" type="primary" icon="mdi mdi-lock-open-outline" @click="linkStatus ? linkStatus = false : linkStatus = true; container.slug_edit = true" />
            </el-input>
            <small class="text-color-danger">
                Kilidi acmamaniz durumunda link otomatik olarak <b>Seo Baslik</b> alanindan olusacaktir. Eger link kismini manuel olarak degistirdiginizde <b>hata aliyorsaniz</b> link alani <b>baska bir yerde</b> kullaniliyordur.
            </small>
        </el-form-item>

        <el-form-item label="SEO Açıklama">
            <el-input v-model="container.meta_description" placeholder="Meta Description"/>
        </el-form-item>

        <el-form-item label="SEO Anahtar Kelimeler">
            <el-input v-model="container.meta_keywords" placeholder="Meta Keywords"/>
        </el-form-item>

        <el-form-item>
            <el-switch v-model="container.meta_no_index"
                       active-text="Arama Motorunda Gözükmesin"/>
        </el-form-item>

        <el-form-item>
            <el-switch v-model="container.meta_no_follow" active-text="Linkleri Takip Etme" />
        </el-form-item>
    </div>
</template>
<script>
    export default {
        name: 'Seo',
        props: ['container'],
        data() {
            return {
                linkStatus : true
            }
        },

        methods: {
            //
        }
    }
</script>