import Vue from 'vue'
import Mdi from "./mdi";
import Avatar from "./avatar";
import CrudButtons from "./crud-buttons";
import StatusDot from "./status-dot";
import SelectStatus from "./SelectStatus";
import ShortDateRangePicker from "./short-daterange-picker";
import ZoneBox from "./ZoneBox";

Vue.component('mdi', Mdi);
Vue.component('avatar', Avatar);
Vue.component('crud-buttons', CrudButtons);
Vue.component('status-dot', StatusDot);
Vue.component('select-status', SelectStatus);
Vue.component('short-daterange-picker', ShortDateRangePicker);
Vue.component('zone-box', ZoneBox);
