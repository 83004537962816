<template>
    <div class="card-box" v-loading="pageLoading">
        <header class="card-box-header">
            <div class="title">Ogrenci Bilgileri</div>
            <div class="actions">
                <el-tooltip content="Yeni Ogrenci Bilgisi Ekle">
                    <el-button icon="el-icon-plus" size="small" type="primary" @click="create"/>
                </el-tooltip>
            </div>
        </header>
        <section class="card-box-tabs">
            <el-tabs :tab-position="'top'">
                <el-tab-pane :label="item.first_name + ' ' + item.last_name" v-for="(item, key) in items" :key="key">

                    <StudentsForm :form="item.form" :key="item.id"/>

                    <div class="padding-20">
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-button type="danger" size="medium" @click="destroy(item.id)" class="fluid">
                                    <i class="mdi mdi-trash"></i> Sil
                                </el-button>
                            </el-col>
                            <el-col :span="12">
                                <el-button type="success" size="medium" @click="update(item)" class="fluid" :loading="dialogLoading" :disabled="dialogLoading">
                                    <i class="mdi mdi-check"></i> Kaydet
                                </el-button>
                            </el-col>
                        </el-row>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </section>

        <el-dialog title="Yeni Ogrenci Ekle" :visible.sync="dialogVisible" width="80%" append-to-body class="no-padding">

            <StudentsForm :form="form" :key="form.id"/>

            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="store" class="fluid" :loading="dialogLoading" :dsaibled="dialogLoading">
                    Kaydet
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import StudentsForm from "@/components/Student.vue";

export default {
    name: 'Students',
    components: {StudentsForm},
    props: ['id', 'userId', 'accountId'],
    data() {
        return {
            pageLoading: false,
            dialogVisible: false,
            form: {
                //
            },

            dialogTitle: 'Yeni Ogrenci Ekle',
            dialogLoading: false,

            items: [],
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        index() {

            this.pageLoading = true;

            this.$http
                .get('school/students', {
                    params: {
                        process: 'interviews',
                        account_id: this.accountId,
                    }
                })
                .then(response => {
                    this.items = response.body.data;
                    this.pageLoading = false;
                });
        },

        create() {
            this.form = {
                //
            }
            this.dialogVisible = true
            this.dialogLoading = false;
        },

        store() {
            this.dialogLoading = true;
            this.form.account_id = this.accountId

            this.$http.post('school/students', this.form)
                .then(response => {
                    this.dialogLoading = false;
                    this.dialogVisible = false;
                    this.apiSuccessMessage(response);
                    this.index()
                })
                .catch((e) => {
                    this.dialogLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        update(item) {
            this.dialogLoading = true;
            this.form = item.form
            this.form.account_id = this.accountId

            this.$http.put('school/students/' + this.form.id, this.form)
                .then(response => {
                    this.apiSuccessMessage(response);
                    this.index()
                    this.dialogLoading = false;
                })
                .catch((e) => {
                    this.dialogLoading = false;
                    this.apiErrorMessage(e);
                });
        }
    }
}
</script>
