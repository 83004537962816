<template>
    <section v-loading="pageLoading">
        <div class="card-box no-shadow">
            <header class="card-box-header has-buttons">
                <div class="title">
                    Adresler
                </div>
                <div class="actions">
                    <el-button icon="el-icon-plus" type="text" class="el-button-link primary" @click="create"/>
                </div>
            </header>
            <section class="card-box-content">
                <el-tabs :tab-position="'top'">
                    <template v-for="item in items">
                        <el-tab-pane :label="item.title" :key="item.id">
                            <div class="padding-20">
                                <AddressForm :form="item" :key="item.id"/>

                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <el-button type="danger" size="medium" @click="destroy(item.id)" class="fluid">
                                            <i class="mdi mdi-trash"></i> Sil
                                        </el-button>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-button type="success" size="medium" @click="update(item)" class="fluid">
                                            <i class="mdi mdi-check"></i> Kaydet
                                        </el-button>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>
                    </template>
                </el-tabs>
            </section>
        </div>

        <el-dialog title="Adres Ekle" :visible.sync="createDialogVisible" v-loading="dialogLoading">
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Adresten Doldur" v-if="!form.id">
                    <ti-select :container="form" model="id" :options="items" :change="handleCreateInitAddress"/>
                </el-form-item>

                <AddressForm :form="form"/>

                <el-button type="success" size="medium" @click="store" class="fluid">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
            </el-form>
        </el-dialog>
    </section>
</template>

<script>
import AddressForm from "@/components/Address";

export default {
    name: 'Addresses',
    components: {
        AddressForm
    },
    props: [
        'source',
        'itemId',
        'accountId'
    ],
    data() {
        return {
            pageLoading: true,
            form: {
                //
            },

            items: [],

            dialogLoading: false,
            createDialogVisible: false,
            editDialogVisible: false,
        }
    },

    mounted() {
        this.index();
    },

    methods: {
        index() {

            this.pageLoading = true;

            this.$http
                .get('crm/accounts/' + this.accountId + '/addresses', {
                    params: {
                        fake_url: this.source,
                    }
                })
                .then(response => {
                    this.items = response.body.rows;
                    this.pageLoading = false;
                });
        },

        create() {
            this.form = {
                //
            };

            this.createDialogVisible = true;
        },

        edit(item) {
            this.form = item;

            this.editDialogVisible = true;
        },

        store() {
            this.dialogLoading = true;

            this.form.fake_url = this.source;

            this.$http
                .post('crm/accounts/' + this.accountId + '/addresses', this.form)
                .then(response => {

                    this.dialogLoading = false;
                    this.createDialogVisible = false;

                    this.apiSuccessMessage(response);
                    this.index();
                })
                .catch((e) => {
                    this.dialogLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        update(item) {
            this.dialogLoading = true;

            this.form = item;
            this.form.fake_url = this.source;

            this.$http
                .put('crm/accounts/' + this.accountId + '/addresses/' + this.form.id, this.form)
                .then(response => {

                    this.dialogLoading = false;
                    this.editDialogVisible = false;

                    this.apiSuccessMessage(response);
                    this.index();
                })
                .catch((e) => {
                    this.dialogLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        destroy(id) {

            this.pageLoading = true;

            this
                .$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                })
                .then(() => {
                    this.$http
                        .delete('crm/accounts/' + this.accountId + '/addresses/' + id, {
                            fake_url: this.source
                        })
                        .then(response => {
                            this.pageLoading = false;

                            this.index();

                            this.$message({
                                type: 'success',
                                message: 'Delete completed'
                            });
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.pageLoading = false;
                });
        },

        handleCreateInitAddress() {

            let item = _.filter(this.items, (o) => {
                return o.id === this.form.id
            });

            this.form = JSON.parse(JSON.stringify(item[0]));
            this.form.title = 'Yeni Adres'
            this.form.is_main = false;
        }
    },
}
</script>
