<template>
    <el-date-picker
        v-model="container[model]"
        :picker-options="datePickerOptions"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="-"
        start-placeholder="Başlangıç"
        end-placeholder="Bitiş"
        value-format="yyyy-MM-dd"
        @change="handleChange"
    />
</template>
<script>
import moment from 'moment-timezone'

export default {
    name: 'short-daterange-picker',
    props: ['container', 'model', 'change'],
    data() {
        return {
            datePickerOptions: {
                firstDayOfWeek : 1,
                shortcuts: [
                    {
                        text: 'Bugün',
                        onClick(picker) {
                            picker.$emit('pick', [
                                moment().startOf('day').toDate(),
                                moment().endOf('day').toDate(),
                            ]);
                        }
                    },
                    {
                        text: 'Dün',
                        onClick(picker) {
                            picker.$emit('pick', [
                                moment().subtract(1, 'days').startOf('day').toDate(),
                                moment().subtract(1, 'days').endOf('day').toDate(),
                            ]);
                        }
                    },
                    {
                        text: 'Bu Hafta',
                        onClick(picker) {
                            picker.$emit('pick', [
                                moment().startOf('week').toDate(),
                                moment().endOf('day').toDate(),
                            ]);
                        }
                    },
                    {
                        text: 'Geçen Hafta',
                        onClick(picker) {
                            picker.$emit('pick', [
                                moment().subtract(1, 'week').startOf('week').toDate(),
                                moment().subtract(1, 'week').endOf('week').toDate(),
                            ]);
                        }
                    },
                    {
                        text: 'Bu Ay',
                        onClick(picker) {
                            picker.$emit('pick', [
                                moment().startOf('month').toDate(),
                                moment().endOf('day').toDate(),
                            ]);
                        }
                    },
                    {
                        text: 'Geçen Ay',
                        onClick(picker) {
                            picker.$emit('pick', [
                                moment().subtract(1, 'month').startOf('month').toDate(),
                                moment().subtract(1, 'month').endOf('month').toDate(),
                            ]);
                        }
                    },
                    {
                        text: 'Bu Yıl',
                        onClick(picker) {
                            picker.$emit('pick', [
                                moment().startOf('year').toDate(),
                                moment().endOf('day').toDate(),
                            ]);
                        }
                    },
                    {
                        text: 'Geçen Yıl',
                        onClick(picker) {
                            picker.$emit('pick', [
                                moment().subtract(1, 'year').startOf('year').toDate(),
                                moment().subtract(1, 'year').endOf('year').toDate(),
                            ]);
                        }
                    }
                ]
            },
        };
    },
    mounted() {
        //
    },
    methods: {
        handleChange(event) {
            if (!this.change) {
                return false;
            }

            return this.change(event)
        }
    }
};
</script>

