<template>
    <vue-scroll>
        <div class="loading-container" v-loading="pageLoading">
            <div class="card-box">
                <header class="card-box-header has-buttons">
                    <div class="title">
                        Teklif Raporlari
                    </div>
                    <div class="actions">
                        <el-button icon="el-icon-search" type="text" class="el-button-link blue d-flex"
                                   @click="filterVisible = true"/>
                    </div>
                </header>
            </div>

            <el-row :gutter="24">
                <el-col :span="8">
                    <div class="card-box">
                        <div class="card-box-content">
                            <div class="box-list">
                                <div class="box-list-item">
                                    <div class="media">
                                        <i class="mdi mdi-package-variant fs-38 fw-500"></i>
                                    </div>
                                    <div class="text">
                                        <div class="total text-right fs-24 fw-500">
                                            175
                                        </div>
                                        <div class="description fluid">
                                            Urun Toplami
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="card-box">
                        <div class="card-box-content">
                            <div class="box-list">
                                <div class="box-list-item">
                                    <div class="media">
                                        <i class="mdi mdi-sale fs-38 fw-500"></i>
                                    </div>
                                    <div class="text">
                                        <div class="total text-right fs-24 fw-500">
                                            175
                                        </div>
                                        <div class="description fluid">
                                            Indirim Toplami
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="card-box">
                        <div class="card-box-content">
                            <div class="box-list">
                                <div class="box-list-item">
                                    <div class="media">
                                        <i class="mdi mdi-cash fs-38 fw-500"></i>
                                    </div>
                                    <div class="text">
                                        <div class="total text-right fs-24 fw-500">
                                            175
                                        </div>
                                        <div class="description fluid">
                                            Toplam
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="12">
                    <div class="card-box">
                        <div class="card-box-content">
                            <div class="box-list">
                                <div class="box-list-item">
                                    <div class="media">
                                        <i class="mdi mdi-gender-female fs-38 fw-500"></i>
                                    </div>
                                    <div class="text">
                                        <div class="total text-right fs-24 fw-500">
                                            175
                                        </div>
                                        <div class="description fluid">
                                            Iptal Edildi
                                        </div>
                                    </div>
                                </div>
                                <div class="box-list-item">
                                    <div class="media">
                                        <i class="mdi mdi-gender-male fs-38 fw-500"></i>
                                    </div>
                                    <div class="text">
                                        <div class="total text-right fs-24 fw-500">
                                            175
                                        </div>
                                        <div class="description fluid">
                                            Bekliyor
                                        </div>
                                    </div>
                                </div>
                                <div class="box-list-item">
                                    <div class="media">
                                        <i class="mdi mdi-school fs-38 fw-500"></i>
                                    </div>
                                    <div class="text">
                                        <div class="total text-right fs-24 fw-500">
                                            175
                                        </div>
                                        <div class="description fluid">
                                            Tamamlandi
                                        </div>
                                    </div>
                                </div>
                                <div class="box-list-item">
                                    <div class="media">
                                        <i class="el-icon-tickets fs-38 fw-500"></i>
                                    </div>
                                    <div class="text">
                                        <div class="total text-right fs-24 fw-500">
                                            175
                                        </div>
                                        <div class="description fluid">
                                            Toplam
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="card-box">
                        <div class="card-box-content has-canvas canvas-center" style="height: 291px">
                            <highcharts :options="statusPie" style="height: 271px;"/>
                        </div>
                    </div>
                </el-col>
            </el-row>

            <div class="card-box">
                <div class="card-box-header">
                    <div class="title">
                        Duruma Gore
                    </div>
                </div>
                <div class="card-box-content has-canvas canvas-center" style="min-height: 400px">
                    <highcharts :options="statusData" style="height: 400px;"/>
                </div>
            </div>
            <div class="card-box">
                <div class="card-box-header">
                    <div class="title">
                        Personel Gore Iptal Edilen
                    </div>
                </div>
                <div class="card-box-content has-canvas canvas-center" style="min-height: 400px">
                    <highcharts :options="staffData" style="height: 400px;"/>
                </div>
            </div>
            <div class="card-box">
                <div class="card-box-header">
                    <div class="title">
                        Personele Gore Bekleyen
                    </div>
                </div>
                <div class="card-box-content has-canvas canvas-center" style="min-height: 400px">
                    <highcharts :options="staffData" style="height: 400px;"/>
                </div>
            </div>
            <div class="card-box">
                <div class="card-box-header">
                    <div class="title">
                        Personele Gore Tamamlanan
                    </div>
                </div>
                <div class="card-box-content has-canvas canvas-center" style="min-height: 400px">
                    <highcharts :options="staffData" style="height: 400px;"/>
                </div>
            </div>
        </div>
        <el-dialog title="Filtrele" :visible.sync="filterVisible" width="50%">
            <el-form :label-position="'top'" ref="form" :model="filterForm">
                <el-form-item>
                    <el-date-picker v-model="filterForm.range" type="daterange" range-separator="To"
                                    start-placeholder="Baslangic" end-placeholder="Bitis"/>
                </el-form-item>

                <div class="ui button labeled icon green fluid" @click="handleSearch">
                    <i class="el-icon-search icon"></i> Ara
                </div>
            </el-form>
        </el-dialog>
    </vue-scroll>
</template>

<script>
    import SelectDefinition from "../../../components/SelectDefinition";

    export default {
        name: 'Offer',
        components: {SelectDefinition},
        data() {
            return {
                pageLoading: true,
                filterVisible: false,
                filterForm: {
                    //
                },
                lineCartWidth: 1024,
                statusPie: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                    },
                    title: {
                        text: ''
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    series: [{
                        colorByPoint: true,
                        data: [
                            {
                                name: 'Iptal Edilen',
                                y: 40,
                                color: "#db2828"
                            },
                            {
                                name: 'Bekleyen',
                                y: 120,
                                color: "#fbbd08"
                            },
                            {
                                name: 'Tamamlanan',
                                y: 200,
                                color: "#21ba45"
                            }
                        ]
                    }]
                },
                statusData: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'line'
                    },
                    title: {
                        text: ''
                    },
                    xAxis: {
                        categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
                    },
                    yAxis: {
                        title: {
                            text: ''
                        }
                    },
                    plotOptions: {
                        line: {
                            dataLabels: {
                                enabled: true
                            },
                            enableMouseTracking: false
                        }
                    },
                    series: [
                        {
                            name: 'Iptal Edilen',
                            color: '#db2828',
                            data: [2, 4, 6, 10, 13, 2, 4, 7, 10, 13, 7, 4, 7, 10, 10, 2, 4, 7, 10, 13, 2, 4, 7, 10, 13, 2, 4, 7, 10, 13, 27],
                        },
                        {
                            name: 'Bekleyen',
                            color: '#fbbd08',
                            data: [4, 4, 4, 10, 45, 2, 8, 7, 10, 2, 2, 4, 7, 10, 15, 2, 4, 7, 10, 13, 2, 4, 7, 10, 13, 2, 4, 7, 10, 13, 5],
                        },
                        {
                            name: 'Tamamlanan',
                            color: '#21ba45',
                            data: [5, 4, 80, 22, 13, 2, 4, 7, 10, 1, 2, 4, 7, 20, 13, 2, 4, 7, 10, 13, 2, 4, 7, 10, 13, 2, 4, 7, 10, 13, 27],
                        }
                    ]
                },
                staffData: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'line'
                    },
                    title: {
                        text: ''
                    },
                    xAxis: {
                        categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
                    },
                    yAxis: {
                        title: {
                            text: ''
                        }
                    },
                    plotOptions: {
                        line: {
                            dataLabels: {
                                enabled: true
                            },
                            enableMouseTracking: false
                        }
                    },
                    series: [
                        {
                            name: 'Hulya Kose',
                            color: '#4f91cd',
                            data: [2, 4, 7, 10, 13, 2, 4, 7, 10, 13, 4, 4, 7, 10, 13, 2, 4, 7, 10, 13, 2, 4, 7, 10, 13, 2, 4, 7, 10, 13, 27],
                        },
                        {
                            name: 'Onur',
                            color: '#f2711c',
                            data: [2, 4, 7, 10, 13, 2, 4, 7, 10, 13, 2, 4, 7, 9, 13, 2, 4, 7, 10, 13, 2, 4, 7, 7, 13, 2, 4, 7, 10, 13, 27],
                        },
                        {
                            name: 'Tansu',
                            color: '#e03997',
                            data: [2, 4, 7, 10, 13, 2, 4, 15, 10, 13, 2, 4, 7, 10, 13, 2, 4, 7, 10, 13, 2, 4, 7, 33, 13, 2, 4, 7, 10, 13, 27],
                        },
                        {
                            name: 'Yasemin',
                            color: '#00b5ad',
                            data: [2, 4, 7, 10, 13, 2, 4, 7, 10, 13, 2, 4, 40, 10, 13, 2, 4, 7, 10, 13, 2, 4, 7, 10, 13, 2, 4, 15, 10, 13, 27],
                        }
                    ]
                },
                options: {
                    segmentShowStroke: false,
                    responsive: false,
                }
            }
        },

        methods: {
            handleSearch() {
                console.log(this.filterForm);
            }
        },

        mounted() {
            this.pageLoading = false;

            this.lineCartWidth = window.innerWidth - 400
        }
    }
</script>0