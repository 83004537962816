<template>
    <div v-if="student">
        <el-tooltip content="Platform'a Aktar" effect="dark" placement="bottom">
            <button class="ui mini button" @click="showDialog" v-loading="pageLoading" :disabled="pageLoading" type="button">
                <i class="icon mdi mdi-school"></i> Platform'a Aktar
            </button>
        </el-tooltip>

        <el-dialog title="Platform'a Aktar" :visible.sync="dialogVisible" v-loading="pageLoading" append-to-body class="platform-merge-dialog">
            <el-form :model="form">

                <div class="ui message yellow" style="border-radius: 0; border: none; box-shadow: none">
                    Eger ogrencinin eposta hesabina sahip ve tamamlanmis bir siparisi varsa eklediginiz urunler o siparise ek olarak olusturulur.
                </div>

                <div class="card-box">
                    <header class="card-box-header">
                        <div class="title">
                            {{ student.full_name }}
                        </div>
                    </header>
                    <div class="card-box-content">
                        <div class="box-list">
                            <div class="box-list-item">
                                <div class="name">E-posta</div>
                                <div class="description">{{ student.identification_no }}@minidil.com</div>
                            </div>
                            <div class="box-list-item">
                                <div class="name">Sifre</div>
                                <div class="description">{{ student.identification_no }}</div>
                            </div>
                            <div class="box-list-item ">
                                <div class="name fluid">
                                    <el-form-item style="margin: 0" class="fluid">
                                        <el-select v-model="form.product_requests" placeholder="Urunler" multiple
                                                   clearable filterable>
                                            <el-option v-for="item in products" :key="item.id" :label="item.title_ui"
                                                       :value="item.id" />
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="handleSend" class="fluid">
                    <i class="mdi mdi-check"></i> Gonder
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'PlatformMerge',
    props: ['student'],
    data() {
        return {
            pageLoading: false,
            dialogVisible: false,
            form: {
                process: 'store',
                name: null,
                email: null,
                password: null,
                product_requests: []
            },

            products: []
        }
    },

    mounted() {
        this.getProducts();
    },

    methods: {
        getProducts() {
            this.pageLoading = true;

            $.ajax({
                url: 'https://minidil.tech/shop/products',
                jsonp: 'callback',
                dataType: 'jsonp',
                success: (response) => {
                    this.products = response.data;
                    this.pageLoading = false;
                },
                error: (response) => {
                    this.pageLoading = false;
                }
            })
        },

        showDialog() {
            this.dialogVisible = true
        },

        handleSend() {
            this.form.process = 'store';
            this.form.name = this.student.full_name;
            this.form.email = this.student.identification_no + '@minidil.com';
            this.form.password = this.student.identification_no;


            if (this.student.account) {
                this.form.address = this.student.account.address.address_1;
                this.form.phone = this.student.account.address.phone;
            } else {
                this.form.address = '';
                this.form.phone = this.student.order.phone;
            }

            this.pageLoading = true;

            $.ajax({
                url: 'https://minidil.tech/shop/products',
                jsonp: 'callback',
                dataType: 'jsonp',
                data: this.form,
                success: (response) => {
                    this.pageLoading = false;
                    this.dialogVisible = false;
                    this.form = {
                        process: 'store',
                        name: null,
                        email: null,
                        password: null,
                        product_requests: []
                    }

                    this.$notify.success({
                        title: 'Tebrikler',
                        message: 'Ogrenci basarili bir sekilde aktarildi!',
                        position: 'top-right'
                    });
                },
                error: ($xhr, textStatus, errorThrown) => {
                    this.$notify.error({
                        title: 'Bir hata oluştu :(',
                        message: 'Lütfen kontrol ederek tekrar deneyiniz. Devam etmesi durumunda teknik ekip ile iletişime geçiniz.',
                        position: 'top-right'
                    });

                    this.pageLoading = false;
                }
            })
        }
    },
}
</script>

<style lang="scss">
.platform-merge-dialog {
    .el-dialog__body {
        padding : 0;

        .card-box {
            margin : 0 !important;
        }
    }
}
</style>
