<template>
    <div v-loading="itemLoading">

        <div class="box-list-item box-list-item-title">
            <div class="name fluid">
                Iadeler
            </div>
            <div class="description d-flex" style="width: auto">
                <el-button icon="el-icon-plus" type="text" class="el-button-link blue" @click="crateItem" />
            </div>
        </div>

        <div class="box-list-item">
            <div>
                <el-collapse v-model="accordionLabel" accordion>
                    <el-collapse-item :name="key" v-for="(item, key) in items" :key="item.id">
                        <template slot="title">
                            <div class="d-flex justify-content-between align-center" style="width: 100%">
                                <div># {{ item.id }}</div>
                                <div>Adet: {{ item.quantity }}</div>
                                <div>Urun Toplami: {{ item.product_total }}</div>
                                <div>Indirim Toplami: {{ item.discount_total }}</div>
                                <div>Vergi Toplami: {{ item.tax_total }}</div>
                                <div>Ekstra Toplami: {{ item.custom_total }}</div>
                                <div>Toplam: {{ item.total }}</div>
                                <div>{{ item.created_at }}</div>
                                <div v-html="item.status_badge"></div>
                            </div>
                        </template>

                        <table class="el-table">
                            <thead>
                            <tr>
                                <th>Urun</th>
                                <th width="100">Fiyat</th>
                                <th width="100">Indirim</th>
                                <th width="150">Adet</th>
                                <th width="250">Ekstra Ucret</th>
                                <th width="100">Toplam</th>
                                <th width="1"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(product, key) in item.products">
                                <td style="padding-right: 20px;">
                                    {{ product.title }}
                                </td>
                                <td style="padding-right: 20px;">
                                    {{ $n(product.price, 'currency') }}
                                </td>
                                <td style="padding-right: 20px;">
                                    {{ product.quantity }}
                                </td>
                                <td style="padding-right: 20px;">
                                    {{ $n(product.discount, 'currency') }}
                                </td>
                                <td style="padding-right: 20px">
                                    {{ $n(product.custom_price, 'currency') }}
                                </td>
                                <td style="padding-right: 20px;">
                                    {{ $n(product.total, 'currency') }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>

        <el-dialog :title="modalTitle" :visible.sync="modalVisible" v-loading="modalLoading" width="90%">
            <el-form :model="form">

                <el-form-item label="Durum">
                    <template>
                        <el-select v-model="form.status_id" value-key="id" placeholder="Durum">
                            <el-option v-for="(item,key) in statuses" :key="item.id" :label="item.title"
                                       :value="item.id"/>
                        </el-select>
                    </template>
                </el-form-item>

                <table class="el-table">
                    <thead>
                    <tr>
                        <th>Urun</th>
                        <th width="150">Adet</th>
                        <th width="100">Fiyat</th>
                        <th width="100">Indirim</th>
                        <th width="250">Ekstra Ucret</th>
                        <th width="100">Toplam</th>
                        <th width="1"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(product, key) in form.products">
                        <td style="padding-right: 20px; font-size: 18px; font-weight: 700;">{{ product.title }}</td>
                        <td style="padding-right: 20px">
                            <el-input-number v-model="product.quantity" :min="1" placeholder="Adet" @change="calculateProduct(key, product)"/>
                        </td>
                        <td style="padding-right: 20px; font-size: 18px; font-weight: 700;">{{ $n(product.price, 'currency') }}</td>
                        <td style="padding-right: 20px; font-size: 18px; font-weight: 700;">{{ $n(product.discount_price, 'currency') }}</td>
                        <td style="padding-right: 20px">
                            <el-input v-model="product.custom_price" placeholder="Ekstra Ucret" @change="calculateProduct(key, product)"/>
                        </td>
                        <td style="padding-right: 20px; font-size: 22px; font-weight: 700;" class="text-color-success">{{ $n(product.total, 'currency') }}</td>
                        <td style="padding-right: 20px">
                            <el-button type="danger" icon="el-icon-delete" size="mini" @click="destroyProduct(key)"/>
                        </td>
                    </tr>

                    </tbody>
                    <tfoot>
                    <tr style="font-size: 16px">
                        <td colspan="5" style="text-align: right; padding-right: 20px">
                            <b>Adet :</b>
                        </td>
                        <td>{{ form.quantity }}</td>
                        <td></td>
                    </tr>
                    <tr style="font-size: 16px">
                        <td colspan="5" style="text-align: right; padding-right: 20px">
                            <b>Urun Toplami :</b>
                        </td>
                        <td>{{ $n(form.product_total, 'currency') }}</td>
                        <td></td>
                    </tr>
                    <tr style="font-size: 16px">
                        <td colspan="5" style="text-align: right; padding-right: 20px">
                            <b>Indirim Toplami :</b>
                        </td>
                        <td>{{ $n(form.discount_total, 'currency') }}</td>
                        <td></td>
                    </tr>
                    <tr style="font-size: 16px">
                        <td colspan="5" style="text-align: right; padding-right: 20px">
                            <b>Vergi Toplami :</b>
                        </td>
                        <td>{{ $n((!form.tax_total ? form.tax_total : 0), 'currency') }}</td>
                        <td></td>
                    </tr>
                    <tr style="font-size: 16px">
                        <td colspan="5" style="text-align: right; padding-right: 20px">
                            <b>Ekstra Toplami :</b>
                        </td>
                        <td>{{ $n(form.custom_total ? form.custom_total : 0, 'currency') }}</td>
                        <td></td>
                    </tr>
                    <tr style="font-size: 18px">
                        <td colspan="5" style="text-align: right; padding-right: 22px" class="text-color-success">
                            <b>Toplam :</b>
                        </td>
                        <td>{{ $n(form.total, 'currency') }}</td>
                        <td></td>
                    </tr>
                    </tfoot>
                </table>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="storeItem" v-if="!form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        name: 'OrderReturns',
        props: ['url', 'order'],
        data() {
            return {
                itemLoading: true,
                modalLoading: false,
                modalVisible: false,
                accordionLabel: 0,
                modalTitle: 'Ekle',
                statuses: [],
                items: [],
                form: {
                    fake_url: 'v1/' + this.url,
                    quantity: 1,
                    product_total: 0,
                    discount_total: 0,
                    tax_total: 0,
                    custom_total: 0,
                    total: 0,
                    products: [],
                },
            }
        },
        mounted() {
            this.index();

            this.getStatuses();
        },
        methods: {
            index() {
                this.$http.get(this.url + '/returns', {
                    params: {
                        fake_url: 'v1/' + this.url
                    }
                }).then(response => {
                    this.items = response.body.rows;
                    this.itemLoading = false;
                });
            },

            crateItem() {
                this.modalTitle = 'Ekle';
                this.modalVisible = true;
                this.form = {
                    fake_url: 'v1/' + this.url,
                    quantity: 1,
                    product_total: 0,
                    discount_total: 0,
                    tax_total: 0,
                    custom_total: 0,
                    total: 0,
                    products: this.order.products,
                };

                this.calculateTotal();
            },

            storeItem() {

                this.modalLoading = true;

                this.$http.post(this.url + '/returns', this.form)
                    .then(response => {
                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.index();

                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.modalLoading = false;

                        this.apiErrorMessage(e);
                    });
            },


            destroyItem(id) {
                this.modalLoading = false;
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete(this.url + '/returns/' + id, {
                        fake_url: 'v1/' + this.url
                    }).then(response => {
                        this.modalLoading = false;
                        this.modalVisible = false;
                        this.itemLoading = false;

                        this.index();

                        this.apiSuccessMessage(response);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.modalLoading = false;
                    this.itemLoading = false;
                });
            },

            destroyProduct(key) {
                this.form.products.splice(key, 1);
            },

            getStatuses() {
                this.itemLoading = true;
                this.$http.get('system/statuses?alias=order-returns').then(response => {
                    this.itemLoading = false;
                    this.statuses = response.body.rows;
                });
            },

            calculateProduct(key, product) {
                let realProduct = this.products[key];
                let perDiscount = parseFloat(realProduct.discount_price) / parseInt(realProduct.quantity);

                product.total = parseFloat(product.price) - (perDiscount * parseInt(product.quantity)) - parseFloat(product.custom_price);

                this.form.products[key] = product;

                this.calculateTotal();
            },

            calculateTotal() {
                let quantity = 0;
                let product_total = 0;
                let vehicle_total = 0;
                let discount_total = 0;
                let tax_total = 0;
                let custom_total = 0;
                let total = 0;

                this.form.products.forEach(function (product) {
                    quantity += parseInt(product.quantity);
                    product_total += parseFloat(product.price);
                    discount_total += parseFloat(product.discount_price);
                    custom_total += parseFloat(product.custom_price);
                    total += parseFloat(product.total);
                });

                this.form.quantity = quantity;
                this.form.product_total = product_total;
                this.form.vehicle_total = vehicle_total;
                this.form.discount_total = discount_total;
                this.form.tax_total = tax_total;
                this.form.custom_total = custom_total;
                this.form.total = total;
            }
        }
    }
</script>