<template>
	<div>
		<el-select v-model="container[model]" :multiple="multiple" filterable remote reserve-keyword :placeholder="placeholder ? placeholder : 'Seçiminiz'" clearable :remote-method="remoteMethod" :loading="loading" @change="handleChange">
			<el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id" />
		</el-select>
	</div>
</template>

<script>
    export default {
        name: 'select-status',
        props: ['container', 'model', 'alias', 'label', 'multiple', 'change', 'placeholder'],
        data() {
            return {
                loading: false,
                options: [],
                list: [],
            }
        },
        mounted() {
            this.list = this.$store.state.ui.statuses[this.alias];

            this.setCurrentOptions();
        },

        methods: {
            handleChange(e) {
                if (typeof this.change === 'function') {
                    return this.change(e)
				}
            },

            remoteMethod(query) {
                if (query === '') {
                    return this.setCurrentOptions();
                }

                this.loading = true;

                setTimeout(() => {
                    this.loading = false;

                    let results = this.list.filter(item => {
                        return item.title.toLowerCase().indexOf(query.toLowerCase()) > -1;
                    });

                    this.options = _.slice(results, 0, 15);

                }, 500);
            },

            setCurrentOptions() {

                this.options = [];

                let formitem = _.filter(this.list, (o) => {

                    if (typeof this.container[this.model] === 'array') {
                        console.log('array')
					} else {
                        return o.id === this.container[this.model];
					}
                });

                if (_.size(formitem)) {
                    this.options.push(formitem[0]);
                }

                if (_.size(this.list) < 50) {
                    this.options = this.list;
				}
            }
        }
    }
</script>