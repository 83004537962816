<template>
    <div class="text-right" style="display: flex; align-items: center">

        <PlatformMerge :student="order.student" v-if="order.id" />

        <div class="ui icon buttons large">
            <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                <div class="ui button" @click="$router.go(-1)">
                    <i class="icon el-icon-back"></i>
                </div>
            </el-tooltip>

            <el-tooltip content="Kayit Formu Pdf" effect="dark" placement="bottom">
                <div class="ui button" @click="openRegisterContract">
                    <i class="icon el-icon-printer"></i>
                </div>
            </el-tooltip>

            <el-tooltip content="Odeme Plani Pdf" effect="dark" placement="bottom">
                <div class="ui button" @click="openPaymentContract">
                    <i class="icon el-icon-printer"></i>
                </div>
            </el-tooltip>

            <el-tooltip content="Sozlesme Pdf" effect="dark" placement="bottom">
                <div class="ui button" @click="openContract">
                    <i class="icon el-icon-printer"></i>
                </div>
            </el-tooltip>

            <el-tooltip :content="!order.accounting_status ? 'Muhasebe Programina Aktar' : 'Daha onceden muhasebe programina aktarim yapmissiniz!'" effect="dark" placement="bottom">
                <div class="ui button" @click="modalAccountingVisible = true":class="{green : order.accounting_status}">
                    <i class="icon el-icon-s-promotion"></i>
                </div>
            </el-tooltip>

            <el-dialog title="Muhasebe Programina Aktar" :visible.sync="modalAccountingVisible" v-loading="itemLoading">
                <el-form :model="form">
                    <div class="ui icon message yellow">
                        <i class="icon el-icon-warning"></i>
                        <div class="content">
                            <p>
                                Girdiginiz siparis sayisi kadar siparis Muhasebe programina gonderilir. <br>
                                Eger duzeltme islemi yapacaksaniz. Ilgili siparisleri muhasebe programindan silmeyi unutmayin.
                            </p>
                        </div>
                    </div>
                    <el-form-item label="Siparis Sayisi">
                        <el-input v-model="form.order_counter" placeholder="Siparis Sayisi"/>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="success" @click="sendAccounting" class="fluid">
                        <i class="mdi mdi-check"></i> Gonder
                    </el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import PlatformMerge from "@/components/PlatformMerge";

    export default {
        name: 'Buttons',
        components: {PlatformMerge},
        props: ['order'],
        data() {
            return {
                itemLoading: null,
                modalAccountingVisible: null,
                form: {
                    process: 'accounting'
                },
            }
        },

        methods: {
            openContract() {
                return window.open(this.appConfig.frontPath + '/prints/orders/' + this.order.crypt_id, '_blank');
            },

            openContractSummer() {
                return window.open(this.appConfig.frontPath + '/prints/orders/' + this.order.crypt_id + '?type=summer', '_blank');
            },

            openRegisterContract() {
                return window.open(this.appConfig.frontPath + '/prints/orders/' + this.order.crypt_id + '?view=register' , '_blank');
            },

            openPaymentContract() {
                return window.open(this.appConfig.frontPath + '/prints/payments/' + this.order.crypt_id, '_blank');
            },

            sendAccounting() {
                this.itemLoading = true;

                this.$confirm('Siparisi muhasebe programina aktarmak istediginizden emin misiniz?', 'Uyari', {
                    confirmButtonText: 'Tamam',
                    cancelButtonText: 'Iptal Et',
                    type: 'warning'
                })
                    .then(() => {

                        this.$http
                            .put(this.$route.meta.end_point + '/' + this.order.id, this.form)
                            .then(response => {
                                this.apiSuccessMessage(response);

                                this.itemLoading = false;
                                this.modalAccountingVisible = false;
                            })
                            .catch((e) => {

                                this.apiErrorMessage(e);

                                this.itemLoading = false;
                            });

                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            },

            sendPlatform() {
                console.log(this.order.student);


            }

        }
    }
</script>
