import layouts from '../config/layout'

import MasterContainer from '../app/Container/Master'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Dt'

import Option from '../app/Pages/Catalog/Options/Show'
import Feature from '../app/Pages/Catalog/Features/Show'
import Brand from '../app/Pages/Catalog/Brand'
import Category from '../app/Pages/Cms/Category'
import Product from '../app/Pages/Catalog/Products/Show'

export default {
    path: '/catalog',
    name: 'catalog',
    component: MasterContainer,
    meta: {
        auth: true,
        layout: layouts.navLeft,
        title: 'Market',
    },
    children: [
        {
            path: 'options',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Secenekler',
            },
            children: [
                {
                    name: 'catalog.options.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Secenekler',
                        path: 'options',
                        end_point: 'cms/options',
                    },
                },
                {
                    name: 'catalog.options.create',
                    path: 'create',
                    component: Option,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Secenek Olustur',
                        path: 'options',
                        end_point: 'cms/options',
                    },
                },
                {
                    name: 'catalog.options.edit',
                    path: 'edit/:id',
                    component: Option,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Secenek Bilgileri',
                        path: 'options',
                        end_point: 'cms/options',
                    },
                }
            ]
        },
        {
            path: 'features',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Ozellikler',
            },
            children: [
                {
                    name: 'catalog.features.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Ozellikler',
                        path: 'features',
                        end_point: 'cms/features',
                    },
                },
                {
                    name: 'catalog.features.create',
                    path: 'create',
                    component: Feature,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Ozellik Olustur',
                        path: 'features',
                        end_point: 'cms/features',
                    },
                },
                {
                    name: 'catalog.features.edit',
                    path: 'edit/:id',
                    component: Feature,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Ozellik Bilgileri',
                        path: 'features',
                        end_point: 'cms/features',
                    },
                }
            ]
        },
        {
            path: 'brands',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Markalar',
            },
            children: [
                {
                    name: 'catalog.brands.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Markalar',
                        path: 'brands',
                        end_point: 'catalog/brands',
                    },
                },
                {
                    name: 'catalog.brands.create',
                    path: 'create',
                    component: Brand,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Marka Olustur',
                        path: 'brands',
                        end_point: 'catalog/brands',
                    },
                },
                {
                    name: 'catalog.brands.edit',
                    path: 'edit/:id',
                    component: Brand,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Marka Bilgileri',
                        path: 'brands',
                        end_point: 'catalog/brands',
                    },
                }
            ]
        },
        {
            path: 'categories',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Kategoriler',
            },
            children: [
                {
                    name: 'catalog.categories.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Kategoriler',
                        path: 'categories',
                        end_point: 'catalog/categories',
                    },
                },
                {
                    name: 'catalog.categories.create',
                    path: 'create',
                    component: Category,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Kategori Olustur',
                        path: 'categories',
                        end_point: 'catalog/categories',
                    },
                },
                {
                    name: 'catalog.categories.edit',
                    path: 'edit/:id',
                    component: Category,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Kategori Bilgileri',
                        path: 'categories',
                        end_point: 'catalog/categories',
                    },
                }
            ]
        },
        {
            path: 'products',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Urunler',
            },
            children: [
                {
                    name: 'catalog.products.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Urunler',
                        path: 'products',
                        end_point: 'catalog/products',
                    },
                },
                {
                    name: 'catalog.products.create',
                    path: 'create',
                    component: Product,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Urun Olustur',
                        path: 'products',
                        end_point: 'catalog/products',
                    },
                },
                {
                    name: 'catalog.products.edit',
                    path: 'edit/:id',
                    component: Product,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Urun Bilgileri',
                        path: 'products',
                        end_point: 'catalog/products',
                    },
                }
            ]
        },
    ]
}
