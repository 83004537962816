import layouts from '../config/layout'

import MasterContainer from '../app/Container/Master'

import Event from '../app/Pages/Cards/Event'
import MarketingBoard from '../app/Pages/Cards/Marketing/Board'
import MarketingPipeline from '../app/Pages/Cards/Marketing/Pipeline'

export default {
    path: '/cards',
    name: 'cards',
    component: MasterContainer,
    meta: {
        auth: true,
        layout: layouts.navLeft,
        title: 'Kartlar',
    },
    children: [
        {
            path: 'events',
            component: Event,
            props: {
                source: 'calendar/events',
            },
            meta: {
                groupId: 1,
                pipelineId: null,
                layout: layouts.navLeft,
                title: 'Etkinlikler',
            }
        },
        {
            path: 'marketing',
            name: 'cards.marketing',
            component: MasterContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Pazarlama Yonetimi',
            },
            children: [
                {
                    path: 'board',
                    name: 'cards.marketing.pipeline.index',
                    component: MarketingBoard,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Pazarlama Yonetimi',
                    },
                },
                {
                    path: 'pipelines/:pipelineId',
                    name: 'cards.marketing.pipeline.show',
                    component: MarketingPipeline,
                    props: {
                        source: 'cards/marketing/pipelines/',
                    },
                    meta: {
                        groupId: 1,
                        layout: layouts.navLeft,
                        collapseNav: true,
                        title: 'Pazarlama Yonetimi',
                    }
                },
                {
                    path: 'pipelines/:pipelineId/:cardId',
                    name: 'cards.marketing.pipeline.cards.show',
                    component: MarketingPipeline,
                    props: {
                        source: 'cards/marketing/pipelines/',
                    },
                    meta: {
                        groupId: 1,
                        layout: layouts.navLeft,
                        collapseNav: true,
                        title: 'Kart Bilgileri',
                    }
                },
            ]
        },
    ]
}
