<style lang="scss">

.edit-form {
    display: flex;

    .delete-btn {
        color: #ea4a5f;
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: #dfe3e6;
        box-shadow: 0 1px 0 0 #c2ccd1;

        &:hover {
            background-color: #ea4a5f;
            color: #ffffff;
        }
    }

    .edit-btn {
        color: #13ce66;
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: #dfe3e6;
        box-shadow: 0 1px 0 0 #c2ccd1;
        margin-right: 5px;
        margin-left: 5px;

        &:hover {
            background-color: #13ce66;
            color: #ffffff;
        }
    }
}

.card-detail-container {

    display: flex;
    justify-content: space-between;

    .card-detail-body {
        width: 100%;

        .card-detail-header {
            padding: 35px;
            border-bottom: 1px solid #e4e4e4;
            background: #f9f9f9;

            .title {
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 20px;
                cursor: pointer;
            }

            .description {
                font-size: 14px;
                cursor: pointer;
            }
        }

        .card-detail-content {

        }
    }

    .card-detail-sidebar {
        width: 460px;
        background-color: #f3f7fc;
        border-top-right-radius: 4px;
        border-left: 1px solid #e4e4e4;

        .card-box {
            margin-bottom: 0;

            .card-box-header {
                background: #f3f7fc;
            }
        }
    }
}
</style>

<template>
    <div class="card-detail scrollable" v-loading="itemLoading" style="min-height: 500px">
        <div v-if="form.id && pipeline">
            <section class="card-detail-container">
                <div class="card-detail-body">
                    <header class="card-detail-header">
                        <div class="title" v-if="!titleClickStatus">
                            {{ form.title }}
                        </div>
                        <div v-if="titleClickStatus" class="fluid title">
                            <el-form :model="form" class="edit-form fluid">
                                <div class="input fluid">
                                    <el-input v-model="form.title" placeholder="Baslik"/>
                                </div>
                                <div class="edit-btn" @click="updateItem({ title : form.title })">
                                    <i class="el-icon-check"></i>
                                </div>
                                <div class="delete-btn" @click="titleClickStatus = false">
                                    <i class="el-icon-close"></i>
                                </div>
                            </el-form>
                        </div>
                        <div class="description">
                            <div v-if="!descriptionClickStatus"
                                 v-html="form.description" style="min-height: 60px"></div>
                            <div v-if="descriptionClickStatus">
                                <el-form :model="form" class="edit-form">
                                    <div class="input fluid">
                                        <el-input v-model="form.description" placeholder="Aciklama"/>
                                    </div>
                                    <div class="edit-btn" @click="updateItem({ description : form.description })">
                                        <i class="el-icon-check"></i>
                                    </div>
                                    <div class="delete-btn" @click="descriptionClickStatus = false">
                                        <i class="el-icon-close"></i>
                                    </div>
                                </el-form>
                            </div>
                        </div>
                    </header>
                    <div class="card-detail-content">
                        <Notes :url="'cards/cards/' + form.id" :key="'notes-' + form.id"/>
                    </div>
                </div>
                <div class="card-detail-sidebar">
                    <div class="card-box">
                        <header class="card-box-header">
                            <div class="title">İşlem Hattı</div>
                        </header>
                        <section class="card-box-content">
                            <div class="box-list">
                                <div class="box-list-item">
                                    <div class="name fluid">
                                        <el-form :label-position="'top'" ref="form" :model="form">
                                            <el-form-item class="m-0">
                                                <el-select v-model="form.card_stage_id" placeholder="Roller"
                                                           @change="updateItem({ card_stage_id : form.card_stage_id })">
                                                    <el-option
                                                        v-for="stage in pipeline.stages"
                                                        :key="stage.id"
                                                        :label="stage.title"
                                                        :value="stage.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                </div>
                                <div class="box-list-item">
                                    <el-button
                                        type="success"
                                        class="fluid"
                                        @click="openBlankWindow('/enrollments/interviews?card_id=' + form.id)"
                                    >
                                        Görüşme Başlat
                                    </el-button>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Assignee :cardId="form.id" :key="'assignees-' + form.id"/>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Labels from "./_parts/Labels";
import Assignee from "./_parts/Assignee";
import Items from "./_parts/Items";
import Events from "./_parts/Events";
import Activities from "../../../../components/Activities";
import Notes from "../../../../components/Notes";
import SingleDraft from "../../../../components/SingleDraft";

export default {
    name: 'Card',
    components: {
        SingleDraft,
        Notes,
        Activities,
        Events,
        Items,
        Assignee,
        Labels,
    },
    props: ['source', 'cardId', 'pipelineId'],

    data() {
        return {
            itemLoading: true,
            noteLoading: true,
            eventLoading: true,
            logLoading: true,
            searchLoading: false,
            pipelines: null,
            pipeline: null,
            form: {
                id: null,
                customer_id: null,
            },

            titleClickStatus: false,
            descriptionClickStatus: false,

            customerSearchItems: [],

            logForm: {
                //
            },

            logs: [],
        }
    },

    methods: {
        getPipelines() {
            this.itemLoading = true;

            this.$http
                .get('cards/pipelines', {
                    params: {
                        card_group_id: 1
                    }
                })
                .then(response => {
                    this.itemLoading = false;
                    this.pipelines = response.body.items;
                });
        },

        getPipeline() {
            this.itemLoading = true;

            this.$http
                .get('cards/pipelines/' + this.pipelineId, {
                    params: {
                        card_group_id: 1
                    }
                })
                .then(response => {
                    this.itemLoading = false;
                    this.pipeline = response.body.item;
                });
        },

        getItem() {
            this.itemLoading = true;

            this.$http.get('cards/cards/' + this.cardId)
                .then(response => {
                    this.itemLoading = false;
                    this.form = response.body.item;
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        updateItem(form) {
            this.itemLoading = true;

            this.$http
                .put('cards/cards/' + this.form.id, form)
                .then(response => {
                    this.itemLoading = false;

                    this.form = response.body.item;

                    if (this.form.card.stage.card_pipeline_id !== this.pipelineId) {
                        this.$router.push('/cards/marketing/pipelines/' + this.form.card.stage.card_pipeline_id + '/' + this.form.id);
                        this.pipelineId = this.form.card.stage.card_pipeline_id;
                    }

                    this.titleClickStatus = false;
                    this.descriptionClickStatus = false;

                    this.apiSuccessMessage(response);

                    this.getLogs();
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        destroyItem() {
            this.itemLoading = true;

            this.$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                confirmButtonText: 'Tamam',
                cancelButtonText: 'Iptal Et',
                type: 'warning'
            })
                .then(() => {
                    this.$http
                        .delete('cards/cards/' + this.form.id)
                        .then(response => {
                            this.itemLoading = false;

                            this.$emit('update:cardModalVisible', null);
                            this.$emit('update:visible', null);

                            this.$router.push('/cards/marketing/pipelines/' + this.pipeline.id);

                            this.apiSuccessMessage(response)
                        })
                        .catch((e) => {
                            this.itemLoading = false;
                            this.apiErrorMessage(e);
                        });
                })
                .catch(() => {
                    this.itemLoading = false;
                });
        },

        changePipeline(pipeline) {
            this.pipeline = pipeline;
        },

        customerSearch(query) {
            this.searchLoading = true;

            this.$http
                .get('tools/search/users', {
                    params: {
                        q: query
                    }
                })
                .then(response => {
                    this.searchLoading = false;
                    this.customerSearchItems = response.body.items;
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.searchLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        getLogs() {
            this.logLoading = true;

            this.$http
                .get('tools/activities', {
                    params: {
                        log_type: 'cards',
                        log_type_id: this.form.id,
                        perPage: 50
                    }
                })
                .then(response => {
                    this.logs = response.body.rows;
                    this.logLoading = false;
                });
        },

        findLogIcon(item) {
            let process = item.permission_request_method_name;

            if (process === 'index') {
                return 'route-icon route-icon-color process-icon el-icon-tickets'
            }

            if (process === 'show') {
                return 'route-icon route-icon-color process-icon el-icon-view'
            }

            if (process === 'store') {
                return 'route-icon route-icon-color process-icon el-icon-check'
            }

            if (process === 'update') {
                return 'route-icon route-icon-color process-icon el-icon-edit'
            }

            if (process === 'destroy') {
                return 'route-icon route-icon-color process-icon el-icon-delete'
            }
        }
    },

    mounted() {
        this.itemLoading = false;

        this.getPipelines();
        this.getPipeline();
        this.getItem();
    },
}
</script>
