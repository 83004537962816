<template>
    <vue-scroll class="page-element-form page-draft">
        <div class="loading-container" v-loading="itemLoading">
            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                            <el-button @click="$router.go(-1)" type="text" icon="el-icon-back"
                                       class="el-button-link grey"/>
                        </el-tooltip>

                        <el-tooltip content="Olustur" effect="dark" placement="bottom" v-if="$route.params.id">
                            <el-button @click="createButton($route.meta.end_point)" type="text" icon="el-icon-plus"
                                       class="el-button-link primary"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="!$route.params.id">
                            <el-button @click="storeItem" type="text" icon="el-icon-check"
                                       class="el-button-link green"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="$route.params.id">
                            <el-button @click="updateItem" type="text" icon="el-icon-check"
                                       class="el-button-link green"/>
                        </el-tooltip>
                    </div>
                </header>
                <section class="card-box-content padding-20">
                    <el-form :label-position="'top'" ref="form" :model="form">

                        <el-form-item label="Başlık">
                            <el-input v-model="form.title" placeholder="Başlık" />
                        </el-form-item>

                        <div class="padding-20 bg-primary-lighter margin-bottom-20" style="border-radius: 5px">
                            <span v-for="item in propItems" style="margin-right: 10px">{{ item }}</span>
                        </div>

                        <el-form-item label="Konu">
                            <el-input v-model="form.subject" placeholder="Konu"/>
                        </el-form-item>

                        <el-form-item label="Taslak" v-if="$route.params.type === 'email'" class="big-editor">
                            <trumbowyg v-model="form.content" :config="$store.state.trumbowygConfig"/>
                        </el-form-item>

                        <el-form-item label="Taslak" v-if="$route.params.type !== 'email'">
                            <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6}" placeholder="Taslak" v-model="form.content"/>
                        </el-form-item>

                    </el-form>
                </section>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
    import Trumbowyg from "vue-trumbowyg/src/component";

    export default {
        name: 'ElementForm',
        components: {
            Trumbowyg
        },
        data() {
            return {
                itemLoading: true,
                form: {
                    alias: this.$route.params.type
                },
                propItems: []
            }
        },

        methods: {
            loadItem() {

                this.itemLoading = true;

                this.$http
                    .get(this.$route.meta.end_point + '/' + this.$route.params.id, {
                        params: this.serverParams
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body.item
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            storeItem() {

                this.itemLoading = true;

                this.$http
                    .post(this.$route.meta.end_point, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                        this.$router.push('edit/' + response.body.item.id);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.itemLoading = true;

                this.$http
                    .put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                    confirmButtonText: 'Tamam',
                    cancelButtonText: 'Iptal Et',
                    type: 'warning'
                })
                    .then(() => {
                        this.$http
                            .delete(this.$route.meta.end_point + '/' + id)
                            .then(response => {
                                this.itemLoading = false;

                                this.$router.push('/');

                                this.apiSuccessMessage(response)
                            })
                            .catch((e) => {
                                this.itemLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            },

            getPropItems() {
                this.itemLoading = true;

                this.$http
                    .get('system/settings?type=draft_props')
                    .then(response => {
                        this.itemLoading = false;
                        this.propItems = response.body
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            }
        },

        mounted() {
            if (this.$route.params.id) {
                this.loadItem()
            } else {
                this.itemLoading = false;
            }

            this.getPropItems();
        }
    }
</script>