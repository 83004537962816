<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading">
            <el-row :gutter="24" class="mb-30">
                <el-col :span="8">
                    <div class="card-base card-shadow--medium widget small-widget"
                         style="background-color: #dc3545; color: #fff">
                        <div class="widget-header">
                            <div class="flex justify-center align-center">
                                <div class="widget-info box grow text-truncate">
                                    <div class="widget-title fs-14">
                                        Hatali
                                    </div>
                                    <div class="fs-28">
                                        {{ form.fail_counter }}
                                    </div>
                                </div>
                                <div class="widget-icon-box">
                                    <i class="el-icon-warning-outline widget-icon fs-40"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="card-base card-shadow--medium widget small-widget"
                         style="background-color: #28a745; color: #fff">
                        <div class="widget-header">
                            <div class="flex justify-center align-center">
                                <div class="widget-info box grow text-truncate">
                                    <div class="widget-title fs-14">
                                        Basarili
                                    </div>
                                    <div class="fs-28">
                                        {{ form.completed_counter }}
                                    </div>
                                </div>
                                <div class="widget-icon-box">
                                    <i class="el-icon-check widget-icon fs-40"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="card-base card-shadow--medium widget small-widget"
                         style="background-color: #007bff; color: #fff">
                        <div class="widget-header">
                            <div class="flex justify-center align-center">
                                <div class="widget-info box grow text-truncate">
                                    <div class="widget-title fs-14">
                                        Toplam
                                    </div>
                                    <div class="fs-28">
                                        {{ form.total_counter }}
                                    </div>
                                </div>
                                <div class="widget-icon-box">
                                    <i class="el-icon-position widget-icon fs-40"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>

            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                            <el-button @click="$router.go(-1)" type="text" icon="el-icon-back"
                                       class="el-button-link grey"/>
                        </el-tooltip>

                        <el-tooltip content="Raporlari Tekrar Al" effect="dark" placement="bottom">
                            <el-button @click="refreshReport" icon="el-icon-refresh" type="text"
                                       class="el-button-link green"/>
                        </el-tooltip>

                        <el-tooltip content="Gonderilmeyen Mesajlari Tekrar Gonder" effect="dark" placement="bottom">
                            <el-button @click="reSend" icon="el-icon-position" type="text"
                                       class="el-button-link primary"/>
                        </el-tooltip>
                    </div>
                </header>
                <section class="card-box-content padding-20">
                    <vue-good-table
                            mode="remote"
                            :totalRows="pagination.total"
                            :rows="rows"
                            :columns="columns"
                            :pagination-options="paginationOptions"
                            :line-numbers="true"

                            @on-sort-change="onSortChange"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            @on-column-filter="onColumnFilter"

                            v-if="form.id"
                    >

                        <template slot="table-row" slot-scope="props">

                            <div v-if="props.column.field === 'action'" class="text-right">
                                <div class="d-flex" style="justify-content: end" v-if="props.row.status !== 'DELIVERED'">
                                    <el-tooltip content="Raporlar Al" effect="dark" placement="bottom">
                                        <el-button @click="refreshReportItem(props.row)"
                                                   icon="el-icon-refresh"
                                                   type="text"
                                                   class="el-button-link green"/>
                                    </el-tooltip>

                                    <el-tooltip content="Tekrar Gonder" effect="dark" placement="bottom">
                                        <el-button @click="reSendItem(props.row)"
                                                    icon="el-icon-position"
                                                   type="text" class="el-button-link primary"/>
                                    </el-tooltip>

                                </div>
                                <div v-if="props.row.status === 'DELIVERED'" class="text-center">
                                    <div class="ui badge" style="background: rgba(40,167,69,0.2); padding: 5px 10px; color: #28a745">
                                        Gonderildi
                                    </div>
                                </div>
                            </div>

                            <div v-if="props.column.field === 'receiver'">
                                <el-popover
                                        placement="bottom-start"
                                        title="Servis Raporu"
                                        width="600"
                                        trigger="hover"
                                        >
                                    <div slot="reference">
                                        <div>
                                            {{ props.row.receiver }}
                                        </div>
                                        <div>
                                            Giden Rapor:  <span v-html="props.row.service_send_response"></span>
                                        </div>
                                    </div>
                                    <div>
                                        {{ props.row.service_report_response }}
                                    </div>
                                </el-popover>
                            </div>

                            <div v-if="props.column.field === 'status'">
                                <span v-if="!props.row.status">
                                    Servis Raporu Bekleniyor
                                </span>
                                <span v-if="props.row.status">
                                    {{ props.row.status }}
                                </span>
                            </div>

                            <div v-if="props.column.field === 'created_at'">
                                <div>
                                    {{ props.row.created_at }}
                                </div>
                                <div>
                                    {{ props.row.updated_at }}
                                </div>
                            </div>
                        </template>
                    </vue-good-table>
                </section>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
    import Datatable from "../../../components/Datatable";

    export default {
        name: 'ElementForm',
        components: {Datatable},
        data() {
            return {
                itemLoading: true,
                form: {
                    //
                },

                columns: [
                    {
                        label: 'Alici',
                        field: 'receiver',
                    },
                    {
                        label: 'Durum',
                        field: 'status',
                    },
                    {
                        label: 'Tarih',
                        field: 'created_at',
                    },
                    {
                        label: null,
                        field: 'action',
                        tdClass: 'text-right',
                        thClass: 'width-1'
                    }
                ],
                rows: [],
                pagination: [],

                paginationOptions: {
                    enabled: true,
                    mode: 'pages',
                    perPage: 30,
                    position: 'bottom',
                    perPageDropdown: [30, 50, 100],
                    dropdownAllowAll: false,
                    nextLabel: 'Sonraki',
                    prevLabel: 'Önceki',
                    rowsPerPageLabel: 'Satır sayısı',
                    ofLabel: 'görüntüleniyor. Toplam sayfa sayısı:',
                    pageLabel: 'Sayfa',
                    allLabel: 'Tümü',
                },

                serverParams: {
                    sorts: {
                        created_at: 'desc'
                    },
                    columnFilters: {},
                    filters: [
                        {
                            relation: null,
                            inputs: [
                                {
                                    name: 'submission_group_id',
                                    field: 'submission_group_id',
                                    type: 'singleSelect',
                                    value: null
                                }
                            ]
                        },
                    ],
                    page: 1,
                    perPage: 10
                }
            }
        },

        methods: {
            loadItem() {

                this.itemLoading = true;
                this.serverParams.filters[0].inputs[0].value = this.$route.params.id;

                this.$http
                    .get(this.$route.meta.end_point + '/' + this.$route.params.id, {
                        //params: this.serverParams
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body.item;

                        this.loadItems()
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            refreshReport() {

                this.itemLoading = true;

                this.$http
                    .put(this.$route.meta.end_point + '/' + this.$route.params.id, {
                        process: 'reports'
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);

                        this.form = response.body.item;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            reSend() {
                this.itemLoading = true;

                this.$http
                    .put(this.$route.meta.end_point + '/' + this.$route.params.id, {
                        process: 'reSend'
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);

                        this.form = response.body.item;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            refreshReportItem(item) {

                this.itemLoading = true;

                this.$http
                    .put('marketing/submissions/messages/' + item.id, {
                        process: 'reports'
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);

                        item = response.body.item;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            reSendItem(item) {
                this.itemLoading = true;

                this.$http
                    .put('marketing/submissions/messages/' + item.id, {
                        process: 'reSend'
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);

                        item = response.body.item;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            loadItems() {

                if (!this.serverParams.filters[0].inputs[0].value) {
                    return false;
                }

                this.$http
                    .get('marketing/submissions/messages', {
                        params: this.serverParams
                    })
                    .then(response => {
                        this.loading = false;
                        this.rows = response.body.rows;
                        this.pagination = response.body.pagination;
                    })
                    .catch((e) => {
                        this.loading = false;
                        this.columns = [];
                        this.rows = [];
                        this.pagination = {};
                        this.apiErrorMessage(e);
                    });
            },

            onSortChange(params) {

                let sorts = this.serverParams.sorts;

                _.forEach(params, function (item, key) {
                    sorts[item.field] = item.type;
                });

                this.serverParams.sorts = sorts;

                this.loadItems();
            },

            onPageChange(params) {
                this.serverParams.page = params.currentPage;

                this.loadItems();
            },

            onPerPageChange(params) {

                this.serverParams.perPage = params.currentPerPage;

                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
        },

        mounted() {
            this.loadItem();
        }
    }
</script>