<style lang="scss" scoped>
    .el-upload-list--picture-card .el-upload-list__item {
        width: 100% !important;
        height: 275px !important;
    }

    .el-upload-list--picture-card .el-upload-list__item-actions {
        background-color: rgba(17, 17, 17, .2);
    }
</style>

<template>
    <div>
        <div class="el-upload-list el-upload-list--picture-card" v-if="!multiple">
            <div class="el-upload-list__item" :style="{height: small ? '130px !important' : '275px !important'}">
                <img src="@/resources/images/dummy.png" alt="" class="el-upload-list__item-thumbnail" v-if="!value">
                <img :src="appConfig.frontPath + '/' + value" alt="" class="el-upload-list__item-thumbnail"
                     v-if="value">

                <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="showFileManager">
                        <el-button type="primary" icon="el-icon-edit" :size="small ? 'small' : null"/>
                    </span>
                    <span class="el-upload-list__item-delete text-color-danger" @click="destroyItem()">
                        <el-button type="danger" icon="el-icon-delete" :size="small ? 'small' : null" />
                    </span>
                </span>
            </div>
        </div>

        <el-button type="primary" @click="fileManagerVisible = true" style="width: 100%" v-if="multiple">
            <i class="fa fa-search"></i> Dosya Yoneticisi
        </el-button>

        <el-dialog width="90%" title="Dosya Yöneticisi" :visible.sync="fileManagerVisible" append-to-body class="file-manager-dialog">

            <file-manager :settings="fileManagerSettings" />

            <div class="fs-actions">
                <el-row :gutter="24">
                    <el-col :span="18">
                        <div v-for="(item, key) in value" v-if="multiple" :key="'file-browser-show-item-' + key" style="display: flex; justify-content: space-between; align-items: center; border-bottom: 1px solid #ccc; padding-bottom: 10px; margin-bottom: 10px">
                            <div>{{ item.path }}</div>
                            <el-button type="danger" icon="el-icon-delete" circle @click="destroyMultiItem(key)"></el-button>
                        </div>
                    </el-col>
                    <el-col :span="multiple ? 6 : 24" class="multiple-button">
                        <button type="button" class="btn btn-primary" title="OK" v-on:click="singleSelect" v-if="!multiple">
                            <i class="fa fa-check-circle"></i> Dosyayi Sec
                        </button>

                        <button type="button" class="btn btn-primary" title="OK" v-on:click="multiSelect" v-if="multiple">
                            <i class="fa fa-check-circle"></i> Dosyayi Sec
                        </button>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        name: 'file-browser',
        props: [
            'value',
            'multiple',
            'url',
            'addingValue',
            'small',
        ],
        data() {
            return {
                fileManagerVisible: false,
                form: {},
                files: {}
            }
        },
        methods: {
            updateValue: function (value) {
                this.$emit('input', value);
            },
            singleSelect() {

                let files = this.$store.state.fm.left.selected.files;

                if (!_.size(files)) {

                    this.$notify.error({
                        title: 'Upss!',
                        message: 'Dosya secimi yapmaniz gerekiyor!',
                        position: 'top-right'
                    });

                    return false;
                }

                this.updateValue('storage/' + files[0])

                this.fileManagerVisible = false;

                this.$store.commit('fm/left/resetSelected');
            },

            destroyItem() {
                this.value = null;
            },

            multiSelect() {
                let files = this.$store.state.fm.left.selected.files;

                if (!_.size(files)) {

                    this.$notify.error({
                        title: 'Upss!',
                        message: 'Dosya secimi yapmaniz gerekiyor!',
                        position: 'top-right'
                    });

                    return false;
                }

                let propFiles = _.size(this.value) ? this.value : [];

                files.forEach(function (item) {
                    const file = {
                        path: 'storage/' + item,
                        title: '',
                        description: '',
                        sort: 100,
                    };

                    propFiles.push(file);
                });

                this.updateValue(propFiles);

                this.files = propFiles;

                this.$store.commit('fm/left/resetSelected');
            },

            destroyMultiItem(key) {
                this.files.splice(key, 1);
            },

            showFileManager() {
                this.fileManagerVisible = true;
            }
        }
    }
</script>
