<template>
    <vue-scroll class="page-element-form" v-loading="itemLoading">
        <div class="loading-container">
            <div v-if="form.id">
                <div class="card-box">
                    <header class="card-box-header">
                        <div class="title">
                            {{ form.first_name }} {{ form.last_name }}
                            <span v-if="form.gender">({{ form.gender.title }})</span>
                        </div>
                        <div class="actions">
                            <PlatformMerge :student="form" v-if="form.id" />
                            <el-button icon="el-icon-edit" type="text" class="el-button-link green" @click="modalStudentVisible = true"/>
                        </div>
                    </header>
                    <section class="card-box-content">
                        <div class="information-header">
                            <div class="media">
                                <div>
                                    <avatar :img="form.avatar" type="student" />
                                </div>
                            </div>
                            <div class="text">
                                <div class="rate mb-10">
                                    <el-rate v-model="form.loyalty_year > 3 ? 3 : form.loyalty_year" :max="3" disabled />
                                </div>
                                <div class="email fs-14">
                                    {{ form.identification_no }}
                                </div>
                                <div class="date  fs-14" v-if="form.birthday">
                                    {{ form.birthday }}
                                </div>
                            </div>
                        </div>
                        <div class="box-list">
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <div class="box-list-item">
                                        <div class="name">
                                            Kan Grubu
                                        </div>
                                        <div class="description">
                                            <span v-if="form.blood">
                                                {{ form.blood.title }}
                                            </span>
                                            <span v-if="!form.blood">
                                                <i class="mdi mdi-emoticon-sad empty-data-icon text-color-danger"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Saglik Problemleri
                                        </div>
                                        <div class="description">
                                            <span v-if="form.health_problems">
                                                {{ form.health_problems }}
                                            </span>
                                            <span v-if="!form.health_problems">
                                                ...
                                            </span>
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Surekli Kullandigi Ilaclar
                                        </div>
                                        <div class="description">
                                            <span v-if="form.using_pills">
                                                {{ form.using_pills }}
                                            </span>
                                            <span v-if="!form.using_pills">
                                                ...
                                            </span>
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Alerjisi
                                        </div>
                                        <div class="description">
                                            <span v-if="form.allergy">
                                                {{ form.allergy }}
                                            </span>
                                            <span v-if="!form.allergy">
                                                ...
                                            </span>
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Kisisel Bilgilerini Paylas?
                                        </div>
                                        <div class="description">
                                            <i class="el-icon-check text-color-success" v-if="form.is_share"></i>
                                            <i class="mdi mdi-cancel text-color-danger" v-if="!form.is_share"></i>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div class="box-list-item">
                                        <div class="name">
                                            Donem
                                        </div>
                                        <div class="description">
                                            <span v-if="form.school_period">
                                                {{ form.school_period.title }}
                                            </span>
                                            <span v-if="!form.school_period">
                                                <i class="mdi mdi-emoticon-sad empty-data-icon text-color-danger"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Okul
                                        </div>
                                        <div class="description">
                                            <span v-if="form.school">
                                                {{ form.school.title }}
                                            </span>
                                            <span v-if="!form.school">
                                                <i class="mdi mdi-emoticon-sad empty-data-icon text-color-danger"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Sinif
                                        </div>
                                        <div class="description">
                                            <span v-if="form.school_class">
                                                {{ form.school_class.title }}
                                            </span>
                                            <span v-if="!form.school_class">
                                                <i class="mdi mdi-emoticon-sad empty-data-icon text-color-danger"></i>
                                            </span>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </section>
                </div>
                <el-row :gutter="24">
                    <el-col :span="16">
                        <Orders :source="$route.meta.end_point + '/' + form.id" />
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <QuizReport :source="$route.meta.end_point + '/' + form.id" />
                            </el-col>
                            <el-col :span="12">
                                <MonthlyReport :source="$route.meta.end_point + '/' + form.id" />
                            </el-col>
                        </el-row>

                        <Notes :url="'crm/accounts/' + form.account_id" :key="form.account_id"/>
                    </el-col>
                    <el-col :span="8">
                        <Parents :source="$route.meta.end_point + '/' +  form.id" :accountId="form.account_id" />
                        <Contacts :source="$route.meta.end_point + '/' +  form.id" :accountId="form.account_id" />
                    </el-col>
                </el-row>
            </div>
        </div>

        <el-dialog :title="form.first_name + ' ' + form.last_name" :visible.sync="modalStudentVisible" v-loading="itemLoading" width="80%">
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-row :gutter="24">
                    <el-col :span="18">

                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="T.C. Kimlik No" prop="identification_no">
                                    <el-input v-model="form.identification_no" placeholder="T.C. Kimlik No" v-mask="'###########'"/>
                                </el-form-item>
                            </el-col>

                            <el-col :span="12">
                                <el-form-item label="Cinsiyet">
                                    <select-definition label="Cinsiyet" alias="genders" model="gender_definition_id" :container="form"/>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="Adi">
                                    <el-input v-model="form.first_name" placeholder="Adi"/>
                                </el-form-item>
                            </el-col>

                            <el-col :span="12">
                                <el-form-item label="Soyadi">
                                    <el-input v-model="form.last_name" placeholder="Soyadi"/>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="Dogum Yeri Ulke">
                                    <template>
                                        <el-select v-model="form.birthplace_country_zone_id" value-key="id"
                                                   placeholder="Ulke" @change="selectedCountry" clearable filterable>
                                            <el-option v-for="(item, key) in countries" :key="item.id"
                                                       :label="item.title" :value="item.id"/>
                                        </el-select>
                                    </template>
                                </el-form-item>
                            </el-col>

                            <el-col :span="12">
                                <el-form-item label="Dogum Yeri Sehir">
                                    <template>
                                        <el-select v-model="form.birthplace_city_zone_id" value-key="id"
                                                   placeholder="Sehir" clearable filterable>
                                            <el-option v-for="(item, key) in cities" :key="item.id"
                                                       :label="item.title" :value="item.id"/>
                                        </el-select>
                                    </template>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-form-item label="Dogum Tarihi">
                            <el-date-picker v-model="form.birthday" type="date" placeholder="Tarih Seçiniz"
                                            value-format="yyyy-MM-dd"/>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item label="Fotograf" style="margin: 0">
                            <file-browser v-model="form.avatar"/>
                        </el-form-item>

                        <div class="text-center">
                            <el-rate v-model="form.loyalty_year > 3 ? 3 : form.loyalty_year" :max="3" disabled />
                        </div>

                    </el-col>
                </el-row>

                <el-form-item label="Kan Grubu">
                    <select-definition label="Kan Grubu" alias="blood-groups" model="blood_group_definition_id" :container="form"/>
                </el-form-item>

                <el-form-item label="Saglik Problemleri">
                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="Saglik Problemleri"
                              v-model="form.health_problems"/>
                </el-form-item>

                <el-form-item label="Surekli Kullandigi Ilaclar">
                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}"
                              placeholder="Surekli Kullandigi Ilaclar" v-model="form.using_pills"/>
                </el-form-item>

                <el-form-item label="Alerjisi">
                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="Alerjisi"
                              v-model="form.allergy"/>
                </el-form-item>

                <el-form-item>
                    <el-switch v-model="form.is_share" active-text="Kisisel Bilgilerini Paylas?"/>
                </el-form-item>

                <h4 class="card-box-sub-title">Okul Bilgileri</h4>
                <el-form-item label="Okul">
                    <select-definition label="Okul" alias="schools" model="school_definition_id" :container="form"/>
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="8">
                        <el-form-item label="Egitim Yili" class="fluid">
                            <select-definition alias="education-years" model="education_year_definition_id" :container="form"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="Egitim Donemi" class="fluid">
                            <select-definition alias="education-periods" model="education_period_definition_id" :container="form"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="Sinif" class="fluid">
                            <select-definition label="Sinif" alias="classes" model="education_class_definition_id" :container="form"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="updateItem" v-if="form.id" class="fluid">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </div>
        </el-dialog>
    </vue-scroll>
</template>

<script>
    import Orders from "./_parts/Orders";
    import Parents from "./_parts/Parents";
    import Contacts from "./_parts/Contacts";
    import MonthlyReport from "./_parts/MonthlyReport";
    import QuizReport from "./_parts/QuizReport";
    import SelectDefinition from "../../../../components/SelectDefinition";
    import Notes from "../../../../components/Notes";
    import FileBrowser from "../../../../components/FileBrowser";
    import PlatformMerge from "@/components/PlatformMerge";

    export default {
        name: 'ElementForm',
        components: {
            PlatformMerge,
            FileBrowser,
            Notes,
            SelectDefinition,
            QuizReport,
            MonthlyReport,
            Contacts,
            Parents,
            Orders,
        },
        data() {
            return {
                itemLoading: true,
                modalStudentVisible: false,
                countries: [],
                cities: [],
                addresses: [],
                classes: [],
                parents: [],
                form: {

                },
                rules: {
                    identification_no: [
                        {
                            min: 11,
                            max: 11,
                            message: '11 haneli olarak girmelisiniz.',
                            trigger: 'blur'
                        },
                        {
                            required: true,
                            message: 'Zorunlu alan.',
                            trigger: 'blur'
                        },
                    ]
                }
            }
        },
        created() {
            this.getZones('countries', 0);
            this.loadItem();
        },
        methods: {
            loadItem() {

                this.itemLoading = true;

                this.$http.get(this.$route.meta.end_point + '/' + this.$route.params.id, {params: this.serverParams})
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body.item;

                        this.getZones('cities', this.form.country_zone_id);
                        this.getClasses();
                    });
            },

            updateItem() {

                this.itemLoading = true;

                this.$http.put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.modalStudentVisible = false;
                        this.apiSuccessMessage(response);

                        this.form = response.body.item;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getClasses() {
                this.itemLoading = true;
                this.$http.get('system/definitions?alias=classes&perPage=1000').then(response => {
                    this.itemLoading = false;
                    this.classes = response.body.rows;
                });
            },

            getZones(alias, parentId) {
                this.$http.get('system/zones', {
                    params: {
                        fake_url: this.$route.meta.end_point,
                        parent_id: parentId,
                        alias: alias,
                        perPage: 1000
                    }
                }).then(response => {
                    this[alias] = response.body.rows;
                    this.itemLoading = false;
                });
            },

            selectedCountry() {
                this.getZones('cities', this.form.birthplace_country_zone_id)
            },
        }
    }
</script>
