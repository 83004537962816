<template>
    <section v-loading="itemLoading" class="ps-container ps">

        <div class="card-box-plus" @click="crateItem" style="margin: 0">
            <div class="box-container">
                <i class="el-icon-plus"></i>
            </div>
        </div>

        <div class="padding-20">
            <table class="ui table table-hover">
                <thead>
                <tr>
                    <th width="1" style="padding-right: 30px !important;">Sira</th>
                    <th>Baslik</th>
                    <th width="100"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, key) in items" :key="'TabsComponentItem-' + key">
                    <td>{{ item.sort }}</td>
                    <td>{{ item.option.title }}</td>
                    <td>
                        <div class="d-flex justify-flex-end">
                            <el-button type="text" class="el-button-link green" icon="el-icon-edit" @click="editItem(item)" />
                            <el-button type="text" class="el-button-link red" icon="el-icon-delete" @click="destroyItem(item.id)" />
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <el-dialog :title="modalTitle" :visible.sync="modalVisible" v-loading="modalLoading" width="90%">
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-form-item label="Secenekler">
                    <el-select v-model="form.option_id" value-key="id" @change="optionChangeHandle"
                               placeholder="Secenekler">
                        <el-option
                                v-for="(item, index) in options"
                                :key="'options-' + index"
                                :label="item.title"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-switch v-model="form.is_required" active-text="Doldurulmasi zorunlu alan?"/>
                </el-form-item>

                <el-form-item>
                    <el-input-number v-model="form.sort" placeholder="Sıralama" />
                </el-form-item>

                <div v-loading="valueLoading" v-if="form.option_id">
                    <table class="ui table table-hover">
                        <thead>
                        <tr>
                            <th width="1" style="padding-right: 30px !important;">Sira</th>
                            <th>Deger</th>
                            <th>Stok</th>
                            <th>Fiyat</th>
                            <th width="1">
                                <el-button type="text" icon="el-icon-plus" class="el-button-link primary" @click="createStepItem" />
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(value, valueKey) in form.values" :key="'option-value-' + valueKey">
                            <td>
                                <el-form-item>
                                    <el-input-number v-model="value.sort" placeholder="Sıralama" />
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item>
                                    <el-select v-model="value.option_value_id" value-key="id" placeholder="Degerler">
                                        <el-option
                                                v-for="(item, index) in values"
                                                :key="'options-values-d-' + index"
                                                :label="item.title"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item>
                                    <el-input-number v-model="value.quantity" placeholder="Stok"></el-input-number>
                                </el-form-item>
                            </td>
                            <td>
                                <el-input placeholder="Please input" v-model="value.price" class="input-with-select">
                                    <el-select v-model="value.price_prefix" slot="prepend" placeholder="Prefix">
                                        <el-option label="+" value="+"></el-option>
                                        <el-option label="-" value="-"></el-option>
                                    </el-select>
                                </el-input>
                            </td>
                            <td>
                                <el-button type="text" icon="el-icon-delete" class="el-button-link red" @click="destroyStepItem(valueKey)" />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="storeItem" v-if="!form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" size="medium" @click="updateItem" v-if="form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </span>
        </el-dialog>
    </section>
</template>
<script>

    export default {
        name: 'Options',
        props: ['source', 'itemId'],
        data() {
            return {
                itemLoading: true,
                modalLoading: false,
                modalVisible: false,
                valueLoading: false,
                options: {},
                values: {},
                modalTitle: 'Ekle',
                items: [],
                form: {
                    values: []
                },
            }
        },
        mounted() {
            this.index();
            this.getOptions();
        },
        methods: {
            index() {
                this.$http.get(this.source + '/options', {
                    params: {
                        fake_url: this.source,
                    }
                }).then(response => {
                    this.items = response.body.rows;
                    this.itemLoading = false;
                });
            },

            crateItem() {
                this.modalTitle = 'Ekle';
                this.modalVisible = true;
                this.form = {
                    values: []
                };
            },

            storeItem() {
                this.modalLoading = true;

                this.form.fake_url = this.source;

                this.$http.post(this.source + '/options', this.form)
                    .then(response => {

                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            editItem(item) {
                this.itemLoading = false;
                this.modalLoading = false;
                this.modalVisible = true;

                this.modalTitle = 'Duzenle';

                this.form = item;
                this.valuesLoad();
            },

            updateItem() {

                this.modalLoading = true;

                this.form.fake_url = this.source;

                this.$http.put(this.source + '/options/' + this.form.id, this.form)
                    .then(response => {

                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {

                this.modalLoading = false;
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete(this.source + '/options/' + id, {
                        fake_url: this.source
                    }).then(response => {
                        this.modalLoading = false;
                        this.modalVisible = false;
                        this.itemLoading = false;

                        this.index();

                        this.$message({
                            type: 'success',
                            message: 'Delete completed'
                        });
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.modalLoading = false;
                    this.itemLoading = false;
                });
            },

            optionChangeHandle() {
                this.form.values = [];

                this.valuesLoad();
            },

            valuesLoad() {
                this.valueLoading = true;

                this.$http.get('cms/options/' + this.form.option_id + '/values', {
                    params: {
                        fake_url: this.source,
                    }
                }).then(response => {
                    this.values = response.body.rows;
                    this.valueLoading = false;
                });
            },

            createStepItem() {
                this.form.values.push({
                    option_value_id: null,
                    quantity: null,
                    price_prefix: null,
                    price: null,
                    sort: 100,
                });
            },

            destroyStepItem(key) {
                this.form.values.splice(key, 1);
            },

            getOptions() {
                this.itemLoading = true;
                this.$http.get('cms/options').then(response => {
                    this.itemLoading = false;
                    this.options = response.body.rows;
                });
            }
        }
    }
</script>