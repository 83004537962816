<template>
	<el-select v-model="container[model]" :placeholder="placeholder ? placeholder : 'Seçiminiz'" :multiple="multiple" clearable filterable @change="handleChange">
		<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
	</el-select>
</template>

<script>
    export default {
        name: 'LabelValueSelect',
        props: [
            'container',
            'model',
            'label',
            'multiple',
            'options',
            'change',
            'placeholder',
        ],
        methods : {
            handleChange(e) {
                if (!this.change) {
                    return false;
                }

                this.change(e)
            }
        }
    };
</script>
