<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading">

            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                            <el-button @click="$router.go(-1)" type="text" icon="el-icon-back"
                                       class="el-button-link grey"/>
                        </el-tooltip>

                        <el-tooltip content="Olustur" effect="dark" placement="bottom" v-if="$route.params.id">
                            <el-button @click="createButton($route.meta.end_point)" type="text" icon="el-icon-plus"
                                       class="el-button-link primary"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="!$route.params.id">
                            <el-button @click="storeItem" type="text" icon="el-icon-check"
                                       class="el-button-link green"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="$route.params.id">
                            <el-button @click="updateItem" type="text" icon="el-icon-check"
                                       class="el-button-link green"/>
                        </el-tooltip>
                    </div>
                </header>
                <section class="card-box-content">
                    <el-form :label-position="'top'" ref="form" :model="form">

                        <div class="padding-20">
                            <SelectCategory label="Etiketler" alias="platform" model="categories" :container="form"/>

                            <el-form-item label="Tur">
                                <select-definition label="Tur" alias="word-types" model="word_type_id" :container="form.metas"/>
                            </el-form-item>

                            <el-form-item label="Ad">
                                <el-input v-model="form.title" placeholder="Ad"/>
                            </el-form-item>

                            <el-form-item label="Turkce">
                                <el-input v-model="form.short_description" placeholder="Turkce"/>
                            </el-form-item>

                            <el-form-item label="Animasyon">
                                <document-browser :filePath.sync="form.metas.video_file"/>
                            </el-form-item>

                            <el-form-item label="Ses">
                                <document-browser :filePath.sync="form.metas.sound_file"/>
                            </el-form-item>
                        </div>

                        <div class="box-list">
                            <div class="box-list-item box-list-item-title">
                                <div class="name">
                                    Resimler
                                </div>
                            </div>
                        </div>

                        <div class="padding-20">
                            <el-row :gutter="24">
                                <el-col :span="6">
                                    <el-form-item label="Resim">
                                        <file-browser v-model="form.file_path"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="Cizim">
                                        <file-browser v-model="form.metas.sketch_img"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>

                        <div class="box-list">
                            <div class="box-list-item box-list-item-title">
                                <div class="name">
                                    Cumleler
                                </div>
                                <div class="">
                                    <el-tooltip content="Cumle Ekle" effect="dark" placement="left-end">
                                        <el-button icon="el-icon-plus" type="text" class="el-button-link primary" @click="handleCreateSentence"/>
                                    </el-tooltip>
                                </div>
                            </div>
                            <div class="box-list-item" v-for="(sentence, key) in form.metas.sentences" :key="key">
                                <el-form-item label="Cumle" class="fluid margin-right-20">
                                    <el-input v-model="sentence.sentence" placeholder="Cumle"/>
                                </el-form-item>
                                <el-form-item label="Ceviri" class="fluid margin-right-20">
                                    <el-input v-model="sentence.translate" placeholder="Ceviri"/>
                                </el-form-item>
                                <div>
                                    <el-tooltip content="Sil" effect="dark" placement="left-end">
                                        <el-button icon="el-icon-delete" type="text" class="el-button-link red"
                                                   @click="handleDestroySentence(key)"/>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </el-form>
                </section>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
    import SelectCategory from "../../../../components/SelectCategory";
    import SelectDefinition from "../../../../components/SelectDefinition";
    import FileBrowser from "../../../../components/FileBrowser";
    import DocumentBrowser from "../../../../components/DocumentBrowser";

    export default {
        name: 'ElementForm',
        components: {
            DocumentBrowser,
            SelectDefinition,
            SelectCategory,
            FileBrowser
        },
        data() {
            return {
                itemLoading: true,
                form: {
                    file_path: null,
                    metas: {
                        video_file: null,
                        sound_file: null,
                        sketch_img: null,
                        sentences: [],
                    }
                },

                classrooms: [],
            }
        },

        methods: {
            loadItem() {

                this.itemLoading = true;

                this.$http
                    .get(this.$route.meta.end_point + '/' + this.$route.params.id)
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body.item;

                        if (!_.size(this.form.metas)) {
                            this.form.metas = {}
                        }

                        if (!this.form.parent_id) {
                            this.form.parent_id = null;
                        }
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            storeItem() {

                this.itemLoading = true;

                this.$http
                    .post(this.$route.meta.end_point, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                        this.$router.push('edit/' + response.body.item.id);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.itemLoading = true;

                this.$http
                    .put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.itemLoading = true;

                this
                    .$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                        confirmButtonText: 'Tamam',
                        cancelButtonText: 'Iptal Et',
                        type: 'warning'
                    })
                    .then(() => {
                        this.$http
                            .delete(this.$route.meta.end_point + '/' + id)
                            .then(response => {
                                this.itemLoading = false;

                                this.$router.push('/');

                                this.apiSuccessMessage(response)
                            })
                            .catch((e) => {
                                this.itemLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            },

            getClassrooms() {
                this.pageLoading = true;

                this.$http
                    .get('school/classrooms', {
                        params: {
                            perPage: 500,
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.classrooms = response.body.rows
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            handleCreateSentence() {
                this.form.metas.sentences.push({
                    sentence : null,
                    translate : null,
                });
            },
            handleDestroySentence(key) {
                this.form.metas.sentences.splice(key, 1);
            }
        },

        mounted() {

            this.getClassrooms();

            if (this.$route.params.id) {
                this.loadItem()
            } else {
                this.itemLoading = false;
            }
        }
    }
</script>