<template>
    <section v-loading="itemLoading">
        <div class="card-box note-box">
            <header class="card-box-header">
                <div class="title"> {{ title ? title : 'Notlar'}}</div>
            </header>
            <section class="card-box-content">
                <vue-scroll>
                    <div class="comments" style="height: 200px" :style="{height : size ? size + 'px' : '200px'}">
                        <Empty :items="items" />
                        <div class="comment" v-for="(item, key) in items" :key="key">
                            <div class="content">
                                <div class="metas">
                                    <span class="name">{{ item.user.name }}</span>
                                    <span class="date">{{ item.human_date }}</span>
                                </div>
                                <div class="summary" v-html="item.content" style="font-size: 14px"></div>
                                <div class="actions">
                                    <el-button type="text" icon="el-icon-edit" class="text-color-success" @click="editItem(item)">Duzenle</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-scroll>
            </section>
            <footer class="card-box-footer">
                <div class="input-container fluid" v-loading="modalLoading">
                    <div class="fluid">
                        <el-form :model="form">
                            <el-form-item>
                                <el-input type="textarea" placeholder="Mesajiniz..." v-model="form.content" />
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="action">
                        <el-button type="success" size="medium" @click="storeItem">
                            <i class="mdi mdi-send"></i>
                        </el-button>
                    </div>
                </div>
            </footer>
        </div>

        <el-dialog :title="modalTitle" :visible.sync="modalVisible" v-loading="modalLoading" append-to-body>
            <el-form :model="form">
                <el-form-item>
                    <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6}" placeholder="Mesajiniz..." v-model="form.content" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="updateItem" v-if="form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>
<script>
    import Empty from "./Empty";

    export default {
        name: 'Notes',
        components: {Empty},
        props: ['url', 'size', 'title'],
        data() {
            return {
                itemLoading: true,
                modalLoading: false,
                modalVisible: false,
                modalTitle: 'Ekle',
                items: [],
                form: {},

                loginUser : {}
            }
        },

        methods: {
            index() {
                this.$http.get('tools/notes', {
                    params: {
                        fake_url: 'v1/' + this.url,
                        url: 'v1/' + this.url,
                    }
                }).then(response => {
                    this.items = response.body.rows;
                    this.itemLoading = false;
                });
            },

            crateItem() {
                this.modalTitle = 'Ekle';
                this.modalVisible = true;
                this.form = {};
            },

            storeItem() {
                this.modalLoading = true;

                this.$http.post('tools/notes', {
                    url: 'v1/' + this.url,
                    content: this.form.content,
                    fake_url: 'v1/' + this.url,
                }).then(response => {
                    this.modalLoading = false;
                    this.modalVisible = false;

                    this.index();
                });
            },

            editItem(item) {
                this.itemLoading = false;
                this.modalLoading = false;
                this.modalVisible = true;

                this.modalTitle = 'Duzenle';
                this.form = item;
            },

            updateItem() {
                this.modalLoading = true;

                this.$http.put('tools/notes/' + this.form.id, {
                    content: this.form.content,
                    fake_url: 'v1/' + this.url,
                }).then(response => {
                    this.modalLoading = false;
                    this.modalVisible = false;

                    this.index();
                });
            },

            destroyItem(id) {
                this.modalLoading = false;
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete('tools/notes/' + id, {
                        fake_url: 'v1/' + this.url
                    }).then(response => {
                        this.modalLoading = false;
                        this.modalVisible = false;
                        this.itemLoading = false;

                        this.index();

                        this.$message({
                            type: 'success',
                            message: 'Delete completed'
                        });
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.modalLoading = false;
                    this.itemLoading = false;
                });
            },
        },

        mounted() {
            this.index();

            if (this.account) {
                this.loginUser = this.account;
            }
        },
    }
</script>
