<template>

    <div class="card-box">
        <header class="card-box-header">
            <div class="title">
                Ogrenciler
            </div>
            <div class="actions">
                {{ itemCount }}
            </div>
        </header>
        <section class="card-box-content" v-loading="itemLoading">
            <vue-scroll>
                <div style="height: 400px">
                    <div class="box-list">
                        <div class="box-list-item" v-for="item in items" :key="item.id">
                            <div>
                                <router-link :to="'/school/students/edit/' + item.id" target="_blank">
                                    {{ item.first_name }} {{ item.last_name }}
                                </router-link>
                            </div>
                            <div class="rate">
                                <el-rate v-model="item.loyalty_year > 3 ? 3 : item.loyalty_year" :max="3" disabled />
                            </div>
                        </div>
                    </div>
                </div>
            </vue-scroll>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'ElementPart',
        props: ['product'],
        data() {
            return {
                endPoint: null,
                itemLoading: true,
                itemCount : 0,
                items: []
            }
        },

        methods: {
            index() {
                this.itemLoading = true;

                this.$http
                    .get(this.endPoint)
                    .then(response => {
                        this.itemLoading = false;
                        this.items = response.body.items;

                        this.itemCount = _.size(this.items);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            }
        },

        mounted() {

            this.endPoint = 'school/contents/' + this.product.content_id + '/periods/' + this.product.id + '/students';

            this.index();
        }
    }
</script>