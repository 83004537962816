<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading">
            <div class="card-box" >
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <crud-buttons :store-item="storeItem" :update-item="updateItem" />
                </header>
                <section class="card-box-content padding-20">
                    <el-form :label-position="'top'" ref="form" :model="form">

                        <el-form-item label="Başlık">
                            <el-input v-model="form.title" placeholder="Başlık"/>
                        </el-form-item>

                        <el-form-item label="Ikon">
                            <el-input v-model="form.icon" placeholder="Ikon"/>
                        </el-form-item>

                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="Arka Plan Rengi">
                                    <el-color-picker v-model="form.bg_color" show-alpha :predefine="appConfig.predefineColors"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Yazi Rengi">
                                    <el-color-picker v-model="form.text_color" show-alpha
                                                     :predefine="appConfig.predefineColors"/>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-form-item label="Sıralama">
                            <el-input-number v-model="form.sort" placeholder="Sıralama"/>
                        </el-form-item>

                        <el-form-item>
                            <el-switch v-model="form.is_active" active-text="Aktif"/>
                        </el-form-item>

                    </el-form>
                </section>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
    export default {
        name: 'ElementForm',
        props: ['source'],
        data() {
            return {
                itemLoading: true,
                form: {
                    alias: this.$route.params.type,
                    metas: {}
                }
            }
        },

        methods: {
            loadItem() {

                this.itemLoading = true;

                this.$http
                    .get(this.$route.meta.end_point + '/' + this.$route.params.id, {
                        params: this.serverParams
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body.item
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            storeItem() {

                this.itemLoading = true;

                this.$http
                    .post(this.$route.meta.end_point, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                        this.$router.push('edit/' + response.body.item.id);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.itemLoading = true;

                this.$http
                    .put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                    confirmButtonText: 'Tamam',
                    cancelButtonText: 'Iptal Et',
                    type: 'warning'
                })
                    .then(() => {
                        this.$http
                            .delete(this.$route.meta.end_point + '/' + id)
                            .then(response => {
                                this.itemLoading = false;

                                this.$router.push('/');

                                this.apiSuccessMessage(response)
                            })
                            .catch((e) => {
                                this.itemLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            },
        },

        mounted() {
            if (this.$route.params.id) {
                this.loadItem()
            } else {
                this.itemLoading = false;
            }
        }
    }
</script>