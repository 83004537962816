<template>
    <div class="offer-page loading-container" v-loading="pageLoading">
        <div class="ui three item menu small mb-20">
            <div class="item font-weight-600" v-for="tab in ui.tabs" :key="tab.value" :class="{active : tab.value === ui.activeTab}">
                {{ tab.label }}
            </div>
        </div>
        <div class="my-flex mb-20" :style="{'justify-content' : ui.activeTab === 1 ? 'end' : 'space-between'}">
            <div class="ui labeled icon button default fw-500" @click="handleTabBack" v-if="ui.activeTab !== 1">
                <i class="el-icon-arrow-left icon"></i> Geri Don
            </div>

            <div class="ui right labeled icon button green fw-500" @click="handleTabNext" v-if="ui.activeTab !== 3">
                <i class="el-icon-arrow-right icon"></i> Devam Et
            </div>
        </div>

        <el-form :label-position="'top'" ref="form" :model="form">
            <div class="tab-body">
                <div class="tab-item" title="Müşteri Bilgileri" v-show="ui.activeTab === 1">
                    <div class="card-box">
                        <header class="card-box-header">
                            <div class="title">Veli Bilgileri</div>
                        </header>
                        <div class="p-20">
                            <el-form-item label="Veli">
                                <search-user :container="form.customer" model="id" :change="handleSelectedCustomer" placeholder="Veli" :key="form.customer.id" />
                            </el-form-item>

                            <div v-if="form.customer.id">
                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <el-form-item label="Veli Adı ve Soyadı">
                                            <el-input v-model="form.customer.name" placeholder="Ad Soyad"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Kurum">
                                            <ti-select :container="form.customer" model="company_id" :options="$store.state.ui.companies"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Telefon Numarasi">
                                            <el-input v-model="form.customer.phone" placeholder="Telefon Numarasi" v-mask="'0 (###) ### ## ##'"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="E-Posta Adresi">
                                            <el-input v-model="form.customer.email" placeholder="Eposta Adresi"/>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>

                    <div class="card-box" v-if="form.customer.id">
                        <header class="card-box-header">
                            <div class="title">Öğrenci Bilgileri</div>
                        </header>
                        <div class="p-20">
                            <el-form-item label="Öğrenci">
                                <el-select v-model="form.student_id" placeholder="Ogrenciler" clearable @change="handleSelectedStudent">
                                    <el-option v-for="item in students" :label="item.full_name" :value="item.id" />
                                </el-select>
                            </el-form-item>
                            <div v-if="form.student.id">
                                <el-row :gutter="24" :key="form.student.id">
                                    <el-col :span="8">
                                        <el-form-item label="Öğrenci Adı">
                                            <el-input v-model="form.student.first_name" placeholder="Öğrenci Adı"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Öğrenci Soy Adı">
                                            <el-input v-model="form.student.last_name" placeholder="Öğrenci Soy Adı"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Doğum Tarihi">
                                            <el-date-picker v-model="form.student.birthday" type="date" placeholder="Tarih Seçiniz" value-format="yyyy-MM-dd" format="dd/MM/yyyy"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Sınıfı">
                                            <select-definition label="Sinif" alias="classes" model="education_class_definition_id" :container="form.student"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Okulu">
                                            <select-definition label="Okul" alias="schools" model="school_definition_id" :container="form.student"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Cinsiyet">
                                            <select-definition label="Cinsiyet" alias="genders" model="gender_definition_id" :container="form.student"/>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-item" title="Fiyat Teklifi Bilgileri" v-show="ui.activeTab === 2">
                    <div class="card-box">
                        <div class="card-box-header fluid">
                            <div class="fluid">
                                <el-row :gutter="24">
                                    <el-col :span="11">
                                        <el-form-item class="mb-0-i">
                                            <select-definition placeholder="Eğitim Yılı" alias="education-years" model="education_year_definition_id" :container="form.offer" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="11">
                                        <el-form-item class="mb-0-i">
                                            <select-definition placeholder="Sınıfı" alias="classes" model="class_definition_id" :container="form.offer" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="2">
                                        <div class="ui icon button blue fluid" @click="handleGetProducts">
                                            <i class="el-icon-search icon"></i>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>

                        <div class="products selected-products" >
                            <div class="product green" v-if="form.offer.product.product_id">
                                <el-row :gutter="24">
                                    <el-col :span="14">
                                        <div class="title">{{ form.offer.product.title }}</div>
                                        <div class="description">{{ form.offer.product.category_text }}</div>
                                    </el-col>
                                    <el-col :span="3">
                                        <div class="stock">
                                                <span :class="{
                                                    red : form.offer.product.current_stock <= 3,
                                                    green : form.offer.product.current_stock <= 12 && form.offer.product.current_stock >= 8,
                                                    blue : form.offer.product.current_stock < 8 && form.offer.product.current_stock > 3,
                                                }">
                                                    {{ form.offer.product.current_stock }}
                                                </span>
                                            <span> / </span>
                                            <span>{{ form.offer.product.stock_total }}</span>
                                        </div>
                                    </el-col>
                                    <el-col :span="3">
                                        <div class="price">
                                            {{ $n(form.offer.product.price, 'currency') }}
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div class="selected">
                                            <div>Birinci Tercih</div>
                                            <div class="ui icon button red" @click="form.offer.product = {}">
                                                <i class="el-icon-delete icon"></i>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            <div class="product blue" v-if="form.offer.alternative.product_id">
                                <el-row :gutter="24">
                                    <el-col :span="14">
                                        <div class="title">{{ form.offer.alternative.title }}</div>
                                        <div class="description">{{ form.offer.alternative.category_text }}</div>
                                    </el-col>
                                    <el-col :span="3">
                                        <div class="stock">
                                                <span :class="{
                                                    red : form.offer.alternative.current_stock <= 3,
                                                    green : form.offer.alternative.current_stock <= 12 && form.offer.alternative.current_stock >= 8,
                                                    blue : form.offer.alternative.current_stock < 8 && form.offer.alternative.current_stock > 3,
                                                }">
                                                    {{ form.offer.alternative.current_stock }}
                                                </span>
                                            <span> / </span>
                                            <span>{{ form.offer.alternative.stock_total }}</span>
                                        </div>
                                    </el-col>
                                    <el-col :span="3">
                                        <div class="price">
                                            {{ $n(form.offer.alternative.price, 'currency') }}
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div class="selected">
                                            <div>Ikinci Tercih</div>
                                            <div class="ui icon button red" @click="form.offer.alternative = {}">
                                                <i class="el-icon-delete icon"></i>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>

                        <div class="products">
                            <template v-for="product in products">
                                <div class="product" v-if="productVisibleCheck(product.product_id)">
                                    <el-row :gutter="24">
                                        <el-col :span="14">
                                            <div class="title">{{ product.title }}</div>
                                            <div class="description">{{ product.category_text }}</div>
                                        </el-col>
                                        <el-col :span="3">
                                            <div class="stock">
                                                <span :class="{
                                                    red : product.current_stock <= 3,
                                                    green : product.current_stock <= 12 && product.current_stock >= 8,
                                                    blue : product.current_stock < 8 && product.current_stock > 3,
                                                }">
                                                    {{ product.current_stock }}
                                                </span>
                                                <span> / </span>
                                                <span>{{ product.stock_total }}</span>
                                            </div>
                                        </el-col>
                                        <el-col :span="3">
                                            <div class="price">
                                                {{ $n(product.price, 'currency') }}
                                            </div>
                                        </el-col>
                                        <el-col :span="4">
                                            <div
                                                class="ui icon button fluid blue"
                                                :class="{disabled : form.offer.product.product_id }"
                                                @click="handleSelectedProduct(product)"
                                            >
                                                Seç
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="tab-item" title="Fiyat Teklifi Özet" v-show="ui.activeTab === 3">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <div class="calculator">
                                <div class="card-box">
                                    <div class="card-box-content">
                                        <div class="box-list pb-10">
                                            <div class="box-list-item fluid">
                                                <el-form-item label="Gecerlilik Tarihi" class="mb-0-i fluid">
                                                    <el-date-picker v-model="form.offer.expiry_at" type="date" placeholder="Gecerlilik Tarihi" value-format="yyyy-MM-dd" format="dd/MM/yyyy" />
                                                </el-form-item>
                                            </div>

                                            <div class="box-list-item fluid">
                                                <el-form-item label="Kampanyalar" class="mb-0-i fluid">
                                                    <el-select v-model="form.offer.campaigns" placeholder="Kampanyalar" multiple clearable filterable>
                                                        <el-option v-for="item in campaigns" :key="item.id" :label="item.title" :value="item.id">
                                                            <span style="float: left">{{ item.title }}</span>
                                                            <span style="float: right; color: #8492a6; font-size: 13px" v-if="item.year">
                                                                {{ item.end_at }}
                                                            </span>
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="card-box result" v-loading="offerLoading">
                                <div class="head">Teklif Özet</div>
                                <div v-if="response">
                                    <table class="el-table--fit el-table--enable-row-hover el-table--enable-row-transition el-table el-table--layout-fixed is-scrolling-none">
                                        <thead>
                                        <tr>
                                            <th class="red">Hizmet Adı</th>
                                            <th class="text-right red">Fiyat</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-for="product in response.products">
                                            <tr>
                                                <td>{{ product.title }}</td>
                                                <td class="text-right">{{ $n(product.price, 'currency') }}</td>
                                            </tr>
                                        </template>
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td class="red">Ara Toplam</td>
                                            <td>{{ $n(response.product_total, 'currency') }}</td>
                                        </tr>
                                        <tr>
                                            <td class="red">İndirim Toplam</td>
                                            <td>{{ $n(response.discount_total, 'currency') }}</td>
                                        </tr>
                                        <tr>
                                            <td class="red">Toplam</td>
                                            <td>{{ $n(response.total, 'currency') }}</td>
                                        </tr>
                                        </tfoot>
                                    </table>

                                    <div class="discounts">
                                        <div class="head">Faydalanılan İndirimler</div>
                                        <template v-for="item in getCampaigns(response)">
                                            <div class="discount">{{ item.title }}</div>
                                        </template>
                                    </div>

                                    <el-row :gutter="24">
                                        <el-col :span="12">
                                            <div class="ui button green fluid" @click="store">
                                                Fiyat Teklifi Oluştur
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="ui button blue fluid" @click="handleCalculate">
                                                Yeniden Hesapla
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import SearchUser from "@/components/search-user";
export default {
    name: 'PageItem',
    components : {
        SearchUser
    },
    data() {
        return {
            pageLoading: false,
            ui : {
                activeTab : 1,
                tabs : [
                    {
                        value: 1,
                        label: 'Müşteri Bilgileri'
                    },
                    {
                        value: 2,
                        label: 'Fiyat Teklifi Bilgileri'
                    },
                    {
                        value: 3,
                        label: 'Fiyat Teklifi Özet'
                    }
                ],
            },
            form  :{
                customer : {
                    id : null,
                },
                student : {
                    id : null
                },
                offer : {
                    product : {},
                    alternative : {},
                    campaigns : [],
                },
            },
            response : null,
            students : [],
            products : [],
            campaigns : [],
            offerLoading : false,
        }
    },
    mounted() {
        if (this.$route.query.customer_id) {
            this.handleSelectedCustomer(this.$route.query.customer_id)
        }
    },
    methods: {
        handleTabBack() {
            let key = this.ui.activeTab - 1;

            if (!key) {
                return;
            }

            this.ui.activeTab = key;
        },

        handleTabNext() {
            let key = this.ui.activeTab + 1;

            if (key > 3) {
                return;
            }

            switch (this.ui.activeTab) {
                case 1 :
                    this.updateCustomer();
                    break;
                case 2 :
                    this.updateProduct();
                    break;
            }
        },

        handleSelectedCustomer(id) {
            if (!id) {
                this.students = []
                this.form.customer = {}
                this.form.student = {}

                return;
            }

            this.pageLoading = true

            this.$http
                .get('enrollments/offers?process=user&id=' + id)
                .then(response => {

                    this.students = response.body.data.students

                    this.form.customer = response.body.data.customer
                    this.form.student = {}

                    this.pageLoading = false
                })
                .catch(() => {
                    this.pageLoading = false
                })
        },

        handleSelectedStudent(id) {
            this.form.student = this.students['student_' + id];
        },

        updateCustomer() {

            if (!this.form.customer.id) {
                return this.$notify.error({
                    title: 'Upss!',
                    message: 'Devam edebilmek icin veli secimi yapmaniz gerekiyor!',
                    position: 'top-right'
                });
            }

            if (!this.form.student.id) {
                return this.$notify.error({
                    title: 'Upss!',
                    message: 'Devam edebilmek icin ogrenci secimi yapmaniz gerekiyor!',
                    position: 'top-right'
                });
            }

            this.pageLoading = true

            this.$http
                .post('enrollments/offers', {
                    process : 'updateCustomer',
                    customer : this.form.customer,
                    student : this.form.student,
                })
                .then(response => {
                    this.pageLoading = false
                    this.ui.activeTab = 2
                })
                .catch(() => {
                    this.pageLoading = false
                })
        },

        handleGetProducts() {

            if (!this.form.offer.education_year_definition_id) {
                return this.$notify.error({
                    title: 'Upss!',
                    message: 'Arama yapabilmek icin yil secimi yapmaniz gerekiyor!',
                    position: 'top-right'
                });
            }

            this.pageLoading = true;
            this.products = []
            this.campaigns = []

            this.$http
                .get('enrollments/offers', {
                    params : {
                        process : 'product',
                        education_year_definition_id : this.form.offer.education_year_definition_id,
                        class_definition_id : this.form.offer.class_definition_id,
                    }
                })
                .then(response => {
                    this.form.offer.product = {};
                    this.form.offer.alternative = {};
                    this.products = response.body.data.products
                    this.campaigns = response.body.data.campaigns
                    this.pageLoading = false
                })
                .catch(() => {
                    this.pageLoading = false
                })
        },

        handleSelectedProduct(product) {

            if (product.current_stock <= 0) {
                return this.$notify.error({
                    title: 'Upss!',
                    message: 'Uzgunum bu sinifin kontenjani doldugu icin kayit yapamazsiniz!',
                    position: 'top-right'
                });
            }

            if (!this.form.offer.product.product_id) {
                return this.form.offer.product = product
            }

            if (!this.form.offer.alternative.product_id) {
                return this.form.offer.alternative = product
            }
        },

        productVisibleCheck(id) {

            if (id === this.form.offer.product.product_id) {
                return false
            }

            if (id === this.form.offer.alternative.product_id) {
                return false
            }

            return true;
        },

        updateProduct() {
            if (!this.form.offer.product.product_id) {
                return this.$notify.error({
                    title: 'Upss!',
                    message: 'Devam edebilmek icin lutfen sinif secin!',
                    position: 'top-right'
                });
            }

            this.ui.activeTab = 3

            this.handleCalculate()
        },

        getCampaigns(response) {
            if (this.lodash.size(response.customCampaigns)) {
                return response.customCampaigns;
            } else {
                return response.campaigns;
            }
        },

        handleCalculate() {

            this.offerLoading = true;

            this.$http
                .post('sales/offers', {
                    process : 'calculate',
                    account_id : this.form.customer.account_id,
                    customer_id : this.form.customer.id,
                    student_id : this.form.student.id,
                    education_year_definition_id : this.form.offer.education_year_definition_id,
                    customCampaigns : this.form.offer.campaigns,
                    classrooms : [
                        {
                            content_id : this.form.offer.product.content_id,
                            product_id : this.form.offer.product.product_id,
                            quantity : 1,
                        }
                    ]
                })
                .then(response => {
                    this.offerLoading = false;
                    this.response = response.body.data
                })
                .catch((e) => {
                    this.offerLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        store() {
            if (!this.form.offer.expiry_at) {
                return this.$notify.error({
                    title: 'Upss!',
                    message: 'Lutfen Gecerlilik Tarihi alanini doldurunuz.',
                    position: 'top-right'
                });
            }

            this
                .$confirm('Teklifinizi bu sekilde olusturmak istediginizden emin misiniz?', 'Uyari', {
                    confirmButtonText: 'Tamam',
                    cancelButtonText: 'Iptal Et',
                    type: 'warning'
                })
                .then(() => {
                    this.offerLoading = true;

                    this.$http
                        .post('sales/offers', {
                            account_id : this.form.customer.account_id,
                            customer_id : this.form.customer.id,
                            student_id : this.form.student.id,
                            expiry_at : this.form.offer.expiry_at,
                            education_year_definition_id : this.form.offer.education_year_definition_id,
                            customCampaigns : this.form.offer.campaigns,
                            classrooms : [
                                {
                                    content_id : this.form.offer.product.content_id,
                                    product_id : this.form.offer.product.product_id,
                                    quantity : 1,
                                }
                            ]
                        })
                        .then(response => {
                            this.offerLoading = false;
                            this.apiSuccessMessage(response);

                            return this.$router.push('edit/' + response.body.item.id);
                        })
                        .catch((e) => {
                            this.offerLoading = false;
                            this.apiErrorMessage(e);
                        });
                })
                .catch(() => {
                    //
                });



        },
    }
}
</script>
