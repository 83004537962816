<style scoped lang="scss">
    .card-box {
        margin-bottom: 10px;
    }

    .items .el-col {
        height: 40px;
        align-items: center;
        display: flex;
    }

    .card-box-header.active {
        background: rgba(40, 167, 69, .1);
    }
</style>

<template>
    <div class="page-vue-good-table scrollable only-y">
        <page-header/>
        <div class="loading-container" v-loading="pageLoading">

            <div class="card-box" style="margin-bottom: 30px">
                <header class="card-box-header">
                    <el-form :label-position="'top'" ref="form" :model="filterForm" class="fluid">
                        <el-row :gutter="24">
                            <el-col :span="10">
                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <el-form-item class="margin-bottom-0">
                                            <el-input v-model="filterForm.first_name" placeholder="Ad"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item class="margin-bottom-0">
                                            <el-input v-model="filterForm.last_name" placeholder="Soyad"/>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="5">
                                <el-form-item class="margin-bottom-0">
                                    <select-definition label="Egitim Yili" alias="education-years" model="education_year_definition_id" :container="filterForm"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="5">
                                <el-form-item class="margin-bottom-0">
                                    <select-definition label="Egitim Donemi" alias="education-periods" model="education_period_definition_id" :container="filterForm"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4">
                                <el-form-item class="margin-bottom-0">
                                    <el-input v-model="filterForm.installment_no" placeholder="Taksit Sayisi" type="number"/>
                                </el-form-item>
                            </el-col>
                        </el-row>

                    </el-form>
                    <div class="actions margin-left-30">
                        <el-button @click="index" icon="el-icon-search" type="text" class="el-button-link blue d-flex"/>
                    </div>
                </header>
            </div>

            <div v-if="appCounter(items)">
                <div v-for="(item, key) in items" :key="item.id" class="items">
                    <div class="card-box " v-loading="item.loading">
                        <header class="card-box-header has-buttons" style="font-size: 14px"
                                :class="{active : item.accounting_status}">
                            <el-row :gutter="24" class="fluid" style="align-items: center">
                                <el-col :span="2">
                                    <b @click="openBlankWindow('/sales/orders/edit/' + item.id)"
                                       style="cursor: pointer">#{{ item.id }}</b>
                                </el-col>
                                <el-col :span="8">
                                    <div>
                                        <div>
                                            <b>{{ item.first_name }} {{ item.last_name }}</b>
                                        </div>
                                        <div v-if="item.student">
                                            {{ item.student.full_name }}
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="4">
                                    <div class="text-right">
                                        {{ $n(item.discount_total, 'currency') }}
                                    </div>
                                </el-col>
                                <el-col :span="4">
                                    <div class="text-right">
                                        {{ $n(item.total, 'currency') }}
                                    </div>
                                </el-col>
                                <el-col :span="4">
                                    <el-input v-model="item.accounting_installment_no" placeholder="Taksit Sayisi"
                                              type="number"/>
                                </el-col>
                            </el-row>
                            <div class="actions">
                                <el-tooltip content="Gonderdim" placement="top">
                                    <el-button icon="el-icon-check" type="text" class="el-button-link grey d-flex"
                                               @click="update(item, 'accounting-up')"/>
                                </el-tooltip>
                                <el-tooltip content="Gonder" placement="top">
                                    <el-button icon="el-icon-s-promotion" type="text"
                                               class="el-button-link green d-flex" @click="update(item, 'accounting')"/>
                                </el-tooltip>
                            </div>
                        </header>
                    </div>
                </div>

                <div class="card-box" style="margin-top: 30px">
                    <header class="card-box-header" style="justify-content: center">
                        <el-pagination background layout="prev, pager, next"
                                       :current-page.sync="pagination.current_page" :total="pagination.total"
                                       :page-size="pagination.per_page" @current-change="handlePaginationChange"/>
                    </header>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from "../../../components/PageHeader";
    import SelectDefinition from "../../../components/SelectDefinition";

    export default {
        name: 'Accounting',
        components: {SelectDefinition, PageHeader},
        props: ['source'],
        data() {
            return {
                pageLoading: true,
                filterForm: {
                    process: 'accounting'
                },

                items: [],
                pagination: {
                    total: 100,
                    per_page: 50,
                    current_page: 1,
                },
            }
        },

        methods: {
            handlePaginationChange(page) {
                this.pagination.current_page = page;

                this.index();
            },

            index() {
                this.pageLoading = true;
                this.items = [];

                this.$http
                    .get(this.$route.meta.end_point, {
                        params: {
                            fake_url: this.source,
                            perPage: this.pagination.per_page,
                            page: this.pagination.current_page,
                            filters: [
                                {
                                    relation: null,
                                    inputs: [
                                        {
                                            name: 'education_year_definition_id',
                                            field: 'education_year_definition_id',
                                            type: 'singleSelect',
                                            value: this.filterForm.education_year_definition_id
                                        },
                                        {
                                            name: 'education_period_definition_id',
                                            field: 'education_period_definition_id',
                                            type: 'singleSelect',
                                            value: this.filterForm.education_period_definition_id
                                        },
                                        {
                                            name: 'first_name',
                                            field: 'first_name',
                                            type: 'singleSelect',
                                            value: this.filterForm.first_name
                                        },
                                        {
                                            name: 'last_name',
                                            field: 'last_name',
                                            type: 'singleSelect',
                                            value: this.filterForm.last_name
                                        }
                                    ]
                                },
                            ],
                            withs: {
                                'products': 'products',
                                'products.content': 'products.content',
                                'products.address': 'products.address',
                            },
                            sorts: {
                                created_at: 'desc'
                            }
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;

                        _.forEach(response.body.rows, (item, key) => {
                            item.accounting_installment_no = this.filterForm.installment_no;
                            this.items.push(item);
                        });

                        this.pagination.total = response.body.pagination.total;
                        this.pagination.current_page = response.body.pagination.current_page;
                    })
                    .catch((e) => {
                        this.pageLoading = false;

                        this.apiErrorMessage(e);
                    });
            },

            update(item, process) {

                item.loading = true;

                this.$http
                    .put(this.$route.meta.end_point + '/' + item.id, {
                        process: process,
                        order_counter: item.accounting_installment_no,
                    })
                    .then(response => {
                        item.loading = false;
                        item.accounting_status = true;

                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        item.loading = false;
                        this.apiErrorMessage(e);
                    });
            },
        },

        mounted() {
            this.pageLoading = false;
        }
    }
</script>