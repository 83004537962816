<template>
    <div class="basic-page-header">
        <div class="left-column">
            <div class="title">
                {{ $route.meta.title }}
            </div>
            <el-breadcrumb separator-class="mdi mdi-chevron-double-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <i class="mdi mdi-home-outline"></i>
                </el-breadcrumb-item>
                <el-breadcrumb-item v-for="(item, key) in items" :key="key" :to="item.path">
                    {{ item.title }}
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="right-column">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageHeader',
    data() {
        return {
            items: [],
        }
    },
    mounted() {
        this.setItems();
    },
    methods: {
        setItems() {
            let items = [];
            let id = this.$route.params.id;
            let type = this.$route.params.type;

            _.forEach(this.$route.matched, function (item, key) {

                let isIndex = false;

                if (item.name) {
                    let names = item.name.split('.');

                    _.forEach(names, function (name, nameKey) {
                        if (name === 'index') {
                            isIndex = true;
                        }
                    });
                }

                if (!isIndex) {

                    let path = item.path.replace(':id', id);

                    if (item.meta.title) {
                        items.push({
                            path: path.replace(':type', type),
                            title: item.meta.title,
                        });
                    }
                }
            });

            this.items = items;
        }
    },
}
</script>
