<template>
    <div class="page-vue-good-table scrollable only-y mb-100">
        <vue-scroll class="page-element-form">
            <page-header/>
            <div class="loading-container" v-loading="itemLoading">
                <el-row :gutter="24" class="mb-40">
                    <el-col :span="12">
                        <div class="card-base card-shadow--medium widget small-widget"
                             style="background-color: #007bff; color: #fff">
                            <div class="widget-header">
                                <div class="flex justify-center align-center">
                                    <div class="widget-info box grow text-truncate">
                                        <div class="widget-title fs-14">
                                            Gonderilecek Öğrenci / Müşteri
                                        </div>
                                        <div class="fs-28">
                                            {{ form.student_count }} / {{ form.customer_count }}
                                        </div>
                                    </div>
                                    <div class="widget-icon-box">
                                        <i class="mdi mdi-account widget-icon fs-40"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="card-base card-shadow--medium widget small-widget"
                             style="background-color: #dc3545; color: #fff">
                            <div class="widget-header">
                                <div class="flex justify-center align-center">
                                    <div class="widget-info box grow text-truncate">
                                        <div class="widget-title fs-14">
                                            Kalan Bakiye
                                        </div>
                                        <div class="fs-28">
                                            {{ balance }}
                                        </div>
                                    </div>
                                    <div class="widget-icon-box">
                                        <i class="mdi mdi-scale-balance widget-icon fs-40"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>

                <el-form :label-position="'top'" ref="form" :model="form" v-loading="itemLoading">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <div class="card-box">
                                <header class="card-box-header">
                                    <div class="title">
                                        Ayarlar
                                    </div>
                                </header>
                                <section class="card-box-content padding-20">
                                    <el-form-item label="Gonderim Turu">
                                        <el-select v-model="form.draft_type_id" placeholder="Gonderim Turu" clearable>
                                            <el-option v-for="item in types" :key="item.id" :label="item.title"
                                                       :value="item.id"/>
                                        </el-select>
                                    </el-form-item>

                                    <el-form-item label="Bildirim Türü">
                                        <el-select v-model="form.notification_id" placeholder="Bildirim Türü" clearable>
                                            <el-option
                                                    v-for="item in notifications"
                                                    :key="item.id"
                                                    :label="item.title"
                                                    :value="item.id"
                                            />
                                        </el-select>
                                    </el-form-item>

                                    <el-form-item label="Musteri Türü">
                                        <el-select v-model="form.customer_type" placeholder="Musteri Türü" clearable>
                                            <el-option
                                                    v-for="item in customerTypes"
                                                    :key="item.id"
                                                    :label="item.title"
                                                    :value="item.id"
                                            />
                                        </el-select>
                                    </el-form-item>

                                    <el-form-item label="Roller">
                                        <el-select v-model="form.roles" placeholder="Roller" multiple clearable>
                                            <el-option
                                                    v-for="item in roles"
                                                    :key="item.id"
                                                    :label="item.title"
                                                    :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </section>
                            </div>

                            <div class="card-box" v-if="form.customer_type === 1">
                                <header class="card-box-header">
                                    <div class="title">
                                        Potansiyel Musteri Filtresi
                                    </div>
                                </header>
                                <section class="card-box-content padding-20">
                                    <el-form-item label="Yillar">
                                        <select-definition label="Yillar" alias="education-years" model="periods"
                                                           :container="form"
                                                           :multiple="isMultiple"/>
                                    </el-form-item>

                                    <el-form-item label="Siniflar">
                                        <select-definition label="Siniflar" alias="classes" model="classes"
                                                           :container="form"
                                                           :multiple="isMultiple"/>
                                    </el-form-item>
                                </section>
                            </div>

                            <div class="card-box" v-if="form.customer_type === 2">
                                <header class="card-box-header">
                                    <div class="title">
                                        Kayitli Musteri
                                    </div>
                                </header>
                                <section class="card-box-content padding-20">
                                    <el-form-item label="Iceren Egitim Yillari">
                                        <select-definition label="Iceren Egitim Yillari" alias="education-years"
                                                           model="years" :container="form" :multiple="isMultiple"/>
                                    </el-form-item>

                                    <el-form-item label="Icermeyen Egitim Yillari">
                                        <select-definition label="Icermeyen Egitim Yillari" alias="education-years"
                                                           model="irrelevantYears" :container="form"
                                                           :multiple="isMultiple"/>
                                    </el-form-item>

                                    <el-form-item label="Siniflar">
                                        <select-definition label="Siniflar" alias="classes" model="classes" :container="form" :multiple="isMultiple"/>
                                    </el-form-item>

                                    <el-form-item label="Kategoriler">
                                        <el-select v-model="form.categories" placeholder="Kategoriler" multiple
                                                   filterable>
                                            <el-option v-for="item in categories" :key="item.id"
                                                       :label="item.nest_title" :value="item.id"/>
                                        </el-select>
                                    </el-form-item>

                                    <el-form-item label="Urunler">
                                        <el-select v-model="form.products" placeholder="Urunler" multiple filterable>
                                            <el-option v-for="item in products" :key="item.id" :label="item.title"
                                                       :value="item.id"/>
                                        </el-select>
                                    </el-form-item>
                                </section>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="card-box">
                                <header class="card-box-header">
                                    <div class="title">
                                        Ogrenciler
                                    </div>
                                    <div class="actions">
                                        <el-tooltip content="Ogrencileri Getir" effect="dark" placement="bottom">
                                            <el-button @click="findStudents" type="text" icon="el-icon-refresh"
                                                       class="el-button-link primary"/>
                                        </el-tooltip>
                                    </div>
                                </header>
                                <section class="card-box-content">
                                    <vue-scroll>
                                        <div style="max-height: 1000px">
                                            <Empty :items="students"/>
                                            <div class="box-list">
                                                <div class="box-list-item" v-for="student in students"
                                                     :key="student.id">
                                                    <div class="fluid mr-20">
                                                        <div class="fw-500 fs-16">
                                                            <router-link :to="'/school/students/edit/' + student.id"
                                                                         target="_blank">
                                                                {{ student.name }}
                                                            </router-link>
                                                        </div>
                                                        <div class="d-flex fs-12">
                                                            <div class="mr-10" v-for="customer in student.customers"
                                                                 :key="customer.id">
                                                                <router-link :to="'/crm/users/edit/' + customer.id"
                                                                             target="_blank">
                                                                    {{ customer.name }}
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <el-form-item style="margin: 0">
                                                            <el-switch v-model="student.selected"/>
                                                        </el-form-item>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </vue-scroll>
                                </section>
                            </div>

                            <div v-if="students[0]">
                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <div class="ui button labeled icon fluid" @click="dialogVisible = true" >
                                            <i class="icon el-icon-user"></i> Detayli Bilgiler
                                        </div>
                                    </el-col>
                                    <el-col :span="12">
                                        <div class="ui button labeled icon green fluid" @click="openModal" >
                                            <i class="icon el-icon-check"></i> Mesaj Gonder
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </vue-scroll>

        <el-dialog title="Toplu Mesaj Gonder" :visible.sync="modalVisible" v-loading="itemLoading" width="60%">
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Taslaklar">
                    <el-select v-model="form.draft_id" placeholder="Taslaklar" clearable @change="getDraft">
                        <el-option v-for="item in drafts" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="Mesaj" v-loading="draftContentLoading" v-if="form.message">
                    <div v-html="form.message"
                         style="min-height: 200px; background-color: #f5f8fc; padding: 20px; border-radius: 4px"></div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="storeItem" class="fluid">
                    <i class="mdi mdi-check"></i> Toplu Mesaj Gonder
                </el-button>
            </div>
        </el-dialog>

        <el-dialog title="Ogrenci Bilgileri" :visible.sync="dialogVisible" width="100%">
            <table class="el-table">
                <thead>
                <tr>
                    <th>Id</th>
                    <th>Ogrenci</th>
                    <th>Okul</th>
                    <th>Veli</th>
                    <th>Meslek</th>
                    <th>Telefon</th>
                    <th>E-Posta</th>
                    <th>Ilce</th>
                    <th>Semt</th>
                    <th>Mahalle</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="student in students" :key="student.id">
                    <td>{{ student.id }}</td>
                    <td>{{ student.name }}</td>
                    <td>{{ student.school }}</td>
                    <td>{{ student.parent }}</td>
                    <td>{{ student.job }}</td>
                    <td>{{ student.phone }}</td>
                    <td>{{ student.email }}</td>
                    <td>{{ student.town }}</td>
                    <td>{{ student.district }}</td>
                    <td>{{ student.neighborhood }}</td>
                </tr>
                </tbody>
            </table>
        </el-dialog>
    </div>
</template>

<script>
    import PageHeader from "../../../components/PageHeader";
    import SelectDefinition from "../../../components/SelectDefinition";
    import Empty from "../../../components/Empty";

    export default {
        name: 'BulkMessage',
        components: {
            Empty,
            SelectDefinition,
            PageHeader
        },
        data() {
            return {
                dialogVisible : false,

                itemLoading: true,
                draftContentLoading: true,
                modalVisible: false,
                isMultiple: true,
                balance: 0,
                form: {
                    student_count: 0,
                    customer_count: 0,
                    has_add_card: false,
                    draft_type_id: null,
                    notification_id: null,
                    customer_type: null,
                    roles: [],

                    // customer type 1
                    periods: [],
                    classes: [],

                    // customer type 2
                    years: [],
                    periods: [],
                    irrelevantYears: [],
                    irrelevantPeriods: [],
                    categories: [],
                    products: [],

                    // Draft
                    draft_id: null,
                    message: null,
                },
                approves: [
                    {
                        value: false,
                        label: 'Hayir'
                    },
                    {
                        value: true,
                        label: 'Evet'
                    }
                ],
                notifications: [
                    {
                        id: 'payments',
                        title: 'Odeme',
                    },
                    {
                        id: 'services',
                        title: 'Servis',
                    },
                    {
                        id: 'news',
                        title: 'Duyurular',
                    },
                    {
                        id: 'campaign',
                        title: 'Kampanyalar',
                    }
                ],
                customerTypes: [
                    {
                        id: 1,
                        title: 'Potansiyel',
                    },
                    {
                        id: 2,
                        title: 'Kayitli',
                    }
                ],
                types: [
                    {
                        id: 'email',
                        title: 'Eposta',
                    },
                    {
                        id: 'sms',
                        title: 'Sms',
                    },
                    {
                        id: 'mobile',
                        title: 'Mobil Bildirim',
                    }
                ],

                roles: [],
                students: [],
                categories: [],
                products: [],
                drafts: [],
            }
        },

        methods: {

            getBalance() {
                this.itemLoading = true;
                this.$http
                    .get('marketing/bulk-message/1')
                    .then(response => {
                        this.itemLoading = false;
                        this.balance = response.body.balance;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getRoles() {
                this.itemLoading = true;

                this.$http
                    .get('crm/roles')
                    .then(response => {
                        this.itemLoading = false;
                        this.roles = response.body.rows;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getCategories() {
                this.itemLoading = true;

                let aliases = [];

                aliases.push('products');
                aliases.push('vehicles');
                aliases.push('classrooms');

                this.$http
                    .get('tools/search/categories', {
                        params: {
                            alias: aliases
                        }
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.categories = response.body.items;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getProducts() {
                this.itemLoading = true;

                this.$http
                    .get('tools/search/products')
                    .then(response => {
                        this.itemLoading = false;
                        this.products = response.body.items;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getDrafts() {
                this.itemLoading = true;

                this.$http
                    .get('marketing/drafts?perPage=1000&alias=' + this.form.draft_type_id)
                    .then(response => {
                        this.itemLoading = false;
                        this.drafts = response.body.rows;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getDraft() {
                this.draftContentLoading = true;
                this.form.message = null;

                this.$http
                    .get('marketing/drafts/' + this.form.draft_id)
                    .then(response => {
                        this.form.message = response.body.item.content;
                        this.draftContentLoading = false;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            findStudents() {
                this.itemLoading = true;

                let form = this.form;
                form.process = 'finder';

                this.$http
                    .post(this.$route.meta.end_point, form)
                    .then(response => {
                        this.itemLoading = false;

                        this.form.student_count = response.body.student_count;
                        this.form.customer_count = response.body.customer_count;

                        let students = [];

                        _.forEach(response.body.students, (item) => {
                            item.selected = true;

                            students.push(item);
                        });

                        this.students = students;

                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            openModal() {
                this.modalVisible = true;
                this.form.draft_id = null;
                this.form.message = null;
                this.getDrafts();
            },

            storeItem() {
                this.itemLoading = true;

                let form = this.form;

                form.process = null;

                let students = [];

                _.forEach(this.students, function(item) {
                    if (item.selected) {
                        students.push(item.id);
                    }
                });

                form.students = students;

                this.$http
                    .post(this.$route.meta.end_point, form)
                    .then(response => {
                        this.itemLoading = false;
                        this.modalVisible = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            }
        },

        mounted() {
            this.itemLoading = false;

            this.getBalance();
            this.getRoles();
            this.getCategories();
            this.getProducts();
        }
    }
</script>
