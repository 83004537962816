<template>
    <div class="offer-page loading-container" v-loading="pageLoading">
        <div v-if="response.id">

            <div class="customer-info">
                <el-row :gutter="24">
                    <el-col :span="10" :offset="2">
                        <div class="card-box">
                            <header class="card-box-header">
                                <div class="title">Veli Bilgileri</div>
                            </header>
                            <div class="p-20">
                                <div class="box-list">
                                    <div class="box-list-item name">
                                        {{ response.customer.name }}
                                    </div>
                                    <div class="box-list-item">
                                        {{ response.customer.phone }}
                                    </div>
                                    <div class="box-list-item">
                                        {{ response.customer.email }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="10">
                        <div class="card-box">
                            <header class="card-box-header">
                                <div class="title">Öğrenci Bilgileri</div>
                            </header>
                            <div class="p-20">
                                <div class="box-list-item name">
                                    {{ response.student.full_name }}
                                </div>
                                <div class="box-list-item">
                                    {{ response.student.class }}
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <el-row :gutter="24">
                <el-col :span="16" :offset="4">
                    <div class="card-box result">
                        <div class="head">Teklif Özet</div>
                        <table class="el-table--fit el-table--enable-row-hover el-table--enable-row-transition el-table el-table--layout-fixed is-scrolling-none">
                            <thead>
                            <tr>
                                <th class="red">Hizmet Adı</th>
                                <th class="text-right red" width="20%">Fiyat</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="product in response.products">
                                <tr>
                                    <td>{{ product.title }}</td>
                                    <td class="text-right">{{ $n(product.price, 'currency') }}</td>
                                </tr>
                            </template>
                            </tbody>
                            <tfoot>
                            <tr>
                                <td class="red">Ara Toplam</td>
                                <td>{{ $n(response.product_total, 'currency') }}</td>
                            </tr>
                            <tr>
                                <td class="red">İndirim Toplam</td>
                                <td>{{ $n(response.discount_total, 'currency') }}</td>
                            </tr>
                            <tr>
                                <td class="red">Toplam</td>
                                <td>{{ $n(response.total, 'currency') }}</td>
                            </tr>
                            </tfoot>
                        </table>

                        <div class="discounts">
                            <div class="head">Faydalanılan İndirimler</div>
                            <template v-for="item in getCampaigns(response)">
                                <div class="discount">{{ item.title }}</div>
                            </template>
                        </div>
                    </div>

                    <div class="mb-20" style="display: flex; justify-content: space-between">
                        <div class="ui button yellow" @click="handlePrint">
                            Fiyat Teklifi Yazdır
                        </div>

                        <div class="ui button green" @click="handleNewOffer">
                            Yeni Fiyat Teklifi Olustur
                        </div>
                    </div>
                    <div class="ui button purple fluid" @click="handleOrder">
                        Sipariş Oluştur
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageItem',
    data() {
        return {
            pageLoading: true,
            response : {
                //
            }
        }
    },

    mounted() {
        this.getShow()
    },

    methods: {
        getShow() {
            this.pageLoading = true;

            this.$http
                .get(this.$route.meta.end_point + '/' + this.$route.params.id)
                .then(response => {
                    this.pageLoading = false;
                    this.response = response.body.data;
                });
        },

        getCampaigns(response) {
            if (this.lodash.size(response.customCampaigns)) {
                return response.customCampaigns;
            } else {
                return response.campaigns;
            }
        },

        handlePrint() {
            return window.open(this.appConfig.frontPath + '/prints/offers/' + this.response.id, '_blank');
        },

        handleNewOffer() {
            return this.$router.push('/enrollments/offers/create?customer_id=' + this.response.customer.id);
        },

        handleOrder() {
            return this.$router.push('/enrollments/orders/create?offer_id=' + this.response.id);
        }
    }
}
</script>
