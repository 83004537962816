<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading">
            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                            <el-button @click="$router.go(-1)" type="text" icon="el-icon-back"
                                       class="el-button-link grey"/>
                        </el-tooltip>

                        <el-tooltip content="Olustur" effect="dark" placement="bottom" v-if="$route.params.id">
                            <el-button @click="createButton($route.meta.end_point)" type="text" icon="el-icon-plus"
                                       class="el-button-link primary"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="!$route.params.id">
                            <el-button @click="storeItem" type="text" icon="el-icon-check"
                                       class="el-button-link green"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="$route.params.id">
                            <el-button @click="updateItem" type="text" icon="el-icon-check"
                                       class="el-button-link green"/>
                        </el-tooltip>
                    </div>
                </header>
                <section class="card-box-content padding-20">
                    <el-form :label-position="'top'" ref="form" :model="form">

                        <el-form-item label="Yetkili Kisi" v-if="!form.id">
                            <el-select
                                    v-model="form.user_id"
                                    value-key="id"
                                    filterable
                                    remote
                                    reserve-keyword
                                    placeholder="Yetkili Kisi"
                                    :remote-method="searchUsers"
                                    :loading="userSearchLoading">
                                <el-option v-for="item in users" :key="item.id" :label="item.title" :value="item.id"/>
                            </el-select>
                        </el-form-item>

                        <div v-if="form.id">
                            <h4>Yetkili: {{ form.user.name }}</h4>
                        </div>

                        <el-form-item label="Degisim Kodu">
                            <el-input placeholder="Degisim Kodu" v-model="form.code" class="input-with-select" readonly="">
                                <el-button slot="append" icon="el-icon-search" type="success" @click="generateCode"/>
                            </el-input>
                        </el-form-item>

                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="Kullanim Limiti">
                                    <el-input v-model="form.max_usage_count" placeholder="Kullanim Limiti" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Kullanilan">
                                    <el-input v-model="form.current_usage_count" placeholder="Kullanilan" disabled />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="Baslangic Tarihi">
                                    <el-date-picker v-model="form.start_at" type="datetime" placeholder="Tarih Seçiniz" value-format="yyyy-MM-dd HH:mm:ss" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Bitis Tarihi">
                                    <el-date-picker v-model="form.end_at" type="datetime" placeholder="Tarih Seçiniz" value-format="yyyy-MM-dd HH:mm:ss" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-form-item>
                            <el-switch v-model="form.is_active" active-text="Aktif" />
                        </el-form-item>

                    </el-form>
                </section>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
    export default {
        name: 'ElementForm',
        data() {
            return {
                itemLoading: true,
                userSearchLoading: false,
                users: [],
                form: {
                    user_id: null,
                    code: null,
                }
            }
        },

        methods: {
            loadItem() {

                this.itemLoading = true;

                this.$http
                    .get(this.$route.meta.end_point + '/' + this.$route.params.id, {
                        params: this.serverParams
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body.item
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            storeItem() {

                this.itemLoading = true;

                this.$http
                    .post(this.$route.meta.end_point, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                        this.$router.push('edit/' + response.body.item.id);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.itemLoading = true;

                this.$http
                    .put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                    confirmButtonText: 'Tamam',
                    cancelButtonText: 'Iptal Et',
                    type: 'warning'
                })
                    .then(() => {
                        this.$http
                            .delete(this.$route.meta.end_point + '/' + id)
                            .then(response => {
                                this.itemLoading = false;

                                this.$router.push('/');

                                this.apiSuccessMessage(response)
                            })
                            .catch((e) => {
                                this.itemLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            },

            generateCode() {
                this.form.code = (Math.random().toString(36).substring(2, 8)).toUpperCase();
            },

            searchUsers(query) {
                this.userSearchLoading = true;

                this.$http
                    .get('tools/search/users?q=' + query)
                    .then(response => {
                        this.userSearchLoading = false;
                        this.users = response.body.items;
                    })
                    .catch((e) => {
                        this.userSearchLoading = false;
                        this.apiErrorMessage(e);
                    });
            },
        },

        mounted() {
            if (this.$route.params.id) {
                this.loadItem()
            } else {
                this.itemLoading = false;
            }
        }
    }
</script>