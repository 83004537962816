<template>
	<div class="bank-installment" v-loading="pageLoading">
		<table class="ui celled padded table small striped celled">
			<thead>
			<tr>
				<th width="1">Taksit</th>
				<th v-for="row in rows" :key="row.id" class="text-center">
					<img :src="'/resources/images/banks/' + row.name + '.png'" alt="" height="15">
					<div class="text-purple">
						{{ row.kart_name }}
					</div>
				</th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="installmentNo in 9" :key="installmentNo" class="middle aligned">
				<td class="text-center">
					<div class="ui black label mini">
						<b>{{ installmentNo }}</b>
					</div>
				</td>
				<td class="text-center middle aligned font-size-12"
					:class="{
				    active : selectedRow.id === row.id && installmentNo === selectedRow.installment_no,
				    negative : findBestRow(row, installmentNo)
				}"
					v-for="row in rows" :key="row.id"
					@click="handleSelected(row, installmentNo)">
					<b v-for="line in row.rows" :key="line.id" v-if="line.variables.installment_no == installmentNo">
						<b>{{ $n(calculate(line.variables.commission), 'currency') }}</b>
					</b>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
    export default {
        name: 'BankInstallment',
        props: {
            total: {
                type: String,
                default: 0
            },
            container: null,
        },
        data() {
            return {
                pageLoading: false,
                rows: [],
                selectedRow: {
                    id: null,
                    installment_no: null,
                },
                bestRow: {
                    id: null,
                    installment_no: null,
                },
                bestRows: []
            }
        },
        mounted() {
            this.index();
        },
        methods: {

            index() {
                this.pageLoading = true;

                this.$http
                    .get('tools/plugins/modules/17/blocks')
                    .then(response => {
                        this.pageLoading = false;
                        this.rows = response.body.rows;

                        this.findBestRows();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            findBestRows() {
                let i;
                let total = parseFloat(this.total);

                for (i = 1; i <= 9; i++) {

                    let commissions = [];

                    _.forEach(this.rows, (bank) => {

                        let commission = 0;

                        _.forEach(bank.rows, (row) => {
                            if (i === parseInt(row.variables.installment_no)) {
                                commission = parseFloat(row.variables.commission)
                            }
                        });

                        commissions.push({
                            total: (total / 100) * commission,
                            installment_no: i,
                            id: bank.id,
                        })
                    });

                    let sorts = _.sortBy(commissions, [function (o) {
                        return o.total;
                    }]);

                    this.bestRows.push(sorts[0])
                }
            },

            findBestRow(row, installmentNo) {

                let item = _.filter(this.bestRows, (o) => {
                    return o.installment_no === installmentNo && o.id === row.id
                });

                if (item[0]) {
                    return true;
                }

                return false;
            },

            calculate(commission) {
                return (parseFloat(this.total) / 100) * parseFloat(commission)
            },

            handleSelected(row, installmentNo) {

                this.selectedRow = {
                    id: row.id,
                    installment_no: installmentNo
                }

                if (!this.container) {
                    return false;
                }

                this.container.bank_name = row.title;
                this.container.installment_no = installmentNo
            },
        }
    };
</script>