<template>
    <div id="Footer">
        <div class="footer flex align-center justify-space-between" :class="{'above':position === 'above'}">
            <div>
                <a href="https://pragmatic.ddmweb.it/docs/#/" target="_blank"><span class="hidden-xs-only">Documentation</span><span
                    class="hidden-sm-and-up">Docs.</span></a>
            </div>
            <div>
                <span>Made by <a href="https://ddmweb.it" target="_blank">Linko</a></span>
                <a class="el-button buy-btn animated fadeInRight el-button--default el-button--small is-plain"
                   href="https://themeforest.net/user/linko91/portfolio" target="_blank">
                    <i class="mdi mdi-cart"></i> Purchase <span class="hidden-xs-only">Pragmatic</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        props: ['position']
    }
</script>