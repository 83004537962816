import layouts from '../config/layout'

import MasterContainer from '../app/Container/Master'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Dt'

import SiteSetting from '../app/Pages/System/SiteSetting'
import BoardSetting from '../app/Pages/System/BoardSetting'

import Menu from '../app/Pages/System/Menu'
import Theme from '../app/Pages/System/Theme'
import Definition from '../app/Pages/System/Definition'
import Status from '../app/Pages/System/Status'
import Zone from '../app/Pages/System/Zone'

export default {
    path: '/system',
    name: 'system',
    component: MasterContainer,
    meta: {
        auth: true,
        layout: layouts.navLeft,
        title: 'Sistem',
    },
    children: [
        {
            path: 'settings',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Ayarlar',
            },
            children: [
                {
                    name: 'system.settings.site.show',
                    path: 'site',
                    component: SiteSetting,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Site Ayarlari',
                        path: 'site',
                        end_point: 'system/settings',
                    },
                },
                {
                    path: 'cards/:type',
                    component: MasterContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Pano Yonetimi',
                    },
                    children: [
                        {
                            name: 'system.settings.cards.index',
                            path: '',
                            component: BoardSetting,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Pano Yonetimi',
                                path: 'system/cards',
                                end_point: 'system/cards',
                            }
                        },
                    ]
                },
            ]
        },
        {
            path: 'menus/:type',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Menuler',
            },
            children: [
                {
                    name: 'system.menus.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Menuler',
                        path: 'system/menus',
                        end_point: 'system/menus',
                    }
                },
                {
                    name: 'system.menus.create',
                    path: 'create',
                    component: Menu,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Menu Olustur',
                        path: 'system/menus',
                        end_point: 'system/menus',
                    }
                },
                {
                    name: 'system.menus.edit',
                    path: 'edit/:id',
                    component: Menu,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Menu Bilgileri',
                        path: 'system/menus',
                        end_point: 'system/menus',
                    }
                }
            ]
        },
        {
            path: 'themes',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Temalar',
            },
            children: [
                {
                    name: 'system.themes.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Temalar',
                        path: '/system/themes',
                        end_point: 'system/themes',
                    }
                },
                {
                    name: 'system.themes.create',
                    path: 'create',
                    component: Theme,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Tema Olustur',
                        path: 'system/themes',
                        end_point: 'system/themes',
                    }
                },
                {
                    name: 'system.themes.edit',
                    path: 'edit/:id',
                    component: Theme,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Tema bilgileri',
                        path: 'system/themes',
                        end_point: 'system/themes',
                    }
                }
            ]
        },
        {
            path: 'definitions/:type',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Tanimlar',
            },
            children: [
                {
                    name: 'system.definitions.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Tanimlar',
                        path: 'system/definitions',
                        end_point: 'system/definitions',
                    }
                },
                {
                    name: 'system.definitions.create',
                    path: 'create',
                    component: Definition,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Tanim Olustur',
                        path: 'system/definitions',
                        end_point: 'system/definitions',
                    }
                },
                {
                    name: 'system.definitions.edit',
                    path: 'edit/:id',
                    component: Definition,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Tanim Bilgileri',
                        path: 'system/definitions',
                        end_point: 'system/definitions',
                    }
                }
            ]
        },
        {
            path: 'statuses/:type',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Durumlar',
            },
            children: [
                {
                    name: 'system.statuses.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Durumlar',
                        path: 'system/statuses',
                        end_point: 'system/statuses',
                    }
                },
                {
                    name: 'system.statuses.create',
                    path: 'create',
                    component: Status,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Durum Olustur',
                        path: 'system/statuses',
                        end_point: 'system/statuses',
                    }
                },
                {
                    name: 'system.statuses.edit',
                    path: 'edit/:id',
                    component: Status,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Durum Bilgileri',
                        path: 'system/statuses',
                        end_point: 'system/statuses',
                    }
                },
            ]
        },
        {
            path: 'zones/:type',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Bolgeler',
            },
            children: [
                {
                    name: 'system.zones.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Bolgeler',
                        path: 'system/zones',
                        end_point: 'system/zones',
                    }
                },
                {
                    name: 'system.zones.create',
                    path: 'create',
                    component: Zone,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Bolge Olustur',
                        path: 'system/zones',
                        end_point: 'system/zones',
                    }
                },
                {
                    name: 'system.zones.edit',
                    path: 'edit/:id',
                    component: Zone,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Bolge Bolgileri',
                        path: 'system/zones',
                        end_point: 'system/zones',
                    }
                }
            ]
        },
    ]
}
