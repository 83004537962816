<template>
	<el-form-item :label="label">
		<el-select
				v-model="container[model]"
				multiple
				filterable
				clearable
				:placeholder="label"
				:loading="itemLoading"
		>
			<el-option v-for="(item, key) in items" :key="'category-' + key" :label="item.nest_title" :value="item.id"
					   v-if="!onlyParent" />
			<el-option v-for="(item, key) in items" :key="'category-' + key" :label="item.nest_title" :value="item.id"
					   v-if="onlyParent && item.parent_id" />
		</el-select>
	</el-form-item>
</template>
<script>
    export default {
        name: 'SelectCategory',
        props: ['container', 'model', 'alias', 'label', 'onlyParent'],
        data() {
            return {
                itemLoading: false,
                lastFormId: null,
                items: []
            }
        },

        mounted() {
            this.items = _.orderBy(this.$store.state.ui.categories[this.alias], 'nest_title');
        }
    }
</script>