<template>
    <vue-scroll class="page-element-form" v-loading="itemLoading">
        <div class="loading-container">
            <div v-if="form.id">
                <div class="card-box">
                    <header class="card-box-header">
                        <div class="title">
                            {{ form.title }}
                        </div>
                        <div class="actions">
                            <el-button icon="el-icon-edit" type="text" class="el-button-link green" @click="modalClassRoomVisible = true"/>
                        </div>
                    </header>
                    <section class="card-box-content">
                        <div class="box-list">
                            <div class="box-list-item">
                                {{ form.category_text }}
                            </div>
                            <div class="box-list-item">
                                <span v-if="form.class">
                                    {{ form.class.title }}
                                </span>
                            </div>
                            <div class="box-list-item">
                                <div class="name">
                                    Aktif
                                </div>
                                <div>
                                    <span v-if="form.is_active">
                                        <i class="mdi mdi-check text-color-success"></i>
                                    </span>
                                    <span v-if="!form.is_active">
                                        <i class="mdi mdi-emoticon-sad empty-data-icon text-color-danger"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Periods :itemId="form.id" :key="form.id" />
                <Notes :url="$route.meta.end_point + '/' + form.id"/>
                <Activities :source="$route.meta.end_point + '/' + form.id"/>

                <el-dialog :title="form.title" :visible.sync="modalClassRoomVisible" v-loading="itemLoading" width="60%">
                    <el-form :label-position="'top'" ref="form" :model="form">
                        <el-form-item>
                            <SelectCategory label="Etiketler" alias="classrooms" model="categories" :container="form" :only-parent="true"/>
                        </el-form-item>

                        <el-form-item label="Sinif">
                            <select-definition label="Sinif" alias="classes" model="class_definition_id" :container="form"/>
                        </el-form-item>

                        <el-form-item label="Başlık">
                            <el-input v-model="form.title" placeholder="Başlık"/>
                        </el-form-item>

                        <el-form-item>
                            <el-switch v-model="form.is_active" active-text="Aktif"/>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="success" size="medium" @click="updateItem" v-if="form.id" class="fluid">
                            <i class="mdi mdi-check"></i> Guncelle
                        </el-button>
                    </div>
                </el-dialog>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
    import Notes from "../../../../../components/Notes";
    import Activities from "../../../../../components/Activities";
    import SelectCategory from "../../../../../components/SelectCategory";
    import SelectDefinition from "../../../../../components/SelectDefinition";
    import Periods from "./_parts/Periods";

    export default {
        name: 'ElementForm',
        components: {
            Periods,
            SelectDefinition,
            SelectCategory,
            Activities,
            Notes
        },
        data() {
            return {
                itemLoading: true,
                form: {
                    title: null,
                    seo: {},
                    metas: {},
                    file_path: [],
                },

                modalClassRoomVisible : false,
            }
        },

        methods: {

            loadItem() {

                this.itemLoading = true;

                this.$http
                    .get(this.$route.meta.end_point + '/' + this.$route.params.id)
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body.item;

                        if (!this.form.metas.lenght) {
                            this.form.metas = {}
                        }

                        if (!_.size(this.form.seo)) {
                            this.form.seo = {};
                        }
                    });
            },

            updateItem() {

                this.itemLoading = true;

                this.form.seo = {
                    meta_title: this.form.title,
                    meta_description: this.form.title,
                    meta_keywords: this.form.title,
                };

                this.$http
                    .put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.modalClassRoomVisible = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            }
        },

        mounted() {
            this.loadItem()
        }
    }
</script>