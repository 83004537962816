<style lang="scss" scoped>
    .empty-items {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 35%;
        }
    }
</style>

<template>
    <div v-if="!appCounter(items)" class="empty-items">
        <img src="@/resources/images/empty-items.png" alt="no content">
    </div>
</template>

<script>
    export default {
        name: 'Empty',
        props: [
            'items'
        ],
    }
</script>