<template>
    <div class="card-box event-card-box">
        <header class="card-box-header">
            <div class="title">Planlanan Etkinlikler</div>
            <div class="actions">
                <el-tooltip content="Yeni Etkinlik Ekle">
                    <el-button icon="el-icon-plus" size="small" type="primary" @click="crateItem"/>
                </el-tooltip>
            </div>
        </header>
        <section class="card-box-content">
            <Empty :items="items" />

            <div class="modern-items" v-loading="itemLoading">
                <template v-for="item in items">
                    <div class="item" :key="item.id" @click="editItem(item.form)">
                        <div class="content">
                            <div class="customer-name">{{ item.title }}</div>
                            <div class="metas">
                                <div class="meta">
                                    <mdi :icon="item.type.icon"/>
                                    {{ item.type.title }}
                                </div>
                                <div class="meta staff">
                                    <mdi icon="mdi-account-box"/>
                                    {{ item.created }}
                                </div>
                                <div class="meta">
                                    <mdi icon="mdi-calendar"/>
                                    {{ item.start_at }}
                                </div>
                                <div class="meta">
                                    <mdi icon="mdi-tag"/>
                                    {{ item.prioritization.title }}
                                </div>
                            </div>
                        </div>
                        <div class="info">
                            <div class="ui label" :style="{background: item.status.bg_color, color: item.status.text_color}">
                                {{ item.status.title }}
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </section>

        <el-dialog :title="modalTitle" :visible.sync="modalVisible" v-loading="modalLoading" width="50%" class="modal-inner" append-to-body>
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Etkinlik Türü">
                    <select-status placeholder="Etkinlik Türü" alias="events" model="status_id" :container="form"/>
                </el-form-item>

                <el-form-item label="Önceliklendirme">
                    <select-status placeholder="Önceliklendirme" alias="prioritizations" model="prioritization_status_id" :container="form"/>
                </el-form-item>

                <el-form-item label="Etkinlik Durumu">
                    <select-status placeholder="Etkinlik Durumu" alias="event-statuses" model="event_status_id" :container="form"/>
                </el-form-item>

                <el-form-item label="Başlık">
                    <el-input v-model="form.title" placeholder="Başlık"/>
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Baslangic Tarihi">
                            <el-date-picker v-model="form.start_at" type="datetime" placeholder="Tarih Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Bitis Tarihi">
                            <el-date-picker v-model="form.end_at" type="datetime" placeholder="Tarih Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Notunuz">
                    <el-input type="textarea" placeholder="Notunuz..."
                              v-model="form.description"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="storeItem" v-if="!form.id" class="fluid">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" size="medium" @click="updateItem" v-if="form.id" class="fluid">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Empty from "@/components/Empty";

export default {
    name: 'Events',
    components: {Empty},
    props: ['source', 'userId', 'cardId'],
    data() {
        return {
            itemLoading: true,
            modalLoading: false,
            modalVisible: false,
            modalTitle: 'Etkinlik Ekle',
            statuses: [],
            items: {},
            form: {
                user_id: this.userId,
                card_id: this.cardId,
            },
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        index() {
            this.$http
                .get('enrollments/events', {
                    params: {
                        user_id: this.userId,
                    }
                })
                .then(response => {
                    this.items = response.body.data.rows;
                    this.itemLoading = false;
                });
        },

        crateItem() {
            this.modalTitle = 'Etkinlik Ekle';
            this.modalVisible = true;
            this.form = {
                //
            };
        },

        storeItem() {
            this.modalLoading = true;

            this.form.fake_url = this.source;
            this.form.card_id = this.cardId;
            this.form.user_id = this.userId;

            console.log(this.form)

            this.$http.post('enrollments/events', this.form)
                .then(response => {

                    this.modalLoading = false;
                    this.modalVisible = false;

                    this.apiSuccessMessage(response);
                    this.index();
                })
                .catch((e) => {
                    this.modalLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        editItem(item) {
            this.itemLoading = false;
            this.modalLoading = false;
            this.modalVisible = true;
            this.modalTitle = 'Etkinlik Duzenle';

            this.form = item;
        },

        updateItem() {
            this.modalLoading = true;
            this.form.fake_url = this.source;

            this.$http
                .put('enrollments/events/' + this.form.id, this.form)
                .then(response => {

                    this.modalLoading = false;
                    this.modalVisible = false;

                    this.apiSuccessMessage(response);
                    this.index();
                })
                .catch((e) => {
                    this.modalLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        destroyItem(id) {

            this.modalLoading = false;
            this.itemLoading = true;

            this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.$http.delete('enrollments/events/' + id, {
                    fake_url: this.source
                }).then(response => {
                    this.modalLoading = false;
                    this.modalVisible = false;
                    this.itemLoading = false;

                    this.index();

                    this.$message({
                        type: 'success',
                        message: 'Delete completed'
                    });
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });

                this.modalLoading = false;
                this.itemLoading = false;
            });
        }
    }
}
</script>
