<template>
    <div class="box-logo flex align-center" :class="{'nav-collapsed':collapseNav, [mode]:true}">
        <img class="image-logo" @click="goto('/')" src="@/resources/images/mini.png" alt="logo"/>
        <div class="app-name" @click="goto('/')">
            <img class="image-logo" @click="goto('/')" src="@/resources/images/logo.svg" alt="logo"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Logo',
        props: ['collapseNav', 'mode'],
        data() {
            return {}
        },
        methods: {
            collapseNavToggle() {
                this.$emit('collapse-nav-toggle')
            },
            goto(index, indexPath) {
                this.$router.push(index)
            }
        },

        mounted() {
            //
        }
    }
</script>
