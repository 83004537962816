<template>
    <section v-loading="itemLoading" class="ps-container ps">

        <div class="card-box">
            <header class="card-box-header">
                <div class="title">
                    Bloklar
                </div>
                <div class="actions">
                    <el-button @click="crateItem" type="text" class="el-button-link primary" icon="el-icon-plus" />
                </div>
            </header>
            <section class="card-box-content padding-20">
                <vue-scroll>
                    <div style="height: 600px">
                        <table class="ui table">
                            <thead>
                            <tr>
                                <th>Baslik</th>
                                <th width="140"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, key) in items">
                                <td>{{ item.title }}</td>
                                <td class="text-right">
                                    <div class="d-flex" style="justify-content: end">
                                        <el-button type="text" icon="el-icon-edit" class="el-button-link primary" @click="editItem(item)" />
                                        <el-button type="text" icon="el-icon-delete" class="el-button-link red" @click="destroyItem(item.id)" />
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </vue-scroll>
            </section>
        </div>

        <el-dialog :title="modalTitle" :visible.sync="modalVisible" v-loading="modalLoading" width="75%">
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-form-item label="Başlık">
                    <el-input v-model="form.title" placeholder="Başlık"/>
                </el-form-item>

                <el-form-item label="Sablon">
                    <el-input v-model="form.name" placeholder="Sablon"/>
                </el-form-item>

                <div v-if="form.id">
                    <Sliders :items="form.rows" v-if="name === 'Sliders'" />
                    <SocialLinks :items="form.rows" v-if="name === 'SocialLinks'" />
                    <Contents :items="form.rows" v-if="name === 'Contents'" />
                    <BankCommission :items="form.rows" :metas="form.metas" v-if="name === 'BankCommission'" />
                </div>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="storeItem" v-if="!form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" size="medium" @click="updateItem" v-if="form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>
<script>

    import Sliders from "./Modules/Slider";
    import SocialLinks from "./Modules/SocialLinks";
    import Contents from "./Modules/Contents";
    import BankCommission from "./Modules/BankCommission";

    export default {
        name: 'Blocks',
        components: {
            BankCommission,
            Contents,
            SocialLinks,
            Sliders
        },
        props: ['source', 'name'],
        data() {
            return {
                itemLoading: true,
                modalLoading: false,
                modalVisible: false,
                modalTitle: 'Ekle',
                teachers: {},
                items: {},
                form: {
                   //
                },
            }
        },
        mounted() {
            this.index();
        },
        methods: {
            index() {
                this.$http.get(this.source + '/blocks', {
                    params: {
                        fake_url: this.source,
                    }
                }).then(response => {
                    this.items = response.body.rows;
                    this.itemLoading = false;
                });
            },

            crateItem() {
                this.modalTitle = 'Ekle';
                this.modalVisible = true;
                this.form = {
                    metas :{}
                };
            },

            storeItem() {
                this.modalLoading = true;

                this.form.fake_url = this.source;

                this.$http.post(this.source + '/blocks', this.form)
                    .then(response => {

                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            editItem(item) {
                this.itemLoading = false;
                this.modalLoading = false;
                this.modalVisible = true;

                this.modalTitle = 'Duzenle';

                if (!_.size(item.metas)) {
                    item.metas = {};
                }

                this.form = item;
            },

            updateItem() {

                this.modalLoading = true;

                this.form.fake_url = this.source;

                this.$http.put(this.source + '/blocks/' + this.form.id, this.form)
                    .then(response => {

                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {

                this.modalLoading = false;
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete(this.source + '/blocks/' + id, {
                        fake_url: this.source
                    }).then(response => {
                        this.modalLoading = false;
                        this.modalVisible = false;
                        this.itemLoading = false;

                        this.index();

                        this.$message({
                            type: 'success',
                            message: 'Delete completed'
                        });
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.modalLoading = false;
                    this.itemLoading = false;
                });
            },

            getTeachers() {
                this.itemLoading = true;
                this.$http.get('school/teachers?perPage=100').then(response => {
                    this.itemLoading = false;
                    this.teachers = response.body.rows;
                });
            }
        }
    }
</script>