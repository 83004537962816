<template>
    <section v-loading="pageLoading">
        <div class="card-box">
            <header class="card-box-header">
                <div class="title">
                    Quiz Sonuclari
                </div>
                <div class="actions">
                    <el-button type="text" icon="el-icon-plus" class="el-button-link primary" @click="createItem"/>
                </div>
            </header>
            <section class="card-box-content">
                <vue-scroll>
                    <div class="box-list" style="height: 400px">
                        <div class="box-list-item" v-for="item in items" :key="item.id">
                            <div>
                                <b>{{ item.title }}</b>
                                <div>{{ item.current_point }} / {{ item.total_point }}</div>
                            </div>
                            <div>
                                <div class="d-flex justify-flex-end">
                                    <el-button type="text" class="el-button-link green" icon="el-icon-edit"
                                               @click="editItem(item)"/>
                                    <el-button type="text" class="el-button-link red" icon="el-icon-delete"
                                               @click="destroyItem(item.id)"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-scroll>
            </section>
        </div>

        <el-dialog title="Rapor Bilgileri" :visible.sync="modalVisible" v-loading="pageLoading" width="70%">
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Kitap">
                    <el-select v-model="form.content_id" clearable filterable value-key="id" placeholder="Kitap">
                        <el-option v-for="item in books" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="Başlık">
                    <el-input v-model="form.title" placeholder="Başlık"/>
                </el-form-item>

                <el-form-item label="Mesaj" >
                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="Mesaj" v-model="form.message"/>
                </el-form-item>

                <el-form-item label="Quiz Puani">
                    <el-input v-model="form.total_point" placeholder="Quiz Puani"/>
                </el-form-item>

                <el-form-item label="Aldigi Puan">
                    <el-input v-model="form.current_point" placeholder="Aldigi Puan"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" v-if="!form.id" class="fluid" @click="storeItem">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" v-if="form.id" class="fluid" @click="updateItem">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
    export default {
        name: 'QuizReport',
        props: ['source'],
        data() {
            return {
                pageLoading: false,
                endPoint: null,
                form: {
                    //
                },

                items: [],
                books: [],

                modalVisible: null,
            }
        },

        methods: {
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint)
                    .then(response => {
                        this.pageLoading = false;
                        this.items = response.body.rows
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            createItem() {
                this.form = {
                    //
                };

                this.modalVisible = true;
            },

            editItem(item) {
                this.form = item;

                this.modalVisible = true;
            },

            storeItem() {

                this.pageLoading = true;

                this.$http
                    .post(this.endPoint, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.modalVisible = false;
                        this.apiSuccessMessage(response);

                        this.index();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.pageLoading = true;

                this.$http
                    .put(this.endPoint + '/' + this.form.id, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.modalVisible = false;
                        this.apiSuccessMessage(response);

                        this.index();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.pageLoading = true;

                this
                    .$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                        confirmButtonText: 'Tamam',
                        cancelButtonText: 'Iptal Et',
                        type: 'warning'
                    })
                    .then(() => {
                        this.$http
                            .delete(this.endPoint + '/' + id)
                            .then(response => {
                                this.pageLoading = false;

                                this.index();

                                this.apiSuccessMessage(response)
                            })
                            .catch((e) => {
                                this.pageLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.pageLoading = false;
                    });
            },

            getBooks() {
                this.pageLoading = true;

                this.$http
                    .get('platform/education-books')
                    .then(response => {
                        this.pageLoading = false;
                        this.books = response.body.rows
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },
        },

        mounted() {

            this.endPoint = this.source + '/quiz-reports';

            this.getBooks();
            this.index();
        },
    }
</script>