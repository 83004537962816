<template>
    <i :class="'icon mdi ' + icon"></i>
</template>

<script>
    export default {
        name: 'mdi',
        props: ['icon']
    };
</script>
