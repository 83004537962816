<style lang="scss" scoped>
    .selectable {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        background: #111;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(92, 80, 185, 0.2);
        color: #5C50B9;

        &.active {
            background: rgba(40, 167, 69, 1);
            color: #fff;
        }
    }
</style>

<template>
    <section v-loading="pageLoading">
        <div class="card-box">
            <header class="card-box-header">
                <div class="title">
                    Raporlar
                </div>
                <div class="actions">
                    <el-button type="text" icon="el-icon-plus" class="el-button-link primary" @click="createItem"/>
                </div>
            </header>
            <section class="card-box-content">
                <vue-scroll>
                    <div class="box-list" style="height: 400px">
                        <div class="box-list-item" v-for="item in items" :key="item.id">
                            <div>
                                <b>{{ item.publish_at }}</b>
                            </div>
                            <div>
                                <div class="d-flex justify-flex-end">
                                    <el-button type="text" class="el-button-link green" icon="el-icon-edit"
                                               @click="editItem(item)"/>
                                    <el-button type="text" class="el-button-link red" icon="el-icon-delete"
                                               @click="destroyItem(item.id)"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-scroll>
            </section>
        </div>

        <el-dialog title="Rapor Bilgileri" :visible.sync="modalVisible" v-loading="pageLoading" width="70%">
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Yayinlanma Tarihi">
                    <el-date-picker v-model="form.publish_at" type="datetime" placeholder="Yayinlanma Tarihi"
                                    value-format="yyyy-MM-dd" format="yyyy-MM-dd"/>
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Baslangic Tarihi">
                            <el-date-picker v-model="form.start_at" type="datetime" placeholder="Baslangic Tarihi"
                                            value-format="yyyy-MM-dd" format="yyyy-MM-dd"/>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="Bitis Tarihi">
                            <el-date-picker v-model="form.end_at" type="datetime" placeholder="Bitis Tarihi"
                                            value-format="yyyy-MM-dd" format="yyyy-MM-dd"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Speaking">
                    <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 2}" placeholder="Speaking"
                              v-model="form.speaking"/>
                </el-form-item>

                <el-form-item label="Listening">
                    <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 2}" placeholder="Listening"
                              v-model="form.listening"/>
                </el-form-item>

                <el-form-item label="Reading">
                    <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 2}" placeholder="Reading"
                              v-model="form.reading"/>
                </el-form-item>

                <el-form-item label="Writing">
                    <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 2}" placeholder="Writing"
                              v-model="form.writing"/>
                </el-form-item>

                <table class="ui table">
                    <thead>
                    <tr>
                        <th>Performence</th>
                        <th width="150" class="text-center" v-for="point in points" :key="point.value">
                            {{ point.value }} {{ point.title }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in performance" :key="item.title">
                        <td>{{ item.title }}</td>
                        <td class="text-center" v-for="point in points" :key="point.value">
                            <div class="fluid" style="display: flex; align-items: center; justify-content: center">
                                <div class="selectable"
                                     :class="{active : (form[item.key] === point.value)}" @click="form[item.key] = point.value">
                                    {{ point.value }}
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" v-if="!form.id" class="fluid" @click="storeItem">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" v-if="form.id" class="fluid" @click="updateItem">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
    export default {
        name: 'MonthlyReport',
        props: ['source'],
        data() {
            return {
                pageLoading: false,
                endPoint: null,
                form: {
                    effort: null,
                    attitude: null,
                    achievement: null,
                },

                items: [],

                modalVisible: null,

                performance: [
                    {
                        key: 'effort',
                        title: 'Effort',
                    },
                    {
                        key: 'attitude',
                        title: 'Attitude',
                    },
                    {
                        key: 'achievement',
                        title: 'Achievement',
                    }
                ],

                points: [
                    {
                        value: 'A',
                        title: 'excellent',
                    },
                    {
                        value: 'B',
                        title: 'good',
                    },
                    {
                        value: 'C',
                        title: 'ok/satisfactory',
                    },
                    {
                        value: 'D',
                        title: 'poor',
                    }
                ]
            }
        },

        methods: {
            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint)
                    .then(response => {
                        this.pageLoading = false;
                        this.items = response.body.rows
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            createItem() {
                this.form = {
                    effort: null,
                    attitude: null,
                    achievement: null,
                };

                this.modalVisible = true;
            },

            editItem(item) {
                this.form = item;

                this.modalVisible = true;
            },

            storeItem() {

                this.pageLoading = true;

                this.$http
                    .post(this.endPoint, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.modalVisible = false;
                        this.apiSuccessMessage(response);

                        this.index();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.pageLoading = true;

                this.$http
                    .put(this.endPoint + '/' + this.form.id, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.modalVisible = false;
                        this.apiSuccessMessage(response);

                        this.index();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.pageLoading = true;

                this
                    .$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                        confirmButtonText: 'Tamam',
                        cancelButtonText: 'Iptal Et',
                        type: 'warning'
                    })
                    .then(() => {
                        this.$http
                            .delete(this.endPoint + '/' + id)
                            .then(response => {
                                this.pageLoading = false;

                                this.index();

                                this.apiSuccessMessage(response)
                            })
                            .catch((e) => {
                                this.pageLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.pageLoading = false;
                    });
            },
        },

        mounted() {

            this.endPoint = this.source + '/monthly-reports';

            this.index();
        },
    }
</script>