<template>
    <section v-loading="itemLoading" >
        <div class="card-box">
            <header class="card-box-header">
                <div class="title">
                    Veliler
                </div>
            </header>
            <section class="card-box-content">
                <div class="box-list">
                    <div class="box-list-item box-list-item-editable box-list-item-media" v-for="(item, key) in items" :key="key" @click="openBlankWindow('/crm/users/edit/' + item.id)">
                        <div class="box-list-item-media-item box-list-item-clickable">
                            <div class="media">
                                <avatar :img="item.avatar" type="customer" />
                            </div>
                            <div class="text fluid">
                                <div class="name">
                                    {{ item.name }}
                                </div>
                                <div class="email" v-if="item.address">
                                    {{ item.address.email }}
                                </div>
                                <div class="phone" v-if="item.address">
                                    {{ item.address.phone }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
    import SingleDraft from "../../../../../components/SingleDraft";

    export default {
        name: 'Parents',
        components: {SingleDraft},
        props: ['source', 'accountId'],
        data() {
            return {
                itemLoading: true,
                items: {}
            }
        },
        mounted() {
            this.index();
        },
        methods: {
            index() {
                this.$http.get('crm/users', {
                    params: {
                        fake_url: this.source,
                        filters: [
                            {
                                relation: null,
                                add_with: 'true',
                                inputs: [
                                    {
                                        name: 'account_id',
                                        field: 'account_id',
                                        type: 'singleSelect',
                                        value: this.accountId
                                    }
                                ]
                            },
                        ]
                    }
                })
                    .then(response => {
                        this.itemLoading = false;
                        this.items = response.body.rows;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            }
        }
    }
</script>