<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading">
            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                            <el-button @click="$router.go(-1)" type="text" icon="el-icon-back"
                                       class="el-button-link grey" />
                        </el-tooltip>

                        <el-tooltip content="Olustur" effect="dark" placement="bottom" v-if="$route.params.id">
                            <el-button @click="createButton($route.meta.end_point)" type="text" icon="el-icon-plus"
                                       class="el-button-link primary" />
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="!$route.params.id">
                            <el-button @click="storeItem" type="text" icon="el-icon-check"
                                       class="el-button-link green" />
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="$route.params.id">
                            <el-button @click="updateItem" type="text" icon="el-icon-check"
                                       class="el-button-link green" />
                        </el-tooltip>
                    </div>
                </header>
                <section class="card-box-tabs padded small">
                    <el-form :label-position="'top'" ref="form" :model="form">
                        <el-tabs :tab-position="'top'">
                            <el-tab-pane label="Icerik">
                                <el-form-item label="Başlık">
                                    <el-input v-model="form.title" placeholder="Başlık" />
                                </el-form-item>
                                <el-form-item label="Yayınlanma Tarihi">
                                    <el-date-picker v-model="form.publish_at" type="datetime" placeholder="Tarih Seçiniz" value-format="yyyy-MM-dd HH:mm:ss" />
                                </el-form-item>

                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <el-form-item label="Hedef Linki">
                                            <el-input v-model="form.target_link" placeholder="Hedef Linki" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Icerik Etiketleri">
                                            <el-input v-model="form.tags" placeholder="Icerik Etiketleri" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-form-item label="Kısa Açıklama" class="short">
                                    <trumbowyg v-model="form.short_description" :config="$store.state.trumbowygConfig"/>
                                </el-form-item>

                                <el-form-item label="Açıklama">
                                    <trumbowyg v-model="form.description" :config="$store.state.trumbowygConfig" />
                                </el-form-item>

                                <div class="card-title fs-16">Ozellikler</div>

                                <el-form-item label="Sıralama">
                                    <el-input-number v-model="form.sort" placeholder="Sıralama" />
                                </el-form-item>

                                <el-form-item>
                                    <el-switch v-model="form.is_featured" active-text="One cikartilsin mi?" />
                                    <div class="small">
                                        One cikarilan urunler sadece ilgili yerlerde cikar. Listeleme sayfalarinda
                                        cikmaz.
                                    </div>
                                </el-form-item>

                                <el-form-item>
                                    <el-switch v-model="form.is_plugin" active-text="Eklentiler gozuksun mu?" />
                                </el-form-item>

                                <el-form-item>
                                    <el-switch v-model="form.is_active" active-text="Aktif" />
                                </el-form-item>

                                <div class="card-title fs-16">Medya</div>
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="Kapak Resmi">
                                            <file-browser v-model="form.file_path" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="Detay Resmi">
                                            <file-browser v-model="form.metas.detail_img" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <div class="card-title fs-16">Video</div>
                                <el-form-item label="Baslik">
                                    <el-input v-model="form.metas.video_title" placeholder="Baslik" />
                                </el-form-item>

                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="Detay Resmi">
                                            <file-browser v-model="form.metas.video_img" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="18">
                                        <el-form-item label="Sure">
                                            <el-input v-model="form.metas.video_time" placeholder="Sure" />
                                        </el-form-item>
                                        <el-form-item label="Kaynak">
                                            <el-input v-model="form.metas.video_source" placeholder="Kaynak" />
                                        </el-form-item>
                                        <el-form-item label="Aciklama">
                                            <el-input v-model="form.metas.video_description" type="textarea" placeholder="Aciklama" rows="4" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="Urun">
                                <el-form-item label="Urun Tipi">
                                    <el-select v-model="form.product.type" clearable filterable value-key="id"
                                               placeholder="Urun Tipi">
                                        <el-option
                                            v-for="item in productTypes"
                                            :key="item.id"
                                            :label="item.title"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="Urun Kodu">
                                    <el-input v-model="form.product.reference" placeholder="Urun Kodu" />
                                </el-form-item>

                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <el-form-item label="Minimum Alis Miktari">
                                            <el-input v-model="form.product.min_quantity"
                                                      placeholder="Minimum Alis Miktari" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Maximum Alis Miktari">
                                            <el-input v-model="form.product.max_quantity"
                                                      placeholder="Maximum Alis Miktari" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <el-form-item label="Satisa Girecegi Tarih">
                                            <el-date-picker v-model="form.product.start_at" type="datetime"
                                                            placeholder="Satisa Girecegi Tarih"
                                                            value-format="yyyy-MM-dd HH:mm:ss" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Satisdan Kaldirilacagi Tarih">
                                            <el-date-picker v-model="form.product.end_at" type="datetime"
                                                            placeholder="Satisdan Kaldirilacagi Tarih"
                                                            value-format="yyyy-MM-dd HH:mm:ss" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <template v-if="form.product.type === 'webinar'">
                                    <el-form-item label="Konferans Tarihi">
                                        <el-date-picker v-model="form.metas.event_at" type="datetime" placeholder="Tarih Seçiniz" value-format="yyyy-MM-dd HH:mm:ss" />
                                    </el-form-item>

                                    <el-form-item label="Minidil.tech Ders Id">
                                        <el-input v-model="form.metas.live_lesson_id" placeholder="Minidil.tech Ders Id" />
                                    </el-form-item>

                                    <el-form-item label="Canli Yayin Linki">
                                        <el-input v-model="form.metas.live_url" placeholder="Canli Yayin Linki" />
                                    </el-form-item>

                                    <el-row :gutter="24">
                                        <el-col :span="6">
                                            <el-form-item label="Dokuman Resmi">
                                                <file-browser v-model="form.metas.document_image" />
                                            </el-form-item>
                                        </el-col>
                                    </el-row>

                                    <el-form-item label="Dokuman">
                                        <document-browser :filePath.sync="form.metas.document_file"/>
                                    </el-form-item>

                                </template>
                            </el-tab-pane>
                            <el-tab-pane label="Kargo">
                                <el-form-item>
                                    <el-switch v-model="form.product.is_shipping" active-text="Kargolu Urun" />
                                </el-form-item>

                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <el-form-item label="Agirlik">
                                            <el-input v-model="form.product.weight" placeholder="Agirlik" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Agirlik Birimi">
                                            <select-definition label="Agirlik Birimi" alias="weight-classes" model="weight_class_definition_id" :container="form.product" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <el-row :gutter="24">
                                            <el-col :span="8">
                                                <el-form-item label="Uzunluk">
                                                    <el-input v-model="form.product.length" placeholder="Uzunluk" />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Genislik">
                                                    <el-input v-model="form.product.width" placeholder="Genislik" />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Yukseklik">
                                                    <el-input v-model="form.product.height" placeholder="Yukseklik" />
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Uzunluk Birimi">
                                            <select-definition label="Uzunluk Birimi" alias="length-classes" model="length_class_definition_id" :container="form.product" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                            </el-tab-pane>
                            <el-tab-pane label="Fiyat">
                                <el-form-item label="Vergi Turu">
                                    <select-definition label="Vergi Turu" alias="tax-classes"
                                                       model="tax_class_definition_id" :container="form.product" />
                                </el-form-item>
                                <el-form-item label="Birim Fiyati">
                                    <el-input v-model="form.product.q_price" placeholder="Birim Fiyati" />
                                </el-form-item>
                                <el-form-item label="Paket Fiyati">
                                    <el-input v-model="form.product.package_price" placeholder="Paket Fiyati" />
                                </el-form-item>
                                <el-form-item label="Fiyat">
                                    <el-input v-model="form.product.price" placeholder="Fiyat" />
                                </el-form-item>
                            </el-tab-pane>
                            <el-tab-pane label="Stok">
                                <el-form-item label="Stokta Yoksa Durumu">
                                    <el-select v-model="form.product.stock_status_id" value-key="id" placeholder="Stokta Yoksa Durumu" clearable>
                                        <el-option v-for="item in stockStatuses" :key="item.id" :label="item.title" :value="item.id" />
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="Stok">
                                    <el-input v-model="form.product.stock_total" placeholder="Stok" />
                                </el-form-item>

                                <el-form-item label="Paket Stok">
                                    <el-input v-model="form.product.package_quantity" placeholder="Paket Stok" />
                                </el-form-item>

                                <el-form-item>
                                    <el-switch v-model="form.product.subtract_stock" active-text="Satin Alindikca Stoktan Dusulsun Mu?" />
                                </el-form-item>
                            </el-tab-pane>
                            <el-tab-pane label="Baglantilar">
                                <SelectCategory label="Kategoriler" alias="products" model="categories"
                                                :container="form" />

                                <el-form-item label="Marka">
                                    <el-select v-model="form.product.brand_id" clearable value-key="id"
                                               placeholder="Marka">
                                        <el-option
                                            v-for="item in brands"
                                            :key="item.id"
                                            :label="item.title"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="Egitim Yili">
                                    <select-definition label="Egitim Yili" alias="education-years"
                                                       model="education_year_definition_id" :container="form.product" />
                                </el-form-item>

                                <el-form-item label="Egitim Donemi">
                                    <select-definition label="Egitim Donemi" alias="education-periods"
                                                       model="education_period_definition_id"
                                                       :container="form.product" />
                                </el-form-item>

                                <el-form-item label="Rozetler">
                                    <el-select v-model="form.badges" value-key="id" multiple clearable placeholder="Rozetler">
                                        <el-option
                                            v-for="item in badges"
                                            :key="item.id"
                                            :label="item.title"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="Onerilen Urunler">
                                    <el-select v-model="form.suggestions" multiple placeholder="Onerilen Urunler" clearable filterable>
                                        <el-option v-for="item in suggestions" :key="item.id" :label="item.title" :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="Sikca Sorulan Sorular">
                                    <el-select v-model="form.metas.faqs" multiple placeholder="Sikca Sorulan Sorular" clearable filterable>
                                        <el-option v-for="item in faqs" :key="item.id" :label="item.title" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="Konusmacilar">
                                    <el-select v-model="form.metas.speakers" multiple placeholder="Konusmacilar" clearable filterable>
                                        <el-option v-for="item in speakers" :key="item.id" :label="item.title" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-tab-pane>
                            <el-tab-pane label="Seo">
                                <Seo :container="form.seo" />
                            </el-tab-pane>
                        </el-tabs>
                    </el-form>
                </section>
            </div>

            <div class="card-box" v-if="form.id">
                <section class="card-box-tabs">
                    <el-tabs :tab-position="'top'">
                        <el-tab-pane label="Secenekler">
                            <Options :source="contentEndPoint + '/' + $route.params.id" />
                        </el-tab-pane>
                        <el-tab-pane label="Dosyalar">
                            <Files :source="contentEndPoint + '/' + $route.params.id" />
                        </el-tab-pane>
                        <el-tab-pane label="Sekmeler">
                            <Tabs :source="contentEndPoint + '/' + $route.params.id" />
                        </el-tab-pane>
                        <el-tab-pane label="Ozellikler">
                            <Features :source="contentEndPoint + '/' + $route.params.id" />
                        </el-tab-pane>
                    </el-tabs>
                </section>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
import Files from "../../Cms/Contents/_parts/Files";
import Tabs from "../../Cms/Contents/_parts/Tabs";
import Features from "../../Cms/Contents/_parts/Features";
import Options from "../../Cms/Contents/_parts/Options";
import FileBrowser from "../../../../components/FileBrowser";
import Trumbowyg from "vue-trumbowyg/src/component";
import SelectDefinition from "../../../../components/SelectDefinition";
import SelectCategory from "../../../../components/SelectCategory";
import Seo from "../../../../components/Seo";
import DocumentBrowser from "../../../../components/DocumentBrowser";

export default {
    name: 'ElementForm',
    components: {
        DocumentBrowser,
        Seo, SelectCategory, SelectDefinition, Trumbowyg, FileBrowser, Options, Features, Tabs, Files},
    data() {
        return {
            itemLoading: true,
            contentEndPoint: null,
            form: {
                file_path: null,
                seo: {},
                metas: {},
                product: {},
                badges: [],
                suggestions: [],
            },
            productTypes: [
                {
                    id: 'physical',
                    title: 'Fiziksel',
                },
                {
                    id: 'digital',
                    title: 'Dijital',
                },
                {
                    id: 'webinar',
                    title: 'Webinar',
                },
            ],
            brands: [],
            badges: [],
            suggestions: [],
            stockStatuses: [],
            faqs: [],
            speakers: [],
        }
    },

    mounted() {

        if (this.$route.params.id) {
            this.loadItem()
        } else {
            this.itemLoading = false;
        }

        this.getBrands();
        this.getBadges();
        this.getSuggestions();
        this.getStockStatuses();
        this.getFaqs();
        this.getSpeakers();
    },

    methods: {
        loadItem() {

            this.itemLoading = true;

            this.$http
                .get(this.$route.meta.end_point + '/' + this.$route.params.id, {
                    params: this.serverParams
                })
                .then(response => {
                    this.itemLoading = false;

                    this.contentEndPoint = 'cms/contents/products';

                    this.form = response.body.item;

                    if (!_.size(this.form.seo)) {
                        this.form.seo = {};
                    }

                    if (!this.form.product) {
                        this.form.product = {}
                    }

                    if (!_.size(this.form.metas)) {
                        this.form.metas = {}
                    }

                    let badges = [];
                    let suggestions = [];

                    _.forEach(this.form.suggestions, function (item, key) {
                        suggestions.push(item.suggested_content_id)
                    });

                    this.form.badges.forEach(function (item) {
                        badges.push(item.status_id);
                    });

                    this.form.badges = badges;
                    this.form.suggestions = suggestions;
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        storeItem() {

            this.itemLoading = true;

            this.$http
                .post(this.$route.meta.end_point, this.form)
                .then(response => {
                    this.itemLoading = false;
                    this.apiSuccessMessage(response);
                    this.$router.push('edit/' + response.body.item.id);
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        updateItem() {

            this.itemLoading = true;

            this.$http
                .put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                .then(response => {
                    this.itemLoading = false;
                    this.apiSuccessMessage(response);
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        destroyItem(id) {
            this.itemLoading = true;

            this.$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                confirmButtonText: 'Tamam',
                cancelButtonText: 'Iptal Et',
                type: 'warning'
            })
                .then(() => {
                    this.$http
                        .delete(this.$route.meta.end_point + '/' + id)
                        .then(response => {
                            this.itemLoading = false;

                            this.$router.push('/');

                            this.apiSuccessMessage(response)
                        })
                        .catch((e) => {
                            this.itemLoading = false;
                            this.apiErrorMessage(e);
                        });
                })
                .catch(() => {
                    this.itemLoading = false;
                });
        },

        getBrands() {
            this.itemLoading = true;

            this.$http
                .get('catalog/brands')
                .then(response => {
                    this.itemLoading = false;
                    this.brands = response.body.rows;
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        getBadges() {
            this.itemLoading = true;

            this.$http
                .get('system/statuses?alias=badges')
                .then(response => {
                    this.itemLoading = false;
                    this.badges = response.body.rows;
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        getSuggestions() {
            this.itemLoading = true;

            this.$http
                .get('catalog/products?perPage=1000')
                .then(response => {
                    this.itemLoading = false;
                    this.suggestions = response.body.rows;
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        getFaqs() {
            this.itemLoading = true;

            this.$http
                .get('cms/contents/faqs?perPage=1000')
                .then(response => {
                    this.itemLoading = false;
                    this.faqs = response.body.rows;
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        getSpeakers() {
            this.itemLoading = true;

            this.$http
                .get('cms/contents/teachers?perPage=1000')
                .then(response => {
                    this.itemLoading = false;
                    this.speakers = response.body.rows;
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        getStockStatuses() {
            this.itemLoading = true;

            this.$http
                .get('system/statuses?alias=stocks')
                .then(response => {
                    this.itemLoading = false;
                    this.stockStatuses = response.body.rows;
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },
    },


}
</script>