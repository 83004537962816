<template>
    <section v-loading="itemLoading">
        <div class="card-box" >
            <header class="card-box-header">
                <div class="title">
                    Siparisler
                </div>
            </header>
            <section class="card-box-content">
                <vue-scroll>
                    <div style="height: 400px">
                        <div class="box-list" v-for="item in items" :key="item.id" v-if="item.status.status_id !== $store.state.settings.order_cancel_status_id">
                            <div class="box-list-item box-list-item-title" @click="openBlankWindow('/sales/orders/edit/' + item.id)" style="cursor: pointer">
                                <div class="name fluid">
                                    <span v-if="item.year">
                                        {{ item.year.title }} /
                                    </span>
                                    <span v-if="item.period">
                                        {{ item.period.title }}
                                    </span>
                                </div>
                                <div class="fluid my-flex" style="justify-content: flex-end">
                                    {{ $d(new Date(item.created_at), 'long', 'tr-TR') }}
                                </div>
                            </div>
                            <div class="box-list-item" v-for="product in item.products" :key="product.id">
                                <div>
                                    <div>
                                        <b>{{ product.title }}</b>
                                        <span v-if="product.address_type === 'service_get'"> (Alinacak)</span>
                                        <span v-if="product.address_type === 'service_delivery'"> (Birakilacak)</span>
                                    </div>
                                    <div v-if="product.alias === 'classrooms' || product.alias === 'products'">
                                        {{ product.content.category_text }}
                                    </div>
                                    <div v-if="product.alias === 'vehicles' && product.address">
                                        {{ product.address.title }}: {{ product.address.address_1 }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-scroll>
            </section>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Orders',
        props: ['source', 'itemId'],
        data() {
            return {
                itemLoading: true,
                form: {
                    //
                },
                items: [],
            }
        },

        methods: {

            index() {
                this.$http
                    .get(this.source + '/products', {
                        params: {
                            fake_url: this.source,
                        }
                    })
                    .then(response => {
                        this.items = response.body.items;
                        this.itemLoading = false;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },
        },

        mounted() {
            this.index();
        },
    }
</script>