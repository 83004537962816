<template>
    <div class="loading-container" v-loading="pageLoading">
        <el-form-item label="Gorunum">
            <el-select v-model="content.view" placeholder="Gorunum" filterable clearable>
                <el-option
                        v-for="item in views"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value" />
            </el-select>
        </el-form-item>

        {{ type.title }}
    </div>
</template>

<script>
    export default {
        name: 'match',
        props : [
            'content',
            'type'
        ],
        data() {
            return {
                pageLoading: true,
                views : [],
            }
        },

        methods: {
            //
        },

        mounted() {
            this.pageLoading = false;
        }
    }
</script>