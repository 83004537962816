<style>
    .fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
        float: right !important;
    }
</style>

<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="pageLoading">
            <div class="card-box">
                <section class="card-box-content padding-20">
                    <el-form ref="form" :model="filterForm">
                        <el-row :gutter="24">
                            <el-col :span="8">
                                <el-form-item class="margin-0">
                                    <el-select v-model="filterForm.room_content_id" value-key="id" placeholder="Odalar"
                                               clearable filterable @change="initFormFilter">
                                        <el-option v-for="item in rooms" :key="item.id" :label="item.title"
                                                   :value="item.id"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item class="margin-0">
                                    <el-select v-model="filterForm.classroom_content_id" value-key="id"
                                               placeholder="Siniflar" clearable filterable
                                               @change="initFormFilter">
                                        <el-option v-for="item in classrooms" :key="item.id" :label="item.title"
                                                   :value="item.id"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item class="margin-0">
                                    <el-select v-model="filterForm.teacher_user_id" value-key="id"
                                               placeholder="Ogretmenler"
                                               clearable filterable @change="initFormFilter">
                                        <el-option v-for="item in teachers" :key="item.id" :label="item.name"
                                                   :value="item.id"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </section>
            </div>

            <div class="card-box">
                <section class="card-box-content">
                    <div id="cal"></div>
                </section>
            </div>

            <el-dialog v-loading="pageLoading" :title="form.title" :visible.sync="modalVisible">
                <el-form :model="form">

                    <el-form-item label="Baslik">
                        <el-input v-model="form.title" placeholder="Baslik"/>
                    </el-form-item>

                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="Baslangic Tarihi">
                                <el-date-picker v-model="form.start_at" type="datetime" placeholder="Tarih Seçiniz"
                                                value-format="yyyy-MM-dd HH:mm:ss" format="dd/MM/yyyy HH:mm:ss"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Bitis Tarihi">
                                <el-date-picker v-model="form.end_at" type="datetime" placeholder="Tarih Seçiniz"
                                                value-format="yyyy-MM-dd HH:mm:ss" format="dd/MM/yyyy HH:mm:ss"/>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="Oda">
                        <el-select v-model="form.room_content_id" value-key="id" placeholder="Oda" clearable filterable>
                            <el-option v-for="item in rooms" :key="item.id" :label="item.title" :value="item.id"/>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="Sinif">
                        <el-select v-model="form.classroom_content_id" value-key="id" placeholder="Sinif" clearable
                                   filterable>
                            <el-option v-for="item in classrooms" :key="item.id" :label="item.title" :value="item.id"/>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="Ogretmenler">
                        <el-select v-model="form.teachers" value-key="id" placeholder="Ogretmenler" clearable filterable
                                   multiple>
                            <el-option v-for="item in teachers" :key="item.id" :label="item.name" :value="item.id"/>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="Renk">
                        <el-color-picker v-model="form.color" show-alpha :predefine="appConfig.predefineColors"/>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="success" class="fluid" @click="storeItem" v-if="!form.id">
                        <i class="mdi mdi-check"></i> Kaydet
                    </el-button>
                    <el-button type="success" class="fluid" @click="updateItem" v-if="form.id">
                        <i class="mdi mdi-check"></i> Guncelle
                    </el-button>
                </div>
            </el-dialog>
        </div>
    </vue-scroll>
</template>

<script>
    import moment from 'moment-timezone'
    import $ from 'jquery'
    import 'fullcalendar'
    import 'fullcalendar/dist/fullcalendar.css'

    export default {
        name: 'RoomProgram',
        data() {
            return {
                pageLoading: false,
                modalVisible: false,
                cal: null,
                filterForm: {
                    teacher_user_id: null,
                    classroom_content_id: null,
                    room_content_id: null,
                },
                form: {
                    teachers: []
                },
                teachers: [],
                rooms: [],
                classrooms: [],
                options: {
                    loading: (isLoading) => {
                        if (isLoading) {
                            this.pageLoading = true
                        } else {
                            this.pageLoading = false
                        }
                    },
                    customButtons: {
                        addEvent: {
                            text: '✚',
                            click: this.handleDayClick
                        }
                    },
                    locale: 'tr',
                    header: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'month,agendaWeek,agendaDay,listWeek addEvent'
                    },
                    height: 'auto',
                    firstDay: 1,
                    allDaySlot: true,
                    slotEventOverlap: true,
                    selectable: true,
                    selectHelper: true,
                    timeFormat: 'HH:mm',
                    navLinks: true, // can click day/week names to navigate views
                    editable: true,
                    eventLimit: true, // allow "more" link when too many events
                    events: {
                        type: 'GET',
                        url: this.$http.options.root + '/' + this.$route.meta.end_point,
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.token
                        },
                        data: {
                            filters: [
                                {
                                    relation: 'teachers',
                                    add_with: 'true',
                                    inputs: [
                                        {
                                            name: 'teacher_user_id',
                                            field: 'teacher_user_id',
                                            type: 'singleSelect',
                                            value: null
                                        }
                                    ]
                                },
                                {
                                    relation: null,
                                    inputs: [
                                        {
                                            name: 'room_content_id',
                                            field: 'room_content_id',
                                            type: 'singleSelect',
                                            value: null
                                        }
                                    ]
                                },
                                {
                                    relation: null,
                                    inputs: [
                                        {
                                            name: 'classroom_content_id',
                                            field: 'classroom_content_id',
                                            type: 'singleSelect',
                                            value: null
                                        }
                                    ]
                                }
                            ]
                        },
                    },
                    dayClick: this.handleDayClick,
                    eventClick: this.handleEventClick,
                    select: this.select,
                    startParam: 'start_at',
                    endParam: 'end_at',
                    titleParam: 'title_format',
                    timezone: 'UTC',
                    eventRender: function(event, element) {
                        element.find('.fc-title').html(event.title_format);
                    }
                },
            }
        },

        methods: {
            storeItem() {

                this.pageLoading = true;

                this.$http
                    .post(this.$route.meta.end_point, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.modalVisible = false;
                        this.cal.fullCalendar('refetchEvents');
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.pageLoading = true;

                this.$http
                    .put(this.$route.meta.end_point + '/' + this.form.id, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.modalVisible = false;

                        this.cal.fullCalendar('refetchEvents');

                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.pageLoading = true;

                this
                    .$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                        confirmButtonText: 'Tamam',
                        cancelButtonText: 'Iptal Et',
                        type: 'warning'
                    })
                    .then(() => {
                        this.$http
                            .delete(this.$route.meta.end_point + '/' + id)
                            .then(response => {
                                this.pageLoading = false;
                                this.modalVisible = false;

                                this.cal.fullCalendar('refetchEvents');

                                this.apiSuccessMessage(response)
                            })
                            .catch((e) => {
                                this.pageLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.pageLoading = false;
                    });
            },

            getRooms() {
                this.pageLoading = true;

                this.$http
                    .get('school/rooms', {
                        params: {
                            perPage: 500
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.rooms = response.body.rows
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getClassrooms() {
                this.pageLoading = true;

                this.$http
                    .get('school/classrooms', {
                        params: {
                            perPage: 500,
                            filters: [
                                {
                                    relation: 'product',
                                    add_with: 'true',
                                    inputs: [
                                        {
                                            name: 'education_year_definition_id',
                                            field: 'education_year_definition_id',
                                            type: 'singleSelect',
                                            value: this.appSettings.current_education_year_id
                                        }
                                    ]
                                }
                            ],
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.classrooms = response.body.rows
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getTeachers() {
                this.pageLoading = true;

                this.$http
                    .get('school/teachers', {
                        params: {
                            perPage: 100
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.teachers = response.body.rows
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            initFormFilter() {
                this.options.events.data.filters[0].inputs[0].value = this.filterForm.teacher_user_id;
                this.options.events.data.filters[1].inputs[0].value = this.filterForm.room_content_id;
                this.options.events.data.filters[2].inputs[0].value = this.filterForm.classroom_content_id;

                this.cal.fullCalendar('refetchEvents');
            },

            handleDayClick(date) {
                this.modalVisible = true;
                this.form = {
                    teachers: [],
                    start_at: moment(date._d).format('YYYY-MM-DD HH:mm:ss'),
                    end_at: moment(date._d).format('YYYY-MM-DD HH:mm:ss'),
                }
            },

            handleEventClick(item) {
                this.modalVisible = true;

                let teachers = [];

                _.forEach(item.teachers, function (item) {
                    teachers.push(item.teacher_user_id);
                });

                this.form = {
                    id: item.id,
                    room_content_id: item.room_content_id,
                    classroom_content_id: item.classroom_content_id,
                    color: item.color,
                    title: item.title,
                    start_at: item.start_at,
                    end_at: item.end_at,
                    teachers: teachers,
                }
            }
        },

        mounted() {
            this.getTeachers();
            this.getRooms();
            this.getClassrooms();

            this.cal = $(window.document.getElementById('cal'));
            this.cal.fullCalendar(this.options);
        }
    }
</script>