<template>
	<vue-scroll class="page-element-form">
		<div class="loading-container" v-loading="pageLoading">
			<el-form :label-position="'top'" ref="form" :model="form">
				<div class="ui five item menu small">
					<div class="item font-weight-600" v-for="tab in tabs" :key="tab.value"
						 :class="{active : tab.value === activeTab}">
						{{ tab.label }}
					</div>
				</div>

				<div class="my-flex mb-20" :style="{'justify-content' : activeTab === 1 ? 'end' : 'space-between'}">
					<div class="ui labeled icon button default fw-500" @click="handleTabBack" v-if="activeTab !== 1">
						<i class="el-icon-arrow-left icon"></i> Geri Don
					</div>

					<div class="ui right labeled icon button blue fw-500" @click="handleTabNext" v-if="activeTab !== 5">
						<i class="el-icon-arrow-right icon"></i> Devam Et
					</div>

					<div v-if="activeTab === 5">
						<div class="ui right labeled icon button green fw-500" @click="saveItem">
							<i class="el-icon-check icon"></i> Teklif Olustur
						</div>
						<div class="ui right labeled icon button purple fw-500" @click="calculateHandle">
							<i class="el-icon-refresh icon"></i> Yeniden Hesapla
						</div>
					</div>
				</div>

				<div v-show="activeTab === 1">
					<div class="card-box">
						<section class="card-box-content">
							<div class="box-list">
								<div class="box-list-item box-list-item-title">
									<div class="name">Musteri Bilgileri</div>
									<div v-if="customer">
										<el-button @click="openBlankWindow('/crm/users/edit/' + customer.id)"
												   type="text" class="el-button-link green" icon="el-icon-edit"
												   style="display: flex !important" />
									</div>
								</div>
							</div>

							<div class="padding-20" style="padding-top: 0 !important">
								<el-form-item label="Musteri" class="margin-bottom-0">
									<search-user :container="form" model="customer_id"
												 :change="handleSelectedCustomer" />
								</el-form-item>
							</div>

							<div v-if="customer">
								<div class="padding-20" style="padding: 0 20px !important">
									<el-form-item label="Sirket">
										<ti-select :container="customerForm" model="company_id"
												   :options="$store.state.ui.companies" />
									</el-form-item>

									<el-row :gutter="24">
										<el-col :span="12">
											<el-form-item label="Meslek">
												<select-definition alias="jobs" model="job_definition_id"
																   :container="customerForm" />
											</el-form-item>
										</el-col>
										<el-col :span="12">
											<el-form-item label="Egitim Durumu">
												<select-definition alias="educations" model="education_definition_id"
																   :container="customerForm" />
											</el-form-item>
										</el-col>
									</el-row>
								</div>
								<div class="box-list">
									<div class="box-list-item box-list-item-title">
										<div class="name">Bildirim Ayarlari</div>
									</div>
									<div class="padding-20">
										<el-row :gutter="24">
											<el-col :span="6" v-for="item in customerForm.notifications_format"
													:key="item.id">
												<el-checkbox v-model="item.status">{{ item.title }}</el-checkbox>
											</el-col>
										</el-row>
									</div>
								</div>
								<Addresses :source="'crm/users/' + customer.id" :accountId="customer.account_id" />
							</div>
						</section>
					</div>
				</div>

				<div v-show="activeTab === 2">
					<div class="card-box">
						<section class="card-box-content padding-20">
							<el-form-item label="Ogrenciler">
								<el-select v-model="form.student_id" placeholder="Ogrenciler" clearable
										   @change="handleSelectedStudent">
									<el-option v-for="item in students" :key="item.id" :label="item.full_name"
											   :value="item.id" />
								</el-select>
							</el-form-item>

							<div v-if="student">
								<el-form-item label="Donem">
									<select-definition alias="periods" model="school_period_definition_id"
													   :container="student" :key="student.id" />
								</el-form-item>

								<el-form-item label="Okul">
									<select-definition alias="schools" model="school_definition_id" :container="student"
													   :key="student.id" />
								</el-form-item>

								<el-form-item label="Sinif">
									<select-definition alias="classes" model="school_class_definition_id"
													   :container="student" :key="student.id" />
								</el-form-item>
							</div>
						</section>
					</div>
				</div>

				<div v-show="activeTab === 3">
					<div class="card-box">
						<section class="card-box-content padding-20">
							<el-form-item label="Egitim Yili">
								<select-definition alias="education-years" model="education_year_definition_id"
												   :container="form" />
							</el-form-item>

							<el-form-item label="Egitim Donemi">
								<select-definition alias="education-periods" model="education_period_definition_id"
												   :container="form" />
							</el-form-item>

							<el-form-item label="Ödeme Yöntemi">
								<ti-select :container="form" model="payment_plugin_id" :options="payments" />
							</el-form-item>

							<el-form-item label="Odeme Plani (Taksit Sayisi)">
								<el-select v-model="form.payment_plain" placeholder="Odeme Plani">
									<el-option v-for="item in 12" :key="item" :label="item + '. Taksit'"
											   :value="item" />
								</el-select>
							</el-form-item>

							<el-row :gutter="24">
								<el-col :span="12">
									<el-form-item label="Gecerlilik Tarihi">
										<el-date-picker v-model="form.expiry_at" type="datetime"
														placeholder="Gecerlilik Tarihi" value-format="yyyy-MM-dd" />
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="Ilk Odeme Tarihi">
										<el-date-picker v-model="form.payment_start_at" type="date"
														placeholder="Ilk Odeme Tarihi" value-format="yyyy-MM-dd" />
									</el-form-item>
								</el-col>
							</el-row>
						</section>
					</div>
				</div>

				<div v-show="activeTab === 4">
					<div class="card-box">
						<section class="card-box-content">
							<div class="classroom-tags-field">
								<el-form-item label="Siniflar">
									<select-definition alias="classes" model="class_definition_id" :container="classroomFilter" />
								</el-form-item>
								<div>
									<el-button icon="el-icon-search" type="text" class="el-button-link primary fluid"
											   @click="getClassrooms" style="display: flex !important">Sube Bul
									</el-button>
								</div>
							</div>
							<div>
								<vue-scroll>
									<div style="height: 500px" v-if="classrooms">
										<div class="box-list">
											<div
													v-for="(classroom, key) in classrooms"
													v-if="classroom.product.stock_total > 0"
													:key="key"
													:class="{
                                                     'box-list-item' : true,
                                                     'box-list-item-editable' : true,
                                                     'box-list-item-media' : true,
                                                     'box-list-item-editable-in-scroll' : true,
                                                     'box-list-item-classroom' : true,
                                                     'bg-success' : classroom.selected
                                                     }"
											>

												<div class="box-list-item-media-item">
													<el-row :gutter="24" class="fluid">
														<el-col :span="14">
															<div class="classroom-text"
																 @click="handleSelectedClassroom(classroom)"
																 style="cursor: pointer">
																<div class="classroom">
																	{{ classroom.title }}
																</div>
																<div class="tags">
																	<el-row :gutter="24">
																		<el-col :span="8"
																				v-for="category in classroom.categories"
																				:key="category.id"
																				v-if="category.category">
																			{{ category.category.title }}
																		</el-col>
																	</el-row>
																</div>
															</div>
														</el-col>
														<el-col :span="5">
															<div class="classroom-quantity">
																<el-input-number v-model="classroom.quantity"
																				 placeholder="Adet" />
															</div>
														</el-col>
														<el-col :span="5">
															<div class="classroom-price">
																<div class="quantity">
                                                                    <span v-if="classroom.product.current_stock <= 3"
																		  class="text-color-danger">
                                                                        {{ classroom.product.current_stock }}
                                                                    </span>
																	<span v-if="classroom.product.current_stock > 3"
																		  class="text-color-success">
                                                                        {{ classroom.product.current_stock }}
                                                                    </span>
																</div>
																<div class="price education-price">
																	{{ $n(classroom.product.package_price *
																	classroom.quantity, 'currency') }}
																</div>
																<div class="price">
																	{{ $n(classroom.product.price * classroom.quantity,
																	'currency') }}
																</div>
															</div>
														</el-col>
													</el-row>
												</div>
											</div>
										</div>
									</div>
								</vue-scroll>
							</div>
						</section>

					</div>
				</div>

				<div v-show="activeTab === 5">
                    <div class="card-box">
                        <section class="card-box-content">
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <header class="card-box-header">
                                        <div class="title">
                                            Teklif
                                        </div>
                                    </header>
                                    <div class="box-list">
                                        <div class="box-list-item">
                                            <div class="name fluid">Kampanyalar Aktif Olarak Uygulansin</div>
                                            <div class="price">
                                                <el-form-item class="margin-0">
                                                    <el-switch v-model="form.is_campaign" />
                                                </el-form-item>
                                            </div>
                                        </div>
                                        <div class="box-list-item" v-if="studentCount > 1">
                                            <div class="name fluid">Kardes Ozelliklerinden Yararlansin</div>
                                            <div class="price">
                                                <el-form-item class="margin-0" >
                                                    <el-switch v-model="form.has_sibling" />
                                                </el-form-item>
                                            </div>
                                        </div>
                                        <div class="box-list-item">
                                            <div class="name fluid">Eğitim Ücreti</div>
                                            <div class="price">
                                                {{ $n(calculate.product_total, 'currency') }}
                                            </div>
                                        </div>
                                        <div class="box-list-item">
                                            <div class="name fluid">Kitap Ücreti</div>
                                            <div class="price">
                                                {{ $n(0, 'currency') }}
                                            </div>
                                        </div>
                                        <div :class="{'box-list-item' : true, 'bg-red' : lodash.size(calculate.customCampaigns)}" v-for="item in calculate.campaigns" v-if="item.type === 'classrooms'">
                                            <div class="name fluid">{{ item.title }}</div>
                                            <div class="price">{{ $n(item.total, 'currency') }}</div>
                                        </div>
                                        <div class="box-list-item bg-green" v-for="item in calculate.customCampaigns" v-if="item.type === 'classrooms'">
                                            <div class="name fluid">{{ item.title }}</div>
                                            <div class="price">{{ $n(item.total, 'currency') }}</div>
                                        </div>
                                        <div class="box-list-item">
                                            <div class="name fluid">İndirim Toplamı</div>
                                            <div class="price">
                                                {{ $n(calculate.discount_total, 'currency') }}
                                            </div>
                                        </div>
                                        <div class="box-list-item">
                                            <div class="name fluid">Toplam</div>
                                            <div class="price" style="font-size:16px;font-weight:600;color:#5c50b9">
                                                {{ $n(calculate.total, 'currency') }}
                                            </div>
                                        </div>
                                    </div>
                                    <footer class="card-box-footer">
                                        <div class="fluid">
                                            <el-select v-model="customCampaigns" value-key="id" placeholder="Kampanyalar" @change="setCustomCampaign" multiple clearable filterable>
                                                <el-option v-for="item in campaigns" :key="item.id" :label="item.title" :value="item" v-if="!item.is_only_vehicles">
                                                    <span style="float: left">{{ item.title }}</span>
                                                    <span style="float: right; color: #8492a6; font-size: 13px">
                                                        <template v-if="item.year">
                                                            {{ item.year.title }}
                                                        </template>
                                                         /
                                                        <template v-if="item.period">
                                                            {{ item.period.title }}
                                                        </template>
                                                    </span>
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </footer>
                                </el-col>
                                <el-col :span="12">
                                    <header class="card-box-header">
                                        <div class="title">
                                            Odeme Takvimi
                                        </div>
                                    </header>
                                    <div class="box-list">
                                        <div class="box-list-item" v-for="item in calculate.plains">
                                            <div class="name fluid">
                                                {{ item.id }}. Taksit
                                                [{{ item.payment_at }}]
                                            </div>
                                            <div class="price">{{ $n(item.price, 'currency') }}</div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

                            <header class="card-box-header">
                                <div class="title">
                                    Komisyon Oranlari
                                </div>
                            </header>
                            <BankInstallment :container="calculate" :total="calculate.total" :key="calculate.total"/>
                        </section>
                    </div>
				</div>
			</el-form>
		</div>
	</vue-scroll>
</template>

<script>
    import SearchUser from "../../../../components/search-user";
    import Addresses from "../../Crm/Users/_parts/Addresses";
    import SelectCategory from "../../../../components/SelectCategory";
    import BankInstallment from "../../../../components/BankInstallment";

    export default {
        name: 'OfferCreate',
        components: {
            BankInstallment,
            SelectCategory,
            Addresses,
            SearchUser
        },
        data() {
            return {
                pageLoading: false,
                activeTab: 1,
                form: {
                    is_campaign: true,
                    has_sibling: false,

                    customer_id: null,
                    account_id: null,
                    student_id: null,
                    job_definition_id: null,
                    education_definition_id: null,
                    company_id: null,
                    education_year_definition_id: null,
                    education_period_definition_id: null,
                    payment_plugin_id: null,
                    payment_plain: null,
                    expiry_at: null,
                    payment_start_at: null,
                    classrooms: [],
                    vehicles: {
                        service_get: {},
                        service_delivery: {},
                    }
                },

                customerForm: {
                    company_id: null,
                    job_definition_id: null,
                    education_definition_id: null,
                    notifications_format: []
                },

                tabs: [
                    {
                        value: 1,
                        label: 'Musteri'
                    },
                    {
                        value: 2,
                        label: 'Ogrenci'
                    },
                    {
                        value: 3,
                        label: 'Odeme'
                    },
                    {
                        value: 4,
                        label: 'Sinif'
                    },
                    {
                        value: 5,
                        label: 'Ozet'
                    },
                ],

                customer: null,
                student: null,
                students: [],
                studentCount: 0,
                payments: [],

                classroomFilter: {
                    classrooms: []
                },

                classrooms: [],

                addresses: [],

                customCampaigns: [],
                customCampaignCode: null,
                customCampaignCodeKey: null,

                calculate: {
                    vehicle_total: 0,
                    product_total: 0,
                    product_discount_total: 0,
                    vehicle_discount_total: 0,
                    discount_total: 0,
                    total: 0,
                    plains: [],
                    campaignProducts: [],
                    campaignVehicles: [],
                    campaignProductCustoms: [],
                },
            }
        },

        created() {
            if (this.$route.query.customer_id) {
                this.form.customer_id = this.$route.query.customer_id;
                this.findCustomer(this.$route.query.customer_id);
            } else {
                this.pageLoading = false;
            }

            this.getPayments();
            this.getCampaigns();
        },

        methods: {

            getPayments() {

                this.pageLoading = true;

                this.$http
                    .get('tools/plugins/payments')
                    .then(response => {
                        this.pageLoading = false;
                        this.payments = response.body.rows;
                    });
            },

            getCampaigns() {
                this.pageLoading = true;

                this.$http
                    .get('marketing/campaigns?view=used&type=4')
                    .then(response => {
                        this.pageLoading = false;
                        this.campaigns = response.body.items
                    });
            },

            handleTabBack() {
                let key = this.activeTab - 1;

                if (!key) {
                    return;
                }

                this.activeTab = key;
            },

            handleTabNext() {

                let key = this.activeTab + 1;

                if (key >= 6) {
                    return;
                }

                switch (this.activeTab) {
                    case 1 :
                        this.updateCustomer();
                        break;

                    case 2 :
                        this.updateStudent();
                        break;

                    case 3:
                        this.validationOffer();
                        break;

                    case 4:
                        this.calculateHandle();
                        break;

                    default:
                        this.activeTab = key;
                        break;
                }
            },

            findCustomer(id) {
                this.pageLoading = true;

                this.$http
                    .get('crm/users/' + id, {
                        params: {
                            withs: ['addresses'],
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;

                        this.customer = response.body.item;
                        this.students = this.customer.students;
                        this.studentCount = _.size(this.students);
                        this.addresses = this.customer.addresses;

                        this.form.customer_id = this.customer.id;
                        this.form.account_id = this.customer.account_id;
                        this.form.job_definition_id = this.customer.job_definition_id;
                        this.form.education_definition_id = this.customer.education_definition_id;
                        this.form.company_id = this.customer.company_id;

                        // Customer information
                        this.customerForm.name = this.customer.name;
                        this.customerForm.company_id = this.customer.company_id;
                        this.customerForm.job_definition_id = this.customer.job_definition_id;
                        this.customerForm.education_definition_id = this.customer.education_definition_id;
                        this.customerForm.notifications_format = this.customer.notifications_format;
                        this.customerForm.address_id = this.customer.address_id;

                        this.form.student_id = null;
                        this.form.has_sibling = false;

                    });
            },

            updateCustomer() {
                this.pageLoading = true;

                if (!this.form.customer_id) {
                    return this.$notify.error({
                        title: 'Upss!',
                        message: 'Devam edebilmek icin musteri secimi yapmaniz gerekiyor!',
                        position: 'top-right'
                    });
                }

                this.$http
                    .put('crm/users/' + this.customer.id, this.customerForm)
                    .then(response => {
                        this.pageLoading = false;

                        this.apiSuccessMessage(response);

                        this.activeTab = this.activeTab + 1;
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateStudent() {

                if (!this.form.student_id) {
                    return this.$notify.error({
                        title: 'Upss!',
                        message: 'Devam edebilmek icin ogrenci secimi yapmaniz gerekiyor!',
                        position: 'top-right'
                    });
                }

                this.pageLoading = true;

                this.$http
                    .put('school/students/' + this.student.id, {
                        school_period_definition_id: this.student.school_period_definition_id,
                        school_definition_id: this.student.school_definition_id,
                        school_class_definition_id: this.student.school_class_definition_id,
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.apiSuccessMessage(response);

                        this.activeTab = this.activeTab + 1;
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            validationOffer() {

                if (!this.form.education_year_definition_id) {
                    return this.$notify.error({
                        title: 'Upss!',
                        message: 'Devam edebilmek icin Egitim Yili secimi yapmaniz gerekiyor!',
                        position: 'top-right'
                    });
                }

                if (!this.form.education_period_definition_id) {
                    return this.$notify.error({
                        title: 'Upss!',
                        message: 'Devam edebilmek icin Egitim Donemi secimi yapmaniz gerekiyor!',
                        position: 'top-right'
                    });
                }

                if (!this.form.payment_plugin_id) {
                    return this.$notify.error({
                        title: 'Upss!',
                        message: 'Devam edebilmek icin Ödeme Yöntemi secimi yapmaniz gerekiyor!',
                        position: 'top-right'
                    });
                }

                if (!this.form.payment_plain) {
                    return this.$notify.error({
                        title: 'Upss!',
                        message: 'Devam edebilmek icin Odeme Plani (Taksit Sayisi) secimi yapmaniz gerekiyor!',
                        position: 'top-right'
                    });
                }

                if (!this.form.expiry_at) {
                    return this.$notify.error({
                        title: 'Upss!',
                        message: 'Devam edebilmek icin Gecerlilik Tarihi secimi yapmaniz gerekiyor!',
                        position: 'top-right'
                    });
                }

                if (!this.form.payment_start_at) {
                    return this.$notify.error({
                        title: 'Upss!',
                        message: 'Devam edebilmek icin Ilk Odeme Tarihi secimi yapmaniz gerekiyor!',
                        position: 'top-right'
                    });
                }

                this.activeTab = this.activeTab + 1;
            },

            handleSelectedCustomer(e) {
                this.findCustomer(this.form.customer_id);
            },

            handleSelectedStudent(id) {

                if (!id) {
                    this.student = null;
                }

                this.student = _.filter(this.students, (o) => {
                    return o.id === id;
                })[0];
            },

            getClassrooms() {
                this.pageLoading = true;

                this.$http
                    .get('school/classrooms', {
                        params: {
                            filters: [
                                {
                                    relation: 'product',
                                    add_with: 'true',
                                    inputs: [
                                        {
                                            name: 'education_year_definition_id',
                                            field: 'education_year_definition_id',
                                            type: 'singleSelect',
                                            value: this.form.education_year_definition_id
                                        }
                                    ]
                                },
                                {
                                    relation: 'product',
                                    add_with: 'true',
                                    inputs: [
                                        {
                                            name: 'education_period_definition_id',
                                            field: 'education_period_definition_id',
                                            type: 'singleSelect',
                                            value: this.form.education_period_definition_id
                                        }
                                    ]
                                },
                                {
                                    relation: null,
                                    inputs: [
                                        {
                                            name: 'class_definition_id',
                                            field: 'class_definition_id',
                                            type: 'singleSelect',
                                            value: this.classroomFilter.class_definition_id
                                        }
                                    ]
                                },
                            ],
                            perPage: 40
                        }
                    })
                    .then(response => {

                        this.classrooms = [];

                        _.forEach(response.body.rows, (item) => {
                            item.quantity = 1;
                            this.classrooms.push(item);
                        });

                        this.pageLoading = false;
                    });
            },

            handleSelectedClassroom(classroom) {
                _.forEach(this.classrooms, (item) => {
                    item.selected = false;
                });

                classroom.selected = true;

                this.form.classrooms = [{
                    content_id: classroom.id,
                    quantity: classroom.quantity,
                }];
            },

            getVehicles(key) {

                this.form.vehicles[key] = {
                    id: this.form.vehicles[key].id,
                    content_id: null,
                    title: null,
                    price: 0,
                    quantity: 1,
                    stock_total: 0,
                    education_year_definition_id: this.form.education_year_definition_id,
                    education_period_definition_id: this.form.education_period_definition_id,
                    contents: []
                };

                if (!this.form.vehicles[key].id) {
                    return;
                }

                this.pageLoading = true;

                this.$http
                    .get('tools/search/vehicles', {
                        params: this.form.vehicles[key]
                    })
                    .then(response => {

                        this.pageLoading = false;

                        if (!_.size(response.body.items)) {

                            this.$notify.error({
                                title: 'Upss',
                                message: 'Uzgunum aramaniza uygun servis bulunamadi!',
                                position: 'top-right'
                            });

                            return;
                        }

                        this.form.vehicles[key].contents = response.body.items;

                        this.apiSuccessMessage(response);

                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            setVehicle(key) {

                if (!this.form.vehicles[key].id) {
                    return;
                }

                let content = null;
                let contentId = this.form.vehicles[key].content_id;

                this.form.vehicles[key].contents.forEach(function (item) {
                    if (item.id === contentId) {
                        content = item;
                    }
                });

                if (content) {
                    this.form.vehicles[key].title = content.title;
                    this.form.vehicles[key].price = content.product.price;
                }
            },

            setCustomCampaign() {

                let customCampaigns = [];

                this.customCampaigns.forEach(function (item) {
                    customCampaigns.push(item.id);
                });

                this.form.customCampaigns = customCampaigns;
            },

            findChangeCode() {

                if (!this.customCampaignCodeKey) {
                    return this.$notify.error({
                        title: 'Upss!',
                        message: 'Uzugunum Degisim Kodu alanini doldurmaniz gerekiyor!',
                        position: 'top-right'
                    });
                }

                this.pageLoading = true;

                this.$http
                    .get('marketing/campaign-change-codes/1?code=' + this.customCampaignCodeKey)
                    .then(response => {
                        this.pageLoading = false;

                        if (response.body.item) {
                            this.customCampaignCode = response.body.item;
                            this.form.campaign_change_code_id = response.body.item.id;
                        } else {
                            this.$notify.error({
                                title: 'Upss!',
                                message: 'Uzugunum arama isteginize uygun sonuc bulunamadi!',
                                position: 'top-right'
                            });
                        }
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyChangeCode() {
                this.customCampaignCode = null;
                this.customCampaignCodeKey = null;
                this.customCampaigns = null;
                this.form.customCampaigns = null;
                this.calculate.productCustomCampaigns = null;

                this.calculateHandle();
            },

            calculateHandle() {
                this.form.process = 'calculate';

                this.storeItem();
            },

            storeItem() {
                let classrooms = [];
                let vehicles = [];

                _.filter(this.classrooms, function (o) {
                    return o.selected;
                }).forEach(function (product) {
                    classrooms.push({
                        content_id: product.id,
                        quantity: product.quantity,
                    });
                });

                _.forEach(this.form.vehicles, function (vehicle, key) {
                    vehicles.push({
                        address_type: key,
                        address_id: vehicle.id,
                        content_id: vehicle.content_id,
                        quantity: vehicle.quantity,
                    });
                });

                let form = Object.assign({}, this.form);

                form.classrooms = classrooms;
                form.vehicles = vehicles;
                form.class_definition_id = this.classroomFilter.class_definition_id

                this.pageLoading = true;

                this.$http
                    .post(this.$route.meta.end_point, form)
                    .then(response => {

                        this.pageLoading = false;

                        this.apiSuccessMessage(response);

                        if (this.form.process) {
                            this.calculate = response.body.item;
                            this.activeTab = 5;
                        } else {
                            return this.$router.push('edit/' + response.body.item.id);
                        }
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            saveItem() {
                this
                    .$confirm('Teklifinizi bu sekilde olusturmak istediginizden emin misiniz?', 'Uyari', {
                        confirmButtonText: 'Tamam',
                        cancelButtonText: 'Iptal Et',
                        type: 'warning'
                    })
                    .then(() => {
                        this.form.process = null;
                        this.storeItem();
                    })
                    .catch(() => {
                        this.pageLoading = false;
                    });
            },
        },
    }
</script>
