<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="pageLoading">
            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                            <el-button @click="$router.go(-1)" type="text" icon="el-icon-back"
                                       class="el-button-link grey"/>
                        </el-tooltip>

                        <el-tooltip content="Olustur" effect="dark" placement="bottom">
                            <el-button @click="createButton($route.meta.end_point)" type="text" icon="el-icon-plus"
                                       class="el-button-link primary"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom">
                            <el-button @click="updateItem" type="text" icon="el-icon-check"
                                       class="el-button-link green"/>
                        </el-tooltip>
                    </div>
                </header>
                <section class="card-box-content padding-20">
                    <el-form :label-position="'top'" ref="form" :model="form">

                        <el-form-item label="Siniflar">
                            <el-select v-model="form.product_id" clearable filterable value-key="id"
                                       placeholder="Siniflar">
                                <el-option v-for="item in user.classrooms" :key="item.id" :label="item.content.title"
                                           :value="item.id"/>
                            </el-select>
                        </el-form-item>

                        <el-form-item :label="$route.meta.fieldTitle">
                            <el-select v-model="form.content_id" clearable filterable value-key="id"
                                       :placeholder="$route.meta.fieldTitle">
                                <el-option v-for="item in items" :key="item.id" :label="item.title" :value="item.id"/>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Yayinlanma Tarihi">
                            <el-date-picker v-model="form.publish_at" type="datetime" placeholder="Yayinlanma Tarihi" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd"/>
                        </el-form-item>
                    </el-form>
                </section>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'ElementForm',
        computed: mapState([
            'user',
            'platform'
        ]),
        data() {
            return {
                pageLoading: true,
                itemEndPoint: null,
                form: {
                    contents: [],
                    products: [],
                },

                items: [],
            }
        },

        methods: {

            getItems() {
                this.itemLoading = true;

                this.$http
                    .get(this.itemEndPoint, {
                        params: {
                            perPage: 1000
                        }
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.items = response.body.rows
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            loadItem() {

                this.pageLoading = true;

                this.$http
                    .get(this.$route.meta.end_point + '/' + this.$route.params.id)
                    .then(response => {
                        this.pageLoading = false;
                        this.form = response.body.item
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            storeItem() {

                this.pageLoading = true;

                this.$http
                    .post(this.$route.meta.end_point, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.apiSuccessMessage(response);
                        this.$router.push('/platform/teacher/' + this.$route.meta.path);
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.pageLoading = true;

                this.$http
                    .put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.pageLoading = true;

                this
                    .$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                        confirmButtonText: 'Tamam',
                        cancelButtonText: 'Iptal Et',
                        type: 'warning'
                    })
                    .then(() => {
                        this.$http
                            .delete(this.$route.meta.end_point + '/' + id)
                            .then(response => {
                                this.pageLoading = false;

                                this.$router.push('/');

                                this.apiSuccessMessage(response)
                            })
                            .catch((e) => {
                                this.pageLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.pageLoading = false;
                    });
            },
        },

        mounted() {

            this.itemEndPoint = 'platform/contents/' + this.$route.meta.path;

            if (this.$route.meta.path === 'homeworks' || this.$route.meta.path === 'newsletters') {
                this.itemEndPoint = 'platform/' + this.$route.meta.path;
            }

            this.getItems();

            if (this.$route.params.id) {
                this.loadItem()
            } else {
                this.pageLoading = false;
            }


        }
    }
</script>