<template>
    <div>
        <datatable :path="path" :source="source" :queryAppend="queryAppend" v-if="source" />
    </div>
</template>

<script>
    import Datatable from "../../components/Datatable";

    export default {
        name: 'Dt',
        components: {
            Datatable
        },
        data() {
            return {
                source: null,
                path: null,
                queryAppend : null,
            }
        },
        mounted() {

            if (this.$route.params.type) {

                if (this.$route.meta.is_link) {
                    this.source = this.$route.meta.end_point + '/' + this.$route.params.type;
                } else {
                    this.source = this.$route.meta.end_point + '?alias=' + this.$route.params.type;
                }

                this.path = this.$route.params.type;

            } else {

                if (!this.$route.meta.alias) {
                    this.source = this.$route.meta.end_point;
                } else {
                    if (this.$route.meta.is_link) {
                        this.source = this.$route.meta.end_point + '/' + this.$route.meta.alias;
                    } else {
                        this.source = this.$route.meta.end_point + '?alias=' + this.$route.meta.alias;
                    }
                }

                this.path = this.$route.meta.path;
            }

            if (this.queryAppend) {
                this.queryAppend = this.$route.meta.queryAppend;
            }
        }
    }
</script>