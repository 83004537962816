<template>
    <div v-loading="itemLoading">
        <table class="ui table">
            <thead>
            <tr>
                <th>Asamalar</th>
                <th width="1" class="text-right">
                    <div class="my-flex" style="justify-content: flex-end">
                        <el-button type="text" class="el-button-link primary" icon="el-icon-plus" @click="createItem" />
                    </div>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(stage, key) in value" :key="key">
                <td>
                    <div class="ui header" style="margin: 0; height: auto">
                        <i :class="'icon mdi mdi-' + stage.metas.icon"></i>
                        <div class="content">
                            {{ stage.title }}
                        </div>
                    </div>
                </td>
                <td class="text-right">
                    <div class="my-flex">
                        <el-button type="text" icon="el-icon-edit" class="el-button-link green" @click="editItem(stage)"/>
                        <el-button type="text"icon="el-icon-delete" class="el-button-link red" @click="destroyItem(stage.id)"/>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>

        <el-dialog :title="form.title" :visible.sync="modalVisible" width="75%" v-loading="itemLoading" append-to-body>
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-form-item label="Başlık">
                    <el-input v-model="form.title" placeholder="Başlık"/>
                </el-form-item>

                <el-form-item label="Ikon">
                    <div style="display: flex">
                        <span class="el-input-group__prepend text-center"
                              style="padding: 0 !important; width: 40px;">
                            <i :class="'mdi mdi-' + form.metas.icon"></i>
                        </span>
                        <el-select v-model="form.metas.icon" filterable allow-create default-first-option
                                   placeholder="Ikon">
                            <el-option v-for="item in icons" :key="item" :label="item" :value="item"/>
                        </el-select>
                    </div>
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Arkaplan Rengi">
                            <el-color-picker v-model="form.metas.bg_color" show-alpha
                                             :predefine="appConfig.predefineColors"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Metin Rengi">
                            <el-color-picker v-model="form.metas.text_color" show-alpha
                                             :predefine="appConfig.predefineColors"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Sıralama">
                    <el-input-number v-model="form.sort" placeholder="Sıralama" />
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="storeItem" v-if="!form.id" class="fluid">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" @click="updateItem" v-if="form.id" class="fluid">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'Stages',
        props: ['value', 'icons', 'pipelineId'],

        data() {
            return {
                source: 'cards/stages',
                itemLoading: true,
                form: {
                    metas: {}
                },
                stages: [],
                modalVisible: false,
            }
        },

        methods: {
            index() {
                this.itemLoading = false;
            },

            destroyItem(id) {

                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                    confirmButtonText: 'Tamam',
                    cancelButtonText: 'Iptal Et',
                    type: 'warning'
                })
                    .then(() => {
                        this.$http
                            .delete('cards/stages/' + id, {
                                params: {
                                    card_pipeline_id: this.pipelineId
                                }
                            })
                            .then(response => {
                                this.itemLoading = false;

                                this.apiSuccessMessage(response);

                                let stages = [];

                                if (_.size(this.value)) {
                                    stages = this.value;
                                }

                                this.stages = stages.filter(function (value) {
                                    return value.id !== id;
                                });

                                this.$emit('input', this.stages);
                            })
                            .catch((e) => {
                                this.itemLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            },

            createItem() {
                this.modalVisible = true;
                this.form = {
                    metas: {}
                }
            },

            storeItem() {
                this.itemLoading = true;
                this.form.card_pipeline_id = this.pipelineId;

                this.$http
                    .post('cards/stages', this.form)
                    .then(response => {
                        this.apiSuccessMessage(response);

                        let stages = this.value;

                        stages.push(response.body.item);

                        this.$emit('input', stages);

                        this.itemLoading = false;
                        this.modalVisible = false;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            editItem(stage) {
                this.modalVisible = true;
                this.form = stage;

                if (!_.size(this.form.metas)) {
                    this.form.metas = {};
                }
            },

            updateItem() {

                this.itemLoading = true;

                this.$http
                    .put('cards/stages/' + this.form.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.modalVisible = false;
                        this.apiSuccessMessage(response);

                        this.$emit('input', this.value);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },
        },

        mounted() {
            this.index();
        }
    }
</script>