<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="pageLoading">
            <div class="card-box">
                <header class="card-box-header has-buttons">
                    <div class="title">
                        Sinif Raporlari
                    </div>
                    <div class="actions">
                        <el-button icon="el-icon-download" type="text" class="el-button-link grey d-flex"
                                   @click="exportVisible = true"/>
                        <el-button icon="el-icon-search" type="text" class="el-button-link blue d-flex"
                                   @click="filterVisible = true"/>
                    </div>
                </header>
            </div>

            <el-row :gutter="24">
                <el-col :span="12">
                    <div class="card-box">
                        <div class="card-box-content">
                            <div class="box-list">
                                <div class="box-list-item">
                                    <div class="media">
                                        <i class="mdi mdi-gender-female fs-38 fw-500"></i>
                                    </div>
                                    <div class="text">
                                        <div class="total text-right fs-24 fw-500">
                                            175
                                        </div>
                                        <div class="description">
                                            Kadin
                                        </div>
                                    </div>
                                </div>
                                <div class="box-list-item">
                                    <div class="media">
                                        <i class="mdi mdi-gender-male fs-38 fw-500"></i>
                                    </div>
                                    <div class="text">
                                        <div class="total text-right fs-24 fw-500">
                                            175
                                        </div>
                                        <div class="description">
                                            Erkek
                                        </div>
                                    </div>
                                </div>
                                <div class="box-list-item">
                                    <div class="media">
                                        <i class="mdi mdi-school fs-38 fw-500"></i>
                                    </div>
                                    <div class="text">
                                        <div class="total text-right fs-24 fw-500">
                                            175
                                        </div>
                                        <div class="description">
                                            Toplam
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="card-box">
                        <div class="card-box-content padding-20" style="height: 218px">
                            <highcharts :options="options" style="height: 200px"/>
                        </div>
                    </div>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="18">
                    <div class="card-box">
                        <div class="card-box-header">
                            <div class="title">
                                1 / A
                            </div>
                            <div class="actions">
                                24
                            </div>
                        </div>
                        <div class="card-box-content">
                            <div class="box-list">
                                <div class="box-list-item box-list-item-title">
                                    <div class="name fluid">
                                        Yaz Okulu-2.dönem Eğitim-Öğretim Dönemi Eğitim Öğretim Dönemi Hafta İçi ÖĞLEN
                                        GRUBU AnaSınıfı 6 YAŞ 16 Temmuz 2019 09 Ağustos 2019 13:30-17:00
                                    </div>
                                </div>
                            </div>
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <div class="box-list">
                                        <div class="box-list-item">
                                            <div class="media">
                                                <i class="mdi mdi-gender-female fs-38 fw-500"></i>
                                            </div>
                                            <div class="text">
                                                <div class="total text-right fs-24 fw-500">
                                                    175
                                                </div>
                                                <div class="description">
                                                    Kadin
                                                </div>
                                            </div>
                                        </div>
                                        <div class="box-list-item">
                                            <div class="media">
                                                <i class="mdi mdi-gender-male fs-38 fw-500"></i>
                                            </div>
                                            <div class="text">
                                                <div class="total text-right fs-24 fw-500">
                                                    175
                                                </div>
                                                <div class="description">
                                                    Erkek
                                                </div>
                                            </div>
                                        </div>
                                        <div class="box-list-item">
                                            <div class="media">
                                                <i class="mdi mdi-school fs-38 fw-500"></i>
                                            </div>
                                            <div class="text">
                                                <div class="total text-right fs-24 fw-500">
                                                    175
                                                </div>
                                                <div class="description">
                                                    Toplam
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div class="box-list">
                                        <div class="box-list-item canvas-center" style="height: 218px">
                                            <highcharts :options="options" style="height: 180px; width: 400px"/>
                                        </div>
                                    </div>
                                </el-col>

                            </el-row>
                            <div class="box-list">
                                <div class="box-list-item box-list-item-title">
                                    <div class="name">
                                        Ogretmenler
                                    </div>
                                </div>
                                <div class="box-list-item box-list-item-editable box-list-item-media d-flex"
                                     v-for="item in 3" :key="item">
                                    <div class="box-list-item-media-item fluid">
                                        <div class="media">
                                            <avatar type="staff"/>
                                        </div>
                                        <div class="text fluid">
                                            <div class="name">
                                                Ogretmen Adi
                                            </div>
                                            <div class="email">
                                                <SingleDraft :userId="1" :receiver="'fff@asdf.com'" alias="email"/>
                                            </div>
                                            <div class="phone">
                                                <SingleDraft :userId="1" :receiver="123123" alias="sms"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <el-button type="text" class="el-button-link blue d-flex"
                                                   icon="el-icon-search"/>
                                    </div>
                                </div>
                            </div>
                            <div class="box-list">
                                <div class="box-list-item box-list-item-title">
                                    <div class="name">
                                        Ogrenciler
                                    </div>
                                </div>
                                <div class="box-list-item box-list-item-editable box-list-item-media d-flex"
                                     v-for="item in 3" :key="item">
                                    <div class="box-list-item-media-item fluid" style="align-items: start">
                                        <div class="media">
                                            <avatar type="student"/>
                                        </div>
                                        <div class="text fluid margin-right-20">
                                            <el-popover placement="top" width="600" trigger="hover">
                                                <div slot="reference">
                                                    <div class="name">
                                                        Ogrenci Adi <i class="mdi mdi-gender-male"></i>
                                                    </div>
                                                    <div class="info-item">
                                                        T.C. Kimlik No
                                                    </div>
                                                    <div class="info-item">
                                                        Dogum Yeri Ulke / Dogum Yeri Sehir - Dogum Tarihi
                                                    </div>
                                                    <div class="my-flex fluid">
                                                        <div>
                                                            Kan Grubu
                                                        </div>
                                                        <div>
                                                            wwwww
                                                        </div>
                                                    </div>
                                                    <div class="my-flex fluid">
                                                        <div>
                                                            Saglik Problemleri
                                                        </div>
                                                        <div>
                                                            wwwww
                                                        </div>
                                                    </div>
                                                    <div class="my-flex fluid">
                                                        <div>
                                                            Surekli Kullandigi Ilaclar
                                                        </div>
                                                        <div>
                                                            wwwww
                                                        </div>
                                                    </div>
                                                    <div class="my-flex fluid">
                                                        <div>
                                                            Alerjisi
                                                        </div>
                                                        <div>
                                                            wwwww
                                                        </div>
                                                    </div>
                                                    <div class="my-flex fluid">
                                                        <div>
                                                            Kisisel Bilgilerini Paylas?
                                                        </div>
                                                        <div>
                                                            wwwww
                                                        </div>
                                                    </div>
                                                    <div class="my-flex fluid border-bottom">
                                                        <div>
                                                            Okul
                                                        </div>
                                                        <div>
                                                            wwwww
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="parents">
                                                    <div class="parent border-bottom padding-top-10 padding-bottom-10">
                                                        <div class="">
                                                            ÖMER GÖYMEN
                                                        </div>
                                                        <div class="">
                                                            05447762687
                                                        </div>
                                                        <div class="">
                                                            egoymen@anadolu.edu.r
                                                        </div>
                                                        <div>
                                                            TUNALI MAH. ÜN SOK. 19/5 Tunali Mah , Fatih / Tepebaşi -
                                                            Eskişehir
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-popover>
                                        </div>
                                    </div>
                                    <div>
                                        <el-button type="text" class="el-button-link blue d-flex"
                                                   icon="el-icon-search"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="card-box">
                        <div class="card-box-header">
                            <div class="title">
                                Subeler
                            </div>
                            <div class="actions">
                                24
                            </div>
                        </div>
                        <div class="card-box-content">
                            <div class="box-list">
                                <div class="box-list-item cursor-pointer" v-for="item in 15" :key="item">
                                    <div class="name">
                                        Sinif {{ item }} / A
                                    </div>
                                    <div class="description">
                                        12
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-dialog title="Filtrele" :visible.sync="filterVisible" width="50%">
            <el-form :label-position="'top'" ref="form" :model="filterForm">
                <el-form-item label="Egitim Yili">
                    <select-definition label="Egitim Yili" alias="education-years" model="education_year_definition_id"
                                       :container="filterForm"/>
                </el-form-item>
                <el-form-item label="Egitim Donemi">
                    <select-definition label="Egitim Donemi" alias="education-periods"
                                       model="education_period_definition_id" :container="filterForm"/>
                </el-form-item>
                <el-form-item label="Siniflar">
                    <select-definition label="Siniflar" alias="classes" model="class_definition_id"
                                       :container="filterForm"/>
                </el-form-item>

                <el-form-item v-for="category in categories" :key="category.id" v-if="category" :label="category.title">
                    <el-select v-model="filterForm.categories[category.id]" :placeholder="category.title + ' secin'"
                               clearable>
                        <el-option
                                v-for="item in category.items"
                                v-if="item"
                                :key="item.id"
                                :label="item.title"
                                :value="item.id"
                        />
                    </el-select>
                </el-form-item>

                <div class="ui button labeled icon green fluid" @click="handleSearch">
                    <i class="el-icon-search icon"></i> Ara
                </div>
            </el-form>
        </el-dialog>
        <el-dialog title="Disa Aktar" :visible.sync="exportVisible" width="50%">
            <div class="card-box m-0">
                <div class="card-box-content">
                    <div class="box-list">
                        <div class="box-list-item cursor-pointer">
                            <div class="name">Sinif Listesi</div>
                        </div>
                        <div class="box-list-item cursor-pointer">
                            <div class="name">Fotografli Sinif Listesi</div>
                        </div>
                        <div class="box-list-item cursor-pointer">
                            <div class="name">Sube Listesi</div>
                        </div>
                        <div class="box-list-item cursor-pointer">
                            <div class="name">Etiket Grubuna Gore</div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </vue-scroll>
</template>

<script>
    import SelectDefinition from "../../../components/SelectDefinition";
    import SingleDraft from "../../../components/SingleDraft";

    export default {
        name: 'Classroom',
        components: {
            SingleDraft,
            SelectDefinition
        },
        data() {
            return {
                pageLoading: true,
                filterVisible: false,
                exportVisible: false,
                filterForm: {
                    categories: []
                },
                categories: [],
                options: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                    },
                    title: {
                        text: ''
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    series: [{
                        colorByPoint: true,
                        data: [
                            {
                                name: 'Kadin',
                                y: 100,
                                color: "#e03997"
                            },
                            {
                                name: 'Erkek',
                                y: 90,
                                color: "#4f91cd"
                            }
                        ]
                    }]
                }
            }
        },

        methods: {
            getCategories() {
                this.pageLoading = true;

                this.$http
                    .get('tools/search/categories', {
                        params: {
                            alias: 'classrooms'
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;

                        _.forEach(response.body.items, (item) => {

                            if (!item) {
                                return;
                            }

                            if (item.parent_id) {
                                this.categories[item.parent_id]['items'][item.id] = item;
                            } else {
                                item.items = [];
                                item.selected = null;
                                this.categories[item.id] = item;
                            }
                        });
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            handleSearch() {
                console.log(this.filterForm);
            }
        },

        mounted() {
            this.pageLoading = false;

            this.getCategories();
        }
    }
</script>