<template>
    <div v-loading="itemLoading">
        <div class="card-box">
            <header class="card-box-header">
                <div class="title">Danışman</div>
                <div class="actions">
                    <el-popover placement="bottom-end" width="400" title="Yetkililer">
                        <div slot="reference">
                            <el-button icon="el-icon-plus" type="text" class="el-button-link primary"/>
                        </div>
                        <div class="app-card-pop" v-loading="itemLoading">
                            <div class="item-title">
                                <div>
                                    <el-select
                                        @change="storeItem"
                                        v-model="form.user_id"
                                        value-key="id"
                                        filterable
                                        remote
                                        reserve-keyword
                                        placeholder="Sorumlular"
                                        :remote-method="searchRelationItem"
                                        :loading="itemSearchLoading">

                                        <el-option v-for="item in searchResults" :key="item.id" :label="item.title" :value="item.id"/>
                                    </el-select>
                                </div>
                                <div>
                                    <el-button type="primary" icon="el-icon-plus" size="small" @click="openBlankWindow(itemCreateLink)"/>
                                </div>
                            </div>
                            <div class="results" v-if="items">
                                <div v-for="(item, key) in items" :key="key" class="item" v-if="item.user">
                                    <div class="customer">
                                        <img :src="appConfig.frontPath + '/' + item.user.avatar" alt="" v-if="item.user.avatar" class="avatar">
                                        <img src="@/resources/images/avatar.jpg" alt="" v-if="!item.user.avatar" class="avatar">
                                        <span>
                                            {{ item.user.name }}
                                        </span>
                                    </div>
                                    <div class="delete" @click="destroyItem(item.id)">
                                        <i class="el-icon-delete"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-popover>
                </div>
            </header>
            <section class="card-box-content">
                <div class="box-list">
                    <div class="box-list-item"  v-for="item in items" :key="item.id" v-if="item.user">
                        <div class="name fluid">
                            {{ item.user.name }}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Assignee',
    props: ['cardId'],
    data() {
        return {
            itemLoading: true,
            itemSearchLoading: false,
            itemCreateLink: '/crm/users/create',
            items: [],
            form: {
                //
            },
            searchResults: [],
        }
    },
    created() {
        this.index();
    },
    methods: {
        index() {

            this.itemLoading = true;

            this.$http.get('cards/cards/' + this.cardId + '/assignees', {params: this.serverParams})
                .then(response => {
                    this.itemLoading = false;
                    this.items = response.body.items
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        storeItem() {

            this.itemLoading = true;

            this.$http.post('cards/cards/' + this.cardId + '/assignees', this.form)
                .then(response => {
                    this.itemLoading = false;
                    this.apiSuccessMessage(response);

                    this.index();
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        destroyItem(id) {

            console.log('adsf')

            this.itemLoading = true;

            this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {

                this.$http.delete('cards/cards/' + this.cardId + '/assignees/' + id)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);

                        this.index();
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });

                this.itemLoading = false;
            });
        },

        searchRelationItem(query) {
            this.itemSearchLoading = true;

            this.$http
                .get('tools/search/users', {
                    params: {
                        q: query,
                        roles: [
                            this.appSettings.staff_role_id
                        ],
                    }
                })
                .then(response => {
                    this.itemSearchLoading = false;
                    this.searchResults = response.body.items;
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },
    }
}
</script>
