<template>
    <el-menu
        :default-active.sync="activeLink"
        :mode="mode"
        @select="goto"
        :collapse="isCollapse"
        :unique-opened="true"
        background-color="transparent"
        class="main-navigation-menu"
        :class="{'nav-collapsed':isCollapse}"
    >
        <el-submenu index="/enrollments" popper-class="main-navigation-submenu">
            <template slot="title">
                <i class="mdi mdi-office-building"></i>
                <span slot="title">Kayıt Programı</span>
            </template>
            <el-menu-item index="/cards/marketing/pipelines/10"><span slot="title">Formlar</span></el-menu-item>
            <el-menu-item index="/enrollments/interviews"><span slot="title">Görüşmeler</span></el-menu-item>
            <el-menu-item index="/enrollments/events"><span slot="title">Etkinlikler</span></el-menu-item>
            <el-menu-item index="/enrollments/offers"><span slot="title">Teklifler</span></el-menu-item>
            <el-menu-item index="/enrollments/orders"><span slot="title">Siparisler</span></el-menu-item>
            <el-menu-item index="/school/students"><span slot="title">Öğrenciler</span></el-menu-item>
            <el-menu-item index="/crm/users"><span slot="title">Veliler</span></el-menu-item>
            <el-menu-item index="/enrollments/reports"><span slot="title">Raporlar</span></el-menu-item>
        </el-submenu>
        <el-submenu index="/crm" popper-class="main-navigation-submenu">
            <template slot="title">
                <i class="mdi mdi-account-multiple"></i>
                <span slot="title">Crm</span>
            </template>
            <el-menu-item index="/crm/users"><span slot="title">Veliler</span></el-menu-item>
            <el-menu-item index="/crm/companies"><span slot="title">Sirketler</span></el-menu-item>
            <el-menu-item index="/crm/roles"><span slot="title">Roller</span></el-menu-item>
        </el-submenu>
        <el-submenu index="/school" popper-class="main-navigation-submenu">
            <template slot="title">
                <i class="mdi mdi-school"></i>
                <span slot="title">Okul</span>
            </template>
            <el-menu-item index="/school/students">Öğrenciler</el-menu-item>
            <el-menu-item index="/school/teachers">Personeller</el-menu-item>
            <el-menu-item index="/school/programs/rooms">Calisma Takvimi</el-menu-item>
            <el-menu-item index="/school/rooms">Odalar</el-menu-item>
            <el-menu-item index="/school/classrooms/classrooms">Siniflar</el-menu-item>
            <el-menu-item index="/school/classrooms/categories">Etiketler</el-menu-item>
            <el-menu-item index="/school/classrooms/education-years">Egitim Yillari</el-menu-item>
            <el-menu-item index="/school/classrooms/education-periods">Egitim Donemleri</el-menu-item>
        </el-submenu>
        <el-submenu index="/marketing" popper-class="main-navigation-submenu">
            <template slot="title">
                <i class="mdi mdi-sale"></i>
                <span slot="title">Pazarlama</span>
            </template>
            <el-menu-item index="/marketing/campaigns"><span slot="title">Kampanyalar</span></el-menu-item>
            <el-menu-item index="/marketing/campaign-change-codes"><span slot="title">Referans Kodları</span>
            </el-menu-item>
            <el-menu-item index="/marketing/gifts"><span slot="title">Hediyeler</span></el-menu-item>

            <el-menu-item index="/marketing/banners"><span slot="title">Reklamlar</span></el-menu-item>
            <el-menu-item index="/marketing/bulk-message"><span slot="title">Toplu Mesaj</span></el-menu-item>
            <el-submenu index="/marketing/submissions" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Gonderiler</span></template>
                <el-menu-item index="/marketing/submissions/groups">Toplu Mesajlar</el-menu-item>
                <el-menu-item index="/marketing/submissions/messages">Tekil Mesajlar</el-menu-item>
            </el-submenu>
            <el-submenu index="/marketing/drafts" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Gonderi Taslaklari</span></template>
                <el-menu-item index="/marketing/drafts/email">E-Posta</el-menu-item>
                <el-menu-item index="/marketing/drafts/sms">Sms</el-menu-item>
            </el-submenu>
        </el-submenu>
        <el-submenu index="/cms" popper-class="main-navigation-submenu">
            <template slot="title">
                <i class="mdi mdi-file-document"></i>
                <span slot="title">Cms</span>
            </template>
            <el-submenu index="21" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Sayfalar</span></template>
                <el-menu-item index="/cms/categories/pages">Kategoriler</el-menu-item>
                <el-menu-item index="/cms/contents/pages">Sayfalar</el-menu-item>
            </el-submenu>
            <el-submenu index="22" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Blog</span></template>
                <el-menu-item index="/cms/categories/posts">Kategoriler</el-menu-item>
                <el-menu-item index="/cms/contents/posts">Yazilar</el-menu-item>
            </el-submenu>
            <el-submenu index="23" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Etkinlikler</span></template>
                <el-menu-item index="/cms/categories/events">Kategoriler</el-menu-item>
                <el-menu-item index="/cms/contents/events">Etkinlikler</el-menu-item>
            </el-submenu>
            <el-submenu index="24" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Galeri</span></template>
                <el-menu-item index="/cms/categories/galleries">Kategoriler</el-menu-item>
                <el-menu-item index="/cms/contents/galleries">Galeriler</el-menu-item>
            </el-submenu>
            <el-submenu index="25" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Haberler</span></template>
                <el-menu-item index="/cms/categories/news">Kategoriler</el-menu-item>
                <el-menu-item index="/cms/contents/news">Haberler</el-menu-item>
            </el-submenu>
            <el-submenu index="26" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Sıkça Sorulan Sorular</span></template>
                <el-menu-item index="/cms/categories/faqs">Kategoriler</el-menu-item>
                <el-menu-item index="/cms/contents/faqs">Sorular</el-menu-item>
            </el-submenu>
            <el-submenu index="/cms/education-programs" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Egitim Programi</span></template>
                <el-menu-item index="/cms/categories/education-programs">Kategoriler</el-menu-item>
                <el-menu-item index="/cms/contents/education-programs">Sayfalar</el-menu-item>
            </el-submenu>
            <el-submenu index="/cms/teachers" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Ogretmenler</span></template>
                <el-menu-item index="/cms/categories/teachers">Kategoriler</el-menu-item>
                <el-menu-item index="/cms/contents/teachers">Sayfalar</el-menu-item>
            </el-submenu>
            <el-submenu index="/cms/references" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Referanslar</span></template>
                <el-menu-item index="/cms/categories/references">Kategoriler</el-menu-item>
                <el-menu-item index="/cms/contents/references">Sayfalar</el-menu-item>
            </el-submenu>
            <el-menu-item index="/cms/comments"><span slot="title">Yorumlar</span></el-menu-item>
        </el-submenu>
        <el-submenu index="/catalog" popper-class="main-navigation-submenu">
            <template slot="title">
                <i class="mdi mdi-shopping"></i>
                <span slot="title">Market</span>
            </template>
            <el-menu-item index="/catalog/products"><span slot="title">Ürünler</span></el-menu-item>
            <el-menu-item index="/catalog/categories"><span slot="title">Kategoriler</span></el-menu-item>
            <el-menu-item index="/catalog/brands"><span slot="title">Markalar</span></el-menu-item>
            <el-menu-item index="/catalog/features"><span slot="title">Özellikler</span></el-menu-item>
            <el-menu-item index="/catalog/options"><span slot="title">Seçenekler</span></el-menu-item>
        </el-submenu>
        <el-submenu index="/reports" popper-class="main-navigation-submenu">
            <template slot="title">
                <i class="mdi mdi-chart-bar"></i>
                <span slot="title">Raporlar</span>
            </template>
            <el-menu-item index="/reports/classrooms"><span slot="title">Sınıf</span></el-menu-item>
            <el-menu-item index="/reports/students"><span slot="title">Öğrenci</span></el-menu-item>
            <el-menu-item index="/reports/marketing-students"><span slot="title">Potansiyel Öğrenci</span>
            </el-menu-item>
            <el-menu-item index="/reports/offers"><span slot="title">Teklif</span></el-menu-item>
            <el-menu-item index="/reports/orders"><span slot="title">Sipariş</span></el-menu-item>
            <el-menu-item index="/reports/sales"><span slot="title">Satış</span></el-menu-item>
            <el-menu-item index="/reports/staffs"><span slot="title">Personel</span></el-menu-item>
        </el-submenu>
        <el-submenu index="/tools" popper-class="main-navigation-submenu">
            <template slot="title">
                <i class="mdi mdi-auto-fix"></i>
                <span slot="title">Araçlar</span>
            </template>
            <el-submenu index="/tools/plugins" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Eklentiler</span></template>
                <el-menu-item index="/tools/plugins/modules">Moduller</el-menu-item>
                <el-menu-item index="/tools/plugins/payments">Odeme Yontemleri</el-menu-item>
                <el-menu-item index="/tools/plugins/deliveries">Teslimat Yontemleri</el-menu-item>
                <el-menu-item index="/tools/plugins/services">Servisler</el-menu-item>
            </el-submenu>
            <el-submenu index="/tools/seo" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Seo</span></template>
                <el-menu-item index="/tools/seo/files">Dosyalar</el-menu-item>
                <el-menu-item index="/tools/seo/redirects">Yonlendirmeler</el-menu-item>
                <el-menu-item index="/tools/seo/links">Linkler</el-menu-item>
            </el-submenu>
            <el-menu-item index="/tools/activities"><span slot="title">Aktiviteler</span></el-menu-item>
            <el-menu-item index="/tools/notes"><span slot="title">Personel Notlari</span></el-menu-item>
            <el-menu-item index="/tools/files"><span slot="title">Dosyalar</span></el-menu-item>
            <el-menu-item index="/tools/exports"><span slot="title">Veri Yonetimi</span></el-menu-item>
            <el-menu-item index="/tools/file-manager"><span slot="title">Dosya Yöneticisi</span></el-menu-item>
        </el-submenu>
        <el-submenu index="/system" popper-class="main-navigation-submenu">
            <template slot="title">
                <i class="mdi mdi-gauge"></i>
                <span slot="title">Sistem</span>
            </template>

            <el-submenu index="/system/settings" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Ayarlar</span></template>
                <el-menu-item index="/system/settings/site">
                    Site Ayarlari
                </el-menu-item>
                <el-submenu index="/system/settings/cards" popper-class="main-navigation-submenu" popper-append-to-body>
                    <template slot="title"><span>Pano Ayarlari</span></template>
                    <el-menu-item index="/system/settings/cards/marketing">Pazarlama Yonetimi</el-menu-item>
                    <el-menu-item index="/system/settings/cards/projects">Proje Yonetimi</el-menu-item>
                </el-submenu>
            </el-submenu>

            <el-submenu index="1" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Gorunumler</span></template>
                <el-menu-item index="/system/menus/web"><span slot="title">Menüler</span></el-menu-item>
                <el-menu-item index="/system/themes"><span slot="title">Temalar</span></el-menu-item>
            </el-submenu>
            <el-submenu index="2" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Tanımlar</span></template>
                <el-menu-item index="/system/definitions/tax-classes">Vergi Tanımlar</el-menu-item>
                <el-menu-item index="/system/definitions/weight-classes">Ağırlık Tanımları</el-menu-item>
                <el-menu-item index="/system/definitions/length-classes">Ölçü Tanımları</el-menu-item>
                <el-menu-item index="/system/definitions/genders">Cinsiyet Tanımları</el-menu-item>
                <el-menu-item index="/system/definitions/jobs">Meslekler</el-menu-item>
                <el-menu-item index="/system/definitions/blood-groups">Kan Grupları</el-menu-item>
                <el-menu-item index="/system/definitions/educations">Eğitim</el-menu-item>
                <el-menu-item index="/system/definitions/schools">Okullar</el-menu-item>
                <el-menu-item index="/system/definitions/classes">Sınıflar</el-menu-item>
                <el-menu-item index="/system/definitions/technical-support-topics">Teknik Destek Konuları</el-menu-item>
                <el-menu-item index="/system/definitions/help-topics">Yardım Konuları</el-menu-item>
                <el-menu-item index="/system/definitions/marketing-process">Bizi Nereden Duydu</el-menu-item>
                <el-menu-item index="/system/definitions/word-types">Kelime Turleri</el-menu-item>
                <el-menu-item index="/system/definitions/skills">Kitap Alistirma Etiketleri</el-menu-item>
                <el-menu-item index="/system/definitions/interview-subjects">Gorusme Konulari</el-menu-item>
            </el-submenu>
            <el-submenu index="3" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Durumlar</span></template>
                <el-menu-item index="/system/statuses/badges">Rozetler</el-menu-item>
                <el-menu-item index="/system/statuses/stocks">Stok Durumlari</el-menu-item>
                <el-menu-item index="/system/statuses/offers">Teklif Durumlari</el-menu-item>
                <el-menu-item index="/system/statuses/orders">Siparis Durumlari</el-menu-item>
                <el-menu-item index="/system/statuses/order-returns">Iade Durumlari</el-menu-item>
                <el-menu-item index="/system/statuses/events">Etkinlik Türleri</el-menu-item>
                <el-menu-item index="/system/statuses/prioritizations">Önceliklendirme</el-menu-item>
                <el-menu-item index="/system/statuses/event-statuses">Etkinlik Durumları</el-menu-item>
                <el-menu-item index="/system/statuses/interviews">Gorusme Durumlari</el-menu-item>
            </el-submenu>
            <el-submenu index="4" popper-class="main-navigation-submenu" popper-append-to-body>
                <template slot="title"><span>Bölgeler</span></template>
                <el-menu-item index="/system/zones/countries">Ülkeler</el-menu-item>
                <el-menu-item index="/system/zones/cities">İl</el-menu-item>
                <el-menu-item index="/system/zones/towns">İlçe</el-menu-item>
                <el-menu-item index="/system/zones/districts">Bölge</el-menu-item>
                <el-menu-item index="/system/zones/neighborhoods">Mahalle</el-menu-item>
            </el-submenu>
        </el-submenu>
    </el-menu>
</template>

<script>
import {detect} from 'detect-browser'

const browser = detect()

export default {
    name: 'Nav',
    props: ['mode', 'isCollapse'],
    data() {
        return {
            isIe: true,
            isEdge: true,
            activeLink: null
        }
    },
    methods: {
        goto(index, indexPath) {
            if (index.charAt(0) === '/') {
                this.$router.push(index)
                this.$emit('push-page', {page: index})
            }

        },
        setLink(path) {
            this.activeLink = path
        }
    },
    created() {
        if (browser.name !== 'ie') this.isIe = false
        if (browser.name !== 'edge') this.isEdge = false

        this.setLink(this.$router.currentRoute.path)
        this.$router.afterEach((to, from) => {
            this.setLink(this.$router.currentRoute.path)
            //console.log('afterEach', to, from)
        })
        //console.log('this.$router.currentRoute.path', this.$router.currentRoute.path)
    },
    mounted() {
        //console.log('nav component mounted')
    }
}
</script>

<style lang="scss" scoped>
.el-menu {
    border : none;
}

.el-menu::before, .el-menu::after {
    display : none;
}

.el-submenu, .el-menu-item {
    .mdi {
        vertical-align : middle;
        margin-right   : 5px;
        display        : inline-block;
        width          : 24px;
        text-align     : center;
        font-size      : 18px;
    }
}
</style>
