<style lang="scss" scoped>
    .el-upload-list--picture-card .el-upload-list__item {
        width: 100% !important;
        height: 275px !important;
    }

    .el-upload-list--picture-card .el-upload-list__item-actions {
        background-color: rgba(17, 17, 17, .2);
    }
</style>

<template>
    <div>
        <el-input placeholder="Link" v-model="filePath" class="input-with-select">
            <template slot="append">
                <el-tooltip content="Dosya Yoneticisi" effect="dark" placement="bottom">
                    <el-button type="primary" icon="el-icon-folder-add" @click="fileManagerVisible = true"/>
                </el-tooltip>

                <el-tooltip content="Sil" effect="dark" placement="bottom">
                    <el-button type="danger" icon="el-icon-delete" @click="destroyItem()"/>
                </el-tooltip>
            </template>
        </el-input>

        <el-button type="primary" @click="fileManagerVisible = true" style="width: 100%" v-if="multiple">
            <i class="fa fa-search"></i> Dosya Yoneticisi
        </el-button>

        <el-dialog width="90%" title="Dosya Yöneticisi" :visible.sync="fileManagerVisible" append-to-body>

            <file-manager :settings="fileManagerSettings" />

            <div class="margin-top-20">
                <el-row :gutter="24">
                    <el-col :span="6">
                        <button type="button" class="btn btn-primary" title="OK" v-on:click="singleSelect"
                                v-if="!multiple">
                            <i class="fa fa-check-circle"></i> Dosyayi Sec
                        </button>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        name: 'document-browser',
        props: [
            'filePath',
            'multiple',
            'url',
            'addingValue'
        ],
        data() {
            return {
                fileManagerVisible: false,
                form: {},
                files: {}
            }
        },
        methods: {
            updateValue: function (value) {
                this.$emit('update:filePath', value)
            },

            singleSelect() {

                let files = this.$store.state.fm.left.selected.files;

                if (!_.size(files)) {

                    this.$notify.error({
                        title: 'Upss!',
                        message: 'Dosya secimi yapmaniz gerekiyor!',
                        position: 'top-right'
                    });

                    return false;
                }

                this.updateValue('storage/' + files[0]);

                this.fileManagerVisible = false;

                this.$store.commit('fm/left/resetSelected');
            },

            destroyItem() {
                this.$emit('update:filePath', null)
            },
        }
    }
</script>