<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading">
            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                            <el-button @click="$router.go(-1)" type="text" icon="el-icon-back"
                                       class="el-button-link grey"/>
                        </el-tooltip>

                        <el-tooltip content="Olustur" effect="dark" placement="bottom" v-if="$route.params.id">
                            <el-button @click="createButton($route.meta.end_point)" type="text" icon="el-icon-plus"
                                       class="el-button-link primary"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="!$route.params.id">
                            <el-button @click="storeItem" type="text" icon="el-icon-check"
                                       class="el-button-link green"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="$route.params.id">
                            <el-button @click="updateItem" type="text" icon="el-icon-check"
                                       class="el-button-link green"/>
                        </el-tooltip>
                    </div>
                </header>
                <section class="card-box-content padding-20">
                    <el-form :label-position="'top'" ref="form" :model="form">

                        <el-form-item label="Sinif">
                            <select-definition label="Sinif" alias="classes" model="class_definition_id" :container="form"/>
                        </el-form-item>

                        <el-form-item label="Kitap">
                            <el-select v-model="form.metas.education_book_id" clearable filterable value-key="id"
                                       placeholder="Kitap">
                                <el-option v-for="item in books" :key="item.id" :label="item.title" :value="item.id"/>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Başlık">
                            <el-input v-model="form.title" placeholder="Başlık"/>
                        </el-form-item>

                        <el-form-item label="Ad">
                            <el-input v-model="form.metas.name" placeholder="Ad"/>
                        </el-form-item>

                        <el-form-item label="Aciklama">
                            <trumbowyg v-model="form.short_description" :config="$store.state.trumbowygConfig"/>
                        </el-form-item>

                        <el-form-item label="Odev">
                            <trumbowyg v-model="form.description" :config="$store.state.trumbowygConfig"/>
                        </el-form-item>

                        <el-form-item label="Kaynaklar">
                            <el-select
                                    v-model="form.suggestions"
                                    value-key="id"
                                    multiple
                                    filterable
                                    remote
                                    reserve-keyword
                                    placeholder="Kaynaklar"
                                    :remote-method="searchContents"
                                    :loading="contentSearchLoading">
                                <el-option v-for="item in contents" :key="item.id" :label="item.title"
                                           :value="item.id"/>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </section>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
    import FileBrowser from "../../../components/FileBrowser";
    import Trumbowyg from "vue-trumbowyg/src/component";
    import SelectDefinition from "../../../components/SelectDefinition";

    export default {
        name: 'ElementForm',
        components: {SelectDefinition, Trumbowyg, FileBrowser},
        data() {
            return {
                itemLoading: true,
                form: {
                    file_path: null,
                    suggestions: [],
                    metas: {
                        name: null,
                        education_book_id: null,
                    }
                },

                books: [],
                contentSearchLoading: null,
                contents: [],
            }
        },

        methods: {
            loadItem() {

                this.itemLoading = true;

                this.$http
                    .get(this.$route.meta.end_point + '/' + this.$route.params.id)
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body.item;

                        if (!_.size(this.form.metas)) {
                            this.form.metas = {}
                        }
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            storeItem() {

                this.itemLoading = true;

                this.$http
                    .post(this.$route.meta.end_point, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                        this.$router.push('edit/' + response.body.item.id);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.itemLoading = true;

                this.$http
                    .put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.itemLoading = true;

                this
                    .$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                        confirmButtonText: 'Tamam',
                        cancelButtonText: 'Iptal Et',
                        type: 'warning'
                    })
                    .then(() => {
                        this.$http
                            .delete(this.$route.meta.end_point + '/' + id)
                            .then(response => {
                                this.itemLoading = false;

                                this.$router.push('/');

                                this.apiSuccessMessage(response)
                            })
                            .catch((e) => {
                                this.itemLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            },

            getBooks() {
                this.itemLoading = true;

                this.$http
                    .get('platform/education-books')
                    .then(response => {
                        this.itemLoading = false;
                        this.books = response.body.rows
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            searchContents(query) {
                this.contentSearchLoading = true;

                this.$http
                    .get('tools/search/contents?type=platform&q=' + query)
                    .then(response => {
                        this.contentSearchLoading = false;
                        this.contents = response.body.items;
                    })
                    .catch((e) => {
                        this.contentSearchLoading = false;
                        this.apiErrorMessage(e);
                    });
            },
        },

        mounted() {
            if (this.$route.params.id) {
                this.loadItem()
            } else {
                this.itemLoading = false;
            }

            this.getBooks();
        }
    }
</script>