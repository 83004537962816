<template>
    <div v-loading="itemLoading">
        <div class="card-box">
            <header class="card-box-header">
                <div class="title">
                    <span v-if="order.year">{{ order.year.title }} / </span>
                    <span v-if="order.period">{{ order.period.title }} / </span>
                    Urun Bilgileri
                </div>
                <div class="actions">
                    <el-tooltip content="Ekle" effect="dark" placement="bottom">
                        <el-button icon="el-icon-plus" type="text" class="el-button-link primary"
                                   @click="crateItem"/>
                    </el-tooltip>
                </div>
            </header>
            <section class="card-box-content">
                <div class="box-list">
                    <div class="box-list-item box-list-item-classroom" v-for="item in items" :key="item.id">
                        <div class="my-flex mr-30 fluid">
                            <div>
                                <div>
                                    <b>{{ item.title }}</b>
                                </div>
                                <div v-if="item.alias === 'classrooms' || item.alias === 'products'">
                                    {{ item.content.category_text }}
                                </div>
                                <div v-if="item.alias === 'vehicles'">
                                    <div>
                                        {{ item.address_type_format }}
                                    </div>
                                    <div v-if="item.address">{{ item.address.title }}</div>
                                </div>
                            </div>
                            <div class="classroom-price">
                                <div class="quantity">
                                    {{ item.quantity }} Adet
                                </div>
                                <div class="price">
                                    {{ $n(item.price, 'currency') }}
                                </div>
                                <div class="price">
                                    {{ $n(item.total, 'currency') }}
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <el-button type="text" class="el-button-link green" icon="el-icon-edit"
                                       @click="editItem(item)"/>
                            <el-button type="text" class="el-button-link red" icon="el-icon-delete"
                                       @click="destroyItem(item.id)"/>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <el-dialog title="Urun Ekle" :visible.sync="modalCreateVisible" v-loading="modalLoading" width="60%">
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Teklifler">
                    <el-select v-model="form.offer_id" value-key="id" placeholder="Teklifler" clearable filterable>
                        <el-option
                                v-for="item in offers"
                                v-if="item.id !== order.offer_id"
                                :key="item.id"
                                :value="item.id"
                                :label="item.code + ' - ' + $n(item.total, 'currency') + ' / ' + item.created_at"
                        />
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="storeItem" class="fluid">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
            </div>
        </el-dialog>

        <el-dialog title="Urun Bilgileri" :visible.sync="modalEditVisible" v-loading="modalLoading" width="60%">
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Urun">
                    <el-select v-model="form.content_id" value-key="id" placeholder="Siniflar" filterable clearable>
                        <el-option v-for="item in products" :key="item.id" :label="item.title" :value="item.id"
                                   v-if="item.alias === form.alias"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="Address" v-if="form.alias === 'vehicles'">
                    <el-select v-model="form.address_id" value-key="id" placeholder="Adresler" filterable clearable>
                        <el-option v-for="item in addresses" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="Adres Turu" v-if="form.alias === 'vehicles'">
                    <el-select v-model="form.address_type" value-key="id" placeholder="Adres Turu" filterable clearable>
                        <el-option v-for="item in addressTypes" :key="item.value" :label="item.label"
                                   :value="item.value"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="Siniflar">
                    <select-definition label="Sinif" alias="classes" model="class_definition_id" :container="form"/>
                </el-form-item>

                <el-form-item label="Adet">
                    <el-input v-model="form.quantity" placeholder="Adet"/>
                </el-form-item>

                <el-form-item label="Paket Fiyati" v-if="form.alias === 'products'">
                    <el-input v-model="form.package_price" placeholder="Paket Fiyati"/>
                </el-form-item>

                <el-form-item label="Birim Fiyati" v-if="form.alias === 'products'">
                    <el-input v-model="form.q_price" placeholder="Birim Fiyati"/>
                </el-form-item>

                <el-form-item label="Fiyat">
                    <el-input v-model="form.price" placeholder="Fiyat"/>
                </el-form-item>

                <el-form-item label="Indirim Tutari">
                    <el-input v-model="form.discount_price" placeholder="Indirim Tutari"/>
                </el-form-item>

                <el-form-item label="Toplam">
                    <el-input v-model="form.total" placeholder="Toplam"/>
                </el-form-item>

                <el-form-item>
                    <el-switch v-model="form.is_gift" active-text="Hediye"/>
                </el-form-item>

                <el-form-item>
                    <el-switch v-model="form.has_shipping" active-text="Kargolu Urun"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="updateItem" class="fluid">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import SelectDefinition from "../../../../../components/SelectDefinition";

    export default {
        name: 'Products',
        components: {SelectDefinition},
        props: ['source', 'order'],
        data() {
            return {
                itemLoading: true,
                modalLoading: false,
                modalCreateVisible: false,
                modalEditVisible: false,
                items: [],
                form: {
                    //
                },

                offers: [],
                products: [],
                addresses: [],
                addressTypes: [
                    {
                        value: 'service_get',
                        label: 'Alinacak',
                    },
                    {
                        value: 'service_delivery',
                        label: 'Birakilacak'
                    }
                ]
            }
        },

        methods: {
            index() {
                this.$http.get(this.source + '/products', {
                    params: {
                        fake_url: this.source,
                    }
                }).then(response => {
                    this.items = response.body.rows;
                    this.itemLoading = false;
                });
            },

            crateItem() {
                this.modalCreateVisible = true;
                this.form = {
                    //
                };

                this.getOffers();
            },

            storeItem() {
                this.modalLoading = true;

                this.form.fake_url = this.source;

                this.$http.post(this.source + '/products', this.form)
                    .then(response => {

                        this.modalLoading = false;
                        this.modalCreateVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            editItem(item) {
                this.itemLoading = false;
                this.modalLoading = false;
                this.modalEditVisible = true;

                this.form = item;

                this.getProducts();
                this.getAddresses();
            },

            updateItem() {

                this.modalLoading = true;

                this.form.fake_url = this.source;

                this.form.education_year_definition_id = this.order.education_year_definition_id;
                this.form.education_period_definition_id = this.order.education_period_definition_id;

                this.$http
                    .put(this.source + '/products/' + this.form.id, this.form)
                    .then(response => {

                        this.modalLoading = false;
                        this.modalEditVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {

                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http
                        .delete(this.source + '/products/' + id, {
                            fake_url: this.source
                        })
                        .then(response => {
                            this.modalLoading = false;
                            this.modalVisible = false;
                            this.itemLoading = false;

                            this.index();

                            this.$message({
                                type: 'success',
                                message: 'Delete completed'
                            });
                        })
                        .catch((e) => {
                            this.modalLoading = false;
                            this.apiErrorMessage(e);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.modalLoading = false;
                    this.itemLoading = false;
                });
            },

            getOffers() {
                this.itemLoading = true;
                this.$http
                    .get('sales/offers', {
                        params: {
                            fake_url: this.url,
                            filters: [
                                {
                                    relation: null,
                                    inputs: [
                                        {
                                            name: 'account_id',
                                            field: 'account_id',
                                            type: 'singleSelect',
                                            value: this.order.account_id
                                        }
                                    ]
                                },
                                {
                                    relation: null,
                                    inputs: [
                                        {
                                            name: 'education_year_definition_id',
                                            field: 'education_year_definition_id',
                                            type: 'singleSelect',
                                            value: this.order.education_year_definition_id
                                        }
                                    ]
                                },
                                {
                                    relation: null,
                                    inputs: [
                                        {
                                            name: 'education_period_definition_id',
                                            field: 'education_period_definition_id',
                                            type: 'singleSelect',
                                            value: this.order.education_period_definition_id
                                        }
                                    ]
                                },
                            ]
                        }
                    })
                    .then(response => {
                        this.modalLoading = false;
                        this.itemLoading = false;
                        this.offers = response.body.rows;
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getProducts() {
                this.modalLoading = true;

                this.$http
                    .get('school/classrooms', {
                        params: {
                            filters: [
                                {
                                    relation: 'product',
                                    add_with: 'true',
                                    inputs: [
                                        {
                                            name: 'education_year_definition_id',
                                            field: 'education_year_definition_id',
                                            type: 'singleSelect',
                                            value: this.order.education_year_definition_id
                                        }
                                    ]
                                },
                                {
                                    relation: 'product',
                                    add_with: 'true',
                                    inputs: [
                                        {
                                            name: 'education_period_definition_id',
                                            field: 'education_period_definition_id',
                                            type: 'singleSelect',
                                            value: this.order.education_period_definition_id
                                        }
                                    ]
                                },
                                {
                                    relation: null,
                                    inputs: [
                                        {
                                            name: 'class_definition_id',
                                            field: 'class_definition_id',
                                            type: 'singleSelect',
                                            value: this.form.class_definition_id
                                        }
                                    ]
                                }
                            ]
                        }
                    })
                    .then(response => {
                        this.modalLoading = false;
                        this.products = response.body.rows
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getAddresses() {

                this.modalLoading = true;

                this.$http
                    .get('crm/addresses', {
                        params: {
                            fake_url: this.url,
                            filters: [
                                {
                                    relation: null,
                                    add_with: 'true',
                                    inputs: [
                                        {
                                            name: 'account_id',
                                            field: 'account_id',
                                            type: 'singleSelect',
                                            value: this.order.account_id
                                        }
                                    ]
                                },
                            ]
                        }
                    })
                    .then(response => {
                        this.modalLoading = false;
                        this.addresses = response.body.rows;
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },
        },

        mounted() {
            this.index();
        }
    }
</script>