<template>
    <div class="page auth">
        <div class="auth-container">
            <div class="auth-box">
                <div class="form-container">
                    <div class="logo">
                        <img src="@/resources/images/logo.svg" alt="logo" class="ui image small centered">
                    </div>
                    <el-form ref="form" label-position="top" :model="form" v-if="!token">
                        <el-form-item>
                            <el-input v-model="form.email" prefix-icon="el-icon-user" placeholder="E-mail"/>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="form.password" prefix-icon="el-icon-key" type="password"
                                      placeholder="Password" show-password/>
                        </el-form-item>
                        <div class="columns">
                            <div class="column">
                                <a href="">
                                    sifremi unuttum!
                                </a>
                            </div>
                            <div class="column">
                                <div class="ui button blue small" :class="{loading : loading, disabled : loading}"
                                     @click="send">
                                    Giris Yap!
                                </div>
                            </div>
                        </div>
                    </el-form>
                    <div v-if="token">
                        <div class="ui message blue">
                            <i class="icon el-icon-user"></i>
                            Lutfen bekleyin, Sisteme giris yapiliyor...
                        </div>
                        <div style="margin-top: 60px">
                            <div v-loading="true"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
            loading: false,
            height: 'height:' + window.innerHeight + 'px',
            form: {
                //
            },
            token: null
        }
    },
    mounted() {

        localStorage.removeItem('token')

        this.token = this.$route.query.token;

        if (this.token) {
            setTimeout(() => {
                localStorage.setItem('token', this.token);
                window.location = '/';
            }, 2000);
        }
    },
    methods: {
        send() {
            this.loading = true;

            this.$http
                .post('auth/login', this.form)
                .then(response => {
                    this.loading = false;
                    localStorage.setItem('token', response.body.token);

                    window.location = '/';
                })
                .catch((e) => {
                    this.loading = false;
                    this.apiErrorMessage(e);
                });
        }
    },
}
</script>
