<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading">
            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                            <el-button @click="$router.go(-1)" type="text" icon="el-icon-back" class="el-button-link grey"/>
                        </el-tooltip>

                        <el-tooltip content="Olustur" effect="dark" placement="bottom">
                            <el-button @click="createItem" type="text" icon="el-icon-plus" class="el-button-link primary"/>
                        </el-tooltip>
                    </div>
                </header>
                <section class="card-box-content" v-if="group">
                    <div class="box-list">
                        <div class="box-list-item" v-for="item in items" :key="item.id">
                            <div class="fluid mr-30">
                                <div class="ui header" style="margin: 0; height: auto">
                                    <i :class="'icon mdi mdi-' + item.icon"></i>
                                    <div class="content">
                                        {{ item.title }}
                                        <div class="sub header" style="margin: 0; height: auto">
                                            <div class="stages my-flex">
                                                <div v-for="stage in item.stages" :key="stage.id" class="mr-20">
                                                    {{ stage.title }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="my-flex">
                                    <el-button type="text" class="el-button-link green" icon="el-icon-edit" @click="editItem(item)" />
                                    <el-button type="text" class="el-button-link red" icon="el-icon-delete" @click="destroyItem(item.id)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <el-dialog :title="form.title" :visible.sync="modalVisible" width="75%" v-loading="itemLoading">
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-form-item label="Roller">
                    <el-select v-model="form.roles" multiple placeholder="Roller">
                        <el-option
                                v-for="item in roles"
                                :key="item.id"
                                :label="item.title"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="Başlık">
                    <el-input v-model="form.title" placeholder="Başlık"/>
                </el-form-item>

                <el-form-item label="Ikon">
                    <div style="display: flex">
                        <span class="el-input-group__prepend text-center" style="padding: 0 !important; width: 40px;">
                            <i :class="'mdi mdi-' + form.metas.icon"></i>
                        </span>
                        <el-select v-model="form.metas.icon" filterable allow-create default-first-option placeholder="Ikon">
                            <el-option v-for="item in icons" :key="item" :label="item" :value="item" />
                        </el-select>
                    </div>
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Arkaplan Rengi">
                            <el-color-picker v-model="form.metas.bg_color" show-alpha :predefine="appConfig.predefineColors"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Metin Rengi">
                            <el-color-picker v-model="form.metas.text_color" show-alpha :predefine="appConfig.predefineColors" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Sıralama">
                    <el-input-number v-model="form.sort" placeholder="Sıralama" />
                </el-form-item>

                <Stages v-model="form.stages" :icons="icons" :pipelineId="form.id" v-if="form.id" />
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="storeItem" v-if="!form.id" class="fluid">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" @click="updateItem" v-if="form.id" class="fluid">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </div>
        </el-dialog>
    </vue-scroll>
</template>

<script>
    import Stages from "./_parts/BoardStages";

    export default {
        name: 'BoardSetting',
        components: {
            Stages
        },
        data() {
            return {
                itemLoading: true,
                modalVisible: false,
                group: null,
                items: [],
                form: {
                    roles : [],
                    stages : [],
                    metas: {}
                },

                icons : [],
                roles : [],
            }
        },

        methods: {
            getIcons () {
                this.$http
                    .get('/api/icons.json')
                    .then(response => {
                        this.itemLoading = false;
                        this.icons = response.body;
                    });
            },

            getRoles () {
                this.$http
                    .get('crm/roles')
                    .then(response => {
                        this.itemLoading = false;
                        this.roles = response.body.rows;
                    });
            },

            getGroup() {

                this.itemLoading = true;

                let groupId = 2;

                if (this.$route.params.type === 'marketing') {
                    groupId = 1;
                }

                this.$http
                    .get('cards/groups/' + groupId)
                    .then(response => {
                        this.itemLoading = false;
                        this.group = response.body.item;

                        this.pipelines();
                    });
            },

            pipelines() {
                this.itemLoading = true;

                this.$http
                    .get('cards/pipelines', {
                        params: {
                            card_group_id: this.group.id,
                            disable_role: true,
                        }
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.items = response.body.items;
                    });
            },

            destroyItem(id) {
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                    confirmButtonText: 'Tamam',
                    cancelButtonText: 'Iptal Et',
                    type: 'warning'
                })
                    .then(() => {
                        this.$http
                            .delete('cards/pipelines/' + id)
                            .then(response => {
                                this.itemLoading = false;
                                this.apiSuccessMessage(response);

                                this.pipelines();
                            })
                            .catch((e) => {
                                this.itemLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            },

            editItem(pipeline) {
                this.form = pipeline;
                this.modalVisible = true;

                if (!_.size(this.form.metas)){
                    this.form.metas = {};
                }

                let roles = [];

                _.forEach(this.form.roles, function(item) {
                    if (item.role_id) {
                        roles.push(item.role_id);
                    } else {
                        roles.push(item);
                    }
                });

                this.form.roles = roles;
            },

            updateItem() {

                this.itemLoading = true;

                this.$http
                    .put('cards/pipelines/' + this.form.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.modalVisible = false;
                        this.apiSuccessMessage(response);

                        this.getPipelines();
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            createItem() {
                this.modalVisible = true;
                this.form = {
                    roles : [],
                    metas : {}
                }
            },

            storeItem() {

                let groupId = 2;

                if (this.$route.params.type === 'marketing') {
                    groupId = 1;
                }

                this.itemLoading = true;
                this.form.card_group_id =groupId;

                this.$http
                    .post('cards/pipelines', this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.modalVisible = false;
                        this.apiSuccessMessage(response);

                        this.getPipelines();
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },
        },

        mounted() {
            this.getGroup();
            this.getIcons();
            this.getRoles();
        }
    }
</script>