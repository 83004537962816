<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="pageLoading">
            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        Sayfalar
                    </div>
                    <div class="actions">
                        <el-tooltip content="Olustur" effect="dark" placement="bottom">
                            <el-button @click="create" type="text" icon="el-icon-plus"
                                       class="el-button-link primary"/>
                        </el-tooltip>
                    </div>
                </header>
                <section class="card-box-content">
                    <div class="box-list">
                        <div class="box-list-item" v-for="item in items" :key="item.id">
                            <div>
                                <b>[{{ item.sort }}]</b> {{ item.title }}
                            </div>
                            <div>
                                <div class="d-flex justify-flex-end">
                                    <el-button type="text" class="el-button-link green" icon="el-icon-edit"
                                               @click="edit(item)"/>
                                    <el-button type="text" class="el-button-link red" icon="el-icon-delete"
                                               @click="destroyItem(item.id)"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <el-dialog title="Sayfa Bilgileri" :visible.sync="modalVisible" width="75%" v-loading="pageLoading">
            <el-form :label-position="'top'" :model="form">

                <el-row :gutter="24">
                    <el-col :span="18">
                        <el-form-item label="Başlık">
                            <el-input v-model="form.title" placeholder="Başlık"/>
                        </el-form-item>

                        <el-form-item label="Video">
                            <el-select
                                    v-model="form.video_content_id"
                                    value-key="id"
                                    filterable
                                    remote
                                    reserve-keyword
                                    placeholder="Video"
                                    :remote-method="searchVideos"
                                    :loading="contentSearchLoading">
                                <el-option v-for="item in videos" :key="item.id" :label="item.title" :value="item.id"/>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Ses">
                            <el-select
                                    v-model="form.sound_content_id"
                                    value-key="id"
                                    filterable
                                    remote
                                    reserve-keyword
                                    placeholder="Ses"
                                    :remote-method="searchSounds"
                                    :loading="contentSearchLoading">
                                <el-option v-for="item in sounds" :key="item.id" :label="item.title" :value="item.id"/>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Oyun">
                            <el-select
                                    v-model="form.game_content_id"
                                    value-key="id"
                                    filterable
                                    remote
                                    reserve-keyword
                                    placeholder="Oyun"
                                    :remote-method="searchGames"
                                    :loading="contentSearchLoading">
                                <el-option v-for="item in games" :key="item.id" :label="item.title" :value="item.id"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="Resim">
                            <file-browser v-model="form.file_path"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Metin">
                    <trumbowyg v-model="form.description" :config="$store.state.trumbowygConfig"/>
                </el-form-item>

                <el-form-item label="Sıralama">
                    <el-input-number v-model="form.sort" placeholder="Sıralama"/>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="storeItem" class="fluid" v-if="!form.id">
                    <i class="el-icon-check"></i> Kaydet
                </el-button>
                <el-button type="success" @click="updateItem" class="fluid" v-if="form.id">
                    <i class="el-icon-check"></i> Guncelle
                </el-button>
            </div>
        </el-dialog>
    </vue-scroll>
</template>

<script>
    import Trumbowyg from "vue-trumbowyg/src/component";
    import FileBrowser from "../../../../../components/FileBrowser";


    export default {
        name: 'Pages',
        components: {FileBrowser, Trumbowyg},
        props: ['source'],
        data() {
            return {
                pageLoading: false,
                endPoint: null,
                items: [],
                form: {
                    file_path: null,
                },

                modalVisible: false,
                contentSearchLoading: false,

                videos: [],
                sounds: [],
                games: [],
            }
        },

        methods: {

            index() {
                this.pageLoading = true;

                this.$http
                    .get(this.endPoint, {
                        params : {
                            sorts : {
                                sort : 'asc'
                            }
                        }
                    })
                    .then(response => {
                        this.items = response.body.rows;
                        this.pageLoading = false;
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            create() {
                this.modalVisible = true;
                this.form = {
                    file_path: null,
                }
            },

            edit(item) {
                this.modalVisible = true;
                this.form = item;

                if (item.video) {
                    this.videos.push(item.video);
                }

                if (item.sound) {
                    this.sounds.push(item.sound);
                }

                if (item.game) {
                    this.games.push(item.game);
                }
            },

            storeItem() {

                this.pageLoading = true;

                this.$http
                    .post(this.endPoint, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.modalVisible = false;
                        this.apiSuccessMessage(response);

                        this.index();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.pageLoading = true;

                this.$http
                    .put(this.endPoint + '/' + this.form.id, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.modalVisible = false;
                        this.apiSuccessMessage(response);

                        this.index();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.pageLoading = true;

                this
                    .$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                        confirmButtonText: 'Tamam',
                        cancelButtonText: 'Iptal Et',
                        type: 'warning'
                    })
                    .then(() => {
                        this.$http
                            .delete(this.endPoint + '/' + id)
                            .then(response => {
                                this.pageLoading = false;
                                this.apiSuccessMessage(response);

                                this.index();
                            })
                            .catch((e) => {
                                this.pageLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.pageLoading = false;
                    });
            },

            searchVideos(query) {
                return this.searchContents(query, 'videos')
            },

            searchSounds(query) {
                return this.searchContents(query, 'sounds')
            },

            searchGames(query) {
                return this.searchContents(query, 'games')
            },

            searchContents(query, alias) {
                this.contentSearchLoading = true;

                this.$http
                    .get('tools/search/contents', {
                        params: {
                            q: query,
                            alias: alias,
                        }
                    })
                    .then(response => {
                        this.contentSearchLoading = false;
                        this[alias] = response.body.items;
                    })
                    .catch((e) => {
                        this.contentSearchLoading = false;
                        this.apiErrorMessage(e);
                    });
            },
        },

        mounted() {
            this.endPoint = this.source + '/pages';
            this.index();
        }
    }
</script>