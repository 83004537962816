<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="pageLoading">
            <div v-if="!form.id">
                <div class="card-box">
                    <header class="card-box-header">
                        <div class="title">
                            {{ $route.meta.title }}
                        </div>
                        <div class="actions">
                            <crud-buttons :storeItem="storeItem" :updateItem="updateItem"/>
                        </div>
                    </header>
                    <section class="card-box-content padding-20">
                        <el-form :label-position="'top'" ref="form" :model="form">
                            <SelectCategory label="Kategoriler" alias="books" model="categories" :container="form"/>

                            <el-form-item label="Başlık">
                                <el-input v-model="form.title" placeholder="Başlık"/>
                            </el-form-item>

                            <el-form-item label="Yayınlanma Tarihi">
                                <el-date-picker v-model="form.publish_at" type="datetime" placeholder="Tarih Seçiniz"
                                                value-format="yyyy-MM-dd HH:mm:ss"/>
                            </el-form-item>

                            <el-form-item label="Kısa Açıklama">
                                <trumbowyg v-model="form.short_description" :config="$store.state.trumbowygConfig"/>
                            </el-form-item>

                            <el-form-item label="Açıklama">
                                <trumbowyg v-model="form.description" :config="$store.state.trumbowygConfig"/>
                            </el-form-item>

                            <el-row :gutter="24">
                                <el-col :span="8">
                                    <el-form-item label="Kapak Resmi" class="margin-0">
                                        <file-browser v-model="form.file_path"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="Arka Kapak Resmi" class="margin-0">
                                        <file-browser v-model="form.metas.bg_cover"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="Filigran" class="margin-0">
                                        <file-browser v-model="form.metas.watermark"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </section>
                </div>
            </div>
            <div v-if="form.id">
                <div class="card-box">
                    <header class="card-box-header">
                        <div class="title">
                            {{ $route.meta.title }}
                        </div>
                        <div class="actions">
                            <el-tooltip content="Duzenle" effect="dark" placement="bottom" >
                                <el-button type="text" @click="editModalVisible = true" icon="el-icon-edit" class="el-button-link green"/>
                            </el-tooltip>
                        </div>
                    </header>
                    <section class="card-box-content">
                        <div class="box-list">
                            <div class="box-list-item box-list-item-title">
                                <div class="name fluid">
                                    {{ form.title }}
                                </div>
                                <div class="fluid text-right">
                                    {{ form.publish_at }}
                                </div>
                            </div>
                            <div class="box-list-item">
                                <span v-html="form.short_description"></span>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="box-list">
                                <div class="box-list-item">
                                    <div style="width: 150px">
                                        <img :src="appConfig.frontPath + '/' + form.file_path" alt="" class="ui image">
                                    </div>
                                </div>
                                <div class="box-list-item">
                                    <div style="width: 150px">
                                        <img :src="appConfig.frontPath + '/' + form.metas.bg_cover" alt="" class="ui image">
                                    </div>
                                </div>
                                <div class="box-list-item">
                                    <div style="width: 150px">
                                        <img :src="appConfig.frontPath + '/' + form.metas.watermark" alt="" class="ui image">
                                    </div>
                                </div>
                            </div>
                            <div class="box-list fluid">
                                <div class="box-list-item">
                                    <span v-html="form.description"></span>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <el-dialog :title="$route.meta.title" :visible.sync="editModalVisible" v-loading="pageLoading" width="85%">
                    <el-form :label-position="'top'" ref="form" :model="form" v-if="form.id">

                        <SelectCategory label="Kategoriler" alias="books" model="categories" :container="form"/>

                        <el-form-item label="Başlık">
                            <el-input v-model="form.title" placeholder="Başlık"/>
                        </el-form-item>

                        <el-form-item label="Yayınlanma Tarihi">
                            <el-date-picker v-model="form.publish_at" type="datetime" placeholder="Tarih Seçiniz"
                                            value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>

                        <el-row :gutter="24">
                            <el-col :span="8">
                                <el-form-item label="Kapak Resmi" class="margin-0">
                                    <file-browser v-model="form.file_path"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="Arka Kapak Resmi" class="margin-0">
                                    <file-browser v-model="form.metas.bg_cover"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="Filigran" class="margin-0">
                                    <file-browser v-model="form.metas.watermark"/>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-form-item label="Kısa Açıklama">
                            <trumbowyg v-model="form.short_description" :config="$store.state.trumbowygConfig"/>
                        </el-form-item>

                        <el-form-item label="Açıklama">
                            <trumbowyg v-model="form.description" :config="$store.state.trumbowygConfig"/>
                        </el-form-item>
                    </el-form>
                    <div slot="footer">
                        <div class="ui button labeled icon green fluid" @click="updateItem">
                            <i class="el-icon-check icon"></i> Guncelle
                        </div>
                    </div>
                </el-dialog>

                <Unit :book="form" :key="form.id" />
            </div>
        </div>
    </vue-scroll>
</template>

<script>
    import FileBrowser from "../../../../components/FileBrowser";
    import Trumbowyg from "vue-trumbowyg/src/component";
    import Unit from "./Unit";
    import SelectCategory from "../../../../components/SelectCategory";

    export default {
        name: 'Book',
        components: {
            SelectCategory,
            Unit,
            Trumbowyg,
            FileBrowser
        },
        data() {
            return {
                pageLoading: true,
                editModalVisible: false,
                form: {
                    file_path: null,
                    metas: {
                        bg_cover: null,
                        watermark: null,
                    }
                }
            }
        },

        methods: {
            loadItem() {

                this.pageLoading = true;

                this.$http
                    .get(this.$route.meta.end_point + '/' + this.$route.params.id)
                    .then(response => {
                        this.pageLoading = false;
                        this.form = response.body.item
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            storeItem() {

                this.pageLoading = true;

                this.$http
                    .post(this.$route.meta.end_point, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.apiSuccessMessage(response);
                        this.$router.push('edit/' + response.body.item.id);
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.pageLoading = true;

                this.$http
                    .put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.editModalVisible = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.pageLoading = true;

                this
                    .$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                        confirmButtonText: 'Tamam',
                        cancelButtonText: 'Iptal Et',
                        type: 'warning'
                    })
                    .then(() => {
                        this.$http
                            .delete(this.$route.meta.end_point + '/' + id)
                            .then(response => {
                                this.pageLoading = false;

                                this.$router.push('/');

                                this.apiSuccessMessage(response)
                            })
                            .catch((e) => {
                                this.pageLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.pageLoading = false;
                    });
            },
        },

        mounted() {
            if (this.$route.params.id) {
                this.loadItem()
            } else {
                this.pageLoading = false;
            }
        }
    }
</script>