<style>
    .status-dot {
        width: 25px;
        height: 25px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .status-dot-container-right {
        display: flex;
        justify-content: flex-end;
    }
</style>

<template>
    <div :class="containerClass">
        <el-tooltip :content="status.title" effect="dark" placement="bottom">
            <div class="status-dot" :style="{'background-color' : status.bg_color, color : status.text_color}">
                <i class="el-icon-check"></i>
            </div>
        </el-tooltip>
    </div>
</template>
<script>
    export default {
        name: 'status-dot',
        props: ['status', 'align'],
        data() {
            return {
                containerClass: ''
            };
        },

        mounted() {
            this.containerClass = 'status-dot-container';

            if (this.align) {
                this.containerClass += '-right';
            }
        }
    };
</script>

