<template>
    <vue-scroll class="page-dashboard">
        <div class="loading-container">
            <el-row v-loading="boardsLoading" class="mt-0" :gutter="24">
                <el-col v-for="board in boards" :key="board.id" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <router-link style="text-decoration: none !important;"
                                 :to="{ path: '/cards/marketing/pipelines/' + board.id, meta: { pipelineId : board.id }}">
                        <div class="card-base card-shadow--medium flex column mb-30 pv-20"
                             :style="'color:'+board['text_color']+'; background-color: '+board['bg_color']"
                             style="height:295px;">
                            <div class="ph-20">
                                <h2 class="white-text mv-0 mb-20 animated fadeInDown">
                                    <i :class="'mdi mdi-'+board['icon']"></i>
                                    {{board.title}}
                                    <span class="font-size-13 white-text mb-5 o-050 animated slideInUp">
                                        {{ board.updated_at }}
                                    </span>
                                </h2>

                            </div>
                            <div class="box grow ph-20">
                                <div style="width:100%; height:100%; position:relative;">
                                    <bulma-chart :type="'bar'" :data="board.data" :options="options"/>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </el-col>
            </el-row>
        </div>
    </vue-scroll>
</template>

<script>
    export default {
        name: 'Board',
        data() {
            return {
                boardsLoading: true,
                boards: [],
                options: {
                    maintainAspectRatio: false,
                    title: {
                        display: false,
                        text: 'Panolar'
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    responsive: true,
                    scales: {
                        xAxes: [{
                            stacked: true,
                            gridLines: {
                                display: false,
                                drawBorder: false,
                            },
                            ticks: {
                                fontColor: "#fff",
                            }
                        }],
                        yAxes: [{
                            stacked: true,
                            gridLines: {
                                display: false,
                                drawBorder: false,
                            },
                            ticks: {
                                display: false
                            }
                        }]
                    }
                }
            };
        },
        methods: {
            loadBoards(id) {
                this.boardsLoading = true;
                this.$http
                    .get('cards/pipelines', {
                        params: {
                            card_group_id: id
                        }
                    })
                    .then(response => {
                        this.boardsLoading = false;
                        this.boards = response.body.items;
                        this.boards.forEach(function (board) {
                            board.data = {};
                            board.data.labels = [];
                            board.data.datasets = [{
                                label: 'Kart Sayısı',
                                backgroundColor: '#fff',
                                stack: 'Stack 0',
                                data: []
                            }];
                            board.stages.forEach(function (stage) {
                                board.data.labels.push(stage.title);
                                board.data.datasets[0].data.push(stage.card_total);
                            })
                        })
                    });
            },
        },
        mounted() {
            this.loadBoards(1);
        }
    }
</script>
