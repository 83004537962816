<template>
	<section v-loading="itemLoading">
		<div class="card-box">
			<header class="card-box-header">
				<div class="title">
					Bolgeler
				</div>
				<div class="actions">
					<el-button icon="el-icon-plus" type="text" class="el-button-link primary" @click="crateItem"/>
				</div>
			</header>
			<section class="card-box-content">
				<vue-scroll>
					<div class="box-list" style="height: 600px">
						<div class="box-list-item" v-for="item in items" :key="item.id">
							<div class=" fluid">
								<div class="d-flex justify-space-between font-weight-700">
									<div class="margin-right-10" v-if="item.town">
										{{ item.town.title }}
									</div>
									<div class="margin-right-10" v-if="item.district">
										{{ item.district.title }}
									</div>
								</div>
								<div v-html="item.neighborhoods_format"></div>
							</div>
							<div class="description d-flex" style="justify-content: end">
								<el-button icon="el-icon-view" type="text" class="el-button-link green" @click="editItem(item)"/>
								<el-button icon="el-icon-delete" type="text" class="el-button-link red" @click="destroyItem(item.id)"/>
							</div>
						</div>
					</div>
				</vue-scroll>
			</section>
		</div>

		<el-dialog :title="modalTitle" :visible.sync="modalVisible" v-loading="modalLoading" width="50%">
			<el-form :label-position="'top'" ref="form" :model="form">
				<el-row :gutter="24">
					<el-col :span="12">
						<el-form-item label="İlçe">
							<el-select v-model="form.town_zone_id" placeholder="İlçe seçiniz" filterable
									   @change="selectedTown">
								<el-option v-for="item in towns" :key="item.id" :label="item.title" :value="item.id" />
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Semt">
							<el-select v-model="form.district_zone_id" placeholder="Semt seçiniz" filterable
									   @change="selectedDistrict">
								<el-option v-for="item in districts" :key="item.id" :label="item.title"
										   :value="item.id" />
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>

				<el-form-item label="Mahalleler">
					<el-select v-model="form.neighborhoods" value-key="id" multiple filterable placeholder="Mahalleler">
						<el-option v-for="item in neighborhoods" :key="item.id" :label="item.title" :value="item.id" />
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="storeItem" v-if="!form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" size="medium" @click="updateItem" v-if="form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </div>
		</el-dialog>
	</section>
</template>

<script>
    export default {
        name: 'Zones',
        props: ['itemId', 'url'],
        data() {
            return {
				source: null,
                itemLoading: true,
                modalLoading: false,
                modalVisible: false,
                modalTitle: 'Ekle',
                towns: {},
                districts: {},
                neighborhoods: {},
                items: [],
                form: {
                    country_zone_id: null,
                    city_zone_id: null,
                    town_zone_id: null,
                    district_zone_id: null,
                    neighborhoods: [],
                },
            }
        },

        methods: {
            index() {
                this.$http.get(this.source + '/zones', {
                    params: {
                        fake_url: this.url,
                    }
                }).then(response => {
                    this.items = response.body.rows;
                    this.itemLoading = false;
                }).catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
            },

            crateItem() {
                this.modalTitle = 'Ekle';
                this.modalVisible = true;
                this.form = {
                    country_zone_id: this.appSettings.site_country_id,
                    city_zone_id: this.appSettings.site_city_id,
                    town_zone_id: null,
                    district_zone_id: null,
                    neighborhoods: [],
                };

                this.districts = {};
                this.neighborhoods = {};
            },

            storeItem() {
                this.modalLoading = true;

                this.form.fake_url = this.url;

                this.$http.post(this.source + '/zones', this.form)
                    .then(response => {

                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            editItem(item) {
                this.itemLoading = false;
                this.modalLoading = false;
                this.modalVisible = true;

                this.modalTitle = 'Duzenle';

                this.form = item;
                this.districts = [];
                this.neighborhoods = [];

                let neighborhoods = [];

                if (_.size(this.form.neighborhoods)) {
                    this.form.neighborhoods.forEach(function (item) {
                        neighborhoods.push(item.zone_id);
                    });
                }

                this.form.neighborhoods = neighborhoods;

                this.getZones('towns', this.form.city_zone_id);
                this.getZones('districts', this.form.town_zone_id);
                this.getZones('neighborhoods', this.form.district_zone_id);
            },

            updateItem() {

                this.modalLoading = true;

                this.form.fake_url = this.url;

                this.$http.put(this.source + '/zones/' + this.form.id, this.form)
                    .then(response => {

                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {

                this.modalLoading = false;
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete(this.source + '/zones/' + id, {
                        fake_url: this.url
                    }).then(response => {
                        this.modalLoading = false;
                        this.modalVisible = false;
                        this.itemLoading = false;

                        this.index();

                        this.$message({
                            type: 'success',
                            message: 'Delete completed'
                        });
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.modalLoading = false;
                    this.itemLoading = false;
                });
            },

            getZones(alias, parentId) {
                this.$http.get('system/zones', {
                    params: {
                        fake_url: this.url,
                        parent_id: parentId,
                        alias: alias,
                        perPage: 1000
                    }
                }).then(response => {
                    this[alias] = response.body.rows;
                    this.itemLoading = false;
                });
            },

            selectedTown() {
                this.form.district_zone_id = null;
                this.form.neighborhoods = [];
                this.districts = [];
                this.neighborhoods = [];

                this.getZones('districts', this.form.town_zone_id)
            },

            selectedDistrict() {
                this.form.neighborhoods = [];
                this.neighborhoods = [];

                this.getZones('neighborhoods', this.form.district_zone_id)
            },
        },

		mounted() {

        	this.source = 'cms/contents/vehicles/' + this.itemId;

			this.index();
			this.getZones('towns', this.appSettings.site_city_id);
		},
    }
</script>