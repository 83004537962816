<template>
    <el-dropdown trigger="click" @command="onCommand" v-if="user">
        <span class="el-dropdown-link">
            <el-tooltip :content="user.name" effect="dark" placement="bottom">
                <avatar :img="user.avatar" class="avatar" v-if="user.avatar"/>
                <avatar type="staff" class="avatar" v-if="!user.avatar"/>
            </el-tooltip>
        </span>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="/profile">
                <i class="mdi mdi-account mr-10"></i> Hesabım
            </el-dropdown-item>
            <el-dropdown-item command="/logout" divided>
                <i class="mdi mdi-logout mr-10"></i> Çıkış Yap!
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Account',
        computed: mapState([
            'user',
        ]),
        data() {
            return {
                //
            }
        },
        methods: {
            onCommand(path) {

                if (path === '/logout') {
                    return this.handleLogout();
                }

                this.$router.push(path)
            },

            handleLogout() {
                localStorage.removeItem('token');

                this.$store.commit('setUser', null);

                return this.$router.push('/auth/login');
            },

            index() {

                this.$store.commit('setSplashScreen', true);

                this.$http
                    .get('account/user')
                    .then(response => {

                        let user = response.data.item;

                        user.roles = response.data.roles;

                        this.$http
                            .get('account/permissions')
                            .then(permissionResponse => {
                                let permissions = permissionResponse.data.permissions;

                                this.$store.commit('setUser', user);
                                this.$store.commit('setPermissions', permissions);

                                if (permissions && permissions['api.dashboard.index']) {
                                    this.$store.commit('setSplashScreen', false);
                                } else {
                                    window.location.href = config['SITE_BASE_URL'];
                                }
                            })
                            .catch(error => {
                                this.handleLogout();
                            })

                    })
                    .catch(error => {
                        this.handleLogout();
                    })
            }
        },

        mounted() {
            this.index();
        }
    }
</script>
