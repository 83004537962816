<style lang="scss" scoped>
	.list-item {
		border-bottom : 1px dashed;
		padding       : 10px;

		.title {
			font-weight : 700;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&:nth-child(2n) {
			background : #f6f8fb;
		}
	}
</style>

<template>
	<section v-loading="itemLoading" class="ps-container ps">
		<div class="card-box-save" @click="updateItem" style="margin: 0">
			<div class="box-container">
				<i class="el-icon-check"></i>
			</div>
		</div>

		<vue-scroll>
			<div style="height: 800px;">
				<el-form :label-position="'top'" ref="form" :model="form">
					<el-tabs>
						<el-tab-pane :label="alias" v-for="(permissions, alias) in items" :key="alias">

							<div class="text-right m-20">
								<el-form-item style="margin: 0;">
									<el-switch v-model="aliases[alias]" active-text="Tumunu Sec" @change="selectedItems(alias, permissions)" />
								</el-form-item>
							</div>

							<div class="list-item pl-15 pr-15" v-for="(item, key) in permissions" :key="key">
								<div class="title">
									<div>
										[{{ item.name }}] {{ item.title }}
									</div>
									<div>
										<el-form-item style="margin: 0">
											<el-switch v-model="item.is_active" @change="selectedItem(item)"/>
										</el-form-item>
									</div>
								</div>
								<div class="description">
									<el-row :gutter="24">
										<el-col :span="3" v-for="requestItem in item.requests" :key="requestItem.id">
											<el-form-item style="margin: 0;">
												<el-switch v-model="requestItem.status" :active-text="requestItem.title" />
											</el-form-item>
										</el-col>
									</el-row>
								</div>
							</div>
						</el-tab-pane>
					</el-tabs>
				</el-form>
			</div>
		</vue-scroll>
	</section>
</template>
<script>
    export default {
        name: 'Permissions',
        props: ['source', 'itemId'],
        data() {
            return {
                itemLoading: true,
				aliases : {},
                items: {},
                form: {}
            }
        },
        mounted() {
            this.index();
        },
        methods: {
            index() {
                this.$http
                    .get(this.source + '/permissions', {
                        params: {
                            fake_url: this.source
                        }
                    })
                    .then(response => {
                        this.items = response.body.items;
                        this.itemLoading = false;
                    });
            },

            updateItem() {

                this.itemLoading = true;

                this.$http
                    .put(this.source + '/permissions/1', {
                        permissions: this.items
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

			selectedItem(item) {
            	_.forEach(item.requests, function(request, index) {
            		if (item.is_active) {
						request.status = true;
					} else {
						request.status = false;
					}
				});
			},

			selectedItems(alias, items) {

            	let aliases = this.aliases;

            	_.forEach(items, function (item, key) {
            		if (aliases[alias]) {
						item.is_active = true;
					} else {
						item.is_active = false;
					}

					_.forEach(item.requests, function(request, index) {
						if (item.is_active) {
							request.status = true;
						} else {
							request.status = false;
						}
					});
				});
			}
        }
    }
</script>