<template>
    <section v-loading="itemLoading" class="ps ps-container">

        <div class="card-box" v-loading="itemLoading">
            <header class="card-box-header">
                <div class="title">
                    Linkler
                </div>
                <div class="actions">
                    <el-button @click="crateItem" type="text" icon="el-icon-plus" class="el-button-link primary" />
                </div>
            </header>
            <section class="card-box-content padding-20">
                <vue-scroll>
                    <table class="el-table">
                        <thead>
                        <tr>
                            <th class="padding-right-20">Sira</th>
                            <th class="padding-right-20">Id</th>
                            <th class="padding-right-20">Parent</th>
                            <th class="padding-right-20">Link</th>
                            <th width="100"></th>
                        </tr>
                        </thead>
                        <tbody v-for="(item, key) in items" :key="key">
                        <tr>
                            <td>
                                {{ item.sort }}
                            </td>
                            <td>
                                {{ item.id }}
                            </td>
                            <td>
                                {{ item.parent_id }}
                            </td>
                            <td>
                                <div>
                                    {{ item.title }}
                                </div>
                                <a :href="appConfig.frontPath + '/' + item.url" target="_blank">
                                    {{ item.url }}
                                </a>
                            </td>
                            <td>
                                <div class="d-flex" style="justify-content: end">
                                    <el-button type="text" icon="el-icon-edit" class="el-button-link primary" @click="editItem(item)"/>
                                    <el-button type="text" icon="el-icon-delete" class="el-button-link red" @click="destroyItem(item.id)"/>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </vue-scroll>
            </section>
        </div>

        <el-dialog :title="modalTitle" :visible.sync="modalVisible" v-loading="modalLoading" width="75%">
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-form-item label="Parent">
                    <el-input v-model="form.parent_id" placeholder="Parent"/>
                </el-form-item>

                <el-form-item label="Url">
                    <el-input v-model="form.url" placeholder="Url"/>
                </el-form-item>

                <el-form-item label="Baslik">
                    <el-input v-model="form.title" placeholder="Baslik"/>
                </el-form-item>

                <el-form-item label="Sıralama">
                    <el-input-number v-model="form.sort" placeholder="Sıralama"/>
                </el-form-item>

                <el-collapse v-model="activeName" accordion>
                    <el-collapse-item title="Link Ozellikleri" name="1">
                        <el-form-item label="Gorunum Turu">
                            <el-select v-model="form.view_type" filterable allow-create default-first-option
                                       placeholder="Gorunum Turu">
                                <el-option v-for="item in layouts" :key="item.id" :label="item.label" :value="item.id"/>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Html Class">
                            <el-input v-model="form.html_class" placeholder="Html Class"/>
                        </el-form-item>

                        <el-form-item label="Ikon">
                            <el-input v-model="form.ikon" placeholder="Ikon"/>
                        </el-form-item>

                        <el-form-item label="Tanitim Yazisi">
                            <el-input type="textarea" :autosize="textareaSize" placeholder="Tanitim Yazisi" v-model="form.description"/>
                        </el-form-item>

                        <el-form-item label="Oncesi">
                            <el-input type="textarea" :autosize="textareaSize" placeholder="Oncesi" v-model="form.before"/>
                        </el-form-item>

                        <el-form-item label="Sonrasi">
                            <el-input type="textarea" :autosize="textareaSize" placeholder="Sonrasi" v-model="form.after"/>
                        </el-form-item>

                        <el-form-item>
                            <el-switch v-model="form.is_active" active-text="Aktif" />
                        </el-form-item>
                    </el-collapse-item>
                </el-collapse>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="storeItem" v-if="!form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" size="medium" @click="updateItem" v-if="form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
    export default {
        name: 'Links',
        props: ['source'],
        data() {
            return {
                itemLoading: true,
                modalLoading: false,
                modalVisible: false,
                activeName: null,
                modalTitle: 'Ekle',
                items: [],
                form: {
                    fake_url: 'v1/' + this.source,
                    alias: 'web',
                },
                layouts: [
                    {
                        id: 'basic',
                        label: 'Varsayilan'
                    },
                    {
                        id: 'content',
                        label: 'Icerik'
                    },
                    {
                        id: 'description',
                        label: 'Aciklama'
                    },
                    {
                        id: 'links',
                        label: 'Linkler'
                    },
                    {
                        id: 'linksLink',
                        label: 'Linkler Link'
                    },
                    {
                        id: 'main',
                        label: 'Main'
                    },
                    {
                        id: 'mainLink',
                        label: 'Ana Menu Alt Link'
                    },
                    {
                        id: 'sub',
                        label: 'Alt Menu Agaci'
                    },
                    {
                        id: 'subLink',
                        label: 'Alt Menu Agaci Link'
                    }
                ],
                textareaSize: {
                    minRows: 8,
                    maxRows: 8
                }
            }
        },
        mounted() {
            this.index();
        },
        methods: {
            index() {
                this.$http.get(this.source + '/links', {
                    params: {
                        fake_url: 'v1/' + this.source,
                        url: 'v1/' + this.source,
                        perPage : 150
                    }
                }).then(response => {
                    this.items = response.body.rows;
                    this.itemLoading = false;
                });
            },

            crateItem() {
                this.modalTitle = 'Ekle';
                this.modalVisible = true;

                this.form = {
                    fake_url: 'v1/' + this.source,
                    alias: 'web',
                };
            },

            storeItem() {

                this.modalLoading = true;

                this.$http.post(this.source + '/links', this.form)
                    .then(response => {
                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.modalLoading = false;

                        this.apiErrorMessage(e);
                    });
            },

            editItem(item) {
                this.itemLoading = false;
                this.modalLoading = false;
                this.modalVisible = true;
                this.modalTitle = 'Duzenle';

                this.form = item;
                this.form.fake_url = 'v1/' + this.source;
            },

            updateItem() {
                this.modalLoading = true;

                this.$http.put(this.source + '/links/' + this.form.id, this.form)
                    .then(response => {
                        this.modalLoading = false;
                        this.modalVisible = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.modalLoading = false;
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete(this.source + '/links/' + id, {
                        fake_url: 'v1/' + this.source
                    }).then(response => {
                        this.modalLoading = false;
                        this.modalVisible = false;
                        this.itemLoading = false;

                        this.index();

                        this.$notify({
                            type: 'success',
                            message: 'Delete completed'
                        });
                    });
                }).catch(() => {
                    this.$notify({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.modalLoading = false;
                    this.itemLoading = false;
                });
            },
        }
    }
</script>