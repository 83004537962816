<template>
    <div class="actions">
        <el-tooltip content="Geri Don" effect="dark" placement="bottom">
            <el-button @click="$router.go(-1)" type="text" icon="el-icon-back" class="el-button-link grey"/>
        </el-tooltip>

        <el-tooltip content="Olustur" effect="dark" placement="bottom" v-if="$route.params.id">
            <el-button @click="createButton($route.meta.end_point)" type="text" icon="el-icon-plus" class="el-button-link primary"/>
        </el-tooltip>

        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="!$route.params.id">
            <el-button @click="storeItem" type="text" icon="el-icon-check" class="el-button-link green"/>
        </el-tooltip>

        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="$route.params.id">
            <el-button @click="updateItem" type="text" icon="el-icon-check" class="el-button-link green"/>
        </el-tooltip>

        <slot name="buttons"></slot>
    </div>
</template>

<script>
    export default {
        name: 'mdi',
        props: {
            storeItem: null,
            updateItem: null,
            buttons: null,
        },
    };
</script>
