<template>
    <section class="loading-container" v-loading="ui.pageLoading">
        <template v-if="form.id">
            <div class="ui five item menu small mb-20">
                <template v-for="tab in ui.tabs">
                    <div
                        class="item font-weight-600 cursor-pointer"
                        :key="tab.value"
                        :class="{active : tab.value === ui.activeTab}"
                        @click="ui.activeTab = tab.value"
                    >
                        {{ tab.label }}
                    </div>
                </template>
            </div>
            <el-form :label-position="'top'" ref="form" :model="form">
                <div v-show="ui.activeTab === 1">
                    <div class="card-box">
                        <header class="card-box-header">
                            <div class="title">Veli Bilgileri</div>
                            <div class="actions">
                                <el-button @click="createParentAddress">Veli Ekle</el-button>
                            </div>
                        </header>
                        <section class="card-box-content">
                            <el-tabs type="border-card" v-model="ui.activeParentTab" closable
                                     @tab-remove="removeParentAddress">
                                <el-tab-pane label="Veli" :name="'parent'">
                                    <el-row :gutter="24">
                                        <el-col :span="8">
                                            <el-form-item label="Veli Adı" class="is-required">
                                                <el-input v-model="form.customer.address.first_name"
                                                          placeholder="Veli Adı"/>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="Veli Soy Adı" class="is-required">
                                                <el-input v-model="form.customer.address.last_name"
                                                          placeholder="Veli Soy Adı"/>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="Yakınlık Derecesi" class="is-required">
                                                <el-select v-model="form.customer.address.title" filterable allow-create
                                                           default-first-option placeholder="Yakınlık Derecesi">
                                                    <el-option v-for="item in ui.addressTitles" :key="item.label"
                                                               :label="item.label" :value="item.label"/>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="Telefon" class="is-required">
                                                <el-input v-model="form.customer.address.phone"
                                                          placeholder="Telefon Numarasi" v-mask="'0 (###) ### ## ##'"/>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="E-Posta" class="is-required">
                                                <el-input v-model="form.customer.address.email" placeholder="E-Posta"/>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="T.C. Kimlik Numarası" class="is-required">
                                                <el-input v-model="form.customer.address.identification_no"
                                                          placeholder="T.C. Kimlik Numarası" v-mask="'###########'"/>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="Kurum">
                                                <ti-select :container="form.customer.address" model="company_id"
                                                           :options="$store.state.ui.companies"/>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="Meslek">
                                                <select-definition label="Meslek" alias="jobs" model="job_definition_id"
                                                                   :container="form.customer.address"/>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="İş yeri">
                                                <el-input v-model="form.customer.address.company_name"
                                                          placeholder="İş yeri"/>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="Eğitim Durumu">
                                                <select-definition label="Eğitim Durumu" alias="educations"
                                                                   model="education_definition_id"
                                                                   :container="form.customer.address"/>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <div class="box-list">
                                        <div class="box-list-item box-list-item-title font-weight-500">
                                            Adres Bilgileri
                                        </div>
                                    </div>
                                    <div class="pt-20">
                                        <zone-box :container="form.customer.address"></zone-box>

                                        <el-form-item label="Adres" class="is-required">
                                            <el-input v-model="form.customer.address.address_1" placeholder="Adres"/>
                                        </el-form-item>

                                        <el-row :gutter="24">
                                            <el-col :span="12">
                                                <el-form-item label="Vergi Numarasi" class="mb-0-i">
                                                    <el-input v-model="form.customer.address.tax_no"
                                                              placeholder="Vergi Numarasi"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-form-item label="Vergi Dairesi" class="mb-0-i">
                                                    <el-input v-model="form.customer.address.tax_center"
                                                              placeholder="Vergi Dairesi"/>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </el-tab-pane>
                                <template v-for="(address, index) in form.addresses">
                                    <el-tab-pane :label="address.title" :name="'address-' + index">
                                        <el-row :gutter="24">
                                            <el-col :span="8">
                                                <el-form-item label="Veli Adı" class="is-required">
                                                    <el-input v-model="address.first_name" placeholder="Veli Adı"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Veli Soy Adı" class="is-required">
                                                    <el-input v-model="address.last_name" placeholder="Veli Soy Adı"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Yakınlık Derecesi" class="is-required">
                                                    <el-select v-model="address.title" filterable allow-create
                                                               default-first-option placeholder="Yakınlık Derecesi">
                                                        <el-option v-for="item in ui.addressTitles" :key="item.label"
                                                                   :label="item.label" :value="item.label"/>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Telefon" class="is-required mb-0-i">
                                                    <el-input v-model="address.phone" placeholder="Telefon"
                                                              v-mask="'0 (###) ### ## ##'"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="E-Posta" class="mb-0-i">
                                                    <el-input v-model="address.email" placeholder="E-Posta"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="T.C. Kimlik Numarası">
                                                    <el-input v-model="address.identification_no"
                                                              placeholder="T.C. Kimlik Numarası"
                                                              v-mask="'###########'"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Kurum">
                                                    <ti-select :container="address" model="company_id"
                                                               :options="$store.state.ui.companies"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Meslek">
                                                    <select-definition label="Meslek" alias="jobs"
                                                                       model="job_definition_id" :container="address"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="İş yeri">
                                                    <el-input v-model="address.company_name" placeholder="İş yeri"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Eğitim Durumu">
                                                    <select-definition label="Eğitim Durumu" alias="educations"
                                                                       model="education_definition_id"
                                                                       :container="address"/>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-tab-pane>
                                </template>
                            </el-tabs>
                        </section>
                    </div>
                </div>
                <div v-show="ui.activeTab === 2">
                    <div class="card-box mb-0">
                        <header class="card-box-header">
                            <div class="title">Öğrenci Bilgileri</div>
                            <div class="actions">
                                <el-button @click="createStudent">Yeni Ogrenci Ekle</el-button>
                            </div>
                        </header>
                        <section class="card-box-content">
                            <el-tabs type="border-card" v-model="ui.activeStudentTab" closable
                                     @tab-remove="removeStudent">
                                <template v-for="(student, index) in form.students">
                                    <el-tab-pane :label="student.first_name" :name="'student-' + index">
                                        <el-form-item label="T.C. Kimlik Numarası">
                                            <el-input v-model="student.identification_no"
                                                      placeholder="T.C. Kimlik Numarası" v-mask="'###########'"/>
                                        </el-form-item>
                                        <el-row :gutter="24">
                                            <el-col :span="8">
                                                <el-form-item label="Öğrenci Adı" class="is-required">
                                                    <el-input v-model="student.first_name" placeholder="Öğrenci Adı"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Öğrenci Soy Adı" class="is-required">
                                                    <el-input v-model="student.last_name"
                                                              placeholder="Öğrenci Soy Adı"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Doğum Tarihi">
                                                    <el-date-picker v-model="student.birthday" type="date"
                                                                    placeholder="Tarih Seçiniz"
                                                                    value-format="yyyy-MM-dd" format="dd/MM/yyyy"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Sınıfı" class="is-required">
                                                    <select-definition label="Sinif" alias="classes"
                                                                       model="education_class_definition_id"
                                                                       :container="student"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Okulu">
                                                    <select-definition label="Okul" alias="schools"
                                                                       model="school_definition_id"
                                                                       :container="student"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Cinsiyet" class="is-required">
                                                    <select-definition label="Cinsiyet" alias="genders"
                                                                       model="gender_definition_id"
                                                                       :container="student"/>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <el-form-item label="Kan Grubu">
                                            <select-definition label="Kan Grubu" alias="blood-groups"
                                                               model="blood_group_definition_id" :container="student"/>
                                        </el-form-item>
                                        <el-form-item label="Saglik Problemleri">
                                            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}"
                                                      placeholder="Saglik Problemleri"
                                                      v-model="student.health_problems"/>
                                        </el-form-item>
                                        <el-form-item label="Surekli Kullandigi Ilaclar">
                                            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}"
                                                      placeholder="Surekli Kullandigi Ilaclar"
                                                      v-model="student.using_pills"/>
                                        </el-form-item>
                                        <el-form-item label="Alerjisi">
                                            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}"
                                                      placeholder="Alerjisi" v-model="student.allergy"/>
                                        </el-form-item>
                                        <el-form-item class="mb-0-i">
                                            <el-switch v-model="student.is_share" active-text="Fotoğraf İzni"/>
                                        </el-form-item>
                                    </el-tab-pane>
                                </template>
                            </el-tabs>
                        </section>
                    </div>
                </div>
                <div v-show="ui.activeTab === 1 || ui.activeTab === 2" class="mt-20">
                    <div class="ui button large fluid green" v-loading="formLoading" :class="{disable : formLoading}" @click="update">
                        Veli ve Ogrenci Bilgilerini Kaydet
                    </div>
                </div>
            </el-form>

            <Offers :source="'sales/offers'" :customer="form" v-show="ui.activeTab === 3"/>
            <Orders :source="'sales/orders'" :customer="form" v-show="ui.activeTab === 4"/>
            <Interviews :source="'enrollments/interviews'" :customer="form" v-show="ui.activeTab === 5"/>
        </template>
    </section>
</template>

<script>

import Offers from "@/app/Pages/Crm/Users/_parts/Offers.vue";
import Orders from "@/app/Pages/Crm/Users/_parts/Orders.vue";
import Interviews from "@/app/Pages/Crm/Users/_parts/Interviews.vue";

export default {
    name: 'UserEdit',
    components: {
        Orders,
        Offers,
        Interviews,
    },
    props: ['source'],
    data() {
        return {
            ui: {
                pageLoading: false,
                activeTab: 1,
                tabs: [
                    {
                        value: 1,
                        label: 'Veli Bilgileri'
                    },
                    {
                        value: 2,
                        label: 'Öğrenci Bilgileri'
                    },
                    {
                        value: 3,
                        label: 'Teklifler'
                    },
                    {
                        value: 4,
                        label: 'Siparişler'
                    },
                    {
                        value: 5,
                        label: 'Görüşmeler'
                    }
                ],
                addressTitles: [
                    {
                        label: 'Anne',
                    },
                    {
                        label: 'Baba',
                    },
                    {
                        label: '3. Şahıs',
                    },
                ],
                activeParentTab: 'parent',
                activeStudentTab: 'student-0'
            },
            formLoading: false,
            form: {
                customer: {
                    address: {
                        //
                    },
                },
                addresses: [],
                students: [],
            }
        }
    },

    mounted() {
        this.show();
    },

    methods: {
        show() {
            this.pageLoading = true;

            this.$http.get(this.$route.meta.end_point + '/' + this.$route.params.id)
                .then(response => {
                    this.form = response.body.data;
                    this.pageLoading = false;
                });
        },

        update() {
            this.formLoading = true;

            this.$http.put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                .then(response => {
                    this.formLoading = false;
                    this.apiSuccessMessage(response);
                })
                .catch((e) => {
                    this.formLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        createParentAddress() {
            this.form.addresses.push({
                'title': 'Yeni Veli Bilgisi',
                country_zone_id: 1,
                city_zone_id: null,
                town_zone_id: null,
                district_zone_id: null,
                neighborhood_zone_id: null,
            })
        },

        removeParentAddress(index) {
            if (index === 'parent') {
                return this.$notify.error({
                    title: 'Upss!',
                    message: 'Gecerli veliyi silemezsiniz!',
                    position: 'top-right'
                });
            }

            this
                .$confirm('Verileriniz kalici olarak silinecek.', 'Uyari', {
                    confirmButtonText: 'Onayliyorum',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {
                    let key = index.replace('address-', '')
                    this.form.addresses.splice(key, 1);
                    this.ui.activeParentTab = 'parent'
                })
                .catch(() => {
                    return false;
                });
        },

        createStudent() {
            this.form.students.push({
                first_name: 'Yeni',
                last_name: 'Ogrenci',
            })
        },

        removeStudent(index) {
            this
                .$confirm('Verileriniz kalici olarak silinecek.', 'Uyari', {
                    confirmButtonText: 'Onayliyorum',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {
                    let key = index.replace('student-', '')
                    this.form.students.splice(key, 1);
                    this.ui.activeStudentTab = 'student-0'
                })
                .catch(() => {
                    return false;
                });
        }
    }
}
</script>
