<style scoped lang="scss">
    .card-box .card-box-content .box-list .box-list-item {

        min-height: 75px;

        .actions {
            right: 0 !important;
            top: 0;
        }
    }
</style>

<template>
    <section v-loading="itemLoading" class="ps-container ps">
        <div class="card-box">
            <header class="card-box-header">
                <div class="title">
                    Planlanan Etkinlikler
                </div>
            </header>
            <section class="card-box-content">
                <vue-scroll>
                    <div style="height: 300px;">

                        <Empty :items="items" />

                        <div class="box-list">
                            <div
                                    class="box-list-item box-list-item-editable box-list-item-media"
                                    v-for="item in items" :key="item.id"
                                    :class="item.color"
                            >
                                <div class="box-list-item-media-item">
                                    <div class="text fluid">
                                        <div class="my-flex">
                                            <div class="name">
                                                {{ item.title }}
                                            </div>
                                            <div class="date fluid">
                                                <i class="mdi mdi-clock"></i> {{ item.start_at }} - {{ item.end_at }}
                                            </div>
                                            <div>
                                                <span v-html="item.status_badge"></span>
                                            </div>
                                        </div>
                                        <div>
                                            {{ item.description }}
                                        </div>
                                    </div>
                                    <div class="actions">
                                        <div class="item-action-button edit" @click="editItem(item)">
                                            <i class="el-icon-edit"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-scroll>
            </section>
        </div>

        <el-dialog :title="modalTitle" :visible.sync="modalVisible" v-loading="modalLoading" width="50%"
                   class="modal-inner" append-to-body>
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-radio-group v-model="form.status_id" v-if="!form.id">
                    <el-radio-button
                            v-for="item in statuses"
                            :label="item.id"
                            :key="item.id"
                    > {{ item.title }}
                    </el-radio-button>
                </el-radio-group>

                <el-form-item label="Başlık">
                    <el-input v-model="form.title" placeholder="Başlık"/>
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Baslangic Tarihi">
                            <el-date-picker v-model="form.start_at" type="datetime"
                                            placeholder="Tarih Seçiniz"
                                            value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Bitis Tarihi">
                            <el-date-picker v-model="form.end_at" type="datetime"
                                            placeholder="Tarih Seçiniz"
                                            value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Notunuz">
                    <el-input type="textarea" placeholder="Notunuz..."
                              v-model="form.description"/>
                </el-form-item>

                <el-form-item v-if="form.id">
                    <el-switch v-model="form.is_active" active-text="Tamamlandi"/>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="storeItem" v-if="!form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" size="medium" @click="updateItem" v-if="form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>
<script>


    import Empty from "../../../../../components/Empty";
    export default {
        name: 'Events',
        components: {Empty},
        props: ['source', 'itemId'],
        data() {
            return {
                itemLoading: true,
                modalLoading: false,
                modalVisible: false,
                modalTitle: 'Etkinlik Ekle',
                statuses: [],
                items: {},
                form: {
                    card_id: this.itemId
                },
            }
        },

        methods: {

            getStatuses() {
                this.itemLoading = true;

                this.$http
                    .get('system/statuses?alias=events')
                    .then(response => {
                        this.itemLoading = false;
                        this.statuses = response.body.rows;
                    });
            },

            index() {
                this.$http
                    .get('calendar/events', {
                        params: {
                            card_id: this.itemId,
                            filters: [
                                {
                                    relation: null,
                                    inputs: [
                                        {
                                            name: 'card_id',
                                            field: 'card_id',
                                            type: 'singleSelect',
                                            value: this.itemId,
                                        }
                                    ]
                                },
                            ]
                        }
                    })
                    .then(response => {
                        this.items = response.body.rows;
                        this.itemLoading = false;
                    });
            },

            crateItem() {
                this.modalTitle = 'Etkinlik Ekle';
                this.modalVisible = true;
                this.form = {
                    //
                };
            },

            storeItem() {
                this.modalLoading = true;

                this.form.fake_url = this.source;
                this.form.card_id = this.itemId;

                this.$http.post('calendar/events', this.form)
                    .then(response => {

                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            editItem(item) {
                this.itemLoading = false;
                this.modalLoading = false;
                this.modalVisible = true;
                this.modalTitle = 'Etkinlik Duzenle';

                this.form = item;
            },

            updateItem() {

                this.modalLoading = true;

                this.form.fake_url = this.source;

                this.$http
                    .put('calendar/events/' + this.form.id, this.form)
                    .then(response => {

                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {

                this.modalLoading = false;
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete('calendar/events/' + id, {
                        fake_url: this.source
                    }).then(response => {
                        this.modalLoading = false;
                        this.modalVisible = false;
                        this.itemLoading = false;

                        this.index();

                        this.$message({
                            type: 'success',
                            message: 'Delete completed'
                        });
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.modalLoading = false;
                    this.itemLoading = false;
                });
            }

        },

        mounted() {
            this.index();
            this.getStatuses();
        },
    }
</script>
