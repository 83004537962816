<template>
    <div class="card-box">
        <header class="card-box-header">
            <div class="title">Musteri Bilgileri</div>
            <div class="actions">
                <el-tooltip content="Kaydet">
                    <el-button icon="el-icon-check" size="small" type="success" @click="update" :loading="formLoading" />
                </el-tooltip>
            </div>
        </header>
        <section class="card-box-content p-20">
            <el-form :label-position="'top'" ref="filterForm" :model="form">
                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Ad Soyad">
                            <el-input v-model="form.name" placeholder="Ad Soyad"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="Cinsiyet">
                            <select-definition label="Cinsiyet" alias="genders" model="gender_definition_id" :container="form"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="Doğum Tarihi">
                            <el-date-picker v-model="form.birthday" format="dd/MM/yyyy" value-format="yyyy-MM-dd" type="date" placeholder="Tarih Seçiniz"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Telefon Numarasi">
                            <el-input v-model="form.phone" placeholder="Telefon Numarasi" v-mask="'0 (###) ### ## ##'"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="E-Posta Adresi">
                            <el-input v-model="form.email" placeholder="Eposta Adresi"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Şirket" class="m-0">
                            <ti-select :container="form" model="company_id" :options="$store.state.ui.companies"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="Meslek" class="m-0">
                            <select-definition label="Meslek" alias="jobs" model="job_definition_id" :container="form"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="Eğitim Seviyesi" class="m-0">
                            <select-definition label="Eğitim" alias="educations" model="education_definition_id" :container="form"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Customer',
    props : ['customer'],
    data() {
        return {
            formLoading : false,
            form : {
                //
            }
        }
    },
    mounted() {
        this.form = this.customer
    },
    methods: {
        update() {
            this.formLoading = true;

            this.$http.put('crm/users/' + this.form.id, this.form)
                .then(response => {
                    this.formLoading = false;
                    this.apiSuccessMessage(response);
                })
                .catch((e) => {
                    this.formLoading = false;
                    this.apiErrorMessage(e);
                });
        }
    }
}
</script>
