<template>
    <section v-loading="itemLoading">
        <div class="card-box">
            <header class="card-box-header">
                <div class="title">
                    Iletisim Bilgileri
                </div>
            </header>
            <section class="card-box-content">
                <div class="box-list" v-for="(item, key) in items" :key="key">
                    <div class="box-list-item box-list-item-title">
                        {{ item.title }}
                    </div>
                    <div class="box-list-item">
                        {{ item.first_name }} {{ item.last_name }}
                    </div>
                    <div class="box-list-item" v-if="item.email">
                        {{ item.email }}
                    </div>
                    <div class="box-list-item" v-if="item.phone">
                        {{ item.phone }}
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Contacts',
        props: ['source', 'accountId'],
        data() {
            return {
                itemLoading: true,
                items: {},
            }
        },
        mounted() {
            this.index();
        },
        methods: {
            index() {
                this.$http
                    .get('crm/accounts/' + this.accountId + '/addresses', {
                        params: {
                            fake_url: this.source,
                        }
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.items = response.body.rows;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },
        }
    }
</script>