<template>
	<section v-loading="itemLoading" class="ps-container ps">
		<div class="card-box-save" @click="updateItem" style="margin: 0">
			<div class="box-container">
				<i class="el-icon-check"></i>
			</div>
		</div>

		<vue-scroll>
			<div style="height: 600px; padding: 20px">
				<el-form :label-position="'top'" ref="form" :model="form">
					<el-row :gutter="24">
						<el-col :span="8" v-for="(item, key) in items" :key="key">
							<el-form-item>
								<el-switch v-model="item.status" :active-text="item.title" />
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</vue-scroll>
	</section>
</template>
<script>
    export default {
        name: 'Accesses',
        props: ['source', 'itemId'],
        data() {
            return {
                itemLoading: true,
                items: {},
                form: {}
            }
        },
        mounted() {
            this.index();
        },
        methods: {
            index() {
                this.$http
                    .get(this.source + '/accesses', {
                        params: {
                            fake_url: this.source
                        }
                    })
                    .then(response => {
                        this.items = response.body.items;
                        this.itemLoading = false;
                    });
            },

            updateItem() {

                this.itemLoading = true;

                this.$http
                    .put(this.source + '/accesses/1', {
                        accesses : this.items
					})
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },
        }
    }
</script>