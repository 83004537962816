<template>
    <el-dialog title="Görüşme Bilgileri" :visible.sync="visible" append-to-body class="card-dialog" @close="handleDialogClose" :close-on-press-escape="false" :close-on-click-modal="false">
        <div v-loading="pageLoading" class="loading-container">
            <el-form :label-position="'top'" :model="form">
                <template v-if="form.id">
                    <div class="card-box mb-0">
                        <section class="card-box-content p-20">
                            <el-row :gutter="24">
                                <el-col :span="8">
                                    <el-form-item label="Görüşme Nedeni">
                                        <select-definition placeholder="Görüşme Nedeni" alias="interview-subjects" model="subject_definition_id" :container="form.interview"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="Eğitim Yılı">
                                        <select-definition alias="education-years" model="education_year_definition_id" :container="form.interview" placeholder="Eğitim Yılı"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="Danışman">
                                        <el-select v-model="form.interview.created_by_id" placeholder="Danışman" clearable filterable class="fluid" :disabled="true">
                                            <el-option v-for="item in staffs" :key="item.id" :label="item.title" :value="item.id"/>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="Görüşme Durumu">
                                        <select-status placeholder="Görüşme Durumu" alias="interviews" model="status_id" :container="form.interview"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="Iletisim Kanali">
                                        <select-definition placeholder="İletişim Kanalı" alias="marketing-process" model="channel_id" :container="form.interview"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="Öğrencinin Sınıfı">
                                        <select-definition alias="classes" model="class_definition_id" :container="form.interview" placeholder="Öğrencinin Sınıfı"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item label="Görüşme Notu" class="mb-0-i">
                                <el-input v-model="form.interview.message" placeholder="Görüşme Notu"/>
                            </el-form-item>
                        </section>
                    </div>
                    <div class="card-box mb-0">
                        <header class="card-box-header">
                            <div class="title">Veli Bilgileri</div>
                            <div class="actions">
                                <el-button @click="createAddress">Veli Iletisim Bilgisi Ekle</el-button>
                            </div>
                        </header>
                        <section class="card-box-content">
                            <el-tabs type="border-card" v-model="activeAddressTab" closable @tab-remove="removeParent">
                                <el-tab-pane :label="'Veli ('+form.customer.address.title+')'" :name="'parent'">
                                    <el-row :gutter="24">
                                        <el-col :span="8">
                                            <el-form-item label="Veli Adı">
                                                <el-input v-model="form.customer.address.first_name" placeholder="Veli Adı"/>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="Veli Soy Adı">
                                                <el-input v-model="form.customer.address.last_name" placeholder="Veli Soy Adı"/>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="Yakınlık Derecesi">
                                                <el-select v-model="form.customer.address.title" filterable allow-create
                                                           default-first-option placeholder="Yakınlık Derecesi">
                                                    <el-option v-for="item in addressTitles" :key="item.label"
                                                               :label="item.label" :value="item.label"/>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="Telefon Numarasi" class="mb-0-i">
                                                <el-input v-model="form.customer.address.phone" placeholder="Telefon Numarasi"
                                                          v-mask="'0 (###) ### ## ##'"/>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="E-Posta Adresi" class="mb-0-i">
                                                <el-input v-model="form.customer.address.email" placeholder="Eposta Adresi"/>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="8">
                                            <el-form-item label="Kurum" class="mb-0-i">
                                                <ti-select :container="form.customer" model="company_id"
                                                           :options="$store.state.ui.companies"/>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-tab-pane>
                                <template v-for="(address, index) in form.addresses">
                                    <el-tab-pane :label="address.title" :name="'address-' + index">
                                        <el-row :gutter="24">
                                            <el-col :span="8">
                                                <el-form-item label="Veli Adı">
                                                    <el-input v-model="address.first_name" placeholder="Veli Adı"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Veli Soy Adı">
                                                    <el-input v-model="address.last_name" placeholder="Veli Soy Adı"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Yakınlık Derecesi">
                                                    <el-select v-model="address.title" filterable allow-create
                                                               default-first-option placeholder="Yakınlık Derecesi">
                                                        <el-option v-for="item in addressTitles" :key="item.label"
                                                                   :label="item.label" :value="item.label"/>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Telefon Numarasi" class="mb-0-i">
                                                    <el-input v-model="address.phone" placeholder="Telefon Numarasi"
                                                              v-mask="'0 (###) ### ## ##'"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="E-Posta Adresi" class="mb-0-i">
                                                    <el-input v-model="address.email" placeholder="Eposta Adresi"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Kurum" class="mb-0-i">
                                                    <ti-select :container="address" model="company_id" :options="$store.state.ui.companies"/>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-tab-pane>
                                </template>
                            </el-tabs>
                        </section>
                    </div>
                    <div class="card-box mb-0">
                        <header class="card-box-header">
                            <div class="title">Öğrenci Bilgileri</div>
                            <div class="actions">
                                <el-button @click="createStudent">Yeni Ogrenci Ekle</el-button>
                            </div>
                        </header>
                        <section class="card-box-content">
                            <el-tabs type="border-card" v-model="activeStudentTab" closable @tab-remove="removeStudent">
                                <template v-for="(student, index) in form.students">
                                    <el-tab-pane :label="student.first_name" :name="'student-' + index">
                                        <el-row :gutter="24">
                                            <el-col :span="8">
                                                <el-form-item label="Öğrenci Adı">
                                                    <el-input v-model="student.first_name" placeholder="Öğrenci Adı"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Öğrenci Soy Adı">
                                                    <el-input v-model="student.last_name" placeholder="Öğrenci Soy Adı"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Doğum Tarihi">
                                                    <el-date-picker v-model="student.birthday" type="date" placeholder="Tarih Seçiniz" value-format="yyyy-MM-dd" format="dd/MM/yyyy"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Sınıfı">
                                                    <select-definition label="Sinif" alias="classes" model="education_class_definition_id" :container="student"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Okulu">
                                                    <select-definition label="Okul" alias="schools" model="school_definition_id" :container="student"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Cinsiyet">
                                                    <select-definition label="Cinsiyet" alias="genders" model="gender_definition_id" :container="student"/>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-tab-pane>
                                </template>
                            </el-tabs>
                        </section>
                    </div>
                    <Offers :source="'sales/offers'" :customer="form.customer" class="offer-box"/>
                </template>
            </el-form>
        </div>

        <div slot="footer" class="dialog-footer d-flex" v-if="form.id">
            <el-button type="primary" class="fluid" style="justify-content: center" :loading="formLoading" :disabled="formLoading" @click="update('simple')">
                Kaydet
            </el-button>
            <el-button type="success" class="fluid" style="justify-content: center" :loading="formLoading" :disabled="formLoading" @click="update('offer')">
                Kadet ve Fiyat Teklifi Ver
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
import Notes from "@/components/Notes";
import Customer from "./Customer";
import Addresses from "./Addresses";
import Students from "./Students";
import Events from "./Events";
import Offers from "@/app/Pages/Crm/Users/_parts/Offers.vue";

export default {
    name: 'InterviewShow',
    components: {Offers, Customer, Addresses, Students, Events, Notes},
    props: ['id', 'staffs', 'close'],
    data() {
        return {
            visible: false,
            pageLoading: false,
            form: {
                //
            },

            formLoading: false,

            addressTitles: [
                {
                    label: 'Anne',
                },
                {
                    label: 'Baba',
                },
                {
                    label: '3. Şahıs',
                },
            ],

            activeAddressTab: 'parent',
            activeStudentTab: 'student-0'
        }
    },
    mounted() {
        this.pageLoading = false;

        if (this.$store.state.cardDialogVisible && this.id) {
            setTimeout(() => {
                this.visible = true
                this.getIndex()
            }, 200)
        }
    },

    methods: {
        handleDialogClose() {
            this.visible = false;
            this.$store.commit('setCardDialogVisible', false)
            this.close()
        },

        getIndex() {
            this.pageLoading = true

            this.$http
                .get('enrollments/interviews/' + this.id)
                .then(response => {
                    this.pageLoading = false;
                    this.form = response.body.data;
                })
                .catch((e) => {
                    this.pageLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        update(type) {
            this.formLoading = true;

            this.$http
                .put('enrollments/interviews/' + this.form.id, this.form)
                .then(response => {
                    this.apiSuccessMessage(response);
                    this.formLoading = false;

                    if (type === 'offer') {
                        this.$router.push('/enrollments/offers/create?customer_id=' + this.form.customer.id)
                    }
                })
                .catch((e) => {
                    this.formLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        createAddress() {
            this.form.addresses.push({
                'title': 'Yeni Iletisim Bilgisi'
            })
        },

        removeParent(index) {
            if (index === 'parent') {
                return this.$notify.error({
                    title: 'Upss!',
                    message: 'Gecerli veliyi silemezsiniz!',
                    position: 'top-right'
                });
            }

            this
                .$confirm('Verileriniz kalici olarak silinecek.', 'Uyari', {
                    confirmButtonText: 'Onayliyorum',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {
                    let key = index.replace('address-', '')

                    this.form.addresses.splice(key, 1);
                })
                .catch(() => {
                    return false;
                });
        },

        createStudent() {
            this.form.students.push({
                first_name : 'Yeni Ogrenci'
            })
        },

        removeStudent(index) {
            this
                .$confirm('Verileriniz kalici olarak silinecek.', 'Uyari', {
                    confirmButtonText: 'Onayliyorum',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {
                    let key = index.replace('student-', '')

                    this.form.students.splice(key, 1);
                })
                .catch(() => {
                    return false;
                });
        }
    }
}
</script>
