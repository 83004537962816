<template>
    <el-row :gutter="24">
        <el-col :span="8">
            <el-form-item label="Şehir" :key="container.country_zone_id" class="is-required">
                <el-select v-model="container.city_zone_id" placeholder="Şehir seçiniz" filterable clearable @change="selectedCity">
                    <el-option v-for="item in getZonesByParentId(container.country_zone_id)" :key="item.id" :label="item.title" :value="item.id"/>
                </el-select>
            </el-form-item>
        </el-col>
        <el-col :span="8">
            <el-form-item label="İlçe" :key="container.city_zone_id" class="is-required">
                <el-select v-model="container.town_zone_id" placeholder="İlçe seçiniz" filterable @change="selectedTown">
                    <el-option v-for="item in getZonesByParentId(container.city_zone_id)" :key="item.id" :label="item.title" :value="item.id"/>
                </el-select>
            </el-form-item>
        </el-col>
        <el-col :span="8">
            <el-form-item label="Semt / Mahalle" :key="container.town_zone_id" class="is-required">
                <el-select v-model="container.neighborhood_zone_id" placeholder="Semt seçiniz" filterable clearable @change="selectedDistrict">
                    <el-option-group
                        v-for="district in getZonesByParentId(container.town_zone_id)"
                        :key="district.id"
                        :label="district.title"
                        :value="district.id"
                    >
                        <el-option v-for="item in getZonesByParentId(district.id)" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-option-group>
                </el-select>
            </el-form-item>
        </el-col>
    </el-row>
</template>
<script>
export default {
    name: 'ZoneBox',
    props: ['container'],
    data() {
        return {

        }
    },
    mounted() {
        if (!this.container.country_zone_id) {
            this.container.country_zone_id = 1
        }

        if (!this.container.city_zone_id) {
            this.container.city_zone_id = 29762
        }
    },

    methods: {
        selectedCountry() {

            if (!this.container.country_zone_id) {
                return null;
            }

            this.container.city_zone_id = null;
            this.container.town_zone_id = null;
            this.container.district_zone_id = null;
            this.container.neighborhood_zone_id = null;
        },

        selectedCity() {
            if (!this.container.city_zone_id) {
                return null;
            }

            this.container.town_zone_id = null;
            this.container.district_zone_id = null;
            this.container.neighborhood_zone_id = null;
        },

        selectedTown() {

            if (!this.container.town_zone_id) {
                return null;
            }

            this.container.district_zone_id = null;
            this.container.neighborhood_zone_id = null;
        },

        selectedDistrict(id) {
            if (!this.container.neighborhood_zone_id) {
                return null
            }

            this.lodash.forEach(this.$store.getters.ui.zones[this.container.town_zone_id], (district) => {
                this.lodash.forEach(this.$store.getters.ui.zones[district.id], (neighborhood) => {
                    if (id === neighborhood.id) {
                        this.container.district_zone_id = neighborhood.parent_id;
                    }
                })
            })
        },

        handleClick(item) {
            console.log(item)
        },

        getZonesByParentId(parentId) {

            if (parentId === undefined) {
                return [];
            }

            return this.$store.getters.ui.zones[parentId] ? this.$store.getters.ui.zones[parentId] : []
        },
    }
}
</script>
