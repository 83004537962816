<template>
    <div class="page-vue-good-table scrollable only-y">
        <page-header>
            <div class="actions">
                <div class="counter" v-if="pagination">
                    <span>{{ pagination.total }}</span> sonuc bulundu.
                </div>
                <el-tooltip content="Filtrele">
                    <el-button icon="el-icon-search" size="small" type="success" @click="getIndex(1)" />
                </el-tooltip>
            </div>
        </page-header>

        <div class="loading-container">
            <el-form :label-position="'top'" ref="filterForm" :model="filterForm" @submit.native.prevent="getIndex(1)">
                <div class="card-box card-global-filter">
                    <el-row :gutter="24">
                        <el-col :span="4">
                            <el-form-item  class="m-0">
                                <el-input v-model="filterForm.customer_name" placeholder="Müşteri"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item class="m-0">
                                <select-status placeholder="Etkinlik Türü" alias="events" model="status_id" :container="filterForm"/>
                            </el-form-item>
                        </el-col>

                        <el-col :span="4">
                            <el-form-item class="m-0">
                                <select-status placeholder="Önceliklendirme" alias="prioritizations" model="prioritization_status_id" :container="filterForm"/>
                            </el-form-item>
                        </el-col>

                        <el-col :span="4">
                            <el-form-item class="m-0">
                                <select-status placeholder="Etkinlik Durumu" alias="event-statuses" model="event_status_id" :container="filterForm"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item class="m-0">
                                <el-select v-model="filterForm.created_by_id" placeholder="Danışman" clearable filterable class="fluid">
                                    <el-option v-for="item in staffs" :key="item.id" :label="item.title" :value="item.id"/>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item class="m-0">
                                <short-daterange-picker :container="filterForm" model="date_range"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>

                <button type="submit" style="display: none"></button>
            </el-form>

            <div class="modern-items" v-loading="pageLoading">
                <template v-for="item in items">
                    <div class="item" :key="item.id" @click="handleShowCard(item.card_id)">
                        <div class="content">
                            <div class="customer-name">{{ item.title }}</div>
                            <div class="metas">
                                <div class="meta">
                                    <mdi :icon="item.type.icon"/>
                                    {{ item.type.title }}
                                </div>
                                <div class="meta staff">
                                    <mdi icon="mdi-account-box"/>
                                    {{ item.created }}
                                </div>
                                <div class="meta">
                                    <mdi icon="mdi-calendar"/>
                                    {{ item.start_at }}
                                </div>
                                <div class="meta">
                                    <mdi icon="mdi-tag"/>
                                    {{ item.prioritization.title }}
                                </div>
                            </div>
                        </div>
                        <div class="info">
                            <div class="ui label" :style="{background: item.status.bg_color, color: item.status.text_color}">
                                {{ item.status.title }}
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <div v-if="pagination && pagination.total > 0" class="text-right margin-bottom-20">
                <el-pagination
                    :currentPage="filterForm.page"
                    :pageCount="pagination.last_page"
                    :pagerCount="5"
                    :small="false"
                    @current-change="getIndex"
                />
            </div>
        </div>

        <interview-show :id="cardId" :close="handleCloseCard" :staffs="staffs" :key="cardId" />
    </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import InterviewShow from "@/app/Pages/Enrollments/Interviews/Show.vue";

export default {
    name: 'Event',
    components: {InterviewShow, PageHeader},
    data() {
        return {
            pageLoading: true,
            filterForm: {
                page : 1
            },
            staffs: [],

            pagination: null,
            items : [],

            cardId : null,
        }
    },
    mounted() {
        this.pageLoading = false;

        this.getStaffs()
        this.getIndex()
    },
    methods: {

        getStaffs() {
            this.itemLoading = false;

            this.$http
                .get('crm/users', {
                    params: {
                        filters: [
                            {
                                relation: 'roles',
                                inputs: [
                                    {
                                        name: 'role_id',
                                        field: 'role_id',
                                        type: 'multiSelect',
                                        value: [
                                            9
                                        ]
                                    }
                                ]
                            },
                        ],
                    }
                })
                .then(response => {
                    this.itemLoading = false;

                    this.staffs.push({
                        id: -1,
                        title : 'System User',
                    })

                    _.forEach(response.body.rows, (item) => {
                        this.staffs.push({
                            id : item.id,
                            title : item.name,
                        })
                    })
                });
        },

        getIndex(page) {
            this.pageLoading = true;

            if (page) {
                this.filterForm.page = page
            }

            this.$http
                .get('enrollments/events', {
                    params: this.filterForm
                })
                .then(response => {
                    this.pageLoading = false;
                    this.pagination = response.data.data.pagination
                    this.items = response.data.data.rows
                })
                .catch((e) => {
                    this.pageLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        handleShowCard(cardId) {
            this.$store.commit('setCardDialogVisible', true)
            this.cardId = cardId
        },

        handleCloseCard() {
            this.$store.commit('setCardDialogVisible', false)
            this.cardId = null

            this.getIndex(1)
        }
    },
}
</script>
