<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading">
            <div class="card-box" >
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                            <el-button @click="$router.go(-1)" type="text" icon="el-icon-back" class="el-button-link grey"/>
                        </el-tooltip>
                    </div>
                </header>
                <section class="card-box-content padding-20">
                    <el-row :gutter="24">
                        <el-col :span="4">
                            <div class="padding-10">Okudu :</div>
                            <div class="padding-10">Etkilesimde Bulundu :</div>
                            <div class="padding-10">Alici :</div>
                            <div class="padding-10">Konu :</div>
                            <div class="padding-10">Mesaj :</div>
                        </el-col>
                        <el-col :span="20">
                            <div class="padding-10">{{ form.is_read }}</div>
                            <div class="padding-10">{{ form.is_click }}</div>
                            <div class="padding-10">{{ form.receiver }}</div>
                            <div class="padding-10">{{ form.subject }}</div>
                            <div class="bg-primary-lighter padding-30" style="border-radius: 5px; overflow: scroll;">
                                <div v-html="form.content"></div>
                            </div>
                        </el-col>
                    </el-row>
                </section>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
    export default {
        name: 'ElementForm',
        data() {
            return {
                itemLoading: true,
                form: {
                    //
                }
            }
        },

        methods: {
            loadItem() {

                this.itemLoading = true;

                this.$http
                    .get(this.$route.meta.end_point + '/' + this.$route.params.id, {
                        params: this.serverParams
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body.item
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            }
        },

        mounted() {
            this.loadItem()
        }
    }
</script>