<template>
    <div class="vertical-nav" :class="{'nav-collapsed':collapseNav, 'open':openSidebar}">
        <div class="sidebar-mask" :class="{'open':openSidebar}" @click="closeNav"></div>

        <div class="sidebar flex column" :class="{'open':openSidebar, ['pos-'+position]:true}">
            <logo :collapse-nav="collapseNav" @collapse-nav-toggle="collapseNavToggle"/>

            <div class="box-nav box grow">
                <div class="scroll-nav" v-bar="{ useScrollbarPseudo: true }">
                    <div>
                        <Nav :is-collapse="collapseNav" @push-page="pushPage"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Logo from "./Logo";
    import Nav from "./Nav";

    export default {
        name: 'VerticalNav',
        components: {Nav, Logo},
        props: ['collapseNav', 'openSidebar', 'position'],
        data() {
            return {}
        },
        methods: {
            collapseNavToggle() {
                this.$emit('collapse-nav-toggle')
            },
            pushPage(index, indexPath) {
                this.$emit('push-page', {page: index})
            },
            closeNav() {
                this.$emit('update:openSidebar', false)
            }
        }
    }
</script>