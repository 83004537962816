<template>
    <div v-loading="itemLoading">
        <el-form :label-position="'top'" ref="form" :model="form">
            <table class="el-table ui table">
                <thead>
                <tr>
                    <th>Deger</th>
                    <th width="1">
                        <el-button type="primary" icon="el-icon-plus" size="mini" @click="createItem"/>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, key) in items" :key="key">

                    <td style="padding-right: 20px">

                        <el-form-item>
                            <el-input v-model="item.variables.title" placeholder="Baslik"/>
                        </el-form-item>

                        <el-form-item>
                            <el-input v-model="item.variables.icon" placeholder="Icon"/>
                        </el-form-item>

                        <el-form-item>
                            <el-input v-model="item.variables.link" placeholder="Link"/>
                        </el-form-item>

                        <el-form-item>
                            <el-input v-model="item.sort" placeholder="Sira"/>
                        </el-form-item>
                    </td>
                    <td>
                        <el-button type="danger" icon="el-icon-delete" size="mini" @click="destroyRowItem(key)"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: 'SocialLinks',
        props: ['source', 'items'],
        data() {
            return {
                itemLoading: true,
                form: {
                    //
                }
            }
        },
        created() {
            this.itemLoading = false;
        },
        methods: {

            createItem() {
                this.items.push({
                    sort: 100,
                    variables: {}
                })
            },

            destroyRowItem(key) {
                this.items.splice(key, 1);
            }
        }
    }
</script>