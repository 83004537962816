<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading">
            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <crud-buttons :storeItem="storeItem" :updateItem="updateItem">
                        <template slot="buttons">
                            <el-tooltip content="Web Gorunumu" effect="dark" placement="bottom" v-if="$route.params.id">
                                <el-button @click="openBlankWindow(appConfig.frontPath +'/' +form.seo.slug)" type="text"
                                           icon="mdi mdi-send" class="el-button-link yellow margin-left-10"/>
                            </el-tooltip>
                        </template>
                    </crud-buttons>
                </header>
                <section class="card-box-tabs padded small">
                    <el-form :label-position="'top'" ref="form" :model="form">
                        <el-tabs :tab-position="'top'">
                            <el-tab-pane label="Icerik">
                                <el-row :gutter="24">
                                    <el-col :span="18">
                                        <el-form-item label="Başlık">
                                            <el-input v-model="form.title" placeholder="Başlık"/>
                                        </el-form-item>

                                        <el-form-item label="Yayınlanma Tarihi">
                                            <el-date-picker v-model="form.publish_at" type="datetime"
                                                            placeholder="Tarih Seçiniz"
                                                            value-format="yyyy-MM-dd HH:mm:ss"/>
                                        </el-form-item>

                                        <el-form-item label="Hedef Linki">
                                            <el-input v-model="form.target_link" placeholder="Hedef Linki"/>
                                        </el-form-item>

                                        <el-form-item label="Icerik Etiketleri">
                                            <el-select
                                                    v-model="form.tags_format"
                                                    multiple
                                                    filterable
                                                    allow-create
                                                    default-first-option
                                                    placeholder="Etiket Seçin Veya Yeni Oluşturun">
                                                <el-option
                                                        v-for="item in form.all_tags"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value">
                                                </el-option>
                                            </el-select>
<!--                                            <el-input disabled v-model="form.tags" placeholder="Icerik Etiketleri"/>-->
                                            <div class="small">
                                               Sistemdeki herhangi bir etiketi seçebilir veya yeni ekleyebilirsiniz.
                                            </div>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="Resim">
                                            <file-browser v-model="form.file_path"/>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-form-item label="Kısa Açıklama">
                                    <trumbowyg v-model="form.short_description" :config="$store.state.trumbowygConfig"/>
                                </el-form-item>

                                <el-form-item label="Açıklama">
                                    <trumbowyg v-model="form.description" :config="$store.state.trumbowygConfig"/>
                                </el-form-item>

                                <el-form-item label="Sıralama">
                                    <el-input-number v-model="form.sort" placeholder="Sıralama"/>
                                </el-form-item>

                            </el-tab-pane>

                            <el-tab-pane label="Tanitim" v-if="$route.params.type === 'pages'">
                                <el-form-item label="Kisa Aciklama">
                                    <trumbowyg v-model="form.metas.banner_short_description"
                                               :config="$store.state.trumbowygConfig"/>
                                </el-form-item>

                                <el-form-item label="Aciklama">
                                    <trumbowyg v-model="form.metas.banner_description"
                                               :config="$store.state.trumbowygConfig"/>
                                </el-form-item>
                            </el-tab-pane>

                            <el-tab-pane label="Etkinlik" v-if="this.$route.params.type === 'events'">
                                <el-form-item label="Etkinlik Tarihi">
                                    <el-date-picker v-model="form.metas.event_date" type="date"
                                                    placeholder="Tarih Seçiniz"
                                                    value-format="dd/MM/yyyy"/>
                                </el-form-item>

                                <el-form-item label="Etkinlik Saati">
                                    <el-input v-model="form.metas.event_time" placeholder="Etkinlik Saati"/>
                                </el-form-item>

                                <el-form-item label="Etkinlik Yeri">
                                    <el-input v-model="form.metas.event_place" placeholder="Etkinlik Yeri"/>
                                </el-form-item>
                            </el-tab-pane>

                            <el-tab-pane label="Egitim Programi"
                                         v-if="this.$route.params.type === 'education-programs'">
                                <el-form-item label="Baslama ve Bitis Tarihi">
                                    <el-input v-model="form.metas.start_and_end_date"
                                              placeholder="Baslama ve Bitis Tarihi"/>
                                </el-form-item>

                                <el-form-item label="Toplam Ders Saati">
                                    <el-input v-model="form.metas.total_courses_time" placeholder="Toplam Ders Saati"/>
                                </el-form-item>

                                <el-form-item label="Servis Imkani">
                                    <el-input v-model="form.metas.service_status" placeholder="Servis Imkani"/>
                                </el-form-item>
                            </el-tab-pane>

                            <el-tab-pane label="Baglantilar">
                                <SelectCategory label="Kategoriler" :alias="$route.params.type" model="categories"
                                                :container="form"/>

                                <el-form-item label="Iliskili Sayfalar">
                                    <el-select v-model="form.suggestions" multiple clearable filterable
                                               placeholder="Iliskili Sayfalar">
                                        <el-option v-for="item in suggestions" :key="'suggestion-' + item.id"
                                                   :label="item.title"
                                                   :value="item.id"/>
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="Egitim Programi Iliskili Kategoriler">
                                    <el-select v-model="formRelationCategories" multiple
                                               placeholder="Egitim Programi Iliskili Kategoriler">
                                        <el-option
                                                v-for="(item, key) in relationCategories"
                                                :key="'education-' + key"
                                                :label="item.nest_title"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="Egitim Programi Iliskili Kategoriler Filtre">
                                    <el-select v-model="formRelationCategoryFilters" multiple
                                               placeholder="Egitim Programi Iliskili Kategoriler Filtre">
                                        <el-option
                                                v-for="(item, key) in relationCategories"
                                                :key="'education-filter-' + key"
                                                :label="item.nest_title"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item>
                                    <el-switch v-model="form.is_featured" active-text="One cikartilsin mi?"/>
                                    <div class="small">
                                        One cikarilan urunler sadece ilgili yerlerde cikar. Listeleme sayfalarinda
                                        cikmaz.
                                    </div>
                                </el-form-item>

                                <el-form-item>
                                    <el-switch v-model="form.is_plugin" active-text="Eklentiler gozuksun mu?"/>
                                </el-form-item>

                                <el-form-item>
                                    <el-switch v-model="form.is_active" active-text="Aktif"/>
                                </el-form-item>
                            </el-tab-pane>

                            <el-tab-pane label="Tasarim">
                                <el-form-item label="Sayfa Gorunumu">
                                    <el-select v-model="form.view_path" filterable allow-create default-first-option
                                               placeholder="Sayfa Gorunumu">
                                        <el-option v-for="item in layouts" :key="item.label" :label="item.label"
                                                   :value="item.id"/>
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="Icon">
                                    <el-select v-model="form.metas.icon" filterable allow-create default-first-option
                                               placeholder="Icon">
                                        <el-option v-for="item in icons" :key="item.label" :label="item.label"
                                                   :value="item.id"/>
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="HTML Class">
                                    <el-select v-model="form.metas.html_class" filterable allow-create
                                               default-first-option
                                               placeholder="HTML Class">
                                        <el-option v-for="item in htmlClasses" :key="item.label" :label="item.label"
                                                   :value="item.id"/>
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="Renk">
                                    <el-color-picker v-model="form.metas.color" show-alpha
                                                     :predefine="appConfig.predefineColors"/>
                                </el-form-item>
                            </el-tab-pane>

                            <el-tab-pane label="Seo">
                                <Seo :container="form.seo"/>
                            </el-tab-pane>
                        </el-tabs>
                    </el-form>
                </section>
            </div>

            <div class="card-box" v-if="form.id">
                <section class="card-box-tabs">
                    <el-tabs :tab-position="'top'">
                        <el-tab-pane label="Dosyalar">
                            <Files :source="endPoint + '/' + $route.params.id"/>
                        </el-tab-pane>
                        <el-tab-pane label="Sekmeler">
                            <Tabs :source="endPoint + '/' + $route.params.id"/>
                        </el-tab-pane>
                    </el-tabs>
                </section>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
    import Trumbowyg from "vue-trumbowyg/src/component";
    import Files from "./_parts/Files";
    import Tabs from "./_parts/Tabs";
    import Seo from "../../../../components/Seo";
    import SelectCategory from "../../../../components/SelectCategory";
    import FileBrowser from "../../../../components/FileBrowser";

    export default {
        name: 'ElementForm',
        components: {
            FileBrowser,
            Trumbowyg,
            SelectCategory,
            Seo,
            Tabs,
            Files,
        },
        props: ['source'],
        data() {
            return {
                itemLoading: true,
                endPoint: null,
                form: {
                    file_path: null,
                    seo: {},
                    metas: {}
                },

                suggestions: [],
                formRelationCategories: [],
                formRelationCategoryFilters: [],
                relationCategories: [],
                layouts: [
                    {
                        id: 'education_age_group',
                        label: 'Yas Grubu'
                    },
                    {
                        id: 'education_group',
                        label: 'Egitim Grubu'
                    },
                    {
                        id: 'vehicle',
                        label: 'Servis'
                    },
                    {
                        id: 'online_education',
                        label: 'Online Egitim'
                    },
                    {
                        id: 'teams.content',
                        label: 'Kadromuz'
                    }
                ],
                icons: [
                    {
                        id: 'icon-toy-horse',
                        label: 'Anaokulu'
                    },
                    {
                        id: 'icon-abc-boxes',
                        label: 'Ilkokul'
                    },
                    {
                        id: 'icon-bag',
                        label: 'Ortaokul'
                    },
                    {
                        id: 'icon-tube',
                        label: 'Lise'
                    },
                    {
                        id: 'icon-normal-education',
                        label: 'Normal Eğitim Prog.'
                    },
                    {
                        id: 'icon-summer-school',
                        label: 'Yaz Okulu'
                    },
                    {
                        id: 'icon-quiz-education',
                        label: 'Sınav Sistemleri'
                    },
                    {
                        id: 'icon-language-workshop',
                        label: 'Dil Atölyeleri'
                    },
                    {
                        id: 'icon-lesson-prv',
                        label: 'Özel Dersler'
                    },
                    {
                        id: 'icon-order-language',
                        label: 'Diğer Diller'
                    }
                ],
                htmlClasses: [
                    {
                        id: 'anaokulu',
                        label: 'Anaokulu'
                    },
                    {
                        id: 'ilkokul',
                        label: 'Ilkokul'
                    },
                    {
                        id: 'ortaokul',
                        label: 'Ortaokul'
                    },
                    {
                        id: 'lise',
                        label: 'Lise'
                    }
                ],
            }
        },

        methods: {
            loadItem() {

                this.itemLoading = true;

                this.$http
                    .get(this.endPoint + '/' + this.$route.params.id, {
                        params: this.serverParams
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body.item;

                        if (!_.size(this.form.seo)) {
                            this.form.seo = {};
                        }

                        if (!_.size(this.form.metas)) {
                            this.form.metas = {}
                        } else {

                            let metas = {};

                            _.forEach(this.form.metas, function (value, key) {
                                metas[key] = value
                            });

                            if (_.size(this.form.relation_categories)) {
                                this.formRelationCategories = this.form.relation_categories;
                            }

                            if (_.size(this.form.relation_categories)) {
                                this.formRelationCategoryFilters = this.form.relation_category_filters;
                            } else {
                                this.formRelationCategoryFilters = [];
                            }
                        }

                        let suggestions = [];

                        _.forEach(this.form.suggestions, function (item, key) {
                            suggestions.push(item.suggested_content_id)
                        });

                        this.form.suggestions = suggestions;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            storeItem() {

                this.itemLoading = true;
                this.form.metas.relation_categories = this.formRelationCategories;
                this.form.metas.relation_category_filters = this.formRelationCategoryFilters;

                this.form.tags = this.form.tags_format.join(', ');

                this.$http
                    .post(this.endPoint, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                        this.$router.push('edit/' + response.body.item.id);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.itemLoading = true;
                this.form.metas.relation_categories = this.formRelationCategories;
                this.form.metas.relation_category_filters = this.formRelationCategoryFilters;

                this.form.tags = this.form.tags_format.join(', ');

                this.$http
                    .put(this.endPoint + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                    confirmButtonText: 'Tamam',
                    cancelButtonText: 'Iptal Et',
                    type: 'warning'
                })
                    .then(() => {
                        this.$http
                            .delete(this.endPoint + '/' + id)
                            .then(response => {
                                this.itemLoading = false;

                                this.$router.push('/');

                                this.apiSuccessMessage(response)
                            })
                            .catch((e) => {
                                this.itemLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            },

            getSuggestions() {
                this.itemLoading = true;

                this.$http
                    .get(this.endPoint + '?perPage=1000')
                    .then(response => {
                        this.itemLoading = false;
                        this.suggestions = response.body.rows;
                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            },

            getRelationCategories() {
                this.itemLoading = true;

                this.$http.get('tools/search/categories', {
                    params: {
                        alias: 'education-programs',
                        perPage: 1000,
                    }
                })
                    .then(response => {
                        this.itemLoading = false;
                        this.relationCategories = response.body.items;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            }
        },

        mounted() {

            this.endPoint = this.$route.meta.end_point + this.$route.params.type;

            this.getSuggestions();
            this.getRelationCategories();

            if (this.$route.params.id) {
                this.loadItem()
            } else {
                this.itemLoading = false;
            }
        }
    }
</script>