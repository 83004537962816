<template>
    <div class="card-box" v-loading="itemLoading">
        <header class="card-box-header">
            <div class="title">
                Kampanyalar
            </div>
        </header>
        <section class="card-box-content">
            <div class="box-list">
                <div class="box-list-item box-list-item-title" v-if="productCampaigns[0]">
                    Sınıf Kampanyaları
                </div>
                <div :class="{'box-list-item' : true, 'bg-red' : customCampaigns[0]}"
                     v-for="item in productCampaigns">
                    <div class="name fluid">{{ item.title }}</div>
                    <div class="price">{{ $n(item.total, 'currency') }}</div>
                </div>
                <div class="box-list-item bg-green" v-for="item in customCampaigns">
                    <div class="name fluid">{{ item.title }}</div>
                    <div class="price">{{ $n(item.total, 'currency') }}</div>
                </div>

                <div class="box-list-item" v-for="item in vehicleCampaigns">
                    <div class="name fluid">
                        {{ item.title }}
                    </div>
                    <div class="price">
                        {{ $n(item.total, 'currency') }}
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'Campaigns',
        props: ['campaigns', 'customCampaigns'],
        data() {
            return {
                itemLoading: true,
                productCampaigns: [],
                vehicleCampaigns: [],
            }
        },

        methods: {
            parseCampaigns () {
                let products = [];
                let vehicles = [];

                _.forEach(this.campaigns, function (item, key) {

                    if (item.type === 'classrooms') {
                        products.push(item);
                    } else {
                        vehicles.push(item);
                    }
                });

                this.productCampaigns = products;
                this.vehicleCampaigns = vehicles;
            }
        },

        mounted() {
            this.itemLoading = false;

            this.parseCampaigns();
        }
    }
</script>
