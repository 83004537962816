<style lang="scss" scoped>
    .event {
        padding: 0px 20px 5px 20px;
    }
</style>

<template>
    <section class="activities ps-container">
        <div class="card-box">
            <header class="card-box-header">
                <div class="title">
                    Logs
                </div>
            </header>
            <section class="card-box-content" v-loading="itemLoading">
                <vue-scroll>
                    <div class="content" style="height: 500px">
                        <div class="feeds">
                            <div class="event" v-for="(item, key) in items" :key="'ActivitiesComponentItem-' + key">
                                <div class="label">
                                    <i :class="'route-icon route-icon-color ' + item.permission_request_method_name"></i>
                                </div>
                                <div class="content">
                                    <div class="date">{{ item.human_date }}</div>
                                    <div class="summary">
                                        <span v-html="item.event_ui_format"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-scroll>
            </section>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Activities',
        props: ['url'],
        data() {
            return {
                itemLoading: true,
                items: []
            }
        },
        mounted() {
            this.$http.get('tools/activities', {
                params: {
                    url: 'v1/' + this.url,
                    fake_url: 'v1/' + this.url,
                }
            }).then(response => {
                this.items = response.body.rows;
                this.itemLoading = false;
            });
        }
    }
</script>