<style lang="scss">
    .platform {
        .platform-container {
            padding: 25px;
        }
    }
</style>

<template>
    <div class="page-vue-good-table scrollable only-y">
        <div class="loading-container" v-loading="user ? false : true">
            <div class="platform teacher" v-if="user">
                <div class="ui menu small m-0">
                    <router-link to="/teacher/dashboard" class="header item text-center" style="width: 200px">
                        <img src="@/resources/images/logo.svg" alt="" class="ui image small centered">
                    </router-link>
                    <router-link :to="'/' + link.url" class="item" v-for="link in platform.teacher.links" :key="link.url">
                        {{ link.title }}
                    </router-link>
                </div>
                <div class="platform-container">
                    <page-header />
                    <router-view :key="$route.fullPath"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import PageHeader from "../../components/PageHeader";

    export default {
        name: 'Teacher',
        components: {PageHeader},
        computed: mapState([
            'user',
            'platform'
        ]),
        data() {
            return {
               pageLoading : true,
            }
        }
    }
</script>