import layouts from '../config/layout'

import MasterContainer from '../app/Container/Master'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Dt'

import Campaign from '../app/Pages/Marketing/Campaign'
import ReferenceCode from '../app/Pages/Marketing/ReferenceCode'
import Gift from '../app/Pages/Marketing/Gift'
import Banner from '../app/Pages/Marketing/Banner'
import BulkMessage from '../app/Pages/Marketing/BulkMessage'
import SubmissionGroup from '../app/Pages/Marketing/SubmissionGroup'
import SubmissionMessage from '../app/Pages/Marketing/SubmissionMessage'
import Draft from '../app/Pages/Marketing/Draft'

export default {
    path: '/marketing',
    name: 'marketing',
    component: MasterContainer,
    meta: {
        auth: true,
        layout: layouts.navLeft,
        title: 'Pazarlama',
    },
    children: [
        {
            path: 'campaigns',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Kampanyalar',
            },
            children: [
                {
                    name: 'marketing.campaigns.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Kampanyalar',
                        path: 'campaigns',
                        end_point: 'marketing/campaigns',
                    },
                },
                {
                    name: 'marketing.campaigns.create',
                    path: 'create',
                    component: Campaign,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Kampanya Olustur',
                        path: 'campaigns',
                        end_point: 'marketing/campaigns',
                    },
                },
                {
                    name: 'marketing.campaigns.edit',
                    path: 'edit/:id',
                    component: Campaign,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Kampanya Bilgileri',
                        path: 'campaigns',
                        end_point: 'marketing/campaigns',
                    },
                }
            ]
        },
        {
            path: 'campaign-change-codes',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Referans Kodları',
            },
            children: [
                {
                    name: 'marketing.campaign_change_codes.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Referans Kodları',
                        path: 'campaign-change-codes',
                        end_point: 'marketing/campaign-change-codes',
                    },
                },
                {
                    name: 'marketing.campaign_change_codes.create',
                    path: 'create',
                    component: ReferenceCode,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Referans Kodu Olustur',
                        path: 'campaign-change-codes',
                        end_point: 'marketing/campaign-change-codes',
                    }
                },
                {
                    name: 'marketing.campaign_change_codes.edit',
                    path: 'edit/:id',
                    component: ReferenceCode,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Referans Kodu Bilgileri',
                        path: 'campaign-change-codes',
                        end_point: 'marketing/campaign-change-codes',
                    }
                }
            ]
        },
        {
            path: 'gifts',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Hediyeler',
            },
            children: [
                {
                    name: 'marketing.gifts.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Hediyeler',
                        path: 'gifts',
                        end_point: 'marketing/gifts',
                    },
                },
                {
                    name: 'marketing.gifts.create',
                    path: 'create',
                    component: Gift,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Hediye Olustur',
                        path: 'gifts',
                        end_point: 'marketing/gifts',
                    },
                },
                {
                    name: 'marketing.gifts.edit',
                    path: 'edit/:id',
                    component: Gift,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Hediye Bilgileri',
                        path: 'gifts',
                        end_point: 'marketing/gifts',
                    },
                }
            ]
        },
        {
            path: 'banners',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Reklamlar',
            },
            children: [
                {
                    name: 'marketing.banners.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Reklamlar',
                        path: 'banners',
                        end_point: 'marketing/banners',
                    },
                },
                {
                    name: 'marketing.banners.create',
                    path: 'create',
                    component: Banner,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Reklam Olustur',
                        path: 'banners',
                        end_point: 'marketing/banners',
                    }
                },
                {
                    name: 'marketing.banners.edit',
                    path: 'edit/:id',
                    component: Banner,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Reklam Bilgileri',
                        path: 'banners',
                        end_point: 'marketing/banners',
                    },
                }
            ]
        },
        {
            path: 'bulk-message',
            name: 'marketing.bulk_message',
            component: BulkMessage,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: ' Toplu Mesaj ',
                path: 'bulk-message',
                end_point: 'marketing/bulk-message',
            }
        },
        {
            path: 'submissions',
            component: MasterContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Gonderiler',
            },
            children: [
                {
                    path: 'groups',
                    component: PageContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Toplu Mesajlar',
                    },
                    children: [
                        {
                            name: 'marketing.submissions.groups.index',
                            path: '',
                            component: DatatableContainer,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Toplu Mesajlar',
                                path: 'groups',
                                end_point: 'marketing/submissions/groups',
                            },
                        },
                        {
                            name: 'marketing.submissions.groups.edit',
                            path: 'edit/:id',
                            component: SubmissionGroup,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Toplu Mesaj Bilgileri',
                                path: 'groups',
                                end_point: 'marketing/submissions/groups',
                            },
                        }
                    ]
                },
                {
                    path: 'messages',
                    component: PageContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Tekil Mesajlar',
                    },
                    children: [
                        {
                            name: 'marketing.submissions.messages.index',
                            path: '',
                            component: DatatableContainer,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Tekil Mesajlar',
                                path: 'messages',
                                end_point: 'marketing/submissions/messages',
                            },
                        },
                        {
                            name: 'marketing.submissions.messages.edit',
                            path: 'edit/:id',
                            component: SubmissionMessage,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Tekil Mesaj Bilgileri',
                                path: 'messages',
                                end_point: 'marketing/submissions/messages',
                            },
                        }
                    ]

                },
            ]
        },
        {
            path: 'drafts/:type',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Taslaklar',
            },
            children: [
                {
                    name: 'marketing.drafts.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Taslaklar',
                        path: 'drafts',
                        end_point: 'marketing/drafts',
                    },
                },
                {
                    name: 'marketing.drafts.create',
                    path: 'create',
                    component: Draft,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Taslak Olustur',
                        path: 'drafts',
                        end_point: 'marketing/drafts',
                    },
                },
                {
                    name: 'marketing.drafts.edit',
                    path: 'edit/:id',
                    component: Draft,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Taslak Bilgileri',
                        path: 'drafts',
                        end_point: 'marketing/drafts',
                    },
                }
            ]
        },
    ]
}
