<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading" style="padding: 0;">
            <el-form :label-position="'top'" ref="form" :model="form">
                <div class="card-box">
                    <header class="card-box-header">
                        <div class="title">
                            {{ $route.meta.title }}
                        </div>
                        <div class="actions">
                            <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                                <el-button @click="$router.go(-1)" type="text" icon="el-icon-back"
                                           class="el-button-link grey"/>
                            </el-tooltip>

                            <el-tooltip content="Kaydet" effect="dark" placement="bottom">
                                <el-button @click="storeItem" type="text" icon="el-icon-check"
                                           class="el-button-link green"/>
                            </el-tooltip>
                        </div>
                    </header>
                    <section class="card-box-content padding-20">
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="Adı">
                                    <el-input v-model="form.first_name" placeholder="Adı"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Soyadı">
                                    <el-input v-model="form.last_name" placeholder="Soyadı"/>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="Telefon Numarasi">
                                    <el-input v-model="form.phone" placeholder="Telefon Numarasi"
                                              v-mask="'0 (###) ### ## ##'"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="E-Posta Adresi">
                                    <el-input v-model="form.email" placeholder="Eposta Adresi"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </section>
                    <section class="card-box-content">
                        <div class="box-list">
                            <div class="box-list-item box-list-item-title">
                                <div class="name">
                                    Ogrenciler
                                </div>
                                <div>
                                    <el-tooltip content="Ogrenci Ekle" effect="dark" placement="left-end">
                                        <el-button @click="createStudent" type="text" icon="el-icon-plus"
                                                   class="el-button-link primary"/>
                                    </el-tooltip>
                                </div>
                            </div>
                            <div class="box-list-item" v-for="(student, key) in form.students" :key="key">
                                <el-form-item label="Adı" class="fluid margin-right-20">
                                    <el-input v-model="student.first_name" placeholder="Adı"/>
                                </el-form-item>
                                <el-form-item label="Soyadi" class="fluid margin-right-20">
                                    <el-input v-model="student.last_name" placeholder="Soyadi"/>
                                </el-form-item>
                                <el-form-item label="Donem" class="fluid margin-right-20">
                                    <select-definition label="Donem" alias="periods" model="school_period_definition_id"
                                                       :container="student"/>
                                </el-form-item>
                                <el-form-item label="Sinif" class="fluid margin-right-20">
                                    <select-definition label="Sinif" alias="classes" model="school_class_definition_id"
                                                       :container="student"/>
                                </el-form-item>
                                <div>
                                    <el-tooltip content="Sil" effect="dark" placement="left-end">
                                        <el-button icon="el-icon-delete" type="text" class="el-button-link red"
                                                   @click="destroyStudent(key)"/>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </el-form>
        </div>
    </vue-scroll>
</template>

<script>
    import SelectDefinition from "../../../../components/SelectDefinition";

    export default {
        name: 'ElementForm',
        components: {SelectDefinition},
        props: ['source'],
        data() {
            return {
                itemLoading: true,
                form: {
                    roles: [],
                    students: [],
                    name: null,
                    email: '@customer.minidil.com',
                }
            }
        },

        methods: {
            storeItem() {

                this.itemLoading = true;
                this.form.name = this.form.first_name + ' ' + this.form.last_name;

                this.form.roles = [];
                this.form.roles.push(this.appSettings.customer_role_id);

                this.$http
                    .post(this.$route.meta.end_point, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                        this.$router.push('edit/' + response.body.item.id);
                    })
                    .catch((e, a, b) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            createStudent() {
                this.form.students.push({
                    first_name: null,
                    last_name: null,
                    school_period_definition_id: null,
                    school_class_definition_id: null,
                })
            },

            destroyStudent(key) {
                this.form.students.splice(key, 1);
            }
        },

        mounted() {
            this.itemLoading = false;
        }
    }
</script>
