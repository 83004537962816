<template>
    <div class="page-vue-good-table scrollable only-y">
        <div class="card-box">
            <header class="card-box-header">
                <div class="title">
                    {{ $route.meta.title }}
                </div>
                <div class="actions">
                    <el-popover placement="bottom-end" trigger="hover">
                        <el-tooltip content="Sutunlar" effect="dark" placement="bottom" slot="reference">
                            <el-button icon="el-icon-more" type="text" class="el-button-link grey"
                                       style="display: inline-flex !important;"/>
                        </el-tooltip>

                        <el-form :label-position="'right'">
                            <el-form-item v-for="column in columns" :key="column.field" v-if="column.label">
                                <el-switch @change="toggleVisibility(column)" :active-text="column.label"
                                           :active-value="false" :inactive-value="true" v-model="column.hidden"/>
                            </el-form-item>
                        </el-form>
                    </el-popover>

                    <el-tooltip content="Filtre" effect="dark" placement="bottom" v-if="filterVisible">
                        <el-button icon="el-icon-search" type="text" class="el-button-link grey ml-10"/>
                    </el-tooltip>

                    <el-tooltip content="Disa Aktar" effect="dark" placement="bottom" v-if="$route.meta.isExport">
                        <el-button icon="el-icon-download" type="text" class="el-button-link blue margin-left-10"
                                   @click="exportFilter"/>
                    </el-tooltip>

                    <el-tooltip content="Olustur" effect="dark" placement="bottom" v-if="canCreate">
                        <router-link :to="path + '/create'" class="ml-10">
                            <el-button icon="el-icon-plus" type="text" class="el-button-link primary"
                                       style="display: inline-flex !important;"/>
                        </router-link>
                    </el-tooltip>
                </div>
            </header>
            <section class="card-box-content padding-20">
                <div class="vue-good-table-box">
                    <vue-good-table
                            mode="remote"
                            :totalRows="pagination.total"
                            :rows="rows"
                            :columns="columns"
                            :pagination-options="paginationOptions"
                            :line-numbers="true"

                            @on-sort-change="onSortChange"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            @on-column-filter="onColumnFilter"
                            :is-loading="loading"
                    >
                        <template slot="loadingContent">
                            <span class="vgt-loading__content">Yukleniyor...</span>
                        </template>
                        <template slot="table-row" slot-scope="props">
                            <router-link v-bind:to="path + '/edit/' + props.row.id"
                                         v-if="props.column.linkable || props.column.field === 'title'"
                                         class="linkable">
                                <b>{{ props.formattedRow[props.column.field] }}</b>
                            </router-link>

                            <div v-else-if="props.column.field === 'action'" class="text-right">
                                <el-dropdown @command="handleRowActionCommand">
                                    <el-button type="text" class="el-button-link grey" size="mini" icon="el-icon-more"
                                               style="display: initial"/>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :command="{type : 'edit', id : props.row.id}">
                                            <i class="el-icon-edit success-text"></i> Duzenle
                                        </el-dropdown-item>
                                        <el-dropdown-item :command="{type : 'destroy', id : props.row.id}">
                                            <i class="el-icon-delete danger-text"></i> Sil
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>

                            <span v-else-if="props.column.type === 'currency'">
                                {{ $n(props.formattedRow[props.column.field], 'currency') }}
                            </span>

                            <span v-else-if="props.column.html">
                                <span v-html="props.formattedRow[props.column.field]"></span>
                            </span>

                            <span v-else-if="props.column.is_loop">
                                <div v-for="(loopItem, loopItemKey) in props.row[props.column.loop_items]"
                                     :key="loopItemKey">

                                    <div v-if="!props.column.is_loop_linkable">
                                        {{ loopItem[props.column.loop_field] }}
                                    </div>

                                    <div v-if="props.column.is_loop_linkable">
                                        <router-link v-bind:to="props.column.is_loop_linkable + '/edit/' + loopItem.id"
                                                     target="_blank">
                                            {{ loopItem[props.column.loop_field] }}
                                        </router-link>
                                    </div>
                                </div>
                            </span>

                            <span v-else>
                                {{props.formattedRow[props.column.field]}}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>

        <el-dialog title="Disa Aktar" :visible.sync="exportDialogVisible">
            <div v-if="exportData">
                <div class="ui message">
                    Tebrikler excel dosyaniz basarili bir sekilde olusturuldu.
                </div>

                <a class="ui button icon labeled fluid" :href="exportData.url" target="_blank">
                    <i class="icon el-icon-download"></i> Indir {{ exportData.title }}
                </a>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'Datatable',
        props: [
            'source',
            'path',
            'queryAppend',
            'customFilter',
        ],
        data() {
            return {
                filterVisible: false,
                exportDialogVisible: false,
                exportData: null,
                canCreate: true,
                loading: false,
                columns: [],
                rows: [],
                pagination: [],

                paginationOptions: {
                    enabled: true,
                    mode: 'pages',
                    perPage: 10,
                    position: 'bottom',
                    perPageDropdown: [10, 30, 50],
                    dropdownAllowAll: false,
                    nextLabel: 'Sonraki',
                    prevLabel: 'Önceki',
                    rowsPerPageLabel: 'Satır sayısı',
                    ofLabel: 'görüntüleniyor. Toplam sayfa sayısı:',
                    pageLabel: 'Sayfa',
                    allLabel: 'Tümü',
                },

                serverParams: {
                    sorts: {
                        created_at: 'desc'
                    },
                    columnFilters: {},
                    filters: [],
                    page: 1,
                    perPage: 10
                },

                backupColumnFilter: {},
            };
        },
        methods: {
            loadItems() {

                this.loading = true;

                if (this.queryAppend) {

                    let serverParams = this.serverParams;
                    let currentThis = this;

                    _.forEach(this.queryAppend, function (item) {

                        let elements = _.get(currentThis, item.element);

                        if (elements) {
                            serverParams[item.field] = Object.values(elements);
                        }
                    });

                    this.serverParams = serverParams;
                }

                if (this.customFilter) {
                    _.forEach(this.customFilter, (item) => {
                        this.serverParams.filters.push(item);
                    });
                }

                this.$http
                    .get(this.source, {
                        params: this.serverParams
                    })
                    .then(response => {
                        this.loading = false;
                        this.columns = response.body.columns;
                        this.rows = response.body.rows;
                        this.pagination = response.body.pagination;

                        this.initCustomizeColumns();

                    })
                    .catch((e) => {
                        this.loading = false;
                        this.columns = [];
                        this.rows = [];
                        this.pagination = {};
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {

                    this.$http
                        .delete(this.refineURL(this.source) + '/' + id)
                        .then(response => {
                            this.apiSuccessMessage(response);
                            this.loadItems();
                        })
                        .catch((e) => {
                            this.apiErrorMessage(e);
                        });

                    return true;

                }).catch(() => {
                    this.$notify({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    return false;
                });
            },

            toggleVisibility(column) {
                if (column.hidden) {
                    localStorage.setItem(this.source + '.' + column.field, 'true');
                } else {
                    localStorage.setItem(this.source + '.' + column.field, 'false');
                }
            },

            initCustomizeColumns() {
                Array
                    .from(this.columns)
                    .forEach(function (value, key) {
                        if (localStorage.getItem(this.source + '.' + value.field) === 'true') {
                            this.$set(value, 'hidden', true);
                        } else {
                            this.$set(value, 'hidden', false);
                        }
                    }.bind(this));
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onSortChange(params) {

                let sorts = this.serverParams.sorts;

                _.forEach(params, function (item, key) {
                    sorts[item.field] = item.type;
                });

                this.serverParams.sorts = sorts;

                this.loadItems();
            },

            onPageChange(params) {
                this.serverParams.page = params.currentPage;
                this.loadItems();
            },

            onPerPageChange(params) {
                this.serverParams.perPage = params.currentPerPage;
                this.loadItems();
            },

            onColumnFilter(params) {

                this.updateParams(params);

                let diff = this.difference(this.backupColumnFilter, params.columnFilters);

                if (!_.size(diff)) {
                    return;
                }

                this.backupColumnFilter = _.clone(params.columnFilters);

                this.loadItems();
            },

            handleRowActionCommand(command) {
                if (command.type === 'destroy') {
                    return this.destroyItem(command.id);
                }

                return this.$router.push(this.path + '/edit/' + command.id)
            },

            refineURL(currURL) {
                return currURL.split('?')[0];
            },

            exportFilter() {

                this.loading = true;

                if (this.queryAppend) {

                    let serverParams = this.serverParams;
                    let currentThis = this;

                    _.forEach(this.queryAppend, function (item) {

                        let elements = _.get(currentThis, item.element);

                        if (elements) {
                            serverParams[item.field] = Object.values(elements);
                        }
                    });

                    this.serverParams = serverParams;
                }

                if (this.customFilter) {
                    _.forEach(this.customFilter, (item) => {
                        this.serverParams.filters.push(item);
                    });
                }

                let params = _.clone(this.serverParams);

                params.export = true;

                this.$http
                    .get(this.source, {
                        params: params
                    })
                    .then(response => {
                        this.loading = false;
                        this.exportData = response.body;
                        this.exportDialogVisible = true
                    })
                    .catch((e) => {
                        this.loading = false;
                        this.apiErrorMessage(e);
                    });
            },

            difference(o1, o2) {
                return Object.keys(o2).reduce((diff, key) => {
                    if (o1[key] === o2[key]) return diff
                    return {
                        ...diff,
                        [key]: o2[key]
                    }
                }, {})
            }
        },

        mounted() {
            if (this.$router.resolve(this.path + '/create').resolved.name === 'error.index') {
                this.canCreate = false;
            }

            if (this.$route.meta.isNotCreate) {
                this.canCreate = false;
            }
        },

        created() {
            this.loadItems();
        }
    }
</script>

