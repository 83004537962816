import Vue from 'vue'
import Router from 'vue-router'
import VueResource from 'vue-resource'
import layouts from '../config/layout'
import store from '../store'

const axios = require('axios');

Vue.use(Router);
Vue.use(VueResource);

import MasterContainer from '../app/Container/Master'

import Dashboard from '../app/Pages/Dashboard'
import ErrorPage from '../app/Pages/Error'

// Route set
import SystemRoutes from './system';
import ToolRoutes from './tools';
import CrmRoutes from './crm';
import SchoolRoutes from './school';
import CmsRoutes from './cms';
import CatalogRoutes from './catalog';
import MarketingRoutes from './marketing';
import SaleRoutes from './sales';
import CardRoutes from './cards';
import PlatformRoutes from './platform';
import ReportRoutes from './reports';
import ProfileRoutes from './profile';
import AuthRoutes from './auth';
import TeacherRoutes from './teacher';
import EnrollmentRoutes from './enrollments';

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: MasterContainer,
            children: [
                {
                    path: '',
                    name: 'dashboard.index',
                    component: Dashboard,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                    }
                }
            ]
        },
        {
            name: 'error.index',
            path: '*',
            component: ErrorPage,
            meta: {
                layout: layouts.contenOnly,
            }
        },
        SystemRoutes,
        ToolRoutes,
        CrmRoutes,
        SchoolRoutes,
        CmsRoutes,
        CatalogRoutes,
        MarketingRoutes,
        SaleRoutes,
        CardRoutes,
        PlatformRoutes,
        ReportRoutes,
        ProfileRoutes,
        AuthRoutes,

        TeacherRoutes,
        EnrollmentRoutes
    ]
});

export const config = [];

config['SITE_BASE_URL'] = 'https://www.minidil.com';
config['SITE_API_URL'] = 'https://api.minidil.com/v1';
config['SITE_BASE_LOGIN_URL'] = 'https://www.minidil.com/giris';

const l = {
    contenOnly() {
        store.commit('setLayout', layouts.contenOnly)
    },
    navLeft() {
        store.commit('setLayout', layouts.navLeft)
    },
    navRight() {
        store.commit('setLayout', layouts.navRight)
    },
    navTop() {
        store.commit('setLayout', layouts.navTop)
    },
    navBottom() {
        store.commit('setLayout', layouts.navBottom)
    },
    set(layout) {
        store.commit('setLayout', layout)
    }
};

const auth = {
    loggedIn() {
        return store.getters.isLogged
    },
    logout() {
        store.commit('setLogout')
    }
};

router.beforeEach((to, from, next) => {
    if (!to) {
        window.location.href = config['SITE_BASE_URL'];
    }

    if (!localStorage.token && to.name !== 'auth.login.index') {
        return window.location.href = '/auth/login';
    }

    if (to.name === 'auth.login.index') {
        store.commit('setSplashScreen', false);
    }

    if (to && to.meta && to.meta.layout) {
        l.set(to.meta.layout)
    }

    document.title = 'Minidil Panel';

    if (to.meta && to.meta.title) {
        document.title = to.meta.title;
    }

    return next();
});

router.afterEach((to, from) => {
    //store.commit('setSplashScreen', false);
});

export default router
