<template>
    <div class="page-vue-good-table scrollable only-y">
        <div class="page-content">
            <el-menu @select="goRoute" style="display: none">
                <el-menu-item :index="item.path" v-for="(item, key) in childs" :key="key">
                    <span>{{ item.meta.title }}</span>
                </el-menu-item>
            </el-menu>
        </div>

        <router-view :key="$route.fullPath"/>
    </div>
</template>

<script>
    export default {
        name: 'Master',
        data() {
            return {
                childs: []
            }
        },
        methods: {
            goRoute(link) {
                this.$router.push(this.$route.path + '/' + link);
            },

            getLinks() {
                let childs = null;
                const currentRoute = this.$route.path;

                childs = _.filter(this.$router.options.routes, function (route) {
                    return route.path === currentRoute
                });

                if (_.size(childs)) {
                    this.childs = childs[0].children;
                }
            }
        },

        mounted() {
            this.getLinks();
        },

        updated() {
            this.getLinks()
        }
    }
</script>