<template>
    <div v-loading="pageLoading">
        <div class="card-box-plus" @click="createItem" style="margin: 0">
            <div class="box-container">
                <i class="el-icon-plus"></i>
            </div>
        </div>
        <div class="card-box no-shadow">
            <section class="card-box-content">
                <vue-scroll>
                    <div class="box-list" style="height: 400px">
                        <div class="box-list-item" v-for="item in items" :key="item.id">
                            <div>
                                <div>
                                    <b>{{ item.content.title }}</b>
                                </div>
                                <div>
                                    {{ item.publish_at }}
                                </div>
                            </div>
                            <div>
                                <div class="d-flex justify-flex-end">
                                    <el-button type="text" class="el-button-link green" icon="el-icon-edit"
                                               @click="editItem(item)"/>
                                    <el-button type="text" class="el-button-link red" icon="el-icon-delete"
                                               @click="destroyItem(item.id)"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-scroll>
            </section>
        </div>

        <el-dialog :title="title" :visible.sync="modalVisible" v-loading="pageLoading" width="50%" append-to-body>
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item :label="fieldTitle">
                    <el-select v-model="form.content_id" clearable filterable value-key="id" :placeholder="fieldTitle">
                        <el-option v-for="item in fieldItems" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="Yayinlanma Tarihi">
                    <el-date-picker v-model="form.publish_at" type="datetime" placeholder="Yayinlanma Tarihi"
                                    value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" v-if="!form.id" class="fluid" @click="storeItem">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" v-if="form.id" class="fluid" @click="updateItem">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'PeriodContent',
        props: ['product', 'title', 'fieldTitle', 'fieldAlias'],
        data() {
            return {
                endPoint: null,
                itemEndPoint: null,
                pageLoading: true,
                form: {
                    //
                },

                items: [],
                fieldItems: [],

                modalVisible: null,
            }
        },

        methods: {
            getItems() {
                this.pageLoading = true;

                this.$http
                    .get(this.itemEndPoint, {
                        params: {
                            perPage: 1000
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.fieldItems = response.body.rows
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            index() {

                this.pageLoading = true;

                this.$http
                    .get(this.endPoint)
                    .then(response => {
                        this.items = response.body.rows;
                        this.pageLoading = false;
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            createItem() {
                this.modalVisible = true;
                this.form = {
                    //
                };
            },

            editItem(item) {
                this.modalVisible = true;
                this.form = item
            },

            storeItem() {

                this.pageLoading = true;

                this.$http
                    .post(this.endPoint, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.modalVisible = false;
                        this.apiSuccessMessage(response);

                        this.index();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.pageLoading = true;

                this.$http
                    .put(this.endPoint + '/' + this.form.id, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.modalVisible = false;
                        this.apiSuccessMessage(response);

                        this.index();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.pageLoading = true;

                this
                    .$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                        confirmButtonText: 'Tamam',
                        cancelButtonText: 'Iptal Et',
                        type: 'warning'
                    })
                    .then(() => {
                        this.$http
                            .delete(this.endPoint + '/' + id)
                            .then(response => {
                                this.pageLoading = false;
                                this.apiSuccessMessage(response);

                                this.index();
                            })
                            .catch((e) => {
                                this.pageLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.pageLoading = false;
                    });
            },
        },

        mounted() {

            this.endPoint = 'school/contents/' + this.product.content_id + '/periods/' + this.product.id + '/' + this.fieldAlias;
            this.itemEndPoint = 'platform/contents/' + this.fieldAlias;

            if (this.fieldAlias === 'homeworks' || this.fieldAlias === 'newsletters') {
                this.itemEndPoint = 'platform/' + this.fieldAlias;
            }

            this.getItems();

            this.index();
        }
    }
</script>