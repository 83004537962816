import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    state: {
        layout: {
            navPos: 'left', //top, bottom, left, right, false
            toolbar: 'top', //top, bottom, false
            footer: true, //above, below, false
            boxed: false, //true, false
            roundedCorners: false, //true, false
            viewAnimation: 'fade-top' // fade-left, fade-right, fade-top, fade-top-in-out, fade-bottom, fade-bottom-in-out, fade, false
        },
        splashScreen: true,
        logged: true,
        showErrorDialog: false,
        user: null,
        permissions: null,
        settings: {},
        zones: [],
        trumbowygConfig : {
            semantic: false
        },
        ui : null,
        cardDialogVisible : false,
        platform : {
            teacher : {
                item : null,
                links : [
                    {
                        title: 'Videolar',
                        media: 'video',
                        url: 'teacher/videos'
                    },
                    {
                        title: 'Sesler',
                        media: 'listening',
                        url: 'teacher/sounds'
                    },
                    {
                        title: 'Okuma Parcalari',
                        media: 'reading',
                        url: 'teacher/readings'
                    },
                    {
                        title: 'Oyunlar',
                        media: 'games',
                        url: 'teacher/games'
                    },
                    {
                        title: 'Galeri',
                        media: 'gallery',
                        url: 'teacher/galleries'
                    },
                    {
                        title: 'Mektuplar',
                        media: 'newsletters',
                        url: 'teacher/newsletters'
                    },
                    {
                        title: 'Siniflar',
                        media: 'classrooms',
                        url: 'teacher/classrooms'
                    }
                ]
            },
            parent : {
                item : null,
                links : [
                    {
                        title: 'Ogrencilerim',
                        media: 'students',
                        url: 'parent/students'
                    },
                    {
                        title: 'Duyurular',
                        media: 'newsletters',
                        url: 'parent/news'
                    },
                    {
                        title: 'Etkinlikler',
                        media: 'events',
                        url: 'parent/events'
                    },
                    {
                        title: 'Geri Bildirim',
                        media: 'feedbacks',
                        url: 'parent/feedbacks'
                    },
                    {
                        title: 'Gorusme Talebi',
                        media: 'meeting',
                        url: 'parent/meeting'
                    },
                    {
                        title: 'Odemeler',
                        media: 'payment',
                        url: 'parent/payments'
                    },

                ]
            },
            student : {
                item : null,
            }
        },
        headerEventCounter : 0,
        headerFormMessageCounter : 0,
    },
    mutations: {
        setLayout(state, payload) {
            if (payload && payload.navPos !== undefined)
                state.layout.navPos = payload.navPos

            if (payload && payload.toolbar !== undefined)
                state.layout.toolbar = payload.toolbar

            if (payload && payload.footer !== undefined)
                state.layout.footer = payload.footer

            if (payload && payload.boxed !== undefined)
                state.layout.boxed = payload.boxed

            if (payload && payload.roundedCorners !== undefined)
                state.layout.roundedCorners = payload.roundedCorners

            if (payload && payload.viewAnimation !== undefined)
                state.layout.viewAnimation = payload.viewAnimation
        },
        setLogin(state, payload) {
            state.logged = true
        },
        setLogout(state, payload) {
            state.layout.navPos = null
            state.layout.toolbar = null
            state.logged = false
        },
        setSplashScreen(state, payload) {
            state.splashScreen = payload
        },
        setDialogStatus(state, payload) {
            state.showErrorDialog = payload
        },
        setUser(state, payload) {
            state.user = payload
        },
        setPermissions(state, payload) {
            state.permissions = payload
        },
        setSettings(state, payload) {
            state.settings = payload
        },
        setZones(state, payload) {
            state.zones = payload
        },
        setTeacher(state, payload) {
            state.platform.teacher.item = payload
        },
        setParent(state, payload) {
            state.platform.parent.item = payload
        },
        setStudent(state, payload) {
            state.platform.student.item = payload
        },
        setUi(state, payload) {
            state.ui = payload
        },
        setCardDialogVisible (state, payload) {
            state.cardDialogVisible = payload
        },
        setHeaderEventCounter(state, payload) {
            state.headerEventCounter = payload
        },
        setHeaderFormMessageCounter(state, payload) {
            state.headerFormMessageCounter = payload
        }
    },
    actions: {},
    getters: {
        layout(state, getters) {
            return state.layout
        },
        navPos(state, getters) {
            return state.layout.navPos
        },
        toolbar(state, getters) {
            return state.layout.toolbar
        },
        footer(state, getters) {
            return state.layout.footer
        },
        boxed(state, getters) {
            return state.layout.boxed
        },
        roundedCorners(state, getters) {
            return state.layout.roundedCorners
        },
        viewAnimation(state, getters) {
            return state.layout.viewAnimation
        },
        isLogged(state, getters) {
            return state.logged
        },
        splashScreen(state, getters) {
            return state.splashScreen
        },
        errorDialogStatus(state, getters) {
            return state.showErrorDialog
        },
        user(state, getters) {
            return state.user
        },
        ui(state, getters) {
            return state.ui
        },
        permissions(state, getters) {
            return state.permissions
        },
        settings(state, getters) {
            return state.settings
        },
        zones(state, getters) {
            return state.zones
        },
        teacher(state, getters) {
            return state.platform.teacher.item
        },
        parent(state, getters) {
            return state.platform.parent.item
        },
        student(state, getters) {
            return state.platform.student.item
        },
        cardDialogVisible(state, getters) {
            return state.cardDialogVisible
        },
        headerEventCounter(state, getters) {
            return state.headerEventCounter
        },
        headerFormMessageCounter(state, getters) {
            return state.headerFormMessageCounter
        }
    },
    plugins: [createPersistedState({paths: ['layout']})],
    strict: debug
})
