<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading">
            <div class="card-box" >
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                            <el-button @click="$router.go(-1)" type="text" icon="el-icon-back" class="el-button-link grey"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="!$route.params.id">
                            <el-button @click="storeItem" type="text" icon="el-icon-check" class="el-button-link green"/>
                        </el-tooltip>
                    </div>
                </header>
                <section class="card-box-content padding-20">
                    <el-form :label-position="'top'" ref="form" :model="form">

                        <el-form-item>
                            <SelectCategory label="Etiketler" alias="classrooms" model="categories" :container="form" :only-parent="true"/>
                        </el-form-item>

                        <el-form-item label="Sinif">
                            <select-definition label="Sinif" alias="classes" model="class_definition_id" :container="form"/>
                        </el-form-item>

                        <el-form-item label="Başlık">
                            <el-input v-model="form.title" placeholder="Başlık"/>
                        </el-form-item>

                        <el-form-item>
                            <el-switch v-model="form.is_active" active-text="Aktif"/>
                        </el-form-item>

                    </el-form>
                </section>
            </div>
        </div>
    </vue-scroll>
</template>

<script>

    import SelectCategory from "../../../../../components/SelectCategory";
    import SelectDefinition from "../../../../../components/SelectDefinition";
    export default {
        name: 'ElementForm',
        components: {SelectDefinition, SelectCategory},
        data() {
            return {
                itemLoading: true,
                form: {
                    title: null,
                    seo: {},
                    metas: {},
                    file_path: [],
                }
            }
        },

        methods: {
            storeItem() {

                this.itemLoading = true;

                this.form.seo = {
                    meta_title: this.form.title,
                    meta_description: this.form.title,
                    meta_keywords: this.form.title,
                };

                this.$http
                    .post(this.$route.meta.end_point, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                        this.$router.push('edit/' + response.body.item.id);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },
        },

        mounted() {
            this.itemLoading = false;
        }
    }
</script>