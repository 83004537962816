<template>
    <div class="page-vue-good-table scrollable only-y" v-loading="itemLoading">

        <page-header />

        <datatable :path="'activities'" :source="'tools/activities'" :canCreate="true">
            <template slot="buttons">
                <el-tooltip content="Tumunu Temizle" effect="dark" placement="bottom">
                    <el-button type="danger" icon="el-icon-delete" @click="truncate"/>
                </el-tooltip>
            </template>
        </datatable>
    </div>
</template>

<script>
    import Datatable from "../../../components/Datatable";
    import PageHeader from "../../../components/PageHeader";

    export default {
        name: 'ElementDatatable',
        components: {
            PageHeader,
            Datatable
        },
        props: ['source'],
        data() {
            return {
                itemLoading: true,
                form: {
                    process: 'truncate'
                }
            }
        },

        methods: {
            truncate() {
                this.itemLoading = true;

                this.$confirm('Butun loglar temizlenecek. Bunu yapmak istediginizden eminmisiniz?', 'Uyari', {
                    confirmButtonText: 'Tamam',
                    cancelButtonText: 'Iptal Et',
                    type: 'warning'
                })
                    .then(() => {
                        this.$http
                            .delete('tools/activities/1?process=truncate', this.form)
                            .then(response => {
                                this.itemLoading = false;

                                this.$router.push('/tools/activities');

                                this.apiSuccessMessage(response)
                            })
                            .catch((e) => {
                                this.itemLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            }
        },

        mounted() {
            this.itemLoading = false;
        }
    }
</script>