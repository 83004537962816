<template>
    <div class="card-box">
        <header class="card-box-header">
            <div class="title">
                Siparis Ozeti
            </div>
        </header>
        <section class="card-box-content">
            <div class="box-list">
                <div class="box-list-item">
                    <div>Urunler Toplami</div>
                    <div class="font-size-20 font-weight-700">
                        {{ $n(offer.product_total, 'currency') }}
                    </div>
                </div>
                <div class="box-list-item">
                    <div>Servis Toplami</div>
                    <div class="font-size-20 font-weight-700">
                        {{ $n(offer.vehicle_total, 'currency') }}
                    </div>
                </div>
                <div class="box-list-item">
                    <div>Indirim</div>
                    <div class="font-size-20 font-weight-700">
                        {{ $n(offer.discount_total, 'currency') }}
                    </div>
                </div>
            </div>
        </section>
        <footer class="card-box-footer">
            <div class="font-size-20 font-weight-700 text-color-success text-right fluid">
                {{ $n(offer.total, 'currency') }}
            </div>
        </footer>
    </div>
</template>

<script>
    export default {
        name: 'Info',
        props: ['offer']
    }
</script>