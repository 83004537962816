<template>
    <el-form :label-position="'top'" ref="form" :model="form" v-loading="pageLoading">
        <el-row :gutter="24">
            <el-col :span="12">
                <el-form-item>
                    <el-switch v-model="form.is_main" active-text="Veli İletişim Adresi"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item>
                    <el-switch v-model="form.is_active" active-text="Aktif"/>
                </el-form-item>
            </el-col>
        </el-row>

        <el-form-item label="Adres Başlığı">
            <el-select v-model="form.title" filterable allow-create default-first-option placeholder="Adres Başlığı">
                <el-option v-for="item in addressTitles" :key="item.label" :label="item.label" :value="item.label"/>
            </el-select>
        </el-form-item>

        <el-form-item label="Adres Turu">
            <el-select v-model="form.address_type" filterable placeholder="Adres Turu">
                <el-option v-for="item in addressTypes" :key="item.value" :label="item.label" :value="item.value"/>
            </el-select>
        </el-form-item>

        <div v-show="form.address_type === 'company'">
            <el-form-item label="Firma Adi">
                <el-input v-model="form.company_name" placeholder="Firma Adi"/>
            </el-form-item>

            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="Vergi Numarasi">
                        <el-input v-model="form.tax_no" placeholder="Vergi Numarasi"/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Vergi Dairesi">
                        <el-input v-model="form.tax_center" placeholder="Vergi Dairesi"/>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>

        <div v-show="form.address_type === 'customer'">
            <el-form-item label="T.C. No">
                <el-input v-model="form.identification_no" placeholder="T.C. No" v-mask="'###########'"/>
            </el-form-item>

            <el-form-item label="Cinsiyet">
                <select-definition label="Cinsiyet" alias="genders" model="gender_definition_id" :container="form"/>
            </el-form-item>

            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="Adı">
                        <el-input v-model="form.first_name" placeholder="Adı"/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Soyadı">
                        <el-input v-model="form.last_name" placeholder="Soyadı"/>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>

        <el-row :gutter="24">
            <el-col :span="12">
                <el-form-item label="E-Mail">
                    <el-input v-model="form.email" placeholder="E-Mail"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="Telefon">
                    <el-input v-model="form.phone" placeholder="Telefon" v-mask="'0 (###) ### ## ##'"/>
                </el-form-item>
            </el-col>
        </el-row>

        <el-form-item label="Adres">
            <el-input v-model="form.address_1" placeholder="Adres"/>
        </el-form-item>

        <el-form-item label="Adres 2">
            <el-input v-model="form.address_2" placeholder="Adres 2"/>
        </el-form-item>

        <el-form-item label="Ülke">
            <el-select v-model="form.country_zone_id" placeholder="Ülke seçiniz" clearable @change="selectedCountry">
                <el-option v-for="item in getZonesByParentId(0)" :key="item.id" :label="item.title" :value="item.id"/>
            </el-select>
        </el-form-item>

        <el-row :gutter="24">
            <el-col :span="12">
                <el-form-item label="Şehir" :key="form.country_zone_id">
                    <el-select v-model="form.city_zone_id" placeholder="Şehir seçiniz" filterable clearable @change="selectedCity">
                        <el-option v-for="item in getZonesByParentId(form.country_zone_id)" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="İlçe" :key="form.city_zone_id">
                    <el-select v-model="form.town_zone_id" placeholder="İlçe seçiniz" filterable @change="selectedTown">
                        <el-option v-for="item in getZonesByParentId(form.city_zone_id)" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="Semt" :key="form.town_zone_id">
                    <el-select v-model="form.district_zone_id" placeholder="Semt seçiniz" filterable clearable @change="selectedDistrict">
                        <el-option v-for="item in getZonesByParentId(form.town_zone_id)" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="Mahalle" :key="form.district_zone_id">
                    <el-select v-model="form.neighborhood_zone_id" placeholder="Mahalle seçiniz" filterable clearable>
                        <el-option v-for="item in getZonesByParentId(form.district_zone_id)" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
import SelectDefinition from "./SelectDefinition";

export default {
    name: 'Address',
    components: {
        SelectDefinition

    },
    props: [
        'form'
    ],
    data() {
        return {
            pageLoading: true,
            addressTypes: [
                {
                    value: 'customer',
                    label: 'Sahis',
                },
                {
                    value: 'company',
                    label: 'Sirket',
                },
            ],
            addressTitles: [
                {
                    label: 'Anne İletişim Bilgileri',
                },
                {
                    label: 'Baba İletişim Bilgileri',
                },
                {
                    label: '3. Şahıs İletişim Bilgileri',
                },
            ],
            lastFormId: null
        }
    },

    mounted() {
        this.pageLoading = false;
    },

    methods: {
        selectedCountry() {

            if (!this.form.country_zone_id) {
                return null;
            }

            this.form.city_zone_id = null;
            this.form.town_zone_id = null;
            this.form.district_zone_id = null;
            this.form.neighborhood_zone_id = null;
        },

        selectedCity() {
            if (!this.form.city_zone_id) {
                return null;
            }

            this.form.town_zone_id = null;
            this.form.district_zone_id = null;
            this.form.neighborhood_zone_id = null;
        },

        selectedTown() {

            if (!this.form.town_zone_id) {
                return null;
            }

            this.form.district_zone_id = null;
            this.form.neighborhood_zone_id = null;
        },

        selectedDistrict() {

            if (!this.form.district_zone_id) {
                return null;
            }

            this.form.neighborhood_zone_id = null;
        },

        getZonesByParentId(parentId) {

            if (parentId === undefined) {
                return [];
            }

            return this.$store.getters.ui.zones[parentId] ? this.$store.getters.ui.zones[parentId] : []
        },
    }
}
</script>
