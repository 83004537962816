<template>
    <div class="horizontal-nav flex" :class="{['pos-'+position]:true}">
        <logo mode="horizontal"/>
        <div class="box grow flex nav-container">
            <vue-scroll class="box grow">
                <Nav @push-page="pushPage" mode="horizontal"/>
            </vue-scroll>
        </div>
    </div>
</template>

<script>

    import Logo from "./Logo";
    import Nav from "./Nav";

    export default {
        name: 'HorizontalNav',
        components: {Nav, Logo},
        props: ['position'],
        data() {
            return {}
        },
        methods: {
            pushPage(index, indexPath) {
                this.$emit('push-page', {page: index})
            }
        }
    }
</script>