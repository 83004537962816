import layouts from '../config/layout'

import MasterContainer from '../app/Container/Master'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Dt'

import Student from '../app/Pages/School/Students/Show'
import Teacher from '../app/Pages/School/Teacher'
import RoomProgram from '../app/Pages/School/RoomProgram'
import Room from '../app/Pages/School/Room'

import VehicleCreate from '../app/Pages/School/Vehicles/Create'
import VehicleEdit from '../app/Pages/School/Vehicles/Edit'

import Definition from '../app/Pages/School/Classrooms/Definition'
import ClassroomCategory from '../app/Pages/School/Classrooms/Category'
import ClassroomCreate from '../app/Pages/School/Classrooms/Branchs/Create'
import ClassroomEdit from '../app/Pages/School/Classrooms/Branchs/Edit'

export default {
    path: '/school',
    name: 'school',
    component: MasterContainer,
    meta: {
        auth: true,
        layout: layouts.navLeft,
        title: 'Okul',
    },
    children: [
        {
            path: 'students',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Ogrenciler',
            },
            children: [
                {
                    name: 'school.students.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Ogrenciler',
                        path: 'students',
                        end_point: 'school/students',
                    },
                },
                {
                    name: 'school.students.edit',
                    path: 'edit/:id',
                    component: Student,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Ogrenci Bilgileri',
                        path: 'students',
                        end_point: 'school/students',
                    }
                }
            ]
        },
        {
            path: 'teachers',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Ogretmenler',
            },
            children: [
                {
                    name: 'school.teachers.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Ogretmenler',
                        path: 'teachers',
                        end_point: 'school/teachers',
                    },
                },
                {
                    name: 'school.teachers.create',
                    path: 'create',
                    component: Teacher,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Ogretmen Ekle',
                        path: 'teachers',
                        end_point: 'school/teachers',
                    }
                },
                {
                    name: 'school.teachers.edit',
                    path: 'edit/:id',
                    component: Teacher,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Ogretmen Bilgileri',
                        path: 'teachers',
                        end_point: 'school/teachers',
                    }
                }
            ]
        },
        {
            path: 'programs/rooms',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Calisma Takvimi',
            },
            children: [
                {
                    name: 'school.programs.rooms.index',
                    path: '',
                    component: RoomProgram,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Calisma Takvimi',
                        path: 'rooms',
                        end_point: 'school/programs/rooms',
                    },
                }
            ]
        },
        {
            path: 'rooms',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Odalar',
            },
            children: [
                {
                    name: 'school.rooms.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Odalar',
                        path: 'rooms',
                        end_point: 'school/rooms',
                    },
                },
                {
                    name: 'school.rooms.create',
                    path: 'create',
                    component: Room,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Oda Ekle',
                        path: 'rooms',
                        end_point: 'school/rooms',
                    }
                },
                {
                    name: 'school.rooms.edit',
                    path: 'edit/:id',
                    component: Room,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Oda Bilgileri',
                        path: 'rooms',
                        end_point: 'school/rooms',
                    }
                }
            ]
        },
        {
            path: 'vehicles',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Servisler',
            },
            children: [
                {
                    name: 'school.vehicles.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Servisler',
                        path: 'vehicles',
                        end_point: 'school/vehicles',
                    },
                },
                {
                    name: 'school.vehicles.create',
                    path: 'create',
                    component: VehicleCreate,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Servis Olustur',
                        path: 'vehicles',
                        end_point: 'school/vehicles',
                    }
                },
                {
                    name: 'school.vehicles.edit',
                    path: 'edit/:id',
                    component: VehicleEdit,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Servis Bilgileri',
                        path: 'vehicles',
                        end_point: 'school/vehicles',
                    }
                }
            ]
        },
        {
            path: 'classrooms',
            component: MasterContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Sinif Islemleri',
            },
            children: [
                {
                    path: 'education-periods',
                    component: PageContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Egitim Donemleri',
                    },
                    children: [
                        {
                            name: 'school.classrooms.education_periods.index',
                            path: '',
                            component: DatatableContainer,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Egitim Donemleri',
                                path: 'education-periods',
                                end_point: 'system/definitions',
                                alias: 'education-periods',
                            }
                        },
                        {
                            name: 'school.classrooms.education_periods.create',
                            path: 'create',
                            component: Definition,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Egitim Donemi Olustur',
                                path: 'education-periods',
                                end_point: 'system/definitions',
                                alias: 'education-periods',
                            }
                        },
                        {
                            name: 'school.classrooms.education_periods.edit',
                            path: 'edit/:id',
                            component: Definition,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Egitim Donemi Bilgileri',
                                path: 'education-periods',
                                end_point: 'system/definitions',
                                alias: 'education-periods',
                            }
                        }
                    ]
                },
                {
                    path: 'education-years',
                    component: PageContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Egitim Yillari',
                    },
                    children: [
                        {
                            name: 'school.classrooms.education_years.index',
                            path: '',
                            component: DatatableContainer,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Egitim Yillari',
                                end_point: 'system/definitions',
                                path: 'education-years',
                                alias: 'education-years',
                            }
                        },
                        {
                            name: 'school.classrooms.education_years.create',
                            path: 'create',
                            component: Definition,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Egitim Yili Olustur',
                                end_point: 'system/definitions',
                                path: 'education-years',
                                alias: 'education-years',

                            }
                        },
                        {
                            name: 'school.classrooms.education_years.edit',
                            path: 'edit/:id',
                            component: Definition,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Egitim Yili Bilgileri',
                                end_point: 'system/definitions',
                                path: 'education-years',
                                alias: 'education-years',
                            }
                        }
                    ]
                },
                {
                    path: 'categories',
                    component: PageContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Etiketler',
                    },
                    children: [
                        {
                            name: 'school.classrooms.categories.index',
                            path: '',
                            component: DatatableContainer,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Etiketler',
                                path: 'categories',
                                end_point: 'school/categories'
                            },
                        },
                        {
                            name: 'school.classrooms.categories.create',
                            path: 'create',
                            component: ClassroomCategory,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Etiket Olustur',
                                path: 'categories',
                                end_point: 'school/categories',
                            }
                        },
                        {
                            name: 'school.classrooms.categories.edit',
                            path: 'edit/:id',
                            component: ClassroomCategory,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Etiket Bilgileri',
                                path: 'categories',
                                end_point: 'school/categories',
                            }
                        }
                    ]
                },
                {
                    path: 'classrooms',
                    component: PageContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Subeler',
                    },
                    children: [
                        {
                            name: 'school.classrooms.classrooms.index',
                            path: '',
                            component: DatatableContainer,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Subeler',
                                path: 'classrooms',
                                end_point: 'school/classrooms',
                            },
                        },
                        {
                            name: 'school.classrooms.classrooms.create',
                            path: 'create',
                            component: ClassroomCreate,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Sube Olustur',
                                path: 'classrooms',
                                end_point: 'school/classrooms',
                            }
                        },
                        {
                            name: 'school.classrooms.classrooms.edit',
                            path: 'edit/:id',
                            component: ClassroomEdit,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Sube Bilgileri',
                                path: 'classrooms',
                                end_point: 'school/classrooms',
                            }
                        }
                    ]
                }
            ]
        },
    ]
}
