<template>
    <div v-loading="itemLoading">

        <el-form-item label="Kart Adi">
            <el-input v-model="metas.kart_name" placeholder="Kart Adi"/>
        </el-form-item>

        <table class="el-table ui table">
            <thead>
            <tr>
                <th>Taksit Sayisi</th>
                <th>Komisyon Orani</th>
                <th width="100">Sira</th>
                <th width="1">
                    <el-button type="primary" icon="el-icon-plus" size="mini" @click="createItem"/>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, key) in items" :key="key">
                <td style="padding-right: 20px">
                    <el-form-item>
                        <el-input v-model="item.variables.installment_no" placeholder="Taksit Sayisi" type="number"/>
                    </el-form-item>
                </td>
                <td>
                    <el-form-item>
                        <el-input v-model="item.variables.commission" placeholder="Komisyon Orani"/>
                    </el-form-item>
                </td>
                <td>
                    <el-form-item>
                        <el-input v-model="item.sort" placeholder="Sira" type="number"/>
                    </el-form-item>
                </td>
                <td>
                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="destroyRowItem(key)"/>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: 'BankCommission',
        props: ['source', 'items', 'metas'],
        data() {
            return {
                itemLoading: true,
                form: {
                    metas : {
                        //
                    }
                }
            }
        },
        created() {
            this.itemLoading = false;
        },
        methods: {

            createItem() {
                this.items.push({
                    sort: 100,
                    variables: {}
                })
            },

            destroyRowItem(key) {
                this.items.splice(key, 1);
            }
        }
    }
</script>