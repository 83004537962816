<template>
    <div v-loading="itemLoading">
        <div class="card-box" v-if="classrooms[0]">
            <header class="card-box-header">
                <div class="title">
                    Sinif Bilgileri
                </div>
            </header>
            <section class="card-box-content">
                <div class="box-list">
                    <div v-for="classRoom in classrooms" :key="classRoom.id" class="box-list-item box-list-item-editable box-list-item-media box-list-item-editable-in-scroll box-list-item-classroom">
                        <div class="box-list-item-media-item">
                            <div class="text fluid">
                                <div class="text-item" style="padding: 0">
                                    <div class="classroom-text fluid">
                                        <div class="classroom">
                                            <router-link
                                                    :to="'/school/classrooms/classrooms/edit/' + classRoom.content_id"
                                                    class="widget-title text-truncate" target="_blank">
                                                <b>{{ classRoom.title }}</b>
                                            </router-link>
                                        </div>
                                        <div class="tags">
                                            <el-row :gutter="24">
                                                <el-col :span="8" v-for="category in classRoom.content.categories" :key="category.id">
                                                    {{ category.category.title }}
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </div>
                                    <div class="classroom-price">
                                        <div class="quantity">
                                            {{ classRoom.quantity }} Adet
                                        </div>
                                        <div class="price">
                                            {{ $n(classRoom.total, 'currency') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="card-box" v-if="vehicles[0]">
            <header class="card-box-header">
                <div class="title">
                    Servis Bilgileri
                </div>
            </header>
            <section class="card-box-content">
                <div class="box-list">
                    <div v-for="vehicle in vehicles" :key="vehicle.id" class="box-list-item box-list-item-editable box-list-item-media box-list-item-editable-in-scroll box-list-item-classroom">
                        <div class="box-list-item-media-item">
                            <div class="text fluid">
                                <div class="text-item">
                                    <div class="classroom-text">
                                        <div class="classroom">
                                            <router-link :to="'/school/vehicles/edit/' + vehicle.content_id" class="widget-title text-truncate" target="_blank">
                                                <b>{{ vehicle.title }}</b>
                                            </router-link>
                                        </div>
                                        <div class="tags">
                                            {{ vehicle.address_type_format }}
                                        </div>
                                        <div v-if="vehicle.address">
                                            {{ vehicle.address.title }}
                                        </div>
                                    </div>
                                    <div class="classroom-price">
                                        <div class="quantity">
                                            {{ vehicle.quantity}} Adet
                                        </div>
                                        <div class="price">
                                            {{ $n(vehicle.price, 'currency') }}
                                        </div>
                                        <div class="price">
                                            {{ $n(vehicle.total, 'currency') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="card-box" v-if="products[0]">
            <header class="card-box-header">
                <div class="title">
                    Urun Bilgileri
                </div>
            </header>
            <section class="card-box-content">
                <div class="box-list">
                    <div class="box-list-item">
                        Pika
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Products',
        props: ['offer'],
        data() {
            return {
                itemLoading: true,
                classrooms: [],
                vehicles: [],
                products: [],
            }
        },

        methods: {
            initAlias() {
                let classrooms = [];
                let vehicles = [];
                let products = [];

                _.forEach(this.offer.products, function (item) {

                    if (item.alias === 'classrooms') {
                        classrooms.push(item);
                    }

                    if (item.alias === 'vehicles') {
                        vehicles.push(item);
                    }

                    if (item.alias === 'products') {
                        products.push(item);
                    }
                });

                this.classrooms = classrooms;
                this.vehicles = vehicles;
                this.products = products;
            }
        },

        mounted() {
            this.itemLoading = false;

            this.initAlias();
        }
    }
</script>