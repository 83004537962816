<template>
    <section v-loading="itemLoading">
        <vue-scroll>
            <div style="height: 600px">
                <table class="ui table very basic table-hover middle aligned">
                    <thead>
                    <th width="1" class="text-center">
                        <div class="pl-20">
                            Durum
                        </div>
                    </th>
                    <th>
                        Teklif
                    </th>
                    <th class="text-right">
                        Fiyat
                    </th>
                    <th width="200" class="text-right">
                        <div class="pr-20">
                            Tarih
                        </div>
                    </th>
                    </thead>
                    <tbody>
                    <tr v-for="(item, key) in items" :key="key"
                        @click="openBlankWindow('/sales/offers/edit/' + item.id)" style="cursor: pointer">
                        <td class="text-right fs-14">
                            <div class="pl-30 mt-10">
                                <status-dot :status="item.status"/>
                            </div>
                        </td>
                        <td class="fs-14">
                            <el-popover placement="bottom-start" title="Urunler" width="400" trigger="hover">
                                <template slot="reference">
                                    <div>{{ item.student.full_name }}</div>
                                    <div>
                                        <span v-if="item.year">{{ item.year.title }}</span>
                                        <span v-if="item.period">{{ item.period.title }}</span>
                                    </div>
                                </template>
                                <div>
                                    <table class="ui table very basic table-hover">
                                        <thead>
                                        <th>
                                            Urun
                                        </th>
                                        <th width="150" class="text-right">
                                            Fiyat
                                        </th>
                                        </thead>
                                        <tbody>
                                        <tr v-for="product in item.products" :key="product.id">
                                            <td>
                                                <div>
                                                    <b>{{ product.title }}</b>
                                                </div>
                                                <div v-if="product.alias === 'classrooms' || product.alias === 'products'">
                                                    {{ product.content.category_text }}
                                                </div>
                                                <div v-if="product.alias === 'vehicles'">
                                                    <div>
                                                        <span v-if="product.address_type === 'service_get'">Alinacak</span>
                                                        <span v-if="product.address_type === 'service_delivery'">Birakilacak</span>
                                                    </div>
                                                    <div>{{ product.address.title }}</div>
                                                </div>
                                            </td>
                                            <td class="text-right">
                                                <div class="quantity"
                                                     v-if="product.alias === 'classrooms' || product.alias === 'products'">
                                                    {{ product.quantity }} Adet
                                                </div>
                                                <div class="quantity" v-if="product.alias === 'vehicles'">
                                                    {{ product.quantity / 2 }} Ay
                                                </div>
                                                <div class="price">
                                                    {{ $n(product.price, 'currency') }}
                                                </div>
                                                <div class="price">
                                                    {{ $n(product.total, 'currency') }}
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </el-popover>
                        </td>
                        <td class="text-right fs-14">
                            <div>{{ $n(item.discount_total, 'currency') }}</div>
                            <div>{{ $n(item.total, 'currency') }}</div>
                        </td>
                        <td class="text-right fs-14">
                            <div style="padding-right: 20px">
                                <div>
                                    {{ $d(new Date(item.created_at), 'long', 'tr-TR') }}
                                </div>
                                <div>
                                    {{ $d(new Date(item.expiry_at), 'long', 'tr-TR') }}
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </vue-scroll>
    </section>
</template>

<script>
    export default {
        name: 'Offers',
        props: ['source', 'customer'],

        data() {
            return {
                itemLoading: true,
                items: {},
                form: {
                    //
                },
            }
        },

        methods: {
            index() {

                this.itemLoading = true;

                this.$http.get('sales/offers', {
                    params: {
                        fake_url: this.source,
                        filters: [
                            {
                                relation: null,
                                add_with: 'true',
                                inputs: [
                                    {
                                        name: 'created_by_id',
                                        field: 'created_by_id',
                                        type: 'singleSelect',
                                        value: this.customer.id
                                    }
                                ]
                            },
                        ],
                        sorts: {
                            created_at: 'desc'
                        },
                        withs: {
                            'products': 'products',
                            'products.content': 'products.content',
                            'products.address': 'products.address',
                        }
                    }
                }).then(response => {
                    this.items = response.body.rows;
                    this.itemLoading = false;
                });
            }
        },

        mounted() {
            this.index();
        }
    }
</script>