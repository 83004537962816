<template>
    <div v-loading="itemLoading">
        <el-form :label-position="'top'" ref="form" :model="form">
            <div class="tab-add-button text-right mb-2">
                <el-button class="modern secondary large extract" icon="el-icon-plus" @click="createItem">Ekle</el-button>
            </div>

            <el-tabs v-model="tabValue" type="border-card" closable @tab-remove="destroyRowItem">
                <el-tab-pane :label="'Tab ' + key" v-for="(item, key) in items" :key="key" :name="'key-' + key">
                    <el-form-item label="Gorunum Turu">
                        <ti-select :options="types" :container="item.variables" model="view_type" placeholder="Gorunum Turu" />
                    </el-form-item>

                    <el-row :gutter="24" v-if="item.variables.view_type !== 'video'">
                        <el-col :span="6">
                            <file-browser v-model="item.variables.image"/>
                        </el-col>
                    </el-row>

                    <el-form-item label="Video" v-if="item.variables.view_type === 'video'">
                        <document-browser v-model="item.variables.video" :filePath="item.variables.video"/>
                    </el-form-item>

                    <el-form-item label="Icerik">
                        <el-input type="textarea" :autosize="{ minRows: 9, maxRows: 9}" v-model="item.variables.description" placeholder="Icerik"/>
                    </el-form-item>

                    <el-form-item label="Sira">
                        <el-input v-model="item.sort" placeholder="Sira" />
                    </el-form-item>

                </el-tab-pane>
            </el-tabs>
        </el-form>
    </div>
</template>

<script>

    import FileBrowser from "../../../../../components/FileBrowser";
    import DocumentBrowser from "../../../../../components/DocumentBrowser";

    export default {
        name: 'Sliders',
        components: {DocumentBrowser, FileBrowser},
        props: ['source', 'items'],
        data() {
            return {
                itemLoading: true,
                form: {
                    //
                },
                types : [
                    {
                        id : 'image',
                        title : 'Resim'
                    },
                    {
                        id : 'video',
                        title : 'Video'
                    }
                ],

                tabValue: 'key-0',
            }
        },
        created() {
            this.itemLoading = false;
        },
        methods: {

            createItem() {
                this.items.push({
                    sort: 100,
                    variables: {
                        video : null
                    }
                })
            },

            destroyRowItem(key) {
                this.items.splice(key, 1);
            }
        }
    }
</script>