<style lang="scss">
    .linkable {
        cursor: pointer !important;
    }

    .phone-input {
        border: none !important;
        background: transparent !important;
        cursor: pointer !important;
    }

    .message-field {
        textarea {
            height: 200px !important;
        }
    }
</style>

<template>
    <div>
        <span @click="openModal" class="linkable">
            <span v-if="form.alias !== 'sms'">
                {{ form.receiver }}
            </span>
            <span v-if="form.alias === 'sms'">
                <input v-mask="'0 (###) ### ## ##'" v-model="form.receiver" type="text" readonly class="phone-input"/>
             </span>
        </span>

        <el-dialog title="Bilgilendirme Mesaji Gonder" :visible.sync="modalVisible" v-loading="modalLoading"
                   :close-on-click-modal="true" width="50%" append-to-body>
            <el-form :label-position="'top'" ref="form" :model="form">

                <div class="ui header" style="margin: 0; height: auto; margin-bottom: 40px">
                    {{ form.receiver }}
                </div>

                <el-form-item class="mt-20 message-field" v-if="form.alias === 'email'">
                    <el-input v-model="form.subject" placeholder="Konu"/>
                </el-form-item>

                <el-form-item class="mt-20 message-field">
                    <el-input type="textarea" v-model="form.message" placeholder="Mesajiniz..." />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <div class="ui labeled icon button green fluid" @click="sendHandle">
                    <i class="mdi mdi-send icon"></i>
                    Gonder
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'SingleDraft',
        props: ['userId', 'receiver', 'alias'],

        data() {
            return {
                modalLoading: false,
                modalVisible: false,
                form: {
                    process: 'single',
                    alias: null,
                    receiver: null,
                    user_id: null,
                },
            }
        },

        methods: {
            sendHandle() {
                this.modalLoading = true;

                this.$http
                    .post('marketing/bulk-message', this.form)
                    .then(response => {
                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            openModal() {
                this.modalVisible = true;
            }
        },

        mounted() {
            this.form.alias = this.alias;
            this.form.receiver = this.receiver;
            this.form.user_id = this.userId;
        },
    }
</script>