<template>
    <div v-loading="itemLoading">
        <div class="card-box">
            <header class="card-box-header">
                <div class="title">
                    Donemler
                </div>
                <div class="actions">
                    <el-button icon="el-icon-plus" type="text" class="el-button-link primary" @click="createItem"/>
                </div>
            </header>
            <section class="card-box-content">
                <div class="box-list">
                    <div class="box-list-item" v-for="item in items" :key="item.id">
                        <div class="fluid margin-right-20">
                            <div class="my-flex">
                                <div class="name fluid">
                                    <div>{{ item.education_year.title }}</div>
                                    <div>{{ item.education_period.title }}</div>
                                </div>
                                <div class="fluid text-right">
                                    {{ $n(item.price, 'currency') }}
                                </div>
                            </div>
                        </div>
                        <div class="d-flex" style="justify-content: end">
                            <el-button icon="el-icon-edit" type="text" class="el-button-link green" @click="editItem(item)"/>
                            <el-button icon="el-icon-delete" type="text" class="el-button-link red" @click="destroyItem(item.id)"/>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <el-dialog title="Donem Bilgileri" :visible.sync="modalCreateVisible" v-loading="itemLoading" width="50%"
                   append-to-body>
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Egitim Yili">
                            <select-definition label="Egitim Yili" alias="education-years"
                                               model="education_year_definition_id" :container="form"/>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="Egitim Donemi">
                            <select-definition label="Egitim Donemi" alias="education-periods"
                                               model="education_period_definition_id" :container="form"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Vergi Sinifi">
                    <select-definition label="Vergi Sinifi" alias="tax-classes" model="tax_class_definition_id"
                                       :container="form"/>
                </el-form-item>

                <el-form-item label="Fiyat">
                    <el-input v-model="form.price" placeholder="Fiyat"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <div class="ui button labeled green icon fluid" @click="storeItem" v-if="!form.id">
                    <i class="icon mdi mdi-check"></i> Kaydet
                </div>
                <div class="ui button labeled green icon fluid" @click="updateItem" v-if="form.id">
                    <i class="icon mdi mdi-check"></i> Guncelle
                </div>
            </div>
        </el-dialog>

        <el-dialog title="Donem Bilgileri" :visible.sync="modalDetailVisible" v-loading="itemLoading" width="95%" :key="form.id" class="classroom-modal-detail">
            <el-form :label-position="'top'" ref="form" :model="form" v-if="form.id">
                <el-row :gutter="24">
                    <el-col :span="8">
                        <div class="card-box">
                            <header class="card-box-header has-buttons">
                                <div class="title">
                                    Egitim
                                </div>
                                <div class="actions">
                                    <el-button icon="el-icon-edit" type="text" class="el-button-link green"
                                               @click="modalCreateVisible = true"/>
                                </div>
                            </header>
                            <section class="card-box-content">
                                <div class="box-list">
                                    <div class="box-list-item">
                                        <div class="name">
                                            Egitim Yili
                                        </div>
                                        <div>
                                            {{ form.education_year.title }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Egitim Donemi
                                        </div>
                                        <div>
                                            {{ form.education_period.title }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Vergi Sinifi
                                        </div>
                                        <div>
                                            {{ form.tax_class.title }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Fiyat
                                        </div>
                                        <div>
                                            {{ $n(form.price, 'currency') }}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </el-col>
                    <el-col :span="16">
                       <el-row :gutter="24">
                           <el-col :span="12">
                               <Students :product="form" title="Alinacak Ogrenciler" addressType="service_get" key="service_get" />
                           </el-col>
                           <el-col :span="12">
                               <Students :product="form" title="Birakilacak Ogrenciler" addressType="service_delivery" key="service_delivery" />
                           </el-col>
                       </el-row>
                    </el-col>
                </el-row>

            </el-form>
        </el-dialog>
    </div>
</template>
<script>
    import Students from "../_parts/Students";
    import SelectDefinition from "../../../../../components/SelectDefinition";

    export default {
        name: 'Periods',
        components: {
            SelectDefinition,
            Students,

        },
        props: ['itemId'],
        data() {
            return {
                itemLoading: false,
                endPoint: null,
                items: [],
                form: {
                    content_id: this.itemId
                },

                modalCreateVisible: false,
                modalDetailVisible: false,
            }
        },

        methods: {
            index() {
                this.itemLoading = true;

                this.$http
                    .get(this.endPoint, {
                        perPage: 100
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.items = response.body.rows
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            createItem() {
                this.form = {
                    content_id: this.itemId
                };

                this.modalCreateVisible = true;
            },

            storeItem() {
                this.itemLoading = true;

                this.$http
                    .post(this.endPoint, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.modalCreateVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            editItem(item) {
                this.form = item;
                this.modalDetailVisible = true;
            },

            updateItem() {
                this.itemLoading = true;

                this.$http
                    .put(this.endPoint + '/' + this.form.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                        this.modalCreateVisible = false;
                        this.modalDetailVisible = false;

                        this.index();
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {

                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http
                        .delete(this.endPoint + '/' + id)
                        .then(response => {
                            this.itemLoading = false;

                            this.index();

                            this.$message({
                                type: 'success',
                                message: 'Delete completed'
                            });
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.itemLoading = false;
                });
            },
        },

        mounted() {

            this.endPoint = 'school/contents/' + this.itemId + '/periods';

            this.index();
        },
    }
</script>