<style lang="scss" rel="stylesheet/scss">
	@import "./resources/scss/App";
</style>

<template>
	<div style="height: 100%" :class="$route.meta.bodyClass ? $route.meta.bodyClass : ''">
		<transition name="fade">
			<div class="splash-screen" v-if="uiLoading">
				<div class="wrap">
					<img src="@/resources/images/logo.svg" class="logo" alt="logo">
					<img src="@/resources/images/Ripple-2s-200px.gif" alt="loading-image">
				</div>
			</div>
		</transition>


		<div v-if="!uiLoading" class="layout-container flex justify-center" :class="{
		'column':navPos === 'top' || navPos === 'bottom',
		'boxed':boxed,
		'footer-above':footer === 'above',
		'content-only':!navPos
	}">
			<transition name="fade">
				<div class="splash-screen" v-if="splashScreen">
					<div class="wrap">
						<img src="@/resources/images/logo.svg" class="logo" alt="logo">
						<img src="@/resources/images/Ripple-2s-200px.gif" alt="loading-image">
					</div>
				</div>
			</transition>

			<vertical-nav
					:position="navPos"
					:collapse-nav="collapseNav"
					:open-sidebar.sync="openSidebar"
					@collapse-nav-toggle="collapseNav = !collapseNav"
					@push-page="closeSidebar"
					v-if="navPos === 'left'" />

			<div class="container flex column box grow">
				<div class="header" v-if="toolbar === 'top'">
					<Toolbar @toggle-sidebar="openSidebar = !openSidebar" :menu-burger="navPos" />
				</div>

				<horizontal-nav :position="navPos" @push-page="closeSidebar" v-if="navPos === 'top'" />

				<div class="header" v-if="toolbar === 'bottom'">
					<Toolbar @toggle-sidebar="openSidebar = !openSidebar" :menu-burger="navPos" />
				</div>

				<div class="main box grow flex">
					<transition :name="viewAnimation" mode="out-in">
						<router-view class="view box grow" />
					</transition>
				</div>

				<horizontal-nav :position="navPos" @push-page="closeSidebar" v-if="navPos === 'bottom'"
								style="margin-bottom:0;" />

				<Footer v-if="footer === 'below'" :position="footer" />
			</div>

			<vertical-nav
					:position="navPos"
					:collapse-nav="collapseNav"
					:open-sidebar.sync="openSidebar"
					@collapse-nav-toggle="collapseNav = !collapseNav"
					@push-page="closeSidebar"
					v-if="navPos === 'right'" />

			<Footer v-if="footer === 'above'" :position="footer" />
		</div>
	</div>
</template>

<script>
    import {detect} from 'detect-browser'
    import HorizontalNav from './app/Layout/HorizontalNav.vue'
    import VerticalNav from './app/Layout/VerticalNav.vue'
    import Toolbar from './app/Layout/Toolbar.vue'
    import Footer from './app/Layout/Footer.vue'

    const browser = detect();

    export default {
        name: 'Provider',
        components: {
            VerticalNav,
            HorizontalNav,
            Toolbar,
            Footer
        },
        data() {
            return {
                collapseNav: false,
                openSidebar: true,
                innerWidth: 0,
                uiLoading: true,
            }
        },
        computed: {
            navPos() {
                if (this.innerWidth <= 768 && (this.$store.getters.navPos === 'top' || this.$store.getters.navPos === 'bottom')) {
                    return 'left'
                }
                return this.$store.getters.navPos
            },
            toolbar() {
                return this.$store.getters.toolbar
            },
            footer() {
                return this.$store.getters.Footer
            },
            boxed() {
                return this.$store.getters.boxed
            },
            roundedCorners() {
                return this.$store.getters.roundedCorners
            },
            viewAnimation() {
                return this.$store.getters.viewAnimation || 'none'
            },
            isLogged() {
                return this.$store.getters.isLogged
            },
            splashScreen() {

                if (!this.$store.state.user) {
                    return false;
                }

                return this.$store.getters.splashScreen
            }
        },
        methods: {
            resizeOpenNav() {
                this.innerWidth = window.innerWidth
                if (window.innerWidth <= 768) {
                    this.collapseNav = false
                }
            },
            closeSidebar() {
                this.openSidebar = false
            },

            setUi() {

                if (this.$route.name === 'auth.login.index') {
                    return this.uiLoading = false;
                }

                this.uiLoading = true;

                this
                    .$http
                    .get('ui')
                    .then(response => {
                        this.uiLoading = false;
                        this.$store.commit('setUi', response.body);
                        this.$store.commit('setSettings', response.body.settings);
                    })
                    .catch((e) => {
                        this.apiErrorMessage(e);
                        this.uiLoading = false;
                        localStorage.removeItem('token')

                        window.location = '/auth/login'
                    });
            }
        },

        created() {
            if (browser.name) {
                document.getElementsByTagName("html")[0].classList.add(browser.name);
            }
        },
        mounted() {
            this.resizeOpenNav()
            window.addEventListener('resize', this.resizeOpenNav);

            this.setUi();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.resizeOpenNav);
        }
    }
</script>
