<template>
    <vue-scroll>
        <div class="loading-container" v-loading="pageLoading">
            <el-card class="custom-padding" id="TableContainer">
                <template slot="header">
                    <div class="crud-header">
                        <div class="title">
                            Satış Raporlari
                        </div>
                        <div class="actions" style="display: flex; align-items: center">
                            <el-tooltip content="Excel Çıktısı Al" placement="bottom">
                                <el-button icon="el-icon-download" type="text" class="el-button-link red d-flex" @click="handleDownload" />
                            </el-tooltip>
                            <el-tooltip content="Filtrele" placement="bottom">
                                <el-button icon="el-icon-search" type="text" class="el-button-link blue d-flex" @click="handleSearch" />
                            </el-tooltip>
                        </div>
                    </div>
                </template>
                <div class="el-card__filters">
                    <el-form :label-position="'top'" ref="form" :model="filter" @submit.native.prevent="handleSearch">
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item>
                                    <lv-select :container="filter" model="product_type" :options="types" placeholder="Stok Türü" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item>
                                    <el-date-picker v-model="filter.start_at" type="date" placeholder="Başlangıç Tarihi" value-format="yyyy-MM-dd" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item>
                                    <el-date-picker v-model="filter.end_at" type="date" placeholder="Bitiş Tarihi" value-format="yyyy-MM-dd" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item class="margin-bottom-0">
                                    <select-status placeholder="Sipariş Durumları" alias="orders" model="statuses" :container="filter" :multiple="true"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <template v-if="filter.product_type === 'classrooms'">
                            <el-row :gutter="24" class="margin-top-20">
                                <el-col :span="12">
                                    <el-form-item class="margin-bottom-0">
                                        <select-definition label="Eğitim Yılı" alias="education-years" model="education_year_definition_id" :container="filter"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item class="margin-bottom-0">
                                        <select-definition label="Eğitim Dönemi" alias="education-periods" model="education_period_definition_id" :container="filter"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </template>
                    </el-form>
                </div>
            </el-card>

            <template v-if="response">
                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-card class="stat-card">
                            <div class="text">
                                <div class="number">{{ response.count }}</div>
                                <div class="title">Satış Sayısı</div>
                            </div>
                            <div class="media bg-teal-opack text-teal">
                                <mdi icon="mdi-gift-outline" />
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="12">
                        <el-card class="stat-card">
                            <div class="text">
                                <div class="number">{{ $n(response.amount, 'currency') }}</div>
                                <div class="title">Satış Değeri</div>
                            </div>
                            <div class="media bg-navy-blue-opack text-navy-blue">
                                <mdi icon="mdi-cart-outline" />
                            </div>
                        </el-card>
                    </el-col>
                </el-row>

                <el-card class="custom-padding">
                    <el-table :data="response.items" :style="{width : '100%', 'max-width' : pageWidth + 'px'}" stripe border size="mini">
                        <el-table-column prop="title" label="Urun" width="460" sortable fixed />
                        <template v-for="(monthTitle, monthTitleIndex) in monthTitles">
                            <el-table-column :label="monthTitle.title" width="160" align="center" :key="monthTitleIndex">
                                <el-table-column label="Adet" width="80" align="right" sortable>
                                    <template slot-scope="scope">
                                        {{ scope.row.months[monthTitle.id] ? scope.row.months[monthTitle.id].count : '-' }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Tutar" width="120" align="right" sortable>
                                    <template slot-scope="scope">
                                        {{ scope.row.months[monthTitle.id] ? $n(scope.row.months[monthTitle.id].amount, 'currency') : '-' }}
                                    </template>
                                </el-table-column>
                            </el-table-column>
                        </template>
                        <el-table-column label="Toplam" width="300" sortable align="right">
                            <el-table-column label="Adet" width="120" align="right" sortable>
                                <template slot-scope="scope">
                                    {{ scope.row.count }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Tutar" width="120" align="right" sortable>
                                <template slot-scope="scope">
                                    {{ $n(scope.row.amount, 'currency') }}
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                </el-card>
            </template>
        </div>
    </vue-scroll>
</template>

<script>
import SelectDefinition from "../../../components/SelectDefinition";

export default {
    name: 'Sale',
    components: {
        SelectDefinition
    },
    data() {
        return {
            pageLoading: false,
            filter: {
                statuses : []
            },
            types: [
                {
                    value: 'classrooms',
                    label: 'Egitim',
                },
                {
                    value: 'products',
                    label: 'Fiziksel',
                }
            ],
            response: null,
            pageWidth : 0,
        }
    },
    mounted() {
        setTimeout(() => {
            this.pageWidth = window.document.getElementById('TableContainer').offsetWidth;
        }, 500)
    },
    methods: {
        handleSearch() {
            this.response = null;
            this.pageLoading = true;
            this.filter.process = 'view';

            this.$http
                .get(this.$route.meta.end_point, {params : this.filter})
                .then(response => {
                    this.pageLoading = false;
                    this.response = response.data.data;
                })
                .catch(response => {
                    this.pageLoading = false;
                    this.apiErrorMessage(response);
                })
        },

        handleDownload() {
            this.pageLoading = true;

            this.filter.process = 'download';

            this.$http
                .get(this.$route.meta.end_point, {params : this.filter})
                .then(response => {
                    this.pageLoading = false;
                    this.openBlankWindow(response.data.data.download_link)
                })
                .catch(response => {
                    this.pageLoading = false;
                    this.apiErrorMessage(response);
                })
        }
    }
}
</script>