<template>
    <div v-loading="pageLoading">
        <div class="card-box-plus" @click="createItem" style="margin: 0">
            <div class="box-container">
                <i class="el-icon-plus"></i>
            </div>
        </div>
        <div class="card-box no-shadow">
            <section class="card-box-content">
                <vue-scroll>
                    <div class="box-list" style="height: 400px">
                        <div class="box-list-item" v-for="item in items" :key="item.id">
                            <div>
                                <div>
                                    <b>{{ item.title }}</b>
                                </div>
                                <div>
                                    <b>{{ item.short_description }}</b>
                                </div>
                            </div>
                            <div>
                                <div class="d-flex justify-flex-end">
                                    <el-button type="text" class="el-button-link green" icon="el-icon-edit"
                                               @click="editItem(item)"/>
                                    <el-button type="text" class="el-button-link red" icon="el-icon-delete"
                                               @click="destroyItem(item.id)"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-scroll>
            </section>
        </div>

        <el-dialog title="Galeri Bilgileri" :visible.sync="modalVisible" v-loading="pageLoading" width="80%" append-to-body>
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-row :gutter="24">
                    <el-col :span="18">
                        <el-form-item label="Başlık">
                            <el-input v-model="form.title" placeholder="Başlık"/>
                        </el-form-item>
                        <el-form label="Kisa Aciklama">
                            <el-input type="textarea" :autosize="{ minRows: 9, maxRows: 9}" placeholder="Kisa Aciklama" v-model="form.short_description"/>
                        </el-form>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="Resim">
                            <file-browser v-model="form.file_path"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="card-box" v-if="form.id">
                    <header class="card-box-header">
                        <div class="title">
                            Resim ve Videolar
                        </div>
                    </header>
                    <section class="card-box-content">
                        <Files :source="endPoint + '/' + form.id" />
                    </section>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" v-if="!form.id" class="fluid" @click="storeItem">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" v-if="form.id" class="fluid" @click="updateItem">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import FileBrowser from "../../../../../../components/FileBrowser";
    import Files from "../../../../Cms/Contents/_parts/Files";

    export default {
        name: 'PeriodGallery',
        components: {Files, FileBrowser},
        props: ['source'],
        data() {
            return {
                endPoint: null,
                pageLoading: true,
                form: {
                    file_path : null
                },

                items: [],

                modalVisible: null,
            }
        },

        methods: {

            index() {

                this.pageLoading = true;

                this.$http
                    .get(this.endPoint)
                    .then(response => {
                        this.items = response.body.rows;
                        this.pageLoading = false;
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            createItem() {
                this.modalVisible = true;
                this.form = {
                    //
                };
            },

            editItem(item) {
                this.modalVisible = true;
                this.form = item
            },

            storeItem() {

                this.pageLoading = true;

                this.$http
                    .post(this.endPoint, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.modalVisible = false;
                        this.apiSuccessMessage(response);

                        this.index();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.pageLoading = true;

                this.$http
                    .put(this.endPoint + '/' + this.form.id, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.modalVisible = false;
                        this.apiSuccessMessage(response);

                        this.index();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.pageLoading = true;

                this
                    .$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                        confirmButtonText: 'Tamam',
                        cancelButtonText: 'Iptal Et',
                        type: 'warning'
                    })
                    .then(() => {
                        this.$http
                            .delete(this.endPoint + '/' + id)
                            .then(response => {
                                this.pageLoading = false;
                                this.apiSuccessMessage(response);

                                this.index();
                            })
                            .catch((e) => {
                                this.pageLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.pageLoading = false;
                    });
            },
        },

        mounted() {

            this.endPoint = this.source + '/galleries';

            console.log(this.endPoint)

            this.index();
        }
    }
</script>