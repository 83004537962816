<style lang="scss">
    .el-upload-list--picture-card .el-upload-list__item {
        width: 100% !important;
        height: 275px !important;
    }

    .el-upload-list--picture-card .el-upload-list__item-actions {
        background-color: rgba(17, 17, 17, .2);
    }

    .el-card__body {
        width: 100%;
    }
</style>

<template>
    <section v-loading="itemLoading" class="ps-container ps">
        <div class="card-box-plus" @click="crateItem" style="margin: 0">
            <div class="box-container">
                <i class="el-icon-plus"></i>
            </div>
        </div>

        <div class="card-box no-shadow">
            <section class="card-box-content">
                <vue-scroll>
                    <div class="box-list" style="height: 500px">
                        <div class="box-list-item" v-for="item in items" :key="item.id">
                            <div class="d-flex">
                                <div class="ui image tiny rounded mr-20">
                                    <img :src="appConfig.frontPath + '/' + item.file_path" alt="image">
                                </div>
                                <div>
                                    <div class="font-size-18">{{ item.file.title }}</div>
                                    <div>{{ item.file.description }}</div>
                                    <div>{{ item.sort }}</div>
                                </div>
                            </div>
                            <div>
                                <div class="d-flex justify-flex-end">
                                    <el-button type="text" class="el-button-link green" icon="el-icon-edit" @click="editItem(item)"/>
                                    <el-button type="text" class="el-button-link red" icon="el-icon-delete" @click="destroyItem(item.id)"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-scroll>
            </section>
        </div>

        <el-dialog :title="modalTitle" :visible.sync="modalVisible" v-loading="modalLoading" width="70%" append-to-body>
            <el-form :label-position="'top'" ref="form" :model="form">
                <div v-if="!form.id">

                    <file-browser v-model="form.files" :multiple="true" />

                    <div class="margin-top-20">
                        <div v-for="(file, fileKey) in form.files" style="position: relative" class="fluid">
                            <el-card class="box-card padding-20 margin-bottom-20 fluid">

                                <div style="position: absolute;right: 0;top: 0;z-index: 1">
                                    <el-button type="danger" icon="el-icon-delete"
                                               @click="destroyCreateFilesItem(fileKey)"></el-button>
                                </div>

                                <el-form-item label="Gosterim Turu">
                                    <el-select v-model="file.type" filterable allow-create default-first-option
                                               placeholder="Gosterim Turu">
                                        <el-option v-for="item in fileTypes" :key="item.value" :label="item.label"
                                                   :value="item.value"/>
                                    </el-select>
                                </el-form-item>

                                <el-row :gutter="24">
                                    <el-col :span="8">
                                        <file-browser v-model="file.path"/>
                                    </el-col>

                                    <el-col :span="16">
                                        <el-form-item label="Başlık">
                                            <el-input v-model="file.title" placeholder="Başlık"/>
                                        </el-form-item>

                                        <el-form-item label="Kisa Aciklama">
                                            <el-input type="textarea" placeholder="Kisa Aciklama"
                                                      v-model="file.description"/>
                                        </el-form-item>

                                        <el-form-item label="Sıralama">
                                            <el-input-number v-model="file.sort"
                                                             placeholder="Sıralama"></el-input-number>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-card>
                        </div>
                    </div>
                </div>
                <div v-if="form.id">

                    <el-form-item label="Gosterim Turu">
                        <el-select v-model="form.type" filterable allow-create default-first-option
                                   placeholder="Gosterim Turu">
                            <el-option v-for="item in fileTypes" :key="item.value" :label="item.label"
                                       :value="item.value"/>
                        </el-select>
                    </el-form-item>

                    <el-row :gutter="24">
                        <el-col :span="8">
                            <file-browser v-model="form.path"/>
                        </el-col>

                        <el-col :span="16">
                            <el-form-item label="Başlık">
                                <el-input v-model="form.title" placeholder="Başlık"/>
                            </el-form-item>

                            <el-form-item label="Kisa Aciklama">
                                <el-input type="textarea" placeholder="Kisa Aciklama" v-model="form.description"/>
                            </el-form-item>

                            <el-form-item label="Sıralama">
                                <el-input-number v-model="form.sort" placeholder="Sıralama"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="storeItem" v-if="!form.id" class="fluid">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" size="medium" @click="updateItem" v-if="form.id" class="fluid">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
    import FileBrowser from "../../../../../components/FileBrowser";

    export default {
        name: 'Files',
        props: ['source'],
        components: {
            FileBrowser

        },
        data() {
            return {
                itemLoading: true,
                modalLoading: false,
                modalVisible: false,
                modalTitle: 'Ekle',
                items: [],
                form: {
                    file_path: null,
                    files: [],
                    videos: [],
                },
                fileTypes: [
                    {
                        value: 'image',
                        label: 'Resim',
                    },
                    {
                        value: 'video',
                        label: 'Video',
                    }
                ],
            }
        },

        methods: {
            index() {
                this.$http
                    .get(this.source + '/files', {
                        params: {
                            fake_url: this.source,
                        }
                    })
                    .then(response => {
                        this.items = response.body.rows;
                        this.itemLoading = false;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            crateItem() {
                this.modalTitle = 'Ekle';
                this.modalVisible = true;
                this.form = {
                    file_path: null,
                    files: [],
                    videos: [],
                };
            },

            storeItem() {
                this.modalLoading = true;

                this.form.fake_url = this.source;

                this.$http
                    .post(this.source + '/files', this.form)
                    .then(response => {

                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            editItem(item) {
                this.itemLoading = false;
                this.modalLoading = false;
                this.modalVisible = true;

                this.modalTitle = 'Duzenle';

                this.form = {
                    id: item.id,
                    type: item.type,
                    path: item.file_path,
                    title: item.file.title,
                    description: item.file.description,
                    sort: item.sort,
                };
            },

            updateItem() {

                this.modalLoading = true;

                this.form.fake_url = this.source;

                this.$http
                    .put(this.source + '/files/' + this.form.id, this.form)
                    .then(response => {

                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {

                this.modalLoading = false;
                this.itemLoading = true;

                this
                    .$confirm('This will permanently delete the file. Continue?', 'Warning', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    })
                    .then(() => {
                        this.$http
                            .delete(this.source + '/files/' + id, {
                                fake_url: this.source
                            })
                            .then(response => {
                                this.modalLoading = false;
                                this.modalVisible = false;
                                this.itemLoading = false;

                                this.index();

                                this.$message({
                                    type: 'success',
                                    message: 'Delete completed'
                                });
                            })
                            .catch((e) => {
                                this.itemLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: 'Delete canceled'
                        });

                        this.modalLoading = false;
                        this.itemLoading = false;
                    });
            },

            destroyCreateFilesItem(key) {
                this.form.files.splice(key, 1);
            }
        },

        mounted() {
            this.index();
        }
    }
</script>