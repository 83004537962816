<template>
    <div class="not-found">
        <div class="msg-box">
            <h1 class="animated fadeInDown">404 Not Found</h1>
            <h2 class="animated fadeInUp">
                Oops!<br>It seems that this page does not exist.
            </h2>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Error'
    }
</script>