<template>
    <div class="loading-container" v-loading="pageLoading">
        <div class="card-box-plus" @click="createItem" style="margin: 0">
            <div class="box-container">
                <i class="el-icon-plus"></i>
            </div>
        </div>
        <div class="card-box no-shadow">
            <section class="card-box-content">
                <div class="box-list">
                    <div class="box-list-item" v-for="item in items" :key="item.id">
                        <div>
                            {{ item.content.title }}
                        </div>
                        <div>
                            <div class="d-flex justify-flex-end">
                                <el-button type="text" class="el-button-link primary" icon="el-icon-view" @click="checkStudent(item)" />
                                <el-button type="text" class="el-button-link green" icon="el-icon-edit" @click="editItem(item)"/>
                                <el-button type="text" class="el-button-link red" icon="el-icon-delete" @click="destroyItem(item.id)"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <el-dialog title="Odev Bilgileri" :visible.sync="modalVisible" v-loading="pageLoading" width="50%" append-to-body>
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Odevler">
                    <el-select v-model="form.homework_content_id" clearable filterable value-key="id" placeholder="Odevler">
                        <el-option v-for="item in homeworks" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" v-if="!form.id" class="fluid" @click="storeItem">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" v-if="form.id" class="fluid" @click="updateItem">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </div>
        </el-dialog>

        <el-dialog title="Odev Kontrolu" :visible.sync="modalCheckVisible" v-loading="pageLoading" width="70%" :key="form.id" v-if="form.id" append-to-body>
            <el-form :label-position="'top'" ref="form" :model="form">
                <div class="card-box">
                    <header class="card-box-header">
                        <div class="title" :style="{color : event.color}">
                            [{{ event.room.title }}] {{ event.classroom.title }} - {{ form.content.title }}
                        </div>
                    </header>
                    <section class="card-box-content card-box-tabs">
                        <div class="card-box-save" style="margin: 0" @click="updateItem">
                            <div class="box-container">
                                <i class="el-icon-check"></i>
                            </div>
                        </div>
                        <div class="box-list">
                            <div class="box-list-item" v-for="(student, key) in form.students" :key="form.id + '-' + key">
                                <div class="fluid d-flex" style="align-items: center">
                                    <div class="mr-10">
                                        <avatar :img="student.avatar" type="student" class="ui image tiny"/>
                                    </div>
                                    <div>
                                        <div>
                                            {{ student.first_name }} {{ student.last_name }}
                                        </div>
                                        <div class="rate">
                                            <el-rate v-model="student.loyalty_year > 3 ? 3 : student.loyalty_year" :max="3" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div class="ml-30">
                                    <div class="student-checklist">
                                        <div class="green" @click="student.status = 1" :class="{'active' : student.status === 1}">
                                            <i class="el-icon-check fs-24"></i>
                                        </div>
                                        <div class="red" @click="student.status = 0"  :class="{'active' : student.status === 0}">
                                            <i class="el-icon-delete fs-24"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>

    export default {
        name: 'Homeworks',
        props: ['event'],
        data() {
            return {
                pageLoading: true,
                itemEndPoint: null,
                form: {
                    //
                },

                homeworks: [],
                items: [],

                modalVisible: null,
                modalCheckVisible: null,
            }
        },

        methods: {
            getHomeworks() {
                this.pageLoading = true;

                this.$http
                    .get('platform/homeworks', {
                        params: {
                            perPage: 1000
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.homeworks = response.body.rows
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            index() {

                this.pageLoading = true;

                this.$http
                    .get(this.endPoint)
                    .then(response => {
                        this.items = response.body.rows;
                        this.pageLoading = false;
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            createItem() {
                this.modalVisible = true;
                this.form = {
                    //
                };
            },

            editItem(item) {
                this.modalVisible = true;
                this.form = item
            },

            storeItem() {

                this.pageLoading = true;

                this.$http
                    .post(this.endPoint, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.modalVisible = false;
                        this.apiSuccessMessage(response);

                        this.index();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.pageLoading = true;

                this.$http
                    .put(this.endPoint + '/' + this.form.id, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.modalVisible = false;
                        this.modalCheckVisible = false;
                        this.apiSuccessMessage(response);

                        this.index();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.pageLoading = true;

                this
                    .$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                        confirmButtonText: 'Tamam',
                        cancelButtonText: 'Iptal Et',
                        type: 'warning'
                    })
                    .then(() => {
                        this.$http
                            .delete(this.endPoint + '/' + id)
                            .then(response => {
                                this.pageLoading = false;
                                this.apiSuccessMessage(response);

                                this.index();
                            })
                            .catch((e) => {
                                this.pageLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.pageLoading = false;
                    });
            },

            checkStudent(item) {
                this.form = item;

                let students = Object.seal(this.event.students);

                _.forEach(students, (student, studentKey) => {

                    students[studentKey].status = null;

                    let programStudent = _.filter(item.students, {student_id : student.student_id});

                    if (_.size(programStudent) >= 1) {
                        programStudent = programStudent[0]
                    } else {
                        programStudent = null;
                    }

                    if (programStudent) {
                        students[studentKey].status = programStudent.status;
                    } else {
                        students[studentKey].status = null;
                    }
                });

                this.form.students = this.event.students;

                this.modalCheckVisible = true;
            }
        },

        mounted() {

            this.endPoint = 'school/programs/rooms/' + this.event.id + '/homeworks';

            this.index();
            this.getHomeworks();
        }
    }
</script>