<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading">

            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                            <el-button @click="$router.go(-1)" type="text" icon="el-icon-back"
                                       class="el-button-link grey"/>
                        </el-tooltip>

                        <el-tooltip content="Olustur" effect="dark" placement="bottom" v-if="$route.params.id">
                            <el-button @click="createButton($route.meta.end_point)" type="text" icon="el-icon-plus"
                                       class="el-button-link primary"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="!$route.params.id">
                            <el-button @click="storeItem" type="text" icon="el-icon-check"
                                       class="el-button-link green"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="$route.params.id">
                            <el-button @click="updateItem" type="text" icon="el-icon-check"
                                       class="el-button-link green"/>
                        </el-tooltip>
                    </div>
                </header>
                <section class="card-box-content padding-20">
                    <el-form :label-position="'top'" ref="form" :model="form">

                        <el-form-item label="Sinif">
                            <select-definition label="Sinif" alias="classes" model="class_definition_id" :container="form"/>
                        </el-form-item>

                        <SelectCategory label="Etiketler" alias="platform" model="categories" :container="form"/>

                        <el-row :gutter="24">
                            <el-col :span="18">
                                <el-form-item label="Başlık">
                                    <el-input v-model="form.title" placeholder="Başlık"/>
                                </el-form-item>

                                <el-form-item label="Kaynak">
                                    <el-input v-model="form.metas.publisher" placeholder="Kaynak"/>
                                </el-form-item>

                                <el-form-item label="Kisa Aciklama">
                                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}"
                                              placeholder="Kisa Aciklama" v-model="form.short_description"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="Kapak Resmi">
                                    <file-browser v-model="form.file_path"/>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-form-item label="Video Turu">
                            <el-select v-model="form.metas.video_type" placeholder="Video Turu" clearable
                                       @change="form.metas.video_content = null">
                                <el-option v-for="item in types" :key="item.label" :label="item.label"
                                           :value="item.value"/>
                            </el-select>
                        </el-form-item>

                        <div v-if="form.metas.video_type === 'embed'">
                            <el-form-item label="Embed Kod" class="margin-0">
                                <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 8}" placeholder="Embed Kod"
                                          v-model="form.metas.video_embed"/>
                            </el-form-item>
                        </div>

                        <div v-if="form.metas.video_type === 'link'">
                            <el-form-item label="Link">
                                <el-input v-model="form.metas.video_link" placeholder="Link"/>
                                <small>Linlerin .mp4 uzantisi oldugundan lutfen emin olunuz.</small>
                            </el-form-item>
                        </div>

                        <div v-if="form.metas.video_type === 'file'">
                            <el-form-item label="Dosya">
                                <document-browser :filePath.sync="form.metas.video_file"/>
                            </el-form-item>
                        </div>
                    </el-form>
                </section>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
    import SelectCategory from "../../../../components/SelectCategory";
    import SelectDefinition from "../../../../components/SelectDefinition";
    import FileBrowser from "../../../../components/FileBrowser";
    import DocumentBrowser from "../../../../components/DocumentBrowser";

    export default {
        name: 'ElementForm',
        components: {
            DocumentBrowser,
            SelectDefinition,
            SelectCategory,
            FileBrowser
        },
        data() {
            return {
                itemLoading: true,
                form: {
                    file_path: null,
                    metas: {
                        video_type: 'embed',
                        video_file: null,
                    }
                },

                types: [
                    {
                        value: 'embed',
                        label: 'Embed Kod'
                    },
                    {
                        value: 'link',
                        label: 'Link'
                    },
                    {
                        value: 'file',
                        label: 'Dosya'
                    }
                ],

                classrooms : [],
            }
        },

        methods: {
            loadItem() {

                this.itemLoading = true;

                this.$http
                    .get(this.$route.meta.end_point + '/' + this.$route.params.id)
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body.item;

                        if (!_.size(this.form.metas)) {
                            this.form.metas = {}
                        }

                        if (!this.form.parent_id) {
                            this.form.parent_id = null;
                        }
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            storeItem() {

                this.itemLoading = true;

                this.$http
                    .post(this.$route.meta.end_point, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                        this.$router.push('edit/' + response.body.item.id);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.itemLoading = true;

                this.$http
                    .put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.itemLoading = true;

                this
                    .$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                        confirmButtonText: 'Tamam',
                        cancelButtonText: 'Iptal Et',
                        type: 'warning'
                    })
                    .then(() => {
                        this.$http
                            .delete(this.$route.meta.end_point + '/' + id)
                            .then(response => {
                                this.itemLoading = false;

                                this.$router.push('/');

                                this.apiSuccessMessage(response)
                            })
                            .catch((e) => {
                                this.itemLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            },

            getClassrooms() {
                this.pageLoading = true;

                this.$http
                    .get('school/classrooms', {
                        params: {
                            perPage: 500,
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.classrooms = response.body.rows
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },
        },

        mounted() {

            this.getClassrooms();

            if (this.$route.params.id) {
                this.loadItem()
            } else {
                this.itemLoading = false;
            }
        }
    }
</script>