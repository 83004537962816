<template>
    <div class="pipelines">
        <div class="card pipeline-filter">
            <el-form :label-position="'top'" ref="form" :model="filter">
                <el-row :gutter="24">
                    <el-col :span="8">
                        <el-form-item>
                            <el-select v-model="filter.stage_id" placeholder="Islem Hatlari" clearable filterable class="fluid">
                                <el-option v-for="item in stages" :key="item.id" :label="item.title" :value="item.id"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item class="m-0">
                            <el-select v-model="filter.created_by_id" placeholder="Danışman" clearable filterable class="fluid">
                                <el-option v-for="item in staffs" :key="item.id" :label="item.title" :value="item.id"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item class="m-0">
                            <short-daterange-picker :container="filter" model="date_range"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="2">
                        <div class="actions">
                            <el-button type="success" icon="el-icon-search" @click="getCards"/>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="pipeline-content">
            <div class="pipeline-pagination start" v-if="pagination && pagination.total > pagination.per_page">
                <div class="pagination-stat">
                    aramaniza uygun <b>{{ pagination.total }}</b> sonuc bulundu.
                </div>
                <el-pagination
                    background
                    :currentPage="filter.page"
                    :pageCount="pagination.last_page"
                    :pagerCount="5" layout="prev, pager, next"
                    :total="pagination.total"
                    @current-change="getCards"
                />
            </div>
            <div class="pipeline-rows" v-loading="pageLoading">
                <template v-for="item in cards">
                    <div class="item" :key="item.id" @click="getShow(item.id)">
                        <div class="content">
                            <div class="media" :style="{background : item.stage.meta.bg_color}">
                                <mdi :icon="'mdi-' + item.stage.meta.icon"></mdi>
                            </div>
                            <div class="text">
                                <div class="title">{{ item.title }}</div>
                                <div class="metas">
                                    <div class="meta">#{{ item.id }}</div>
                                    <div class="meta">{{ item.stage.title }}</div>
                                    <div class="meta">{{ item.created_at }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="actions">
                            <div class="users">
                                <template v-for="user in item.assignees">
                                    <el-tooltip :content="user.name" effect="dark" placement="bottom">
                                        <div class="user">
                                            <img src="@/resources/images/avatar.jpg" alt="avatar" v-if="!user.avatar">
                                            <img :src="appConfig.frontPath + '/' + user.avatar" alt="avatar"
                                                 v-if="user.avatar">
                                        </div>
                                    </el-tooltip>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="pipeline-pagination" v-if="pagination && pagination.total > pagination.per_page">
                <div class="pagination-stat">
                    aramaniza uygun <b>{{ pagination.total }}</b> sonuc bulundu.
                </div>
                <el-pagination
                    background
                    :currentPage="filter.page"
                    :pageCount="pagination.per_page"
                    :pagerCount="5" layout="prev, pager, next"
                    :total="pagination.total"
                    @current-change="getCards"
                />
            </div>
        </div>

        <el-dialog :visible.sync="cardShowModalVisible" v-loading="pageLoading" width="90%" class="card-detail-modal">
            <div class="close-icon" @click="cardShowModalVisible = false">
                <i class="el-icon-close"></i>
            </div>
            <div v-if="cardResponseId">
                <Card :pipelineId="pipelineId" :cardId="cardResponseId" :key="cardResponseId"/>
            </div>
        </el-dialog>

        <el-dialog title="Müşteri Temsilcisi Ata" :visible.sync="cardCreateModalVisible" v-loading="pageLoading" append-to-body>
            <el-form :label-position="'top'" ref="form" :model="cardCreateForm">
                <el-form-item label="Müşteri Temsilcisi">
                    <el-select v-model="cardCreateForm.move_created_by_id" placeholder="Müşteri Temsilcisi">
                        <el-option v-for="item in staffs" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Kart Sayısı: Başlangıç">
                            <el-input v-model="cardCreateForm.start_row" placeholder="Başlangıç"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Kart Sayısı: Bitiş">
                            <el-input v-model="cardCreateForm.end_row" placeholder="Bitiş"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" @click="setStaff" class="btn-dialog">
                    Yaptığım Filtreye Uygun Olarak, Seçtiğim Müşteri Temsilcisini Ata!
                </el-button>
            </div>
        </el-dialog>

        <el-dialog title="Disa Aktar" :visible.sync="exportDialogVisible">
            <div v-if="cardExport">
                <div class="ui message">
                    Tebrikler excel dosyaniz basarili bir sekilde olusturuldu.
                </div>
                <a :href="cardExport.url" class="ui button icon labeled fluid">
                    <i class="icon el-icon-download"></i> Indir {{ cardExport.title }}
                </a>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Card from "./Card";

export default {
    name: 'Pipelines',
    components : {
        Card
    },
    props: ['source'],
    watch: {
        '$route': () => {
            this.pipelineId = this.$route.params.pipelineId
        }
    },
    data() {
        return {
            pageLoading: false,
            filter: {
                page: 1
            },
            pagination: null,
            pipelineId: null,
            stages: [],
            cards: [],
            marketingProcessItems: [],

            // Show
            cardShowModalVisible : false,
            cardResponseId : null,

            // Create / Store
            cardCreateModalVisible : false,
            cardCreateForm : {
                //
            },

            // export
            exportDialogVisible : false,
            cardExport : null,

            staffs: [],
        }
    },
    mounted() {
        this.pipelineId = this.$route.params.pipelineId

        this.getStages()
        this.getMarketingProcessItems()
        this.getCards()
        this.getStaffs()

        if (this.$route.params.cardId) {
            this.getShow(this.$route.params.cardId)
        }
    },
    methods: {

        getStaffs() {
            this.itemLoading = false;

            this.$http
                .get('crm/users', {
                    params: {
                        filters: [
                            {
                                relation: 'roles',
                                inputs: [
                                    {
                                        name: 'role_id',
                                        field: 'role_id',
                                        type: 'multiSelect',
                                        value: [
                                            9
                                        ]
                                    }
                                ]
                            },
                        ],
                    }
                })
                .then(response => {
                    this.itemLoading = false;

                    _.forEach(response.body.rows, (item) => {
                        this.staffs.push({
                            id : item.id,
                            title : item.name,
                        })
                    })
                });
        },

        getStages() {
            this.pageLoading = false;

            this.$http
                .get('cards/stages', {
                    params: {
                        card_pipeline_id: this.pipelineId
                    }
                })
                .then(response => {
                    this.pageLoading = false;
                    this.stages = response.body.items;
                });
        },

        getMarketingProcessItems() {
            this.pageLoading = true;

            this.$http
                .get('system/definitions', {
                    params: {
                        alias: 'marketing-process',
                        perPage: 1000
                    }
                })
                .then(response => {
                    this.pageLoading = false;
                    this.marketingProcessItems = response.body.rows;
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.pageLoading = false;

                    this.apiErrorMessage(e);
                });
        },

        getCards(page) {
            this.pageLoading = true;
            this.filter.card_pipeline_id = this.pipelineId;
            this.filter.view = 'table'

            if (!page) {
                page = 1
            }

            this.filter.page = page
            this.filter.move_created_by_id = 0

            this.$http
                .get('cards/cards', {
                    params: this.filter
                })
                .then(response => {
                    this.pageLoading = false;
                    this.cards = response.body.rows;
                    this.pagination = response.body.pagination;
                })
                .catch((e) => {
                    this.pageLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        setStaff() {
            this.pageLoading = true;

            let form = this.filter

            form.card_pipeline_id = this.pipelineId;
            form.view = 'table'
            form.page = 1
            form.move_created_by_id = this.cardCreateForm.move_created_by_id;
            form.start_row = this.cardCreateForm.start_row;
            form.end_row = this.cardCreateForm.end_row;

            this.$http
                .get('cards/cards', {
                    params: form
                })
                .then(response => {
                    this.pageLoading = false;

                    this.getCards(1)
                })
                .catch((e) => {
                    this.pageLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        getExport() {
            this.pageLoading = true;
            this.filter.card_pipeline_id = this.pipelineId;
            this.filter.view = 'table'
            this.filter.export = true

            this.$http
                .get('cards/cards', {
                    params: this.filter
                })
                .then(response => {
                    this.pageLoading = false;
                    this.cardExport = response.body
                    this.exportDialogVisible = true
                })
                .catch((e) => {
                    this.pageLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        store() {
            this.pageLoading = true;

            this.$http
                .post('cards/cards', this.cardCreateForm)
                .then(response => {
                    this.pageLoading = false;
                    this.cardCreateModalVisible = false;

                    this.apiSuccessMessage(response);

                    this.getCards(1);
                })
                .catch((e) => {
                    this.pageLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        getShow(id) {
            this.cardResponseId = null;
            this.cardResponseId = id;

            this.cardShowModalVisible = true;
        }
    }
}
</script>
