import layouts from '../config/layout'

import MasterContainer from '../app/Container/Master'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Dt'

import Pos from '../app/Pages/Sales/Pos'
import Accounting from '../app/Pages/Sales/Accounting'

import OfferCreate from '../app/Pages/Sales/Offers/Create'
import OfferEdit from '../app/Pages/Sales/Offers/Edit'

import OrderCreate from '../app/Pages/Sales/Orders/Create'
import OrderEdit from '../app/Pages/Sales/Orders/Edit'

export default {
    path: '/sales',
    name: 'sales',
    component: MasterContainer,
    meta: {
        auth: true,
        layout: layouts.navLeft,
        title: 'Satis',
    },
    children: [
        {
            path: 'offers',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Teklifler',
            },
            children: [
                {
                    name: 'sales.offers.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Teklifler',
                        path: 'offers',
                        end_point: 'sales/offers',
                        isExport : true,
                    },
                },
                {
                    name: 'sales.offers.create',
                    path: 'create',
                    component: OfferCreate,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Teklif Olustur',
                        path: 'offers',
                        end_point: 'sales/offers',
                    },
                },
                {
                    name: 'sales.offers.edit',
                    path: 'edit/:id',
                    component: OfferEdit,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Teklif Bilgileri',
                        path: 'offers',
                        end_point: 'sales/offers',
                    },
                }
            ]
        },
        {
            path: 'orders',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Siparisler',
            },
            children: [
                {
                    name: 'sales.orders.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Siparisler',
                        path: 'orders',
                        end_point: 'sales/orders',
                        isExport : true,
                    },
                },
                {
                    name: 'sales.orders.create',
                    path: 'create',
                    component: OrderCreate,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Siparis Olustur',
                        path: 'orders',
                        end_point: 'sales/orders',
                    },
                },
                {
                    name: 'sales.orders.edit',
                    path: 'edit/:id',
                    component: OrderEdit,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Siparis Bilgileri',
                        path: 'orders',
                        end_point: 'sales/orders',
                    },
                }
            ]
        },
        {
            path: 'pos',
            name: 'sales.pos.index',
            component: Pos,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Sanal Pos',
                path: 'payments',
                end_point: 'sales/payments',
            }
        },
        {
            path: 'accounting',
            name: 'sales.accounting.index',
            component: Accounting,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Muhasebe',
                path: 'orders',
                end_point: 'sales/orders',
            }
        },
    ]
}
