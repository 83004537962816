import layouts from '../config/layout'
import MasterContainer from '../app/Container/Master'

import Profile from '../app/Pages/Profile/Index'

export default {
    path: '/profile',
    component: MasterContainer,
    meta: {
        auth: true,
        layout: layouts.navLeft,
        title: 'Profil',
    },
    children: [
        {
            name: 'profile.profile.index',
            path: '/',
            component: Profile,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Profil Bilgileriniz',
                path: 'account/user/',
                end_point: 'account/user',
                is_link : true
            }
        },
    ]
}
