<template>
    <div class="card-box">
        <header class="card-box-header has-buttons">
            <div class="title">
                Ogretmenler
            </div>
            <div class="actions">
                <el-button icon="el-icon-plus" type="text" class="el-button-link primary"/>
            </div>
        </header>
        <section class="card-box-content">
            <vue-scroll>
                <div class="box-list" style="height: 400px">
                    <div class="box-list-item" v-for="i in 10" :key="i">
                        <div class="d-flex fluid" style="align-items: center">
                            <div class="mr-20 ui tiny image rounded">
                                <img src="@/resources/images/dummy.png" alt="galeri" >
                            </div>
                            <div>
                                name
                            </div>
                        </div>
                        <div>
                            <el-button type="text" class="el-button-link red" icon="el-icon-delete" @click="destroyItem(item.id)" />
                        </div>
                    </div>
                </div>
            </vue-scroll>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'ElementPart',
        props: ['product'],
        data() {
            return {
                itemLoading: true,
                form: {
                    //
                }
            }
        },

        methods: {
            destroyItem(id) {
                this.form = {}
            },
        },

        mounted() {
            this.itemLoading = false;
        }
    }
</script>