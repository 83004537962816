import layouts from '../config/layout'

import MasterContainer from '../app/Container/Master'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Dt'

import PluginCreate from '../app/Pages/Tools/Plugins/Create'
import PluginEdit from '../app/Pages/Tools/Plugins/Edit'

import SeoLink from '../app/Pages/Tools/Seo/Link'
import SeoFile from '../app/Pages/Tools/Seo/File'
import SeoRedirect from '../app/Pages/Tools/Seo/Redirect'

import Activity from '../app/Pages/Tools/Activity'
import Note from '../app/Pages/Tools/Note'
import File from '../app/Pages/Tools/File'
import Export from '../app/Pages/Tools/Export'
import FileManager from '../app/Pages/Tools/FileManager'

export default {
    path: '/tools',
    name: 'tools',
    component: MasterContainer,
    meta: {
        auth: true,
        layout: layouts.navLeft,
        title: 'Araclar',
    },
    children: [
        {
            path: 'plugins/:type',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Eklentiler',
            },
            children: [
                {
                    name: 'tools.plugins.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Eklentiler',
                        path: 'tools/plugins/',
                        end_point: 'tools/plugins',
                        is_link : true
                    }
                },
                {
                    name: 'tools.plugins.create',
                    path: 'create',
                    component: PluginCreate,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Eklenti Olustur',
                        path: 'tools/plugins/',
                        end_point: 'tools/plugins/',
                    }
                },
                {
                    name: 'tools.plugins.edit',
                    path: 'edit/:id',
                    component: PluginEdit,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Eklenti Bilgileri',
                        path: 'tools/plugins/',
                        end_point: 'tools/plugins/',
                    }
                }
            ]
        },
        {
            path: 'seo/files',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Seo Dosyalar',
            },
            children: [
                {
                    name: 'tools.seo.files.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Seo Dosyalar',
                        path: 'files',
                        end_point: 'tools/seo/create',
                    }
                },
                {
                    name: 'tools.seo.files.create',
                    path: 'create',
                    component: SeoFile,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Dosya Olustur',
                        path: 'files',
                        end_point: 'tools/seo/create',
                    }
                },
                {
                    name: 'tools.seo.files.edit',
                    path: 'edit/:id',
                    component: SeoFile,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Dosya Bilgileri',
                        path: 'files',
                        end_point: 'tools/seo/create',
                    }
                }
            ]
        },
        {
            path: 'seo/redirects',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Seo Yonlendirmeleri',
            },
            children: [
                {
                    name: 'tools.seo.redirects.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Seo Yonlendirmeleri',
                        path: 'redirects',
                        end_point: 'tools/seo/redirects',
                    }
                },
                {
                    name: 'tools.seo.redirects.create',
                    path: 'create',
                    component: SeoRedirect,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Yonlendirme Olustur',
                        path: 'redirects',
                        end_point: 'tools/seo/redirects',
                    }
                },
                {
                    name: 'tools.seo.redirects.edit',
                    path: 'edit/:id',
                    component: SeoRedirect,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Yonlendirme Bilgileri',
                        path: 'redirects',
                        end_point: 'tools/seo/redirects',
                    }
                },
            ]
        },
        {
            path: 'seo/links',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Seo Linkler',
            },
            children: [
                {
                    name: 'tools.seo.links.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Seo Linkler',
                        path: 'links',
                        end_point: 'tools/seo/links',
                    }
                },
                {
                    name: 'tools.seo.links.edit',
                    path: 'edit/:id',
                    component: SeoLink,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Seo Link Bilgileri',
                        path: 'links',
                        end_point: 'tools/seo/links',
                    }
                }
            ]
        },
        {
            path: 'activities',
            name: 'tools.activities.index',
            component: Activity,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Aktiviteler',
            },
        },
        {
            path: 'notes',
            name: 'tools.notes.index',
            component: Note,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Notlar',
            },
        },
        {
            path: 'files',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Dosyalar',
            },
            children: [
                {
                    name: 'tools.files.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Dosyalar',
                        path: 'files',
                        end_point: 'tools/files',
                    }
                },
                {
                    name: 'tools.files.edit',
                    path: 'edit/:id',
                    component: File,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Dosya Bilgileri',
                        path: 'files',
                        end_point: 'tools/files',
                    }
                }
            ]
        },
        {
            path: 'exports',
            name: 'tools.exports.index',
            component: Export,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Veri Yonetimi',
            },
        },
        {
            path: 'file-manager',
            name: 'tools.file_manager.index',
            component: FileManager,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Dosya Yoneticisi',
            },
        },
    ]
}
