<template>
    <div class="notification-box" v-loading="loading">
        <vue-scroll>
            <div style="height: 400px">
                <div class="notify" v-for="item in items" :key="item.id">
                    <div class="flex">
                        <div class="n-title box grow">
                            <b>
                                <i :class="findIcon(item)"></i>
                                {{ item.author }}
                            </b>
                        </div>
                        <div class="n-date">{{ item.created_at }} <i class="mdi mdi-clock"></i></div>
                    </div>
                    <div class="n-desc mt-10">
                        {{ item.event }}
                    </div>
                </div>
            </div>
        </vue-scroll>
        <div class="see-all text-center fluid cursor-pointer">Tum Bildirimler</div>
    </div>
</template>

<script>
import Echo from 'laravel-echo'

window.Pusher = require('pusher-js');

export default {
    name: 'NotificationBox',

    data() {
        return {
            loading: true,
            items: [],
            dialogData : null,
            dialogVisible : true
        }
    },

    mounted() {
        this.connect();
        this.listen();
        this.getItems();
    },

    methods: {
        getItems() {
            this.loading = true;

            this.$http
                .get('tools/activities', {
                    params: {
                        log_type: 'cards',
                        perPage: 10,
                    }
                })
                .then(response => {
                    this.items = response.body.rows;
                    this.loading = false;
                });
        },

        connect() {
            this.echo = new Echo({
                broadcaster: 'pusher',
                key: '85f4fa3d4161cad8ba82',
                cluster: 'eu',
               encrypted: true
            });
        },

        listen() {
            const pusher = new window.Pusher('85f4fa3d4161cad8ba82', {
                cluster: 'eu'
            });

            pusher
                .subscribe('card-create')
                .bind('CardCreateEvent', (response) => {

                    let audio = new Audio('/alert.mp3');

                    audio.play();

                    this.$alert(response.title, 'Görüşme İsteği!', {
                        confirmButtonText: 'Form Mesajına Git',
                        type: 'warning',
                        callback: action => {
                            if (action === 'confirm') {
                                this.$router.push('/cards/marketing/pipelines/10/' + response.id)
                            }
                        }
                    });
                })
        },

        findIcon(item) {
            let process = item.permission_request_method_name;

            if (process === 'index') {
                return 'el-icon-tickets'
            }

            if (process === 'show') {
                return 'el-icon-view'
            }

            if (process === 'store') {
                return 'el-icon-check'
            }

            if (process === 'update') {
                return 'el-icon-edit'
            }

            if (process === 'destroy') {
                return 'el-icon-delete'
            }
        }
    },
}
</script>
