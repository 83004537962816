import layouts from '../config/layout'

import AuthContainer from '../app/Container/Auth'

import Login from '../app/Pages/Auth/Login'

export default {
    path: '/auth',
    component: AuthContainer,
    meta: {
        auth: true,
        layout: layouts.contenOnly,
        title: 'Auth',
    },
    children: [
        {
            name: 'auth.login.index',
            path: 'login',
            component: Login,
            meta: {
                auth: true,
                layout: layouts.contenOnly,
                title: 'Giriş',
                bodyClass: 'auth-page',
            },
        },
    ]
}
