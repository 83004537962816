<template>
    <section v-loading="itemLoading" class="ps-container ps">
        <div class="card-box">
            <header class="card-box-header">
                <div class="title">
                    Degerler
                </div>
                <div class="actions">
                    <el-button @click="crateItem" type="text" class="el-button-link primary" icon="el-icon-plus" />
                </div>
            </header>
            <section class="card-box-content padding-20">
                <table class="ui table table-hover">
                    <thead>
                    <tr>
                        <th width="1" style="padding-right: 30px !important;">Sira</th>
                        <th>Baslik</th>
                        <th width="100"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in items">
                        <td>{{ item.sort }}</td>
                        <td>{{ item.title }}</td>
                        <td>
                            <div class="d-flex" style="justify-content: end">
                                <el-button type="text" class="el-button-link green" icon="el-icon-edit" @click="editItem(item)" />
                                <el-button type="text" class="el-button-link red" icon="el-icon-delete" @click="destroyItem(item.id)" />
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </section>
        </div>

        <el-dialog :title="modalTitle" :visible.sync="modalVisible" v-loading="modalLoading">
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-form-item label="Başlık">
                    <el-input v-model="form.title" placeholder="Başlık" />
                </el-form-item>

                <el-form-item label="Aciklama">
                    <el-input v-model="form.description" placeholder="Aciklama"/>
                </el-form-item>

                <el-form-item label="Sıralama">
                    <el-input-number v-model="form.sort" placeholder="Sıralama" />
                </el-form-item>

                <el-form-item>
                    <el-switch v-model="form.is_active" active-text="Aktif" />
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="storeItem" v-if="!form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" size="medium" @click="updateItem" v-if="form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </span>
        </el-dialog>
    </section>
</template>

<script>
    export default {
        name: 'Values',
        props: ['source', 'itemId'],
        data() {
            return {
                itemLoading: true,
                modalLoading: false,
                modalVisible: false,
                modalTitle: 'Ekle',
                items: [],
                form: {},
            }
        },
        mounted() {
            this.index();
        },
        methods: {
            index() {
                this.$http.get(this.source + '/values', {
                    params: {
                        fake_url: this.source,
                    }
                }).then(response => {
                    this.items = response.body.rows;
                    this.itemLoading = false;
                });
            },

            crateItem() {
                this.modalTitle = 'Ekle';
                this.modalVisible = true;
                this.form = {};
            },

            storeItem() {
                this.modalLoading = true;

                this.form.fake_url = this.source;

                this.$http.post(this.source + '/values', this.form)
                    .then(response => {

                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            editItem(item) {
                this.itemLoading = false;
                this.modalLoading = false;
                this.modalVisible = true;

                this.modalTitle = 'Duzenle';

                this.form = item;
            },

            updateItem() {

                this.modalLoading = true;

                this.form.fake_url = this.source;

                this.$http.put(this.source + '/values/' + this.form.id, this.form)
                    .then(response => {

                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {

                this.modalLoading = false;
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete(this.source + '/values/' + id, {
                        fake_url: this.source
                    }).then(response => {
                        this.modalLoading = false;
                        this.modalVisible = false;
                        this.itemLoading = false;

                        this.index();

                        this.$message({
                            type: 'success',
                            message: 'Delete completed'
                        });
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.modalLoading = false;
                    this.itemLoading = false;
                });
            },
        }
    }
</script>