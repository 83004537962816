<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="pageLoading">
            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        Uniteler
                    </div>
                    <div class="actions">
                        <el-tooltip content="Olustur" effect="dark" placement="bottom">
                            <el-button type="text" icon="el-icon-plus" class="el-button-link primary"
                                       @click="createItem"/>
                        </el-tooltip>
                    </div>
                </header>
                <section class="card-box-content">
                    <Empty :items="rows"/>
                    <div class="box-list">
                        <div class="box-list-item" v-for="row in rows" :key="row.id">
                            <div class="fluid d-flex" style="align-items: center">
                                <div class="row-number">
                                    <div>{{ row.sort }}</div>
                                </div>
                                <div>
                                    <div class="fw-500 fs-16">
                                        {{ row.title }}
                                    </div>
                                </div>
                            </div>
                            <div class="ml-20">
                                <div class="d-flex" style="justify-content: end">
                                    <el-tooltip content="Alistirmalar" effect="dark" placement="bottom">
                                        <el-button type="text" class="el-button-link blue" icon="mdi mdi-menu" @click="openExerciseModal(row)"/>
                                    </el-tooltip>
                                    <el-tooltip content="Duzenle" effect="dark" placement="bottom">
                                        <el-button type="text" class="el-button-link green" icon="el-icon-edit" @click="editItem(row)"/>
                                    </el-tooltip>
                                    <el-tooltip content="Sil" effect="dark" placement="bottom">
                                        <el-button type="text" class="el-button-link red" icon="el-icon-delete" @click="destroyItem(row.id)"/>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <el-dialog title="Unite Bilgileri" :visible.sync="modalVisible" v-loading="pageLoading" width="85%">
                <el-form :label-position="'top'" ref="form" :model="form">

                    <el-row :gutter="24">
                        <el-col :span="18">
                            <el-form-item label="Başlık">
                                <el-input v-model="form.title" placeholder="Başlık"/>
                            </el-form-item>

                            <el-form-item label="Yayınlanma Tarihi">
                                <el-date-picker v-model="form.publish_at" type="datetime" placeholder="Tarih Seçiniz"
                                                value-format="yyyy-MM-dd HH:mm:ss"/>
                            </el-form-item>

                            <el-form-item label="Sıralama">
                                <el-input-number v-model="form.sort" placeholder="Sıralama"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="Resim">
                                <file-browser v-model="form.file_path"/>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="Kisa Aciklama">
                        <trumbowyg v-model="form.short_description" :config="$store.state.trumbowygConfig"/>
                    </el-form-item>

                    <el-form-item label="Açıklama">
                        <trumbowyg v-model="form.description" :config="$store.state.trumbowygConfig"/>
                    </el-form-item>
                </el-form>
                <div slot="footer">
                    <div class="ui button labeled icon green fluid" @click="storeItem" v-if="!form.id">
                        <i class="el-icon-check icon"></i> Kaydet
                    </div>
                    <div class="ui button labeled icon green fluid" @click="updateItem" v-if="form.id">
                        <i class="el-icon-check icon"></i> Guncelle
                    </div>
                </div>
            </el-dialog>

            <el-dialog :title="form.title" :visible.sync="exerciseModalVisible" width="85%">
                <Exercise
                        :unit="form"
                        :indexModalClose="closeExerciseModal"
                        :unitEndPoint="endPoint + '/' + form.id"
                        :key="form.id"
                        v-if="exerciseModalVisible"
                />
            </el-dialog>
        </div>
    </vue-scroll>
</template>

<script>
    import Empty from "../../../../components/Empty";
    import Trumbowyg from "vue-trumbowyg/src/component";
    import FileBrowser from "../../../../components/FileBrowser";
    import Exercise from "./Exercise";

    export default {
        name: 'Unit',
        components: {
            Exercise,
            FileBrowser,
            Trumbowyg,
            Empty
        },
        props: ['book'],
        data() {
            return {
                endPoint: null,
                pageLoading: false,
                modalVisible: false,
                exerciseModalVisible : false,
                form: {
                    file_path : null
                },

                rows: [],
            }
        },

        methods: {
            index() {

                this.pageLoading = true;

                this.$http
                    .get(this.endPoint, {
                        params: {
                            fake_url: this.$route.meta.end_point + '/' + this.book.id
                        }
                    })
                    .then(response => {
                        this.rows = response.body.rows;
                        this.pageLoading = false;
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            createItem() {
                this.modalVisible = true;
                this.exerciseModalVisible = false;
                this.form = {
                    file_path : null
                }
            },

            storeItem() {
                this.pageLoading = true;

                this.form.fake_url = this.$route.meta.end_point + '/' + this.book.id;

                this.$http
                    .post(this.endPoint, this.form)
                    .then(response => {

                        this.pageLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            editItem(item) {
                this.modalVisible = true;
                this.exerciseModalVisible = false;
                this.form = item;
            },

            updateItem() {
                this.pageLoading = true;

                this.form.fake_url = this.$route.meta.end_point + '/' + this.book.id;

                this.$http.put(this.endPoint + '/' + this.form.id, this.form)
                    .then(response => {

                        this.pageLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.exerciseModalVisible = false;

                this.pageLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete(this.endPoint + '/' + id, {
                        fake_url: this.source
                    }).then(response => {
                        this.pageLoading = false;

                        this.index();

                        this.$message({
                            type: 'success',
                            message: 'Delete completed'
                        });
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.pageLoading = false;
                });
            },

            openExerciseModal(item) {
                this.form = item;
                this.exerciseModalVisible = true;
            },

            closeExerciseModal() {
                this.exerciseModalVisible = false;
                this.form = {
                    file_path : null
                }
            }
        },

        mounted() {
            this.endPoint = this.$route.meta.end_point + '/' + this.book.id + '/units';

            this.index();
        }
    }
</script>