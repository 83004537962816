<template>
    <section v-loading="itemLoading" class="ps-container ps">

        <div class="card-box-plus" @click="crateItem" style="margin: 0">
            <div class="box-container">
                <i class="el-icon-plus"></i>
            </div>
        </div>

        <div class="padding-20">
            <table class="ui table table-hover">
                <thead>
                <tr>
                    <th width="1" style="padding-right: 30px !important;">Sira</th>
                    <th>Baslik</th>
                    <th width="100"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, key) in items" :key="'FeaturesComponentItem-' + key">
                    <td>{{ item.sort }}</td>
                    <td>{{ item.feature.title }}</td>
                    <td>
                        <div class="d-flex justify-flex-end">
                            <el-button type="text" class="el-button-link green" icon="el-icon-edit" @click="editItem(item)" />
                            <el-button type="text" class="el-button-link red" icon="el-icon-delete" @click="destroyItem(item.id)" />
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <el-dialog :title="modalTitle" :visible.sync="modalVisible" v-loading="modalLoading" width="90%">
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-form-item label="Ozellikler">
                    <el-select v-model="form.feature_id" value-key="id" @change="featureChangeHandle" placeholder="Ozellikler">
                        <el-option
                                v-for="(item, index) in features"
                                :key="'features-' + index"
                                :label="item.title"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-input-number v-model="form.sort" placeholder="Sıralama" />
                </el-form-item>

                <div v-loading="stepLoading" v-if="form.feature_id">
                    <table class="ui table table-hover">
                        <thead>
                        <tr>
                            <th width="1" style="padding-right: 30px !important;">Sira</th>
                            <th>Adim</th>
                            <th>Aciklama</th>
                            <th width="1">
                                <el-button type="text" icon="el-icon-plus" class="el-button-link primary" @click="createStepItem" />
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(step, stepKey) in form.steps" :key="'feature-step-' + stepKey">
                            <td>
                                <el-form-item>
                                    <el-input-number v-model="step.sort" placeholder="Sıralama"/>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item>
                                    <el-select v-model="step.feature_step_id" value-key="id" placeholder="Adimlar">
                                        <el-option
                                                v-for="(item, index) in steps"
                                                :key="'features-steps-d-' + index"
                                                :label="item.title"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </td>
                            <td>
                                <el-form-item>
                                    <el-input v-model="step.description" placeholder="Aciklama"/>
                                </el-form-item>
                            </td>
                            <td>
                                <el-button type="text" icon="el-icon-delete" class="el-button-link red" @click="destroyStepItem(stepKey)" />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="storeItem" v-if="!form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" size="medium" @click="updateItem" v-if="form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </span>
        </el-dialog>
    </section>
</template>
<script>

    export default {
        name: 'Features',
        props: ['source', 'itemId'],
        data() {
            return {
                itemLoading: true,
                modalLoading: false,
                modalVisible: false,
                stepLoading: false,
                features: {},
                steps: {},
                modalTitle: 'Ekle',
                items: [],
                form: {
                    steps: []
                },
            }
        },
        mounted() {
            this.index();
            this.getFeatures();
        },
        methods: {
            index() {
                this.$http.get(this.source + '/features', {
                    params: {
                        fake_url: this.source,
                    }
                }).then(response => {
                    this.items = response.body.rows;
                    this.itemLoading = false;
                });
            },

            crateItem() {
                this.modalTitle = 'Ekle';
                this.modalVisible = true;
                this.form = {
                    steps: []
                };
            },

            storeItem() {
                this.modalLoading = true;

                this.form.fake_url = this.source;

                this.$http.post(this.source + '/features', this.form)
                    .then(response => {

                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            editItem(item) {
                this.itemLoading = false;
                this.modalLoading = false;
                this.modalVisible = true;

                this.modalTitle = 'Duzenle';

                this.form = item;
                this.stepsLoad();
            },

            updateItem() {

                this.modalLoading = true;

                this.form.fake_url = this.source;

                this.$http.put(this.source + '/features/' + this.form.id, this.form)
                    .then(response => {

                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {

                this.modalLoading = false;
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete(this.source + '/features/' + id, {
                        fake_url: this.source
                    }).then(response => {
                        this.modalLoading = false;
                        this.modalVisible = false;
                        this.itemLoading = false;

                        this.index();

                        this.$message({
                            type: 'success',
                            message: 'Delete completed'
                        });
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.modalLoading = false;
                    this.itemLoading = false;
                });
            },

            featureChangeHandle() {
                this.form.steps = [];

                this.stepsLoad();
            },

            stepsLoad() {
                this.stepLoading = true;

                this.$http.get('cms/features/' + this.form.feature_id + '/steps', {
                    params: {
                        fake_url: this.source,
                    }
                }).then(response => {
                    this.steps = response.body.rows;
                    this.stepLoading = false;
                });
            },

            createStepItem() {
                this.form.steps.push({
                    feature_step_id: null,
                    content: null,
                    sort: 100,
                });
            },

            destroyStepItem(key) {
                this.form.steps.splice(key, 1);
            },

            getFeatures() {
                this.itemLoading = true;
                this.$http.get('cms/features').then(response => {
                    this.itemLoading = false;
                    this.features = response.body.rows;
                });
            }
        }
    }
</script>