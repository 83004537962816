<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading">
            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                            <el-button @click="$router.go(-1)" type="text" icon="el-icon-back"
                                       class="el-button-link grey"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom">
                            <el-button @click="updateItem" type="text" icon="el-icon-check"
                                       class="el-button-link green"/>
                        </el-tooltip>
                    </div>
                </header>
                <section class="card-box-tabs small padded">
                    <el-form :label-position="'top'" ref="form" :model="form">
                        <el-tabs :tab-position="'top'">
                            <el-tab-pane label="Site">
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="Site Adı">
                                            <el-input v-model="form.site_name"/>
                                        </el-form-item>
                                        <el-form-item label="Site Gerçek Adı">
                                            <el-input v-model="form.site_real_name"/>
                                        </el-form-item>
                                        <el-form-item label="Site Sahibi">
                                            <el-input v-model="form.site_owner"/>
                                        </el-form-item>
                                        <el-form-item label="Web Site">
                                            <el-input v-model="form.site_website"/>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="12">
                                        <el-form-item label="Site Email">
                                            <el-input v-model="form.site_email"/>
                                        </el-form-item>
                                        <el-form-item label="Gsm">
                                            <el-input v-model="form.site_gsm"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Telefon">
                                            <el-input v-model="form.site_phone"/>
                                        </el-form-item>
                                        <el-form-item label="Fax">
                                            <el-input v-model="form.site_fax"/>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="24">
                                        <el-form-item label="Ülke">
                                            <el-select v-model="form.site_country_id" placeholder="Ülke" filterable
                                                       clearable>
                                                <el-option
                                                        v-for="zone in countries"
                                                        :key="zone.id"
                                                        :label="zone.title"
                                                        :value="zone.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>

                                        <el-form-item label="Şehir">
                                            <el-select v-model="form.site_city_id" placeholder="Şehir" filterable
                                                       clearable>
                                                <el-option
                                                        v-for="zone in cities"
                                                        :key="zone.id"
                                                        :label="zone.title"
                                                        :value="zone.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>

                                        <el-form-item label="Adres">
                                            <el-input v-model="form.site_address"/>
                                        </el-form-item>
                                        <el-form-item label="Site GeoLocation">
                                            <el-input v-model="form.site_geolocation"/>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="12">
                                        <el-form-item label="Hafta içi Çalışma Günleri">
                                            <el-input v-model="form.site_work_time_weekdays"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Hafta Sonu Çalışma Günleri">
                                            <el-input v-model="form.site_work_time_weekend"/>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="Seo">
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="Başlık">
                                            <el-input v-model="form.meta_title"/>
                                        </el-form-item>
                                        <el-form-item label="Açıklama">
                                            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}"
                                                      placeholder="Açıklama" v-model="form.meta_description"/>
                                        </el-form-item>
                                        <el-form-item label="Anahtar Kelimeler">
                                            <el-input v-model="form.meta_keywords"/>
                                        </el-form-item>
                                        <el-form-item label="Sahip">
                                            <el-input v-model="form.meta_author"/>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="Secenekler">
                                <el-row :gutter="24">

                                    <el-col :span="24">
                                        <h4>Okul Ayarları</h4>
                                    </el-col>

                                    <el-col :span="24">
                                        <el-form-item label="Yil">
                                            <select-definition label="Yil" alias="education-years"
                                                               model="current_education_year_id" :container="form"/>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="12">
                                        <el-form-item label="Egitim Donemi">
                                            <select-definition label="Egitim Donemi" alias="education-periods"
                                                               model="education_normal_period_id" :container="form"/>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="12">
                                        <el-form-item label="Yaz Donemi">
                                            <select-definition label="Yaz Donemi" alias="education-periods"
                                                               model="education_summer_period_id" :container="form"/>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="24">
                                        <h4>Görünüm Ayarları</h4>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-form-item label="Varsayilan Tema">
                                            <el-select v-model="form.theme_id" placeholder="Select">
                                                <el-option
                                                        v-for="theme in themes"
                                                        :key="theme.id"
                                                        :label="theme.title"
                                                        :value="theme.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24">
                                        <h4>Bakim Modu</h4>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-form-item label="Durum">
                                            <el-input v-model="form.maintenance_status"/>
                                        </el-form-item>
                                        <el-form-item label="Mesaj">
                                            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}"
                                                      placeholder="Mesaj"
                                                      v-model="form.maintenance_message"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24">
                                        <h4>Sözleşmeler</h4>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Gizlilik Sözleşmesi">
                                            <el-select v-model="form.privacy_contract_id" placeholder="Select">
                                                <el-option
                                                        v-for="page in pages"
                                                        :key="page.id"
                                                        :label="page.title"
                                                        :value="page.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Mesafeli Satış Sözleşmesi">
                                            <el-select v-model="form.sales_contract_id" placeholder="Select">
                                                <el-option
                                                        v-for="page in pages"
                                                        :key="page.id"
                                                        :label="page.title"
                                                        :value="page.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Üyelik Sözleşmesi">
                                            <el-select v-model="form.membership_contract_id" placeholder="Select">
                                                <el-option
                                                        v-for="page in pages"
                                                        :key="page.id"
                                                        :label="page.title"
                                                        :value="page.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Teslimat ve İade Şartları">
                                            <el-select v-model="form.order_return_contract_id" placeholder="Select">
                                                <el-option
                                                        v-for="page in pages"
                                                        :key="page.id"
                                                        :label="page.title"
                                                        :value="page.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="24">
                                        <h4>Siparis Durumlari</h4>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Bekliyor">
                                            <el-select v-model="form.order_wait_status_id" placeholder="Select">
                                                <el-option
                                                        v-for="status in orderStatuses"
                                                        :key="status.id"
                                                        :label="status.title"
                                                        :value="status.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Tamamlandi">
                                            <el-select v-model="form.order_success_status_id" placeholder="Select">
                                                <el-option
                                                        v-for="status in orderStatuses"
                                                        :key="status.id"
                                                        :label="status.title"
                                                        :value="status.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Iptal Edildi">
                                            <el-select v-model="form.order_cancel_status_id" placeholder="Select">
                                                <el-option
                                                        v-for="status in orderStatuses"
                                                        :key="status.id"
                                                        :label="status.title"
                                                        :value="status.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="24">
                                        <h4>Teklif Durumlari</h4>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Bekliyor">
                                            <el-select v-model="form.offer_wait_status_id" placeholder="Select">
                                                <el-option
                                                        v-for="status in offerStatuses"
                                                        :key="status.id"
                                                        :label="status.title"
                                                        :value="status.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Tamamlandi">
                                            <el-select v-model="form.offer_success_status_id" placeholder="Select">
                                                <el-option
                                                        v-for="status in offerStatuses"
                                                        :key="status.id"
                                                        :label="status.title"
                                                        :value="status.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Iptal Edildi">
                                            <el-select v-model="form.offer_cancel_status_id" placeholder="Select">
                                                <el-option
                                                        v-for="status in offerStatuses"
                                                        :key="status.id"
                                                        :label="status.title"
                                                        :value="status.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="24">
                                        <h4>Iade Durumlari</h4>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Bekliyor">
                                            <el-select v-model="form.return_wait_status_id" placeholder="Select">
                                                <el-option
                                                        v-for="status in returnStatuses"
                                                        :key="status.id"
                                                        :label="status.title"
                                                        :value="status.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Tamamlandi">
                                            <el-select v-model="form.return_success_status_id" placeholder="Select">
                                                <el-option
                                                        v-for="status in returnStatuses"
                                                        :key="status.id"
                                                        :label="status.title"
                                                        :value="status.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Iptal Edildi">
                                            <el-select v-model="form.return_cancel_status_id" placeholder="Select">
                                                <el-option
                                                        v-for="status in returnStatuses"
                                                        :key="status.id"
                                                        :label="status.title"
                                                        :value="status.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="Eposta">
                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <el-form-item label="Gonderen Adi">
                                            <el-input v-model="form.email_from_name"/>
                                        </el-form-item>
                                        <el-form-item label="Gonderen Eposta">
                                            <el-input v-model="form.email_from_address"/>
                                        </el-form-item>
                                        <el-form-item label="Bildirim Mail Adresleri">
                                            <el-input v-model="form.email_notify_all"/>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :span="24">
                                        <h4>Taslaklar</h4>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Müşteri Kayıt">
                                            <el-select v-model="form.email_draft_register" placeholder="Select">
                                                <el-option
                                                        v-for="draft in drafts"
                                                        :key="draft.id"
                                                        :label="draft.title"
                                                        :value="draft.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Şifre Yenileme">
                                            <el-select v-model="form.email_draft_password_reset" placeholder="Select">
                                                <el-option
                                                        v-for="draft in drafts"
                                                        :key="draft.id"
                                                        :label="draft.title"
                                                        :value="draft.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Hesap Değiştirme">
                                            <el-select v-model="form.email_draft_change_account" placeholder="Select">
                                                <el-option
                                                        v-for="draft in drafts"
                                                        :key="draft.id"
                                                        :label="draft.title"
                                                        :value="draft.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Kayitli Olmayan Kullanici Hesap Erisim">
                                            <el-select v-model="form.email_draft_new_account_user" placeholder="Select">
                                                <el-option
                                                        v-for="draft in drafts"
                                                        :key="draft.id"
                                                        :label="draft.title"
                                                        :value="draft.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Hesap Tamamlandı">
                                            <el-select v-model="form.email_draft_account_completed"
                                                       placeholder="Select">
                                                <el-option
                                                        v-for="draft in drafts"
                                                        :key="draft.id"
                                                        :label="draft.title"
                                                        :value="draft.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="On Kayit Formu">
                                            <el-select v-model="form.email_draft_education_register"
                                                       placeholder="Select">
                                                <el-option
                                                        v-for="draft in drafts"
                                                        :key="draft.id"
                                                        :label="draft.title"
                                                        :value="draft.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Etkinlik Kayit Formu">
                                            <el-select v-model="form.email_draft_event_register" placeholder="Select">
                                                <el-option
                                                        v-for="draft in drafts"
                                                        :key="draft.id"
                                                        :label="draft.title"
                                                        :value="draft.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Sende Soru Sor (S.S.S)">
                                            <el-select v-model="form.email_draft_faq_question" placeholder="Select">
                                                <el-option
                                                        v-for="draft in drafts"
                                                        :key="draft.id"
                                                        :label="draft.title"
                                                        :value="draft.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="Roller">
                                <el-row :gutter="24">
                                    <el-col :span="6">
                                        <el-form-item label="Ziyaretci">
                                            <el-select v-model="form.guest_role_id" placeholder="Select">
                                                <el-option
                                                        v-for="role in roles"
                                                        :key="role.id"
                                                        :label="role.title"
                                                        :value="role.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="Müşteri">
                                            <el-select v-model="form.customer_role_id" placeholder="Select">
                                                <el-option
                                                        v-for="role in roles"
                                                        :key="role.id"
                                                        :label="role.title"
                                                        :value="role.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="Personel">
                                            <el-select v-model="form.staff_role_id" placeholder="Select">
                                                <el-option
                                                        v-for="role in roles"
                                                        :key="role.id"
                                                        :label="role.title"
                                                        :value="role.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="Admin">
                                            <el-select v-model="form.admin_role_id" placeholder="Select">
                                                <el-option
                                                        v-for="role in roles"
                                                        :key="role.id"
                                                        :label="role.title"
                                                        :value="role.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="Ogretmen">
                                            <el-select v-model="form.teacher_role_id" placeholder="Select">
                                                <el-option
                                                        v-for="role in roles"
                                                        :key="role.id"
                                                        :label="role.title"
                                                        :value="role.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="Root">
                                            <el-select v-model="form.root_role_id" placeholder="Select">
                                                <el-option
                                                        v-for="role in roles"
                                                        :key="role.id"
                                                        :label="role.title"
                                                        :value="role.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                        </el-tabs>
                    </el-form>
                </section>
            </div>
        </div>
    </vue-scroll>
</template>

<script>

    import SelectDefinition from "../../../components/SelectDefinition";

    export default {
        name: 'SiteSetting',
        components: {
            SelectDefinition
        },
        props: ['source'],
        data() {
            return {
                itemLoading: true,
                form: {
                    //
                },

                themes: [],
                pages: [],
                countries: [],
                cities: [],
                roles: [],
                drafts: [],
                orderStatuses: [],
                offerStatuses: [],
                returnStatuses: [],
            }
        },

        methods: {
            loadItem() {

                this.itemLoading = true;

                this.$http
                    .get(this.$route.meta.end_point, {
                        params: this.serverParams
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body;

                        this.getZones('countries', 0);
                        this.getZones('cities', this.form.site_country_id);
                        this.getThemes();
                        this.getPages();

                        this.getStatuses('orders', 'orderStatuses');
                        this.getStatuses('offers', 'offerStatuses');
                        this.getStatuses('order-returns', 'returnStatuses');

                        this.getRoles();
                        this.getDrafts();
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.itemLoading = true;

                let settings = {
                    settings: this.form,
                    fake_url: 'v1/' + this.end_point
                };

                this.$http
                    .put(this.$route.meta.end_point + '/' + 1, settings)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);

                        this.$store.commit('setSettings', response.body);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getZones(alias, parentId) {
                this.$http
                    .get('system/zones', {
                        params: {
                            parent_id: parentId,
                            alias: alias,
                            perPage: 1000
                        }
                    })
                    .then(response => {
                        this[alias] = response.body.rows;
                        this.itemLoading = false;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getThemes() {
                this.$http
                    .get('system/themes')
                    .then(response => {
                        this.itemLoading = false;
                        this.themes = response.body.rows
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getPages() {
                this.$http
                    .get('cms/contents/pages')
                    .then(response => {
                        this.itemLoading = false;
                        this.pages = response.body.rows
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getStatuses(alias, key) {
                this.$http
                    .get('system/statuses?alias=' + alias)
                    .then(response => {
                        this.itemLoading = false;
                        this[key] = response.body.rows
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getRoles() {
                this.$http
                    .get('crm/roles')
                    .then(response => {
                        this.itemLoading = false;
                        this.roles = response.body.rows
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getDrafts() {
                this.$http
                    .get('marketing/drafts?alias=email')
                    .then(response => {
                        this.itemLoading = false;
                        this.drafts = response.body.rows
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },
        },

        mounted() {
            this.loadItem();
        }
    }
</script>