<template>
	<div v-loading="pageLoading">
		<div class="card-box">
			<section class="card-box-content">
				<div :style="'background-color: ' + order.status.status.bg_color + '; color: ' + order.status.status.text_color"
					 class="status my-flex padding-15" v-if="order.status.status" @click="create">
					<div class="fluid">
						<div>{{ order.created_at }}</div>
						<h2 class="m-0 fs-24">{{ order.status.status.title }}</h2>
					</div>
					<div>
						<i :class="'widget-icon accent-text fs-30 ' + (order.status.status.icon ? order.status.status.icon : 'mdi mdi-label-outline')"
						   :style="'color:' + order.status.status.text_color "></i>
					</div>
				</div>
				<vue-scroll>
					<div style="height: 257px">
						<div class="box-list" v-for="item in items" :key="item.id" style="cursor: pointer">
							<div class="box-list-item" @click="edit(item)">
								<div>
									{{ item.created_at }}
								</div>
								<div>
									<span v-html="item.status_badge"></span>
								</div>
							</div>
							<div class="box-list-item" v-if="item.message" @click="edit(item)">
								{{ item.message }}
							</div>
						</div>
					</div>
				</vue-scroll>
			</section>
		</div>

		<el-dialog title="Durum Bilgileri" :visible.sync="dialogVisible" v-loading="dialogLoading" append-to-body>
			<el-form :model="form">
				<el-form-item label="Durum" v-if="!form.id">
					<el-select v-model="form.status_id" value-key="id" placeholder="Durum">
						<el-option v-for="item in $store.state.ui.statuses.orders" :key="item.id" :label="item.title" :value="item.id" />
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6}" placeholder="Mesajiniz..."
							  v-model="form.message" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="success" size="medium" @click="store" v-if="!form.id" style="width: 100%">
					<i class="mdi mdi-check"></i> Kaydet
				</el-button>
				<el-row :gutter="24" v-if="form.id">
					<el-col :span="12">
						<el-button type="danger" size="medium" @click="destroy(form.id)" style="width: 100%">
							<i class="el-icon-delete"></i> Sil
						</el-button>
					</el-col>
					<el-col :span="12">
						<el-button type="success" size="medium" @click="update" style="width: 100%">
							<i class="mdi mdi-check"></i> Guncelle
						</el-button>
					</el-col>
				</el-row>
			</div>
		</el-dialog>
	</div>
</template>

<script>
    export default {
        name: 'Statuses',
        props: ['url', 'order'],
        data() {
            return {
                pageLoading: false,
                items: [],

				form : {
                    //
				},

                dialogVisible : false,
                dialogLoading : false,
            }
        },

        mounted() {
            this.index();
        },

        methods: {

            updatePropStatus: function (status) {
                this.$emit('input', status);
            },

            index() {

                this.pageLoading = true;

                this.$http
                    .get(this.url + '/statuses', {
                        params: {
                            fake_url: 'v1/' + this.url,
                            url: 'v1/' + this.url,
                        }
                    })
                    .then(response => {
                        this.items = response.body.rows;
                        this.pageLoading = false;
                    });
            },

            create() {
                this.dialogVisible = true;
                this.form = {
                    //
				};
            },

            edit(item) {
                this.dialogVisible = true;
                this.form = item;
            },

            store() {
                this.dialogLoading = true;

                this.$http.post(this.url + '/statuses', {
                    fake_url: 'v1/' + this.url,
                    status_id: this.form.status_id,
                    message: this.form.message,
                }).then(response => {
                    this.dialogLoading = false;
                    this.dialogVisible = false;

                    this.index();

                    this.apiSuccessMessage(response);

                    this.updatePropStatus(response.body.item)
                });
            },

			update() {
                this.dialogLoading = true;

                this.$http.put(this.url + '/statuses/' + this.form.id, {
                    fake_url: 'v1/' + this.url,
                    status_id: this.form.status_id,
                    message: this.form.message,
                }).then(response => {
                    this.dialogLoading = false;
                    this.dialogVisible = false;

                    this.index();

                    this.apiSuccessMessage(response);
                });
			},

            destroy(id) {
                this.dialogLoading = true;
                this.pageLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete(this.url + '/statuses/' + id, {
                        fake_url: 'v1/' + this.url
                    }).then(response => {
                        this.dialogLoading = false;
                        this.dialogVisible = false;
                        this.pageLoading = false;

                        this.index();

                        this.apiSuccessMessage(response);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.dialogLoading = false;
                    this.pageLoading = false;
                });
            }
        },
    }
</script>