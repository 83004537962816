<template>
    <div v-loading="itemLoading">

        <div class="card-box">
            <header class="card-box-header">
                <div class="title">
                    Etiketler
                </div>
                <div class="actions">
                    <el-popover placement="bottom-start" width="400" title="Etiketler">
                        <div slot="reference">
                            <el-button icon="el-icon-plus" type="text" class="el-button-link primary"/>
                        </div>
                        <div class="app-card-pop" >
                            <div class="item-title">
                                <div>
                                    <el-select
                                            @change="storeItem"
                                            v-model="form.status_id"
                                            value-key="id"
                                            filterable
                                            remote
                                            reserve-keyword
                                            placeholder="Etiket"
                                            :remote-method="searchRelationItem"
                                            :loading="itemSearchLoading">

                                        <el-option v-for="item in searchResults" :key="item.id" :label="item.title" :value="item.id"/>
                                    </el-select>
                                </div>
                                <div>
                                    <el-button type="primary" icon="el-icon-plus" size="small" @click="openBlankWindow(itemCreateLink)"/>
                                </div>
                            </div>
                            <div class="results" v-if="items">
                                <div v-for="(item, key) in items" :key="key" class="item">
                                    <div class="label" :style="{color: item.label.text_color, 'background' : item.label.bg_color}">
                                        {{ item.label.title }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-popover>
                </div>
            </header>
            <section class="card-box-content">
                <div class="box-list">
                    <div class="box-list-item" v-for="item in items" :key="item.id" :style="{'color' : item.label.bg_color}">
                        <div class="name">
                            {{ item.label.title }}
                        </div>
                        <div>
                            <el-button type="text" icon="el-icon-delete" class="el-button-link red" @click="destroy(item.id)"/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Labels',
        props: ['cardId'],
        data() {
            return {
                itemLoading: true,
                itemSearchLoading: false,
                itemCreateLink: '/system/statuses/cards/create',
                items: [],
                form: {
                    //
                },
                searchResults: [],
            }
        },

        methods: {
            index() {

                this.itemLoading = true;

                this.$http
                    .get('cards/cards/' + this.cardId + '/labels', {params: this.serverParams})
                    .then(response => {
                        this.itemLoading = false;
                        this.items = response.body.items
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            storeItem() {

                this.itemLoading = true;

                this.$http.post('cards/cards/' + this.cardId + '/labels', this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);

                        this.index();
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {

                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {

                    this.$http.delete('cards/cards/' + this.cardId + '/labels/' + id)
                        .then(response => {
                            this.itemLoading = false;
                            this.apiSuccessMessage(response);

                            this.index();
                        })
                        .catch((e) => {
                            this.itemLoading = false;
                            this.apiErrorMessage(e);
                        });

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.itemLoading = false;
                });
            },

            searchRelationItem(query) {
                this.itemSearchLoading = true;

                this.$http
                    .get('tools/search/statuses', {
                        params: {
                            q: query,
                            alias: 'cards',
                        }
                    })
                    .then(response => {
                        this.itemSearchLoading = false;
                        this.searchResults = response.body.items;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },
        },

        mounted() {
            this.index();
        }
    }
</script>
