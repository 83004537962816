<template>
    <vue-scroll>
        <div class="loading-container" v-loading="pageLoading">
            <div class="card-box">
                <header class="card-box-header has-buttons">
                    <div class="title">
                        Servis Raporlari
                    </div>
                    <div class="actions">
                        <el-button icon="el-icon-download" type="text" class="el-button-link grey d-flex" @click="exportVisible = true"/>
                        <el-button icon="el-icon-search" type="text" class="el-button-link blue d-flex" @click="filterVisible = true"/>
                    </div>
                </header>
            </div>

            <el-row :gutter="24" v-if="appCounter(items)">
                <el-col :span="18">
                    <div class="card-box">
                        <div class="card-box-header">
                            <div class="title">
                                Bolge 1
                            </div>
                            <div class="actions">
                                45
                            </div>
                        </div>
                        <div class="card-box-content">
                            <div class="d-flex">
                                <div class="box-list fluid">
                                    <div class="box-list-item box-list-item-title">
                                        <div class="name">
                                            Alinacak
                                        </div>
                                        <div class="description">
                                            25
                                        </div>
                                    </div>
                                    <div class="box-list-item box-list-item-editable box-list-item-media d-flex" v-for="item in 10" :key="item">
                                        <div class="box-list-item-media-item fluid" style="align-items: start">
                                            <div class="media">
                                                <avatar type="student"/>
                                            </div>
                                            <div class="text fluid margin-right-20">
                                                <el-popover placement="top" width="600" trigger="hover">
                                                    <div slot="reference">
                                                        <div class="name">
                                                            Ogrenci Adi <i class="mdi mdi-gender-male"></i>
                                                        </div>
                                                        <div class="info-item">
                                                            Sinif
                                                        </div>
                                                        <div class="info-item">
                                                            Etiketler
                                                        </div>
                                                    </div>
                                                    <div class="parents">
                                                        <div class="parent border-bottom padding-top-10 padding-bottom-10">
                                                            <div class="">
                                                                ÖMER GÖYMEN
                                                            </div>
                                                            <div class="">
                                                                05447762687
                                                            </div>
                                                            <div class="">
                                                                egoymen@anadolu.edu.r
                                                            </div>
                                                            <div>
                                                                TUNALI MAH. ÜN SOK. 19/5 Tunali Mah , Fatih / Tepebaşi -
                                                                Eskişehir
                                                            </div>
                                                        </div>
                                                    </div>
                                                </el-popover>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-list fluid">
                                    <div class="box-list-item box-list-item-title">
                                        <div class="name">
                                            Birakilacak
                                        </div>
                                        <div class="description">
                                            10
                                        </div>
                                    </div>
                                    <div class="box-list-item box-list-item-editable box-list-item-media d-flex" v-for="item in 10" :key="item">
                                        <div class="box-list-item-media-item fluid" style="align-items: start">
                                            <div class="media">
                                                <avatar type="student"/>
                                            </div>
                                            <div class="text fluid margin-right-20">
                                                <el-popover placement="top" width="600" trigger="hover">
                                                    <div slot="reference">
                                                        <div class="name">
                                                            Ogrenci Adi <i class="mdi mdi-gender-male"></i>
                                                        </div>
                                                        <div class="info-item">
                                                           Sinif
                                                        </div>
                                                        <div class="info-item">
                                                            Etiketler
                                                        </div>
                                                    </div>
                                                    <div class="parents">
                                                        <div class="parent border-bottom padding-top-10 padding-bottom-10">
                                                            <div class="">
                                                                ÖMER GÖYMEN
                                                            </div>
                                                            <div class="">
                                                                05447762687
                                                            </div>
                                                            <div class="">
                                                                egoymen@anadolu.edu.r
                                                            </div>
                                                            <div>
                                                                TUNALI MAH. ÜN SOK. 19/5 Tunali Mah , Fatih / Tepebaşi -
                                                                Eskişehir
                                                            </div>
                                                        </div>
                                                    </div>
                                                </el-popover>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="card-box">
                        <div class="card-box-header">
                            <div class="title">
                                Servisler
                            </div>
                            <div class="actions">
                                24
                            </div>
                        </div>
                        <div class="card-box-content">
                            <div class="box-list">
                                <div class="box-list-item cursor-pointer" v-for="item in 15" :key="item">
                                    <div class="name">
                                        Bolge {{ item }}
                                    </div>
                                    <div class="description">
                                        12
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <el-dialog title="Disa Aktar" :visible.sync="exportVisible" width="50%">
            <div class="card-box m-0">
                <div class="card-box-content">
                    <div class="box-list">
                        <div class="box-list-item cursor-pointer" @click="handleExport('query')">
                            <div class="name">Sorgu Listesi</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-box margin-top-20" v-if="exportData">
                <div class="card-box-content padding-20">
                    <div class="ui message">
                        {{ exportData.success }}
                    </div>

                    <div class="ui button icon labeled fluid green fw-500" @click="openBlankWindow(exportData.link)">
                        <i class="icon el-icon-download"></i> Indir
                    </div>
                </div>
            </div>
        </el-dialog>

        <el-dialog title="Filtrele" :visible.sync="filterVisible" width="50%">
            <el-form :label-position="'top'" ref="form" :model="filterForm">
                <el-form-item label="Egitim Yili">
                    <select-definition label="Egitim Yili" alias="education-years" model="education_year_definition_id" :container="filterForm"/>
                </el-form-item>

                <el-form-item label="Egitim Donemi">
                    <select-definition label="Egitim Donemi" alias="education-periods" model="education_period_definition_id" :container="filterForm"/>
                </el-form-item>

                <div class="ui button labeled icon green fluid" @click="handleSearch">
                    <i class="el-icon-search icon"></i> Ara
                </div>
            </el-form>
        </el-dialog>
    </vue-scroll>
</template>

<script>
    import SelectDefinition from "../../../components/SelectDefinition";
    export default {
        name: 'Vehicle',
        components: {SelectDefinition},
        data() {
            return {
                pageLoading: true,
                exportVisible: false,
                filterVisible: false,
                filterForm: {
                   //
                },

                items : [],
                exportData : null,
            }
        },

        methods: {
            handleSearch() {
                this.pageLoading = true;
                this.filterForm.export = null;
                this.exportData = null;

                this.$http
                    .get(this.$route.meta.end_point, {
                        params: this.filterForm
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.filterVisible = false;

                        this.items = response.body.items;
                    })
                    .catch((e) => {
                        this.pageLoading = false;

                        this.apiErrorMessage(e);
                    });
            },

            handleExport(exportType) {
                this.pageLoading = true;

                this.filterForm.export = exportType;

                this.$http
                    .get(this.$route.meta.end_point, {
                        params: this.filterForm
                    })
                    .then(response => {
                        this.pageLoading = false;

                        this.exportData = response.body;
                    })
                    .catch((e) => {
                        this.pageLoading = false;

                        this.apiErrorMessage(e);
                    });
            }
        },

        mounted() {
            this.pageLoading = false;
        }
    }
</script>