<template>
    <div class="loading-container" v-loading="pageLoading">
        <div class="card-box">
            <div class="card-box-header">
                <div class="title">
                    Icerikler
                </div>
                <div class="actions">
                    <el-tooltip content="Olustur" effect="dark" placement="bottom">
                        <el-button type="text" icon="el-icon-plus" class="el-button-link primary" @click="createItem"/>
                    </el-tooltip>
                </div>
            </div>
            <div class="card-box-content">
                <Empty :items="rows"/>

                <div class="box-list">
                    <div class="box-list-item" v-for="row in rows" :key="row.id">
                        <div class="fluid d-flex" style="align-items: center">
                            <div class="row-number">
                                <div>{{ row.sort }}</div>
                            </div>
                            <div>
                                <div class="fw-500 fs-16">
                                    {{ row.title }}
                                </div>
                            </div>
                        </div>
                        <div class="ml-20">
                            <div class="d-flex" style="justify-content: end">
                                <el-tooltip content="Duzenle" effect="dark" placement="bottom">
                                    <el-button type="text" class="el-button-link green" icon="el-icon-edit"
                                               @click="editItem(row)"/>
                                </el-tooltip>
                                <el-tooltip content="Sil" effect="dark" placement="bottom">
                                    <el-button type="text" class="el-button-link red" icon="el-icon-delete"
                                               @click="destroyItem(row.id)"/>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog title="Icerik Ekle" :visible.sync="modalVisible" v-loading="pageLoading" width="95%" append-to-body>
            <el-row :gutter="24" v-if="!selectedType">
                <el-col :span="6" v-for="(type, key) in types" :key="key">
                    <div class="card-box card-box-clickable" @click="selectedType = type">
                        <div class="card-box-content padding-20">
                            <div class="media">
                                <img :src="image(type.media)" class="ui image small centered" style="height: 100px">
                            </div>
                            <div class="text fs-18 fw-600 text-center mt-10">
                                {{ type.title }}
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>

            <div v-if="selectedType">
                <div class="card-box">
                    <div class="card-box-content" style="background: #f7f7f7">
                        <div class="box-list">
                            <div class="box-list-item">
                                <div class="media mr-40">
                                    <img :src="image(selectedType.media)" alt="" class="ui image small">
                                </div>
                                <div class="text fluid">
                                    <div class="name fs-18">
                                        {{ selectedType.title }}
                                    </div>
                                    <div class="">
                                        {{ selectedType.description }}
                                    </div>
                                </div>
                                <div class="ml-40 d-flex">
                                    <el-tooltip content="Geri Don!" effect="dark" placement="bottom">
                                        <el-button type="text" @click="selectedType = null" icon="el-icon-back"
                                                   class="el-button-link grey d-flex" v-if="!form.id" />
                                    </el-tooltip>
                                    <el-tooltip content="Kaydet" effect="dark" placement="bottom">
                                        <el-button type="text" icon="el-icon-check" class="el-button-link green d-flex" @click="storeItem" v-if="!form.id"/>
                                    </el-tooltip>
                                    <el-tooltip content="Guncelle" effect="dark" placement="bottom">
                                        <el-button type="text" icon="el-icon-check" class="el-button-link green d-flex" @click="updateItem" v-if="form.id"/>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <el-form :label-position="'top'" ref="form" :model="form">
                    <el-row :gutter="24">
                        <el-col :span="18">
                            <el-form-item label="Başlık">
                                <el-input v-model="form.title" placeholder="Başlık"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="Sıralama">
                                <el-input-number v-model="form.sort" placeholder="Sıralama"/>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="Talimat">
                        <trumbowyg v-model="form.description" :config="$store.state.trumbowygConfig"/>
                    </el-form-item>

                    <el-form-item label="Sablon">
                        <el-select v-model="form.layout" placeholder="Sablon" filterable clearable>
                            <el-option
                                    v-for="item in layouts"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"/>
                        </el-select>
                    </el-form-item>

                    <component :is="selectedType.type" :content="form" :type="selectedType" v-if="selectedType" :key="selectedType.type"/>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import * as components from './Types'

    export default {
        name: 'Content',
        components,
        props: [
            'exercise',
            'exerciseEndPoint',
            'closeContentModal',
        ],
        data() {
            return {
                pageLoading: true,
                endPoint: null,
                modalVisible: null,
                rows: [],
                form: {
                    //
                },
                selectedType: null,
                layouts: [
                    {
                        value: 'single',
                        label: 'Tekil Gosterim'
                    },
                    {
                        value: 'list',
                        label: 'Ardisik Gosterim'
                    }
                ],
                types: [
                    {
                        type: 'sentence_building',
                        title: 'Sentence Building ',
                        description: 'Dağınık bir şekilde kelimeler verilir , ve öğrenciden bu kelimeleri bir yere sürükleyip bırakarak anlamlı cümle yapması istenir',
                        media: 'sentence-building'
                    },
                    {
                        type: 'match',
                        title: 'Match',
                        description: 'Resim + kelime / Resim + Resim / Kelime + Kelime  Eşleştirme',
                        media: 'match'
                    },
                    {
                        type: 'fill_in_the_blanks',
                        title: 'Fill in the blanks',
                        description: 'Cümlenin herhangi bir yerini yazarak doldurma ancak burada doldurmak için kullanacağı kelimeler yazı olarak yada resim olarak liste şeklinde verilmeli',
                        media: 'fill-in-the-blanks'
                    },
                    {
                        type: 'circle_the_right_word',
                        title: 'Circle the right word',
                        description: 'Bir cümlenin içinde belli bir noktaya yerleştirilmiş hot/cold gibi iki kelimeden birini seçmek ama burada bir sese göre filans seçmesi lazım yada sadece verilenlerden seçmesi lazım',
                        media: 'circle-the-right-word'
                    },
                    {
                        type: 'true_false',
                        title: 'True-false',
                        description: 'Verilen bir cümle doğru mu yanlış mı bunu seçmek - Bu bir metnin sorusu da olabilir , bir dinlemenin de , video nun da yani bir dataya göre de doğru cevap denilebilir , yada sadece cümle olarak da ',
                        media: 'true-false'
                    },
                    {
                        type: 'scrambled_words',
                        title: 'Scrambled Words',
                        description: 'Harf sırası karışık kelimelerin harflerini sıraya sokma',
                        media: 'scrambled-words'
                    },
                    {
                        type: '',
                        title: 'Resimdekini yaz',
                        description: 'Verilen bir resimde ne olduğunu yazma , resim + yazma slotu ve doğru cevap ',
                        media: 'image-write'
                    },
                    {
                        type: 'listen_and_point',
                        title: 'Listen and point',
                        description: 'Verilen bir resimde belli bir bölgeyi , dinlediği şeye göre işaretleme ',
                        media: 'listen-and-point'
                    },
                    {
                        type: 'reading_comprehension',
                        title: 'Reading comprehension',
                        description: 'Verilen okuma parçası ile ilgili klasik soruları cevaplama',
                        media: 'reading-comprehension'
                    },
                    {
                        type: 'definition_matching',
                        title: 'Definition matching',
                        description: 'Verilen kelimeleri açıklamalarıyla eşleştirme',
                        media: 'definition-matching'
                    },
                    {
                        type: 'multiple_choice',
                        title: 'Multiple choice',
                        description: 'Doğru şıkkı işaretlame',
                        media: 'multiple-choice'
                    },
                    {
                        type: 'circle_the_different_word',
                        title: 'Circle the different word',
                        description: 'Grup şeklinde verilen kelimelerden farklı olanı yuvarlak içine alma',
                        media: 'circle-the-different-word'
                    }
                ]
            }
        },

        methods: {
            index() {

                this.pageLoading = true;

                this.$http
                    .get(this.endPoint, {
                        params: {
                            fake_url: this.exerciseEndPoint
                        }
                    })
                    .then(response => {
                        this.rows = response.body.rows;
                        this.pageLoading = false;
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            createItem() {
                this.modalVisible = true;
                this.selectedType = null;
                this.form = {
                    //
                }
            },

            storeItem() {
                this.pageLoading = true;

                this.form.fake_url = this.exerciseEndPoint;
                this.form.type = this.selectedType.type;

                this.$http
                    .post(this.endPoint, this.form)
                    .then(response => {

                        this.pageLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            editItem(item) {
                this.selectedType = this.findType(item.type);
                this.form = item;

                this.modalVisible = true;
            },

            updateItem() {
                this.pageLoading = true;

                this.form.fake_url = this.exerciseEndPoint;

                this.$http.put(this.endPoint + '/' + this.form.id, this.form)
                    .then(response => {

                        this.pageLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.pageLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete(this.endPoint + '/' + id, {
                        fake_url: this.source
                    }).then(response => {
                        this.pageLoading = false;

                        this.index();

                        this.$message({
                            type: 'success',
                            message: 'Delete completed'
                        });
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.pageLoading = false;
                });
            },

            image(pet) {

                let images = require.context('../../../../resources/images/types/', false, /\.svg$/);

                return images('./' + pet + '.svg')
            },

            findType(type) {
                return _.filter(this.types, { type : type })[0];
            }
        },

        mounted() {
            this.pageLoading = false;

            this.endPoint = this.exerciseEndPoint + '/contents';

            this.index();
        }
    }
</script>