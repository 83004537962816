const settings = {
    domain: 'https://minidil.com',
    endPoint: 'https://minidil.com/api/v1',
};

import 'es6-promise/auto'
import _ from 'lodash'
import moment from 'moment-timezone'

import Vue from 'vue'
import VueResource from 'vue-resource'
import VueI18n from 'vue-i18n'

import Element from 'element-ui'
import ElementLocale from 'element-ui/lib/locale'
import ElementLang from 'element-ui/lib/locale/lang/tr-TR'
import VueGoodTable from 'vue-good-table'
import Vuebar from 'vuebar'
import Viewer from 'v-viewer'
import vClickOutside from 'v-click-outside'
import VueResize from 'vue-resize'
import VirtualCollection from 'vue-virtual-collection'
import VueFloatLabel from 'vue-float-label'
import {StatusIndicator} from 'vue-status-indicator'
import Bars from 'vuebars'
import Trend from 'vuetrend'
import D3Network from 'vue-d3-network'
import BulmaChart from 'vue-bulma-chartjs'
import FullCalendar from 'vue-full-calendar'
import VueCodemirror from 'vue-codemirror'
import {mask} from 'vue-the-mask'
import FileManager from 'laravel-file-manager'
import HighChartsVue from 'highcharts-vue'

import Provider from './Provider.vue'
import Affix from './directives/affix'
import VuePerfectScrollbar from './components/vue-ps'
import LabelValueSelect from './components/label-value-select'
import TitleIdSelect from './components/title-id-select'
import SelectDefinition from './components/SelectDefinition'

import store from './store'
import router from './routes/'
import {Languages} from './resources/lang';
import './components'

Vue.config.performance = true;
Vue.config.productionTip = false;

Vue.directive('mask', mask);
Vue.directive('affix', Affix);

Vue.component('StatusIndicator', StatusIndicator);
Vue.component('BulmaChart', BulmaChart);
Vue.component('D3Network', D3Network);
Vue.component('vue-scroll', VuePerfectScrollbar);
Vue.component('lv-select', LabelValueSelect);
Vue.component('ti-select', TitleIdSelect);
Vue.component('select-definition', SelectDefinition);

ElementLocale.use(ElementLang);

Vue.use(Element, {ElementLocale});
Vue.use(VueGoodTable);
Vue.use(Trend);
Vue.use(Bars);
Vue.use(VueResource);
Vue.use(VueFloatLabel);
Vue.use(VirtualCollection);
Vue.use(VueResize);
Vue.use(VueI18n);
Vue.use(vClickOutside);
Vue.use(Viewer);
Vue.use(Vuebar);
Vue.use(FullCalendar);
Vue.use(HighChartsVue);
Vue.use(VueCodemirror, {
    tabSize: 4,
    styleActiveLine: true,
    lineNumbers: true,
    autoCloseTags: true,
    line: true,
    mode: 'text/html',
    theme: 'eclipse'
});

Vue.use(FileManager, {
    store,
    headers: {'Authorization': 'Bearer ' + localStorage.token},
    baseUrl: settings.endPoint + '/tools/file-manager/'
});

Vue.prototype.lodash = _;
Vue.prototype.moment = moment;

Vue.http.options.root = settings.endPoint;
Vue.http.headers.common['Authorization'] = 'Bearer ' + localStorage.token;
Vue.http.interceptors.push(function (request) {
    return function (response) {
        if (response.status === 500) {
            this.$notify.error({
                title: 'Önemli bir hata oluştu :(',
                message: 'Lütfen teknik ekip ile iletişime geçiniz.',
                position: 'top-right'
            });
        } else if (response.status === 405) {
            this.$notify.error({
                title: 'Bir hata oluştu :(',
                message: 'Lütfen kontrol ederek tekrar deneyiniz. Devam etmesi durumuunda teknik ekip ile iletişime geçiniz.',
                position: 'top-right'
            });
        }

    };
});

const numberFormats = {
    'en-Us': {
        currency: {
            style: 'currency', currency: 'TRY', symbol: '₺'
        }
    },
    'tr-TR': {
        currency: {
            style: 'currency', currency: 'TRY', symbol: '₺'
        }
    }
};

const dateTimeFormats = {
    'tr-TR': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    }
};

const i18n = new VueI18n({
    locale: 'tr-TR',
    silentTranslationWarn: true,
    messages: Languages,
    dateTimeFormats,
    numberFormats
});

Vue.mixin({
    methods: {
        convertToUTC(date) {
            date = new Date(date);
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        },
        createButton(source) {

            if (this.$route.params.type) {
                return this.$router.push('/' + source + '/' + this.$route.params.type + '/create');
            }

            return this.$router.push('/' + source + '/create');
        },
        apiSuccessMessage(response) {
            this.$notify.success({
                title: 'Başarılı',
                message: response.body.success,
                position: 'top-right'
            });
        },
        apiErrorMessage(response) {
            let message = null;

            if (!response.body) {
                return null;
            }

            if (response.status === 422) {
                message = response.body.message;
            }

            if (response.status === 500) {
                message = 'Onemli bir hata olustu, Lütfen teknik ekip ile iletişime geçiniz.';
            }

            if (_.size(response.body.errors)) {

                let notify = this.$notify;

                _.forEach(response.body.errors, function (error) {

                    notify.error({
                        title: 'Upss!',
                        message: error[0],
                        position: 'top-right'
                    });
                });

                return;
            }

            if (response.body.error) {
                message = response.body.error;
            }

            if (message) {
                this.$notify.error({
                    title: 'Upss!',
                    message: message,
                    position: 'top-right'
                });
            }
        },
        openBlankWindow(link) {
            return window.open(link, '_blank');
        },
        appCounter(items) {
            if (!items) {
                return null;
            }

            return _.size(items)
        },
        generateHashCode() {
            return (Math.random().toString(36).substring(2, 8)).toUpperCase();
        },
        getTextWidth(text, font) {
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            context.font = font ? font : 'bold 12pt arial';

            let metrics = context.measureText(text);

            return Math.ceil(metrics.width);
        },

        getDaysInMonth(month, year) {
            var date = new Date(year, month, 1);
            var days = [];
            while (date.getMonth() === month) {
                days.push(new Date(date));
                date.setDate(date.getDate() + 1);
            }
            return days;
        }
    },
    data: () => {
        return {
            account: store.state.user,
            teacher: store.state.platform.teacher.item,
            appParent: store.state.platform.parent.item,
            appStudent: store.state.platform.student.item,
            appConfig: {
                basePath: settings.endPoint,
                frontPath: settings.domain,
                predefineColors: [
                    '#ff4500',
                    '#ff8c00',
                    '#ffd700',
                    '#90ee90',
                    '#00ced1',
                    '#1e90ff',
                    '#c71585',
                    '#ffffff',
                    '#5C50B9',
                ],
            },
            appSettings: store.state.settings,
            fileManagerSettings: {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
                },
                baseUrl: settings.endPoint + '/tools/file-manager/',
                viewType: 'grid'
            },
            monthTitles: [
                {
                    id: 1,
                    title: 'Ocak',
                },
                {
                    id: 2,
                    title: 'Subat',
                },
                {
                    id: 3,
                    title: 'Mart',
                },
                {
                    id: 4,
                    title: 'Nisan',
                },
                {
                    id: 5,
                    title: 'Mayis',
                },
                {
                    id: 6,
                    title: 'Haziran',
                },
                {
                    id: 7,
                    title: 'Temmuz',
                },
                {
                    id: 8,
                    title: 'Agustos',
                },
                {
                    id: 9,
                    title: 'Eylul',
                },
                {
                    id: 10,
                    title: 'Ekim',
                },
                {
                    id: 11,
                    title: 'Kasim',
                },
                {
                    id: 12,
                    title: 'Aralik',
                }
            ],
            monthTitlesSystem: [
                {
                    id: '01',
                    title: 'Ocak',
                },
                {
                    id: '02',
                    title: 'Subat',
                },
                {
                    id: '03',
                    title: 'Mart',
                },
                {
                    id: '04',
                    title: 'Nisan',
                },
                {
                    id: '05',
                    title: 'Mayis',
                },
                {
                    id: '06',
                    title: 'Haziran',
                },
                {
                    id: '07',
                    title: 'Temmuz',
                },
                {
                    id: '08',
                    title: 'Agustos',
                },
                {
                    id: '09',
                    title: 'Eylul',
                },
                {
                    id: '10',
                    title: 'Ekim',
                },
                {
                    id: '11',
                    title: 'Kasim',
                },
                {
                    id: '12',
                    title: 'Aralik',
                }
            ]
        }
    }
});

new Vue({
    i18n,
    router,
    store,
    render: h => h(Provider)
}).$mount('#app');
