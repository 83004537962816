<template>
    <div v-loading="addressLoading">
        <div v-for="address in order.addresses" :key="address.id" v-if="address.type === alias">
            <div class="box-list-item box-list-item-title">
                <div class="name">
                    <span v-if="address.type === 'invoice'">
                        Fatura Adresi
                    </span>
                    <span v-if="address.type === 'contract'">
                        Sozlesme Adresi
                    </span>
                    <span v-if="address.type === 'delivery'">
                        Kargo Adresi
                    </span>
                </div>
                <div class="d-flex" style="width: auto">
                    <el-button icon="el-icon-edit" type="text" class="el-button-link green" @click="edit(address)"/>
                </div>
            </div>

            <div class="box-list-item">
                <div class="name fluid">
                    {{ address.title }}
                </div>
            </div>
            <div class="box-list-item">
                <div class="name fluid">
                    Ad Soyad
                </div>
                <div class="description">
                    {{ address.first_name }} {{ address.last_name }}
                </div>
            </div>
            <div class="box-list-item">
                <div class="name">
                    Cinsiyet
                </div>
                <div class="description">
                    {{ address.gender }}
                </div>
            </div>
            <div class="box-list-item">
                <div class="name">
                    T.C. No
                </div>
                <div class="description">
                    {{ address.identification_no }}
                </div>
            </div>
            <div class="box-list-item">
                <div class="name">
                    Telefon
                </div>
                <div class="description">
                    <SingleDraft :userId="order.customer_id" :receiver="address.phone" alias="sms"/>
                </div>
            </div>
            <div class="box-list-item">
                <div class="name">
                    E-Posta
                </div>
                <div class="description">
                    <SingleDraft :userId="order.customer_id" :receiver="address.email" alias="email"/>
                </div>
            </div>
            <div class="box-list-item">
                <div class="description fluid">
                    {{ address.address_1 }}
                </div>
            </div>
            <div class="box-list-item" v-if="address.address_2">
                <div class="description fluid">
                    {{ address.address_2 }}
                </div>
            </div>
            <div class="box-list-item">
                <div class="description fluid">
                    {{ address.neighborhood }}
                    {{ address.district }} -
                    {{ address.town }} /
                    {{ address.city }}
                </div>
            </div>
        </div>

        <el-dialog :title="modalTitle" :visible.sync="modalVisible" v-loading="modalLoading">
            <el-form :model="form">
                <el-form-item label="Address">
                    <el-select v-model="form.address_id" value-key="id" placeholder="Adresler" filterable clearable
                               @change="handleChangeAddress">
                        <el-option v-for="item in addresses" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>

                <AddressForm :form="form" :key="form.id + '-' + form.address_id"/>

                <el-form-item>
                    <el-switch v-model="form.is_confirmed" active-text="Onaylandi"/>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="update" type="success" class="btn-dialog">
                    <i class="mdi mdi-check"></i> Gonder
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import AddressForm from "../../../../../components/Address";
    import SingleDraft from "../../../../../components/SingleDraft";

    export default {
        name: 'Address',
        components: {
            SingleDraft,
            AddressForm
        },
        props: [
            'order',
            'alias',
        ],
        data() {
            return {
                addressLoading: false,
                endPoint: null,
                form: {
                    //
                },

                modalTitle: null,
                modalVisible: false,
                modalLoading: false,

                addresses: [],
            }
        },

        methods: {
            edit(address) {
                this.form = address;
                this.modalVisible = true;
                this.modalTitle = address.title;

                this.getAddresses();
            },

            update() {
                this.modalLoading = true;

                this.form.fake_url = this.source;

                this.$http
                    .put(this.endPoint + this.form.id, this.form)
                    .then(response => {

                        this.modalLoading = false;
                        this.modalVisible = false;

                        this.form = response.body.item;

                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });

            },

            handleChangeAddress() {
                this.modalLoading = true;
                let lastId = this.form.id;

                this.$http
                    .get('crm/addresses/' + this.form.address_id)
                    .then(response => {
                        this.modalLoading = false;

                        let address = this.form;

                        _.forEach(response.body.item, function (value, key) {

                            if (key === 'country') {
                                value = value.title;
                            }

                            if (key === 'city') {
                                value = value.title;
                            }

                            if (key === 'town') {
                                value = value.title;
                            }

                            if (key === 'district') {
                                value = value.title;
                            }

                            if (key === 'neighborhood') {
                                value = value.title;
                            }

                            if (key === 'gender') {
                                value = value.title;
                            }

                            address[key] = value;
                        });

                        console.log(lastId);

                        address.id = lastId;

                        this.form = address;
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getAddresses() {

                this.modalLoading = true;

                this.$http
                    .get('crm/addresses', {
                        params: {
                            fake_url: this.url,
                            filters: [
                                {
                                    relation: null,
                                    add_with: 'true',
                                    inputs: [
                                        {
                                            name: 'account_id',
                                            field: 'account_id',
                                            type: 'singleSelect',
                                            value: this.order.account_id
                                        }
                                    ]
                                },
                            ]
                        }
                    })
                    .then(response => {
                        this.modalLoading = false;
                        this.addresses = response.body.rows;
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },
        },

        mounted() {
            this.endPoint = 'sales/orders/' + this.order.id + '/addresses/';
        }
    }
</script>