<template>
    <section v-loading="itemLoading">
        <div class="card-box no-shadow">
            <header class="card-box-header">
                <div class="title">Siparisler</div>
            </header>
            <section class="card-box-content">
                <vue-scroll>
                    <div style="height: 300px">
                        <table class="ui table very basic table-hover middle aligned">
                            <thead>
                            <th width="1" class="text-center">
                                <div class="pl-20">
                                    Durum
                                </div>
                            </th>
                            <th>
                                Siparis
                            </th>
                            <th class="text-right">
                                Fiyat
                            </th>
                            <th width="200" class="text-right">
                                <div class="pr-20">
                                    Tarih
                                </div>
                            </th>
                            </thead>
                            <tbody>
                            <tr v-for="(item, key) in items" :key="key"
                                @click="openBlankWindow('/enrollments/orders/edit/' + item.id)" style="cursor: pointer">
                                <td class="text-right fs-14">
                                    <div class="pl-30 mt-10">
                                        <status-dot :status="item.status.status" v-if="item.status"/>
                                    </div>
                                </td>
                                <td class="fs-14">
                                    <el-popover placement="bottom-start" title="Urunler" width="700" trigger="hover">
                                        <template slot="reference">
                                            <div v-if="item.student">{{ item.student.full_name }}</div>
                                            <div>
                                                <span v-if="item.year">{{ item.year.title }}</span>
                                                <span v-if="item.period">{{ item.period.title }}</span>
                                            </div>
                                        </template>
                                        <div>
                                            <table class="ui table very basic table-hover">
                                                <thead>
                                                <th>
                                                    Urun
                                                </th>
                                                <th width="150" class="text-right">
                                                    Fiyat
                                                </th>
                                                </thead>
                                                <tbody>
                                                <tr v-for="product in item.products" :key="product.id">
                                                    <td>
                                                        <div>
                                                            <b>{{ product.title }}</b>
                                                        </div>
                                                        <div
                                                            v-if="(product.alias === 'classrooms' || product.alias === 'products') && product.content">
                                                            {{ product.content.category_text }}
                                                        </div>
                                                        <div v-if="product.alias === 'vehicles'">
                                                            <div>
                                                                <span v-if="product.address_type === 'service_get'">Alinacak</span>
                                                                <span
                                                                    v-if="product.address_type === 'service_delivery'">Birakilacak</span>
                                                            </div>
                                                            <div v-if="product.address">{{
                                                                    product.address.title
                                                                }}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="quantity"
                                                             v-if="product.alias === 'classrooms' || product.alias === 'products'">
                                                            {{ product.quantity }} Adet
                                                        </div>
                                                        <div class="quantity" v-if="product.alias === 'vehicles'">
                                                            {{ product.quantity / 2 }} Ay
                                                        </div>
                                                        <div class="price">
                                                            {{ $n(product.price, 'currency') }}
                                                        </div>
                                                        <div class="price">
                                                            {{ $n(product.total, 'currency') }}
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </el-popover>

                                </td>
                                <td class="text-right fs-14">
                                    <div>{{ $n(item.discount_total, 'currency') }}</div>
                                    <div>{{ $n(item.total, 'currency') }}</div>
                                </td>
                                <td class="text-right fs-14">
                                    <div style="padding-right: 20px" class="mt-10">
                                        {{ $d(new Date(item.created_at), 'long', 'tr-TR') }}
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </vue-scroll>
            </section>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Orders',
    props: ['source', 'customer'],
    data() {
        return {
            itemLoading: true,
            items: {},
            form: {
                account_id: this.accountId
            },
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        index() {
            this.itemLoading = true;

            this.$http.get(this.source, {
                params: {
                    fake_url: this.source,
                    filters: [
                        {
                            relation: null,
                            add_with: 'true',
                            inputs: [
                                {
                                    name: 'account_id',
                                    field: 'account_id',
                                    type: 'singleSelect',
                                    value: this.customer.account_id
                                }
                            ]
                        },
                    ],
                    sorts: {
                        created_at: 'desc'
                    },
                    withs: {
                        'products': 'products',
                        'products.content': 'products.content',
                        'products.address': 'products.address',
                    }
                }
            }).then(response => {
                this.items = response.body.rows;
                this.itemLoading = false;
            });
        }

    }
}
</script>
