<template>
    <div class="loading-container" v-loading="pageLoading">
        <div class="card-box">
            <div class="card-box-header">
                <div class="title">
                    Alistirmalar
                </div>
                <div class="actions">
                    <el-tooltip content="Olustur" effect="dark" placement="bottom">
                        <el-button type="text" icon="el-icon-plus" class="el-button-link primary"
                                   @click="createItem"/>
                    </el-tooltip>
                </div>
            </div>
            <div class="card-box-content">
                <Empty :items="rows" />

                <div class="box-list">
                    <div class="box-list-item" v-for="row in rows" :key="row.id">
                        <div class="fluid d-flex" style="align-items: center">
                            <div class="row-number">
                                <div>{{ row.sort }}</div>
                            </div>
                            <div>
                                <div class="fw-500 fs-16">
                                    {{ row.title }}
                                </div>
                            </div>
                        </div>
                        <div class="ml-20">
                            <div class="d-flex" style="justify-content: end">
                                <el-tooltip content="Icerikler" effect="dark" placement="bottom">
                                    <el-button type="text" class="el-button-link blue" icon="mdi mdi-menu" @click="openContentModal(row)"/>
                                </el-tooltip>
                                <el-tooltip content="Duzenle" effect="dark" placement="bottom">
                                    <el-button type="text" class="el-button-link green" icon="el-icon-edit" @click="editItem(row)"/>
                                </el-tooltip>
                                <el-tooltip content="Sil" effect="dark" placement="bottom">
                                    <el-button type="text" class="el-button-link red" icon="el-icon-delete" @click="destroyItem(row.id)"/>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog title="Alistirma Bilgileri" :visible.sync="modalVisible" v-loading="pageLoading" width="85%" append-to-body>
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Etiketler">
                    <select-definition label="Etiketler" alias="skills" model="skills" :container="form" :multiple="true"/>
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="18">
                        <el-form-item label="Başlık">
                            <el-input v-model="form.title" placeholder="Başlık"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="Sıralama">
                            <el-input-number v-model="form.sort" placeholder="Sıralama"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Açıklama">
                    <trumbowyg v-model="form.short_description" :config="$store.state.trumbowygConfig"/>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <div class="ui button labeled icon green fluid" v-if="!form.id" @click="storeItem">
                    <i class="el-icon-check icon"></i> Kaydet
                </div>
                <div class="ui button labeled icon green fluid" v-if="form.id" @click="updateItem">
                    <i class="el-icon-check icon"></i> Guncelle
                </div>
            </div>
        </el-dialog>

        <el-dialog :title="form.title" :visible.sync="contentModalVisible" width="90%" append-to-body>
            <Content
                    :exercise="form"
                    :indexModalClose="closeContentModal"
                    :exerciseEndPoint="endPoint + '/' + form.id"
                    :key="form.id"
                    v-if="contentModalVisible"
            />
        </el-dialog>
    </div>
</template>

<script>
    import Trumbowyg from "vue-trumbowyg/src/component";
    import Empty from "../../../../components/Empty";
    import SelectDefinition from "../../../../components/SelectDefinition";
    import Content from "./Content";

    export default {
        name: 'Exercise',
        components: {
            Content,
            SelectDefinition,
            Empty,
            Trumbowyg
        },
        props : [
            'unit',
            'unitEndPoint',
            'closeExerciseModal',
        ],
        data() {
            return {
                endPoint: null,
                pageLoading: true,
                modalVisible: false,
                rows : [],
                form : {
                    //
                },
                contentModalVisible : false,
            }
        },

        methods: {
            index() {

                this.pageLoading = true;

                this.$http
                    .get(this.endPoint, {
                        params: {
                            fake_url: this.unitEndPoint
                        }
                    })
                    .then(response => {
                        this.rows = response.body.rows;
                        this.pageLoading = false;
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            createItem() {
                this.modalVisible = true;
                this.form = {
                    //
                }
            },

            storeItem() {
                this.pageLoading = true;

                this.form.fake_url = this.unitEndPoint;

                this.$http
                    .post(this.endPoint, this.form)
                    .then(response => {

                        this.pageLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            editItem(item) {
                this.modalVisible = true;

                let skills = [];

                _.forEach(item.skills, (skill) => {
                    if (skill.skill_definition_id) {
                        skills.push(skill.skill_definition_id);
                    } else {
                        skills.push(skill);
                    }
                });

                item.skills = skills;

                this.form = item;
            },

            updateItem() {
                this.pageLoading = true;

                this.form.fake_url = this.unitEndPoint;

                this.$http.put(this.endPoint + '/' + this.form.id, this.form)
                    .then(response => {

                        this.pageLoading = false;
                        this.modalVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.modalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.pageLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete(this.endPoint + '/' + id, {
                        fake_url: this.source
                    }).then(response => {
                        this.pageLoading = false;

                        this.index();

                        this.$message({
                            type: 'success',
                            message: 'Delete completed'
                        });
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.pageLoading = false;
                });
            },

            openContentModal(item) {
                this.form = item;
                this.contentModalVisible = true;
            },

            closeContentModal() {
                this.contentModalVisible = false;
                this.form = {
                    //
                }
            }
        },

        mounted() {
            this.endPoint = this.unitEndPoint + '/exercises';

            this.index();
        }
    }
</script>