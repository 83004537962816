<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading">
            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                            <el-button @click="$router.go(-1)" type="text" icon="el-icon-back"
                                       class="el-button-link grey"/>
                        </el-tooltip>

                        <el-tooltip content="Olustur" effect="dark" placement="bottom" v-if="$route.params.id">
                            <el-button @click="createButton($route.meta.end_point)" type="text" icon="el-icon-plus"
                                       class="el-button-link primary"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="!$route.params.id">
                            <el-button @click="storeItem" type="text" icon="el-icon-check"
                                       class="el-button-link green"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="$route.params.id">
                            <el-button @click="updateItem" type="text" icon="el-icon-check"
                                       class="el-button-link green"/>
                        </el-tooltip>
                    </div>
                </header>
                <section class="card-box-content padding-20">
                    <el-form :label-position="'top'" ref="form" :model="form">

                        <el-form-item>
                            <el-switch v-model="form.is_active" active-text="Aktif"/>
                        </el-form-item>

                        <el-form-item label="Başlık">
                            <el-input v-model="form.metas.title" placeholder="Başlık"/>
                        </el-form-item>

                        <div v-if="form.alias !== 'modules'">
                            <div v-for="input in form.inputs" :key="input.name">

                                <el-form-item :label="input.label" v-if="input.name !== 'row'">
                                    <el-input v-if="input.type === 'text'" type="textarea" :autosize="{ minRows: 3, maxRows: 3}" v-model="form.metas[input.name]" :placeholder="input.label"/>
                                    <el-date-picker v-if="input.type === 'date'" v-model="form.metas[input.name]" type="datetime" placeholder="Tarih Seçiniz" value-format="yyyy-MM-dd HH:mm:ss" />
                                </el-form-item>

                                <div v-if="input.name === 'row'">
                                    <div class="card-base card-shadow--medium">
                                        <table class="el-table ui table">
                                            <thead>
                                            <th style="padding-right: 20px" v-for="row in input.inputs">
                                                {{ row.label }}
                                            </th>
                                            <th width="140" class="text-right">
                                                <el-button type="primary" icon="el-icon-plus" size="mini"
                                                           @click="createRowItem(input.inputs)"/>
                                            </th>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(itemRow, itemRowKey) in form.rows">
                                                <td v-for="row in input.inputs" style="padding-right: 20px">
                                                    <span v-if="row.type === 'image'">
                                                        <img :src="appConfig.frontPath + '/' + itemRow[row.name]" width="75">
                                                    </span>
                                                    <span v-if="row.type !== 'image'">
                                                        {{ itemRow[row.name] }}
                                                    </span>
                                                </td>
                                                <td class="text-right">
                                                    <el-button type="primary" icon="el-icon-edit" size="mini"
                                                               @click="editRowItem(itemRowKey, itemRow, input.inputs)"/>
                                                    <el-button type="danger" icon="el-icon-delete" size="mini"
                                                               @click="destroyRowItem(itemRowKey)"/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </el-form>
                </section>
            </div>

            <Blocks :source="endPoint + '/' + form.id" :name="form.name"  v-if="form.alias === 'modules'"/>
        </div>

        <el-dialog :title="modalTitle" :visible.sync="modalVisible" v-loading="modalLoading">
            <el-form :model="rowForm">
                <div v-for="input in rowInputs" :key="input.name">
                    <el-form-item :label="input.label" v-if="input.type === 'image'">
                        <file-browser v-model="rowForm[input.name]"/>
                    </el-form-item>

                    <el-form-item :label="input.label" v-if="input.type !== 'image'">
                        <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 3}" v-model="rowForm[input.name]"
                                  :placeholder="input.label"/>
                    </el-form-item>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="storeRowForm" v-if="!modalData" style="width: 100%">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" size="medium" @click="updateRowItem" v-if="modalData" style="width: 100%">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </span>
        </el-dialog>
    </vue-scroll>
</template>

<script>
    import Blocks from "./Blocks";
    import FileBrowser from "../../../../components/FileBrowser";

    export default {
        name: 'ElementForm',
        components: {
            FileBrowser,
            Blocks,

        },
        props: ['source'],
        data() {
            return {
                itemLoading: true,
                endPoint: null,
                modalData: null,
                modalTitle: 'Ekle',
                modalVisible: false,
                modalLoading: true,
                form: {
                    metas: {},
                    rows: [],
                },
                rowFormKey: {},
                rowForm: {},
                rowInputs: {}
            }
        },

        methods: {
            loadItem() {

                this.itemLoading = true;

                this.$http
                    .get(this.endPoint + '/' + this.$route.params.id, {
                        params: this.serverParams
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body.item;

                        if (!_.size(this.form.metas)) {
                            this.form.metas = {}
                        }

                        if (!_.size(this.form.rows)) {
                            this.form.rows = []
                        }
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.itemLoading = true;

                this.$http
                    .put(this.endPoint + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            createRowItem(inputs) {
                this.rowInputs = inputs;
                this.rowForm = {};
                this.rowFormKey = null;
                this.modalVisible = true;
            },

            editRowItem(key, rowForm, inputs) {
                this.rowInputs = inputs;
                this.rowForm = rowForm;
                this.rowFormKey = key;
                this.modalVisible = true;
                this.modalTitle = 'Duzenle';
                this.modalData = true;
            },

            storeRowForm() {
                this.form.rows.push(this.rowForm);
                this.rowInputs = [];
                this.rowForm = {};
                this.modalVisible = false;
                this.modalData = false;
            },

            updateRowItem() {
                this.form.rows[this.rowFormKey] = this.rowForm;
                this.rowInputs = [];
                this.rowForm = {};
                this.modalVisible = false;
                this.modalData = false;
                this.rowFormKey = null;
            },

            destroyRowItem(key) {
                this.form.rows.splice(key, 1);
            }
        },

        mounted() {
            this.endPoint = this.$route.meta.end_point + this.$route.params.type;

            this.loadItem();
        }
    }
</script>