<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading">
            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                            <el-button @click="$router.go(-1)" type="text" icon="el-icon-back"
                                       class="el-button-link grey"/>
                        </el-tooltip>

                        <el-tooltip content="Olustur" effect="dark" placement="bottom" v-if="$route.params.id">
                            <el-button @click="createButton($route.meta.end_point)" type="text" icon="el-icon-plus"
                                       class="el-button-link primary"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="!$route.params.id">
                            <el-button @click="storeItem" type="text" icon="el-icon-check"
                                       class="el-button-link green"/>
                        </el-tooltip>

                        <el-tooltip content="Kaydet" effect="dark" placement="bottom" v-if="$route.params.id">
                            <el-button @click="updateItem" type="text" icon="el-icon-check"
                                       class="el-button-link green"/>
                        </el-tooltip>
                    </div>
                </header>
                <section class="card-box-tabs padded small">
                    <el-form :label-position="'top'" ref="form" :model="form">
                        <el-tabs :tab-position="'top'">
                            <el-tab-pane label="Reklam">
                                <el-form-item label="Ad">
                                    <el-input v-model="form.title" placeholder="Ad"/>
                                </el-form-item>

                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <el-form-item label="Baslangic Tarihi">
                                            <el-date-picker v-model="form.start_at" type="datetime" placeholder="Tarih Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Bitis Tarihi">
                                            <el-date-picker v-model="form.end_at" type="datetime" placeholder="Tarih Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-form-item label="Gorunum">
                                    <el-select v-model="form.layout_key" placeholder="Gorunum" filterable clearable>
                                        <el-option
                                                v-for="item in layouts"
                                                :key="item.id"
                                                :label="item.title"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>

                                <el-row :gutter="24">
                                    <el-col :span="18">
                                        <el-form-item label="Baslik">
                                            <el-input v-model="form.public_title" placeholder="Baslik"/>
                                        </el-form-item>

                                        <el-form-item label="Açıklama">
                                            <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 8}" placeholder="Açıklama"
                                                      v-model="form.description"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="Reklam">
                                            <file-browser v-model="form.file_path"/>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-form-item>
                                    <el-switch v-model="form.is_active" active-text="Aktif"/>
                                </el-form-item>
                            </el-tab-pane>

                            <el-tab-pane label="Linkler">

                                <el-form-item label="Tumunu Listele Link Basligi">
                                    <el-input v-model="form.list_link_title"
                                              placeholder="Tumunu Listele Link Basligi"/>
                                </el-form-item>

                                <el-form-item label="Tumunu Listele Linki">
                                    <el-input v-model="form.list_link" placeholder="Tumunu Listele Linki"/>
                                </el-form-item>

                                <el-form-item label="Detay Link Basligi">
                                    <el-input v-model="form.detail_link_title" placeholder="Detay Link Basligi"/>
                                </el-form-item>

                                <el-form-item label="Detay Linki">
                                    <el-input v-model="form.detail_link" placeholder="Detay Linki"/>
                                </el-form-item>
                            </el-tab-pane>

                            <el-tab-pane label="Kategoriler">
                                <el-transfer
                                        filterable
                                        :filter-method="filterMethod"
                                        filter-placeholder="Kategoriler de Ara"
                                        :titles="['Tüm Kategoriler', 'Seçili Olanlar']"
                                        v-model="form.categories"
                                        :props="{key: 'id', label: 'nest_title'}"
                                        :data="categories">
                                </el-transfer>
                            </el-tab-pane>
                        </el-tabs>
                    </el-form>
                </section>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
    import FileBrowser from "../../../components/FileBrowser";

    export default {
        name: 'ElementForm',
        components: {FileBrowser},
        data() {
            return {
                itemLoading: true,
                form: {
                    file_path: null,
                    categories: []
                },
                categories: [],
                layouts: [
                    {
                        id: 'index',
                        title: 'Listeleme Sayfasinda',
                    },
                    {
                        id: 'show',
                        title: 'Detay Sayfasinda',
                    }
                ],
                filterMethod(query, item) {
                    return item.title.toLowerCase().indexOf(query.toLowerCase()) > -1;
                },
            }
        },

        methods: {
            loadItem() {

                this.itemLoading = true;

                this.$http
                    .get(this.$route.meta.end_point + '/' + this.$route.params.id, {
                        params: this.serverParams
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body.item
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            storeItem() {

                this.itemLoading = true;

                this.$http
                    .post(this.$route.meta.end_point, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                        this.$router.push('edit/' + response.body.item.id);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.itemLoading = true;

                this.$http
                    .put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                    confirmButtonText: 'Tamam',
                    cancelButtonText: 'Iptal Et',
                    type: 'warning'
                })
                    .then(() => {
                        this.$http
                            .delete(this.$route.meta.end_point + '/' + id)
                            .then(response => {
                                this.itemLoading = false;

                                this.$router.push('/');

                                this.apiSuccessMessage(response)
                            })
                            .catch((e) => {
                                this.itemLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            },

            getCategories() {
                this.itemLoading = true;

                this.$http
                    .get('tools/search/categories')
                    .then(response => {
                        this.itemLoading = false;
                        this.categories = response.body.items;
                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            },
        },

        mounted() {
            if (this.$route.params.id) {
                this.loadItem()
            } else {
                this.itemLoading = false;
            }

            this.getCategories();
        }
    }
</script>