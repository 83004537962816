<template>
    <div class="card-box">
        <header class="card-box-header">
            <div class="title">Iletisim Bilgileri</div>
            <div class="actions">
                <el-tooltip content="Yeni Iletisim Bilgisi Ekle">
                    <el-button icon="el-icon-plus" size="small" type="primary" @click="create"/>
                </el-tooltip>
            </div>
        </header>
        <section class="card-box-content">
            <div class="box-list address-list-container">
                <div class="box-list-item" v-for="item in items" :key="item.id">
                    <div class="fluid cursor-pointer" @click="edit(item)">
                        <div><b>{{ item.title }}</b></div>
                        <div class="fs-12">{{ item.first_name }} {{ item.last_name }}</div>
                        <div class="fs-12">{{ item.phone }}</div>
                        <div class="fs-12">{{ item.address_1 }}</div>
                    </div>
                </div>
            </div>
        </section>

        <el-dialog title="Adres Ekle" :visible.sync="createDialogVisible" v-loading="dialogLoading" append-to-body>
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Adresten Doldur" v-if="!form.id">
                    <ti-select :container="form" model="id" :options="items" :change="handleCreateInitAddress"/>
                </el-form-item>

                <div v-if="form.id">
                    <AddressForm :form="form"/>

                    <el-button type="success" size="medium" @click="store" class="fluid">
                        <i class="mdi mdi-check"></i> Kaydet
                    </el-button>
                </div>
            </el-form>
        </el-dialog>

        <el-dialog title="Adres Bilgileri" :visible.sync="editDialogVisible" v-loading="dialogLoading" append-to-body>
            <el-form :label-position="'top'" ref="form" :model="form">
                <AddressForm :form="form"/>

                <el-button type="success" size="medium" @click="update" class="fluid">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import AddressForm from "@/components/Address";

export default {
    name: 'Addresses',
    components: {AddressForm},
    props: [
        'source',
        'accountId'
    ],
    data() {
        return {
            pageLoading: true,
            form: {
                //
            },

            items: [],

            dialogLoading: false,
            createDialogVisible: false,
            editDialogVisible: false,
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        index() {

            this.pageLoading = true;

            this.$http
                .get('crm/accounts/' + this.accountId + '/addresses', {
                    params: {
                        fake_url: this.source,
                    }
                })
                .then(response => {
                    this.items = response.body.rows;
                    this.pageLoading = false;
                });
        },

        create() {
            this.form = {
                //
            };

            this.createDialogVisible = true;
        },

        edit(item) {
            this.form = item;

            this.editDialogVisible = true;
        },

        store() {
            this.dialogLoading = true;

            this.form.fake_url = this.source;

            this.$http
                .post('crm/accounts/' + this.accountId + '/addresses', this.form)
                .then(response => {

                    this.dialogLoading = false;
                    this.createDialogVisible = false;

                    this.apiSuccessMessage(response);
                    this.index();
                })
                .catch((e) => {
                    this.dialogLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        update() {
            this.dialogLoading = true;

            this.form.fake_url = this.source;

            this.$http
                .put('crm/accounts/' + this.accountId + '/addresses/' + this.form.id, this.form)
                .then(response => {

                    this.dialogLoading = false;
                    this.editDialogVisible = false;

                    this.apiSuccessMessage(response);
                    this.index();
                })
                .catch((e) => {
                    this.dialogLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        destroy(id) {

            this.pageLoading = true;

            this
                .$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                })
                .then(() => {
                    this.$http
                        .delete('crm/accounts/' + this.accountId + '/addresses/' + id, {
                            fake_url: this.source
                        })
                        .then(response => {
                            this.pageLoading = false;

                            this.index();

                            this.$message({
                                type: 'success',
                                message: 'Delete completed'
                            });
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.pageLoading = false;
                });
        },

        handleCreateInitAddress() {

            let item = _.filter(this.items, (o) => {
                return o.id === this.form.id
            });

            this.form = JSON.parse(JSON.stringify(item[0]));
            this.form.title = 'Yeni Adres'
            this.form.is_main = false;
        }
    }
}
</script>
