<style lang="scss">
    .student-checklist {
        display: flex;
        align-items: center;
        border-radius: 4px;
        height: 70px;
        border: 1px solid rgba(92, 80, 185, 0.2);

        div {
            width: 80px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }

            &:last-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }

            &.green {
                background: rgba(40, 167, 69, 0.2);
                color: #fff;

                &:hover, &.active {
                    background: rgba(40, 167, 69, 1);
                    color: #fff;
                }
            }

            &.red {
                background: rgba(220, 53, 69, 0.2);
                color:#fff;

                &:hover, &.active {
                    background: rgba(220, 53, 69, 1);
                    color: #fff;
                }
            }

            &.grey {
                background: #e0e1e2;
                color: rgba(0, 0, 0, .6);

                &:hover {
                    background: rgba(0, 0, 0, .6);
                    color: #fff;
                }
            }
        }
    }
</style>

<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="pageLoading">
            <el-row :gutter="24">
                <el-col :span="8" v-for="item in items" :key="item.id">
                    <div class="card-box">
                        <header class="card-box-header">
                            <div class="title">
                                {{ item.title }}
                            </div>
                            <div class="actions">
                                <div class="badge bg-green">
                                    {{ counter(item.students) }}
                                </div>
                            </div>
                        </header>
                        <section class="card-box-content">
                            <vue-scroll>
                                <div class="box-list" style="height: 425px">
                                    <div class="box-list-item" v-for="student in item.students" :key="student.id"
                                         @click="goStudent(student)" style="cursor: pointer">
                                        <div class="fluid d-flex" style="align-items: center">
                                            <div class="mr-10">
                                                <avatar :img="student.avatar" type="student" class="ui image tiny"/>
                                            </div>
                                            <div>
                                                {{ student.first_name }} {{ student.last_name }}
                                                <div class="rate">
                                                    <el-rate v-model="student.loyalty_year > 3 ? 3 : student.loyalty_year" :max="3" disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </vue-scroll>
                        </section>
                    </div>
                </el-col>
            </el-row>

            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        Ders Programi
                    </div>
                    <el-form class="actions" ref="form" :model="filterForm">
                        <el-form-item class="mt-0 mb-0 mr-20">
                            <el-select v-model="filterForm.room_content_id" value-key="id" placeholder="Odalar"
                                       clearable filterable @change="initFormFilter">
                                <el-option v-for="item in rooms" :key="item.id" :label="item.title" :value="item.id"/>
                            </el-select>
                        </el-form-item>
                        <el-form-item class="m-0">
                            <el-select v-model="filterForm.classroom_content_id" value-key="id" placeholder="Siniflar"
                                       clearable filterable @change="initFormFilter">
                                <el-option v-for="item in user.classrooms" :key="item.id" :label="item.content.title"
                                           :value="item.id"/>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </header>
                <section class="card-box-content">
                    <div id="cal"></div>
                </section>
            </div>
        </div>

        <el-dialog v-loading="pageLoading" title="Program Bilgileri" :visible.sync="modalVisible" width="80%">
            <el-form :model="form" v-if="form.id" :key="form.id">
                <div class="card-box">
                    <header class="card-box-header">
                        <div class="title" :style="{color : form.color}">
                            [{{ form.room.title }}] {{ form.classroom.title }}
                        </div>
                        <div class="actions" style="align-items: center">
                            <div class="mr-20">{{ $d(new Date(form.start_at), 'long', 'tr-TR') }}</div>
                            <div class="mr-20">{{ $d(new Date(form.end_at), 'long', 'tr-TR') }}</div>
                        </div>
                    </header>
                    <section class="card-box-content card-box-tabs">
                        <el-tabs :tab-position="'top'">
                            <el-tab-pane label="Yoklama">
                                <div class="card-box-save" style="margin: 0" @click="updateItem">
                                    <div class="box-container">
                                        <i class="el-icon-check"></i>
                                    </div>
                                </div>
                                <div class="box-list">
                                    <div class="box-list-item" v-for="(student, key) in form.students" :key="form.id + '-' + key">
                                        <div class="fluid d-flex" style="align-items: center">
                                            <div class="mr-10">
                                                <avatar :img="student.avatar" type="student" class="ui image tiny"/>
                                            </div>
                                            <div>
                                                <div>
                                                    {{ student.first_name }} {{ student.last_name }}
                                                </div>
                                                <div class="rate">
                                                    <el-rate v-model="student.loyalty_year > 3 ? 3 : student.loyalty_year" :max="3" disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ml-30">
                                            <div class="student-checklist">
                                                <div class="green" @click="student.status = 1" :class="{'active' : student.status === 1}">
                                                    <i class="el-icon-check fs-24"></i>
                                                </div>
                                                <div class="red" @click="student.status = 0"  :class="{'active' : student.status === 0}">
                                                    <i class="el-icon-delete fs-24"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Odevler">
                                <Homeworks :event="form" />
                            </el-tab-pane>
                        </el-tabs>
                    </section>
                </div>
            </el-form>
        </el-dialog>
    </vue-scroll>
</template>

<script>
    import {mapState} from 'vuex';
    import { Calendar } from '@fullcalendar/core';
    import dayGridPlugin from '@fullcalendar/daygrid';
    import timeGridPlugin from '@fullcalendar/timegrid';
    import listPlugin from '@fullcalendar/list';


    import Homeworks from "./_parts/Homeworks";

    export default {
        name: 'ClassroomIndex',
        components: {
            Homeworks,
        },
        computed: mapState([
            'user',
            'platform'
        ]),
        data() {
            return {
                pageLoading: false,
                form: {
                    //
                },

                items: [],
                rooms: [],

                filterForm: {
                    room_content_id: null,
                    classroom_content_id: null,
                },
                modalVisible: false,
                calendar: null,
                options: {
                    events: {
                        type: 'GET',
                        url: this.$http.options.root + '/school/programs/rooms',
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.token
                        },
                        data: {
                            filters: [
                                {
                                    relation: 'teachers',
                                    add_with: 'true',
                                    inputs: [
                                        {
                                            name: 'teacher_user_id',
                                            field: 'teacher_user_id',
                                            type: 'singleSelect',
                                            value: null
                                        }
                                    ]
                                },
                                {
                                    relation: null,
                                    inputs: [
                                        {
                                            name: 'room_content_id',
                                            field: 'room_content_id',
                                            type: 'singleSelect',
                                            value: null
                                        }
                                    ]
                                },
                                {
                                    relation: null,
                                    inputs: [
                                        {
                                            name: 'classroom_content_id',
                                            field: 'classroom_content_id',
                                            type: 'singleSelect',
                                            value: null
                                        }
                                    ]
                                }
                            ]
                        },
                    },
                },
            }
        },

        methods: {
            goStudent(student) {
                return this.$router.push('classrooms/students/show/' + student.id);
            },

            counter(items) {
                return _.size(items);
            },

            index() {

                this.pageLoading = true;

                this.$http
                    .get('platform/teacher/students', {
                        params: {
                            products: this.user.classroomIds
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.items = response.body.rows
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getRooms() {
                this.pageLoading = true;

                this.$http
                    .get('school/rooms', {
                        params: {
                            perPage: 100
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.rooms = response.body.rows
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.pageLoading = true;

                this.$http
                    .put('school/programs/rooms/' + this.form.id, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.modalVisible = false;

                        this.cal.fullCalendar('refetchEvents');

                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            initFormFilter() {
                this.options.events.data.filters[0].inputs[0].value = this.user.id;
                this.options.events.data.filters[1].inputs[0].value = this.filterForm.room_content_id;
                this.options.events.data.filters[2].inputs[0].value = this.filterForm.classroom_content_id;

                this.cal.fullCalendar('refetchEvents');
            },

            handleEventClick(event) {

                alert('Clicked on');

                this.form = {};

                let items = Object.seal(this.items);
                let classroom = _.filter(items, {'content_id': event.classroom_content_id});

                if (_.size(classroom) >= 1) {
                    classroom = classroom[0]
                } else {
                    return;
                }

                _.forEach(classroom.students, (student, studentKey) => {

                    let programStudent = _.filter(event.students, {student_id : student.student_id});

                    if (_.size(programStudent) >= 1) {
                        programStudent = programStudent[0]
                    } else {
                        programStudent = null;
                    }

                    if (programStudent) {
                        classroom.students[studentKey].status = programStudent.status;
                    } else {
                        classroom.students[studentKey].status = null;
                    }
                });

                let teachers = [];

                _.forEach(event.teachers, function (item) {
                    teachers.push(item.teacher_user_id);
                });

                this.form = {
                    id: event.id,
                    room_content_id: event.room_content_id,
                    classroom_content_id: event.classroom_content_id,
                    room: event.room,
                    classroom: event.classroom,
                    color: event.color,
                    title: event.title,
                    start_at: event.start_at,
                    end_at: event.end_at,
                    teachers: teachers,
                    students: classroom.students,
                };

                this.modalVisible = true;
            },

            initCalendar() {
                this.calendar = new Calendar(document.getElementById('cal'), {
                    plugins: [ dayGridPlugin, timeGridPlugin, listPlugin ],
                    loading: (isLoading) => {
                        if (isLoading) {
                            this.pageLoading = true
                        } else {
                            this.pageLoading = false
                        }
                    },
                    header: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    },
                    height: 'auto',
                    firstDay: 1,
                    allDaySlot: true,
                    slotEventOverlap: true,
                    selectable: true,
                    selectHelper: true,
                    timeFormat: 'HH:mm',
                    navLinks: true, // can click day/week names to navigate views
                    editable: true,
                    eventLimit: true, // allow "more" link when too many events
                    events: {
                        type: 'GET',
                        url: this.$http.options.root + '/school/programs/rooms',
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.token
                        },
                        data: {
                            filters: [
                                {
                                    relation: 'teachers',
                                    add_with: 'true',
                                    inputs: [
                                        {
                                            name: 'teacher_user_id',
                                            field: 'teacher_user_id',
                                            type: 'singleSelect',
                                            value: null
                                        }
                                    ]
                                },
                                {
                                    relation: null,
                                    inputs: [
                                        {
                                            name: 'room_content_id',
                                            field: 'room_content_id',
                                            type: 'singleSelect',
                                            value: null
                                        }
                                    ]
                                },
                                {
                                    relation: null,
                                    inputs: [
                                        {
                                            name: 'classroom_content_id',
                                            field: 'classroom_content_id',
                                            type: 'singleSelect',
                                            value: null
                                        }
                                    ]
                                }
                            ]
                        },
                    },
                    eventClick: this.handleEventClick,
                    startParam: 'start_at',
                    endParam: 'end_at',
                    timezone: 'UTC',
                    eventRender: function(event, element) {
                        element.find('.fc-title').html(event.title_format);
                    },
                    dateClick: function(info) {
                        alert('clicked ' );
                    },
                });

                this.calendar.render();
            }
        },

        mounted() {
            this.index();
            this.getRooms();

            this.options.events.data.filters[0].inputs[0].value = this.user.id;

            this.initCalendar();
        }
    }
</script>