<template>
    <vue-scroll class="page-element-form" v-loading="itemLoading">
        <div class="loading-container">
            <div v-if="form.id">
                <div class="card-box">
                    <header class="card-box-header">
                        <div class="title">
                            {{ form.title }}
                        </div>
                        <div class="actions">
                            <el-button icon="el-icon-edit" type="text" class="el-button-link green"
                                       @click="modalVisible = true"/>
                        </div>
                    </header>
                </div>

                <Periods :itemId="form.id"/>

                <el-row :gutter="24">
                    <el-col :span="14">
                        <Zones :url="$route.meta.end_point + '/' + form.id" :itemId="form.id"/>
                    </el-col>
                    <el-col :span="10">
                        <Notes :url="$route.meta.end_point + '/' + form.id"/>
                        <Activities :source="$route.meta.end_point + '/' + form.id"/>
                    </el-col>
                </el-row>

                <el-dialog :title="form.title" :visible.sync="modalVisible" v-loading="itemLoading" width="60%">
                    <el-form :label-position="'top'" ref="form" :model="form">
                        <el-form-item label="Plaka">
                            <el-input v-model="form.title" placeholder="Plaka"/>
                        </el-form-item>

                        <el-form-item>
                            <el-switch v-model="form.is_active" active-text="Aktif"/>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="success" size="medium" @click="updateItem" v-if="form.id" class="fluid">
                            <i class="mdi mdi-check"></i> Guncelle
                        </el-button>
                    </div>
                </el-dialog>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
    import Periods from "./_parts/Periods";
    import Notes from "../../../../components/Notes";
    import Activities from "../../../../components/Activities";
    import Zones from "./_parts/Zones";

    export default {
        name: 'ElementForm',
        components: {
            Zones,
            Activities,
            Notes,
            Periods,

        },
        data() {
            return {
                itemLoading: true,
                form: {
                    title: null,
                    seo: {},
                    metas: {},
                    file_path: [],
                },

                modalVisible: false,
            }
        },

        methods: {

            toggleInfoWindow: function(marker, idx) {
                this.infoWindowPos = marker.position;
                this.infoContent = marker.infoText;

                //check if its the same marker that was selected if yes toggle
                if (this.currentMidx == idx) {
                    this.infoWinOpen = !this.infoWinOpen;
                }
                //if different marker set infowindow to open and reset current marker index
                else {
                    this.infoWinOpen = true;
                    this.currentMidx = idx;

                }
            },

            loadItem() {

                this.itemLoading = true;

                this.$http
                    .get(this.$route.meta.end_point + '/' + this.$route.params.id)
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body.item;

                        if (!this.form.metas.lenght) {
                            this.form.metas = {}
                        }
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.itemLoading = true;

                this.form.seo = {
                    meta_title: this.form.title,
                    meta_description: this.form.title,
                    meta_keywords: this.form.title,
                };

                this.$http
                    .put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.modalVisible = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            }
        },

        mounted() {
            this.loadItem()
        }
    }
</script>