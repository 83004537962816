<template>
    <div class="page-vue-good-table scrollable only-y">
        <page-header/>
        <router-view :key="$route.fullPath"/>
    </div>
</template>

<script>
    import PageHeader from "../../components/PageHeader";

    export default {
        name: 'Page',
        components: {
            PageHeader
        }
    }
</script>