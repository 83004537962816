<style lang="scss" scoped>
    .success-row {
        background: #f0f9eb;
    }
</style>

<template>
    <div v-loading="itemLoading">
        <div class="box-list-item box-list-item-title">
            <div class="name fluid">
                Odeme Takvimi ({{ paymentCount }} Taksit)
            </div>
            <div class="description d-flex" style="width: auto">
                <el-tooltip content="Is Bankasi Kmh Hesabina Gonder" effect="dark" placement="bottom">
                    <el-button icon="el-icon-s-promotion" type="text" class="el-button-link yellow" @click="sendTransfer"/>
                </el-tooltip>

                <el-button icon="el-icon-plus" type="text" class="el-button-link blue" @click="crateItem"/>
            </div>
        </div>

        <div class="box-list-item" v-for="(item, key) in items" :key="item.id"
             :class="{'el-table__row' : true, 'success-row' :item.is_active }">
            <div class="name fluid">
                <div class="d-flex">
                    <div style="width: 160px">
                        {{ key + 1 }}. Taksit
                        [{{ item.payment_at_format }}]
                    </div>
                    <div class="ml-15">
                        <span v-if="item.plugin">
                            {{ item.plugin.title }}
                        </span>
                    </div>
                </div>

                {{ item.message }}
            </div>
            <div class="price">{{ $n(item.price, 'currency') }}</div>
            <div style="padding-left: 15px">
                <el-dropdown @command="handleDropdownButton">
                    <el-button type="text" class="el-button-link primary">
                        <i class="el-icon-arrow-down"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="{item : item, process : 'payment'}">
                            <i class="mdi mdi-credit-card mr-10 text-color-success"></i>
                            Odeme Yap
                        </el-dropdown-item>
                        <el-dropdown-item :command="{item : item, process : 'edit'}">
                            <i class="el-icon-edit mr-10 text-color-primary"></i>
                            Duzenle
                        </el-dropdown-item>
                        <el-dropdown-item :command="{item : item, process : 'delete'}">
                            <i class="el-icon-delete mr-10 text-color-danger"></i>
                            Sil
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>

        <el-dialog :title="modalTitle" :visible.sync="modalVisible" v-loading="modalLoading" width="80%">
            <el-form :model="form">

                <div class="card-box no-shadow">
                    <section class="card-box-content">

                        <el-row :gutter="24">
                            <el-col :span="12">
                                <div class="box-list">
                                    <div class="box-list-item box-list-item-title">
                                        Odeme Bilgileri
                                    </div>
                                </div>
                                <el-form-item label="Odeme Yontemi">
                                    <el-select v-model="form.payment_plugin_id" value-key="id" placeholder="Odeme Yontemi">
                                        <el-option v-for="(item,key) in payments" :key="item.id" :label="item.title" :value="item.id"/>
                                    </el-select>
                                </el-form-item>

                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <el-form-item label="Odeme Tarihi">
                                            <el-date-picker v-model="form.payment_at" type="datetime" placeholder="Tarih Seçiniz"
                                                            value-format="yyyy-MM-dd HH:mm:ss"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Fiyat">
                                            <el-input v-model="form.price" placeholder="Fiyat"/>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-col>
                            <el-col :span="12">
                                <div class="box-list">
                                    <div class="box-list-item box-list-item-title">
                                        Fatura Bilgileri
                                    </div>
                                </div>

                                <el-form-item label="Fatura No">
                                    <el-input v-model="form.invoice_no" placeholder="Fatura No"/>
                                </el-form-item>

                                <el-form-item label="Kesim Tarihi">
                                    <el-date-picker v-model="form.invoice_at" type="datetime" placeholder="Tarih Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"/>
                                </el-form-item>
                            </el-col>
                        </el-row>

                    </section>
                </div>

                <el-form-item>
                    <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6}" placeholder="Mesajiniz..."
                              v-model="form.message"/>
                </el-form-item>

                <el-form-item>
                    <el-switch v-model="form.is_active" active-text="Odendi"/>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="storeItem" v-if="!form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
                <el-button type="success" size="medium" @click="updateItem" v-if="form.id" style="width: 100%">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </span>
        </el-dialog>

        <el-dialog title="Odeme Yap" :visible.sync="paymentModalVisible" v-loading="paymentModalLoading">
            <el-form :model="paymentForm">

                <div class="ui icon message yellow">
                    <i class="icon mdi mdi-alert"></i>
                    <div class="content">
                        <div class="header" style="margin: 0; height: auto">
                            Onemli!
                        </div>
                        <p>
                            Kredi Karti ile odemeler de addres bilgilerinin tamaminin dolu olmasi zorunludur.
                        </p>
                    </div>
                </div>

                <el-form-item>
                    <el-input v-model="paymentForm.cc.owner" placeholder="Kart Üzerindeki İsim"/>
                </el-form-item>

                <el-form-item>
                    <el-input v-model="paymentForm.cc.number" placeholder="Kart Numarası"
                              v-mask="'#### #### #### ####'"/>
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item>
                            <el-input v-model="paymentForm.cc.month" placeholder="Ay" v-mask="'##'"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item>
                            <el-input v-model="paymentForm.cc.year" placeholder="Yil" v-mask="'####'"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item>
                    <el-input v-model="paymentForm.cc.cvv" placeholder="Guvenlik Kodu" v-mask="'###'"/>
                </el-form-item>

                <el-form-item>
                    <el-input v-model="paymentForm.installment_no" placeholder="Taksit"/>
                </el-form-item>

                <BankInstallment :total="paymentItem.price" :key="paymentItem.price" v-if="paymentItem"/>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="success" @click="sendPayment" style="width: 100%">
                    <i class="mdi mdi-check"></i> Kaydet
                </el-button>
            </span>
        </el-dialog>

        <div id="CreditCardServiceResponse" style="display: none">
            <div v-html="PaymentDataForm"></div>
        </div>
    </div>
</template>

<script>
    import BankInstallment from "../../../../../components/BankInstallment";
    export default {
        name: 'Payments',
        components: {BankInstallment},
        props: ['url', 'order'],
        data() {
            return {
                itemLoading: true,
                modalLoading: false,
                modalVisible: false,
                paymentModalVisible: false,
                paymentModalLoading: false,
                modalTitle: 'Ekle',
                items: [],
                payments: [],
                form: {},
                paymentItem: null,
                paymentCount: 0,
                paymentForm: {
                    order_payment_id: 1,
                    installment_no: 1,
                    cc: {
                        owner: null,
                        number: null,
                        month: null,
                        year: null,
                        cvv: null,
                    },
                },

                PaymentDataForm : ''
            }
        },
        mounted() {

            if (this.$route.query.error) {
                this.$notify.error({
                    title: 'Upss!',
                    message: this.$route.query.error,
                    position: 'top-right'
                });
            }

            this.index();
            this.getPayments();
        },
        methods: {
            index() {
                this.$http.get(this.url + '/payments', {
                    params: {
                        fake_url: 'v1/' + this.url
                    }
                }).then(response => {
                    this.items = response.body.rows;

                    this.paymentCount = _.size(this.items);

                    this.itemLoading = false;
                });
            },

            crateItem() {
                this.modalTitle = 'Ekle';
                this.modalVisible = true;
                this.form = {};
            },

            storeItem() {
                this.modalLoading = true;

                this.$http.post(this.url + '/payments', {
                    fake_url: 'v1/' + this.url,
                    payment_gate: this.form.payment_gate,
                    payment_at: this.form.payment_at,
                    price: this.form.price,
                    message: this.form.message,
                    is_active: this.form.is_active,
                    payment_plugin_id: this.form.payment_plugin_id,
                }).then(response => {
                    this.modalLoading = false;
                    this.modalVisible = false;

                    this.index();

                    this.apiSuccessMessage(response);
                });
            },

            editItem(item) {
                this.itemLoading = false;
                this.modalLoading = false;
                this.modalVisible = true;

                this.modalTitle = 'Duzenle';
                this.form = item;
            },

            updateItem() {
                this.modalLoading = true;

                this.$http.put(this.url + '/payments/' + this.form.id, {
                    fake_url: 'v1/' + this.url,
                    payment_gate: this.form.payment_gate,
                    payment_at: this.form.payment_at,
                    price: this.form.price,
                    message: this.form.message,
                    is_active: this.form.is_active,
                    payment_plugin_id: this.form.payment_plugin_id,
                }).then(response => {
                    this.modalLoading = false;
                    this.modalVisible = false;

                    this.index();

                    this.apiSuccessMessage(response);
                });
            },

            destroyItem(id) {
                this.modalLoading = false;
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete(this.url + '/payments/' + id, {
                        fake_url: 'v1/' + this.url
                    }).then(response => {
                        this.modalLoading = false;
                        this.modalVisible = false;
                        this.itemLoading = false;

                        this.index();

                        this.apiSuccessMessage(response);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.modalLoading = false;
                    this.itemLoading = false;
                });
            },

            getPayments() {

                this.itemLoading = true;

                this.$http.get('tools/plugins/payments', {params: this.serverParams})
                    .then(response => {
                        this.itemLoading = false;
                        this.payments = response.body.rows;
                    });
            },

            handleDropdownButton(command) {
                if (command.process === 'payment') {
                    return this.openPayment(command.item);
                }

                if (command.process === 'edit') {
                    return this.editItem(command.item);
                }

                if (command.process === 'delete') {
                    return this.destroyItem(command.item.id);
                }
            },

            openPayment(item) {
                this.paymentModalVisible = true;
                this.paymentItem = item;
                this.paymentForm.order_payment_id = item.id;
            },

            sendPayment() {

                this.paymentModalLoading = true;

                this.$http.post('sales/payment', this.paymentForm)
                    .then(response => {

                        if (response.body.form) {
                            this.PaymentDataForm = response.body.form;

                            setTimeout(() => {
                                $('#CreditCardServiceResponse form').submit();
                            }, 2000)
                        } else {
                            this.apiSuccessMessage(response);
                            return window.location = response.body.redirect.url;
                        }
                    })
                    .catch((e) => {
                        this.paymentModalLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            sendTransfer() {

                this.itemLoading = true;

                this.$confirm('Is Bankasi Kmh hesabina gondermek istediginizden emin misiniz?', 'Uyari!', {
                    confirmButtonText: 'Gonder',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                }).then(() => {
                    this.$http
                        .put(this.url, {
                            fake_url: 'v1/' + this.url,
                            process: 'transfer'
                        })
                        .then(response => {
                            this.apiSuccessMessage(response);
                            this.itemLoading = false;
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.itemLoading = false;
                });



            }
        }
    }
</script>