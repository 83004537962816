<template>
    <div class="loading-container" v-loading="pageLoading">

        <el-form-item label="Gorunum">
            <el-select v-model="content.view" placeholder="Gorunum" filterable clearable>
                <el-option
                        v-for="item in views"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"/>
            </el-select>
        </el-form-item>

        <div class="card-box">
            <div class="card-box-header no-shadow">
                <div class="title">
                    Ozellikler
                </div>
                <div class="actions">
                    <el-button type="text" class="el-button-link primary" icon="el-icon-plus" @click="addRow"/>
                </div>
            </div>
            <div class="card-box-content">
                <div class="box-list">
                    <div class="box-list-item" v-for="(item, key) in content.items" :key="key">
                        <div class="fluid d-flex" style="align-items: center">
                            <div class="row-number">
                                <div>{{ key + 1 }}</div>
                            </div>
                            <div class="fluid">
                                <el-form-item class="no-margin">
                                    <el-input v-model="item.metas.sentence" placeholder="Cumle" @keyup="sentencePreview(key, item.metas)" @change="sentencePreview(key, item.metas)"/>
                                </el-form-item>
                                <el-form-item class="no-margin">
                                    <el-input v-model="item.metas.blank" placeholder="Bosluk" @change="sentencePreview(key, item.metas)" @keyup="sentencePreview(key, item.metas)"/>
                                </el-form-item>
                                <div :key="item.metas.preview" class="fs-12 fw-500"
                                     style="background: #f9f8f8; padding: 3px 5px; font-style: italic">
                                    {{ item.metas.preview || 'on izleme' }}
                                </div>
                            </div>
                        </div>
                        <div class="ml-20">
                            <div class="d-flex" style="justify-content: end">
                                <el-button type="text" class="el-button-link red" icon="el-icon-delete" @click="destroySentence(key)"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'sentence_building',
        props: [
            'content',
            'type'
        ],
        data() {
            return {
                pageLoading: true,
                views: [
                    {
                        value: 'write',
                        label: 'Yazarak Doldur',
                    },
                    {
                        value: 'drag_and_drop',
                        label: 'Surekleyerek Doldur',
                    }
                ]
            }
        },

        methods: {
            addRow() {
                this.content.items.push({
                    unit_exercise_content_id: this.content.id,
                    unit_exercise_content_item_id: null,
                    parent_id: null,
                    sort: null,
                    metas: {
                        sentence: null,
                        blank: null,
                        preview: null,
                    }
                });
            },

            destroySentence(key) {
                this.content.items.splice(key, 1);
            },

            sentencePreview(key, sentence) {

                if (!sentence.sentence) {
                    return null;
                }

                if (!sentence.blank) {
                    return null;
                }

                let blankText = '';

                let i;

                for (i = 0; i < _.size(sentence.blank); i++) {
                    blankText += '_';
                }

                sentence.preview = sentence.sentence.replace(sentence.blank, blankText);

            },
        },

        mounted() {
            this.pageLoading = false;
        }
    }
</script>