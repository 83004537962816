<style lang="scss">
    .platform-title {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 30px;
    }

    .card-box {
        &.clickable {
            cursor: pointer;

            .card-box-media {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 120px;
            }

            &:hover {
                background: rgba(92, 80, 185, 0.2);
                color: #5C50B9;
            }
        }
    }
</style>

<template>
    <div class="loading-container" v-loading="pageLoading">
        <div class="platform-title">
            {{ user.name }}
            <div>
                Ogretmen Platformuna Hosgeldin
            </div>
        </div>
        <el-row :gutter="24">
            <el-col :span="6" v-for="link in platform.teacher.links" :key="link.url">
                <router-link :to="'/' +link.url">
                    <div class="card-box fluid clickable">
                        <section class="card-box-content padding-20 text-center ">
                            <div class="card-box-media">
                                <img :src="image(link.media)" alt="" class="ui image small centered">
                            </div>
                            <div class="card-box-text fs-16 mt-20">
                                {{ link.title }}
                            </div>
                        </section>
                    </div>
                </router-link>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Dashboard',
        computed: mapState([
            'user',
            'platform'
        ]),
        data() {
            return {
                pageLoading: false,
            }
        },

        methods: {
            image(pet) {

                let images = require.context('../../../../resources/images/platform/', false, /\.png$/);

                return images('./' + pet + '.png')
            }
        },

        mounted() {
            //
        }
    }
</script>