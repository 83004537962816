<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading">
            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <crud-buttons :storeItem="storeItem" :updateItem="updateItem" />
                </header>
                <section>
                    <el-form :label-position="'top'" ref="form" :model="form">
                        <div class="card-box-sub-title">Genel</div>
                        <div class="card-box-sub-container">
                            <el-form-item label="Başlık">
                                <el-input v-model="form.title" placeholder="Başlık"/>
                            </el-form-item>

                            <el-form-item label="Açıklama">
                                <el-input type="textarea" placeholder="Açıklama" v-model="form.description"/>
                            </el-form-item>
                        </div>
                        <div class="card-box-sub-title">Resim</div>
                        <div class="card-box-sub-container">
                            <el-row :gutter="24">
                                <el-col :span="4">
                                    <el-form-item label="Kapak Resmi">
                                        <file-browser v-model="form.file_path" :small="true"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="card-box-sub-title">Seo</div>
                        <div class="card-box-sub-container">
                            <Seo :container="form.seo"/>
                        </div>
                        <div class="card-box-sub-title">Tasarim</div>
                        <div class="card-box-sub-container">
                            <el-form-item label="Sayfa Siralama Gorunumu">
                                <el-select v-model="form.sort_type" filterable default-first-option clearable placeholder="Sayfa Siralama Gorunumu" value-key="value">
                                    <el-option v-for="item in sorts" :key="item.value" :label="item.label" :value="item.value"/>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="Sayfa Gorunumu">
                                <el-select v-model="form.view_path" filterable allow-create default-first-option clearable placeholder="Sayfa Gorunumu">
                                    <el-option v-for="item in layouts" :key="item.label" :label="item.label" :value="item.id"/>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="Icon">
                                <el-select v-model="form.metas.icon" filterable allow-create default-first-option clearable placeholder="Icon">
                                    <el-option v-for="item in icons" :key="item.label" :label="item.label" :value="item.id"/>
                                </el-select>
                            </el-form-item>

                            <el-form-item label="HTML Class">
                                <el-select v-model="form.metas.html_class" filterable allow-create default-first-option clearable placeholder="HTML Class">
                                    <el-option v-for="item in htmlClasses" :key="item.label" :label="item.label" :value="item.id"/>
                                </el-select>
                            </el-form-item>
                        </div>
                        <div class="card-box-sub-title">Diger Ozellikler</div>
                        <div class="card-box-sub-container">
                            <el-form-item label="Sıralama">
                                <el-input-number v-model="form.sort" placeholder="Sıralama"/>
                            </el-form-item>

                            <el-form-item label="Durum">
                                <el-switch v-model="form.is_active" active-text="Aktif"/>
                            </el-form-item>
                        </div>
                    </el-form>
                </section>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
    import FileBrowser from "../../../components/FileBrowser";
    import Seo from "../../../components/Seo";

    export default {
        name: 'ElementForm',
        components: {
            Seo,
            FileBrowser
        },
        props: ['source'],
        data() {
            return {
                itemLoading: true,
                endPoint: null,
                form: {
                    seo: {},
                    metas: {}
                },

                categories: [],

                sorts: [
                    {
                        value: 'creadet_at',
                        label: 'Olusturma Tarihi'
                    },
                    {
                        value: 'updated_at',
                        label: 'Guncellenme Tarihi'
                    },
                    {
                        value: 'sort',
                        label: 'Siralama Sayisi'
                    }
                ],

                layouts: [
                    {
                        id: 'teams.category',
                        label: 'Kadromuz'
                    },
                    {
                        id: 'webinar.index',
                        label: 'Webinar'
                    }
                ],

                icons: [
                    {
                        id: 'icon-toy-horse',
                        label: 'Anaokulu'
                    },
                    {
                        id: 'icon-abc-boxes',
                        label: 'Ilkokul'
                    },
                    {
                        id: 'icon-bag',
                        label: 'Ortaokul'
                    },
                    {
                        id: 'icon-tube',
                        label: 'Lise'
                    },
                    {
                        id: 'icon-normal-education',
                        label: 'Normal Eğitim Prog.'
                    },
                    {
                        id: 'icon-summer-school',
                        label: 'Yaz Okulu'
                    },
                    {
                        id: 'icon-quiz-education',
                        label: 'Sınav Sistemleri'
                    },
                    {
                        id: 'icon-language-workshop',
                        label: 'Dil Atölyeleri'
                    },
                    {
                        id: 'icon-lesson-prv',
                        label: 'Özel Dersler'
                    },
                    {
                        id: 'icon-faqs',
                        label: 'S.S.S Genel'
                    },
                    {
                        id: 'icon-edeucation',
                        label: 'Ozgun Ogretim'
                    },
                    {
                        id: 'icon-online-education',
                        label: 'Online Egitim'
                    },
                    {
                        id: 'icon-school',
                        label: 'Egitim Binamiz'
                    },
                    {
                        id: 'icon-personals',
                        label: 'Personel'
                    },
                    {
                        id: 'icon-vehicles',
                        label: 'Servislerimiz'
                    }
                ],

                htmlClasses: [
                    {
                        id: 'anaokulu',
                        label: 'Anaokulu'
                    },
                    {
                        id: 'ilkokul',
                        label: 'Ilkokul'
                    },
                    {
                        id: 'ortaokul',
                        label: 'Ortaokul'
                    },
                    {
                        id: 'lise',
                        label: 'Lise'
                    }
                ],
            }
        },

        methods: {
            loadItem() {

                this.itemLoading = true;

                this.$http
                    .get(this.endPoint + '/' + this.$route.params.id, {
                        params: this.serverParams
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body.item;

                        if (!_.size(this.form.metas)) {
                            this.form.metas = {}
                        }

                        if (!_.size(this.form.seo)) {
                            this.form.seo = {};
                        }
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            storeItem() {

                this.itemLoading = true;

                this.$http
                    .post(this.endPoint, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                        this.$router.push('edit/' + response.body.item.id);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.itemLoading = true;

                this.$http
                    .put(this.endPoint + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                    confirmButtonText: 'Tamam',
                    cancelButtonText: 'Iptal Et',
                    type: 'warning'
                })
                    .then(() => {
                        this.$http
                            .delete(this.endPoint + '/' + id)
                            .then(response => {
                                this.itemLoading = false;

                                this.$router.push('/');

                                this.apiSuccessMessage(response)
                            })
                            .catch((e) => {
                                this.itemLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            },

            getCategories() {

                this.itemLoading = true;

                this.$http
                    .get(this.endPoint)
                    .then(response => {
                        this.itemLoading = false;
                        this.categories = _.orderBy(response.body.rows, 'nest_title')
                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            }
        },

        mounted() {

            this.endPoint = this.$route.meta.end_point + (this.$route.params.type ? this.$route.params.type : '');

            this.getCategories();

            if (this.$route.params.id) {
                this.loadItem()
            } else {
                this.itemLoading = false;
            }
        }
    }
</script>