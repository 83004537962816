<template>
    <div class="page-vue-good-table scrollable only-y">
        <page-header/>
        <div class="loading-container" v-loading="itemLoading">
            <el-form :label-position="'top'" ref="form" :model="form">
                <div class="ui message green fluid" v-if="successMessage">
                    Tebrikler odemeniz basarili bir sekilde tahsil edildi.
                </div>

                <div class="ui message red fluid" v-if="errorMessage">
                    {{ errorMessage }}
                </div>

                <div class="card-box">
                    <header class="card-box-header">
                        <div class="title">
                            Sanal Pos
                        </div>
                    </header>
                    <section class="card-box-content">
                        <div class="box-list">
                            <div class="box-list-item box-list-item-title">
                                Siparis Bilgileri
                            </div>
                        </div>

                        <div class="pl-20 pr-20 mt-20">
                            <el-form-item>
                                <el-select v-model="form.customer_id" value-key="id" filterable remote reserve-keyword
                                           placeholder="Musteri" :remote-method="searchCustomers"
                                           :loading="customerSearchLoading">
                                    <el-option v-for="item in customers" :key="item.id" :label="item.title"
                                               :value="item.id"/>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-input v-model="form.price" placeholder="Tutar"/>
                            </el-form-item>

                            <el-form-item>
                                <el-input v-model="form.product_name" placeholder="Urun Adi"/>
                            </el-form-item>
                        </div>

                        <div class="box-list">
                            <div class="box-list-item box-list-item-title">
                                Kart Bilgileri
                            </div>
                        </div>

                        <div class="pl-20 pr-20 mt-20">
                            <el-form-item>
                                <el-input v-model="form.cc.owner" placeholder="Kart Üzerindeki İsim"/>
                            </el-form-item>

                            <el-form-item>
                                <el-input v-model="form.cc.number" placeholder="Kart Numarası"
                                          v-mask="'#### #### #### ####'"/>
                            </el-form-item>

                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item>
                                        <el-input v-model="form.cc.month" placeholder="Ay" v-mask="'##'"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item>
                                        <el-input v-model="form.cc.year" placeholder="Yil" v-mask="'####'"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-form-item>
                                <el-input v-model="form.cc.cvv" placeholder="Guvenlik Kodu" v-mask="'###'"/>
                            </el-form-item>

                            <el-form-item>
                                <el-input v-model="form.installment_no" placeholder="Taksit"/>
                            </el-form-item>
                        </div>

                        <div class="box-list">
                            <div class="box-list-item box-list-item-title">
                                Taksit Bilgileri
                            </div>
                        </div>

                        <div class="pl-20 pr-20 mt-20">
                            <BankInstallment :total="form.price" :key="form.price"/>
                        </div>
                    </section>
                    <footer class="card-box-footer">
                        <div class="ui labeled icon button green fluid" @click="send">
                            <i class="icon el-icon-check"></i> Gonder
                        </div>
                    </footer>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
    import PageHeader from "../../../components/PageHeader";
    import BankInstallment from "../../../components/BankInstallment";

    export default {
        name: 'Payments',
        components: {BankInstallment, PageHeader},
        props: ['source',],
        data() {
            return {
                itemLoading: true,
                customerSearchLoading: false,
                form: {
                    process: 'pos',
                    customer_id: null,
                    product_name: null,
                    price: null,
                    installment_no: null,
                    cc: {
                        owner: null,
                        number: null,
                        month: null,
                        year: null,
                        cvv: null,
                    },
                },

                customers: [],

                successMessage: null,
                errorMessage: null,
            }
        },

        methods: {

            initMessage() {

                let status = this.$route.query.status;

                if (!status) {
                    this.successMessage = null;
                    this.errorMessage = null;

                    return false;
                }

                if (status === 'success') {

                    this.errorMessage = null;

                    return this.successMessage = true;
                }

                this.successMessage = null;

                return this.errorMessage = this.$route.query.message;
            },

            searchCustomers(query) {
                this.customerSearchLoading = true;

                this.$http
                    .get('tools/search/users?q=' + query)
                    .then(response => {
                        this.customerSearchLoading = false;
                        this.customers = response.body.items;
                    })
                    .catch((e) => {
                        this.customerSearchLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            send() {
                this.itemLoading = true;

                this.$http
                    .post('sales/payment', this.form)
                    .then(response => {
                        this.apiSuccessMessage(response);
                        this.itemLoading = false;

                        return window.location = response.body.redirect.url;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            }
        },

        mounted() {
            this.itemLoading = false;

            this.initMessage();

        },
    }
</script>