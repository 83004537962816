<template>
    <div class="toolbar flex align-center justify-space-between">
        <div class="box-left box grow flex" style="width: 100%">
            <button @click="toggleSidebar" v-if="menuBurger !== 'right'"
                    class="toggle-sidebar card-base card-shadow--small">
                <i class="mdi mdi-menu"></i>
            </button>
            <div class="card-stats">
                <NewUser />
            </div>
            <div style="display: flex; align-items: center; width: 100%">
                <search class="hidden-xs-only fluid" style="max-width:none"/>
            </div>
        </div>
        <div class="box-right flex align-center pl-10">
            <el-tooltip content="Minidil.com" effect="dark" placement="bottom">
                <a :href="appConfig.frontPath" target="_blank" class="mr-20 ml-20">
                    <i class="mdi mdi-link mdi-24px"></i>
                </a>
            </el-tooltip>
            <el-tooltip content="Dosya Yöneticisi" effect="dark" placement="bottom">
                <router-link to="/tools/file-manager">
                    <i class="mdi mdi-folder-multiple-outline mdi-24px"></i>
                </router-link>
            </el-tooltip>
            <el-popover ref="popover" placement="bottom-end" :width="popoverWidth" trigger="click">
                <notification-box/>
            </el-popover>
            <el-badge :is-dot="true" class="notification-icon-badge">
                <el-button v-popover:popover icon="mdi mdi-bell mdi-24px" class="notification-icon"></el-button>
            </el-badge>
            <Account/>
        </div>
    </div>
</template>

<script>
import NotificationBox from "./_header/NotificationBox";
import Search from "./_header/Search";
import Account from "./_header/Account";
import NewUser from "./_header/NewUser.vue";

export default {
    name: 'Toolbar',
    components: {
        NewUser,
        Account,
        Search,
        NotificationBox
    },
    props: ['menuBurger'],
    data() {
        return {
            popoverWidth: 450,
            fullscreen: false,
            lang: 'us',
            accountLoading: true,
        }
    },
    mounted() {
        this.resizePopoverWidth();
        window.addEventListener('resize', this.resizePopoverWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizePopoverWidth);
    },
    methods: {
        toggleSidebar() {
            this.$emit('toggle-sidebar')
        },

        resizePopoverWidth() {
            if (window.innerWidth <= 768) {
                this.popoverWidth = 250
            } else {
                this.popoverWidth = 450
            }
        },
    }
}
</script>
