import layouts from '@/config/layout'

import MasterContainer from '@/app/Container/Master'
import DatatableContainer from "@/app/Container/Dt.vue";

// Pages
import Event from '@/app/Pages/Enrollments/Event'
import Interview from '@/app/Pages/Enrollments/Interviews/Index'

import Student from '@/app/Pages/Enrollments/Student'
import Parent from '@/app/Pages/Enrollments/Parent'
import Report from '@/app/Pages/Enrollments/Reports/Index'
import PageContainer from "@/app/Container/Page.vue";
import OfferCreate from "@/app/Pages/Enrollments/Offers/Create.vue";
import OfferEdit from "@/app/Pages/Enrollments/Offers/Show.vue";

import OrderCreate from "@/app/Pages/Enrollments/Orders/Create.vue";
import OrderEdit from "@/app/Pages/Sales/Orders/Edit.vue";

export default {
    path: '/enrollments',
    name: 'enrollments',
    component: MasterContainer,
    meta: {
        auth: true,
        layout: layouts.navLeft,
        title: 'Kayıt Programı',
    },
    children: [
        {
            name: 'enrollments.events.index',
            path: 'events',
            component: Event,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Etkinlikler',
                path: 'events',
                end_point: 'enrollments/events',
                isExport: true,
            },
        },
        {
            name: 'enrollments.interviews.index',
            path: 'interviews',
            component: Interview,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Görüşmeler',
                path: 'interviews',
                end_point: 'enrollments/interviews',
                isExport: true,
            },
        },
        {
            path: 'offers',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Teklifler',
            },
            children: [
                {
                    name: 'enrollments.offers.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Teklifler',
                        path: 'offers',
                        end_point: 'sales/offers',
                        isExport: true,
                    },
                },
                {
                    name: 'enrollments.offers.create',
                    path: 'create',
                    component: OfferCreate,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Teklif Olustur',
                        path: 'offers',
                        end_point: 'sales/offers',
                    },
                },
                {
                    name: 'enrollments.offers.edit',
                    path: 'edit/:id',
                    component: OfferEdit,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Teklif Bilgileri',
                        path: 'offers',
                        end_point: 'sales/offers',
                    },
                }
            ]
        },
        {
            path: 'orders',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Siparisler',
            },
            children: [
                {
                    name: 'enrollments.orders.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Siparisler',
                        path: 'orders',
                        end_point: 'sales/orders',
                        isExport: true,
                        isNotCreate: true,
                    },
                },
                {
                    name: 'enrollments.orders.create',
                    path: 'create',
                    component: OrderCreate,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Siparis Olustur',
                        path: 'orders',
                        end_point: 'sales/orders',
                    },
                },
                {
                    name: 'enrollments.orders.edit',
                    path: 'edit/:id',
                    component: OrderEdit,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Siparis Bilgileri',
                        path: 'orders',
                        end_point: 'sales/orders',
                    },
                }
            ]
        },
        {
            name: 'enrollments.students.index',
            path: 'students',
            component: Student,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Öğrenciler',
                path: 'students',
                end_point: 'enrollments/students',
                isExport: true,
            },
        },
        {
            name: 'enrollments.parents.index',
            path: 'parents',
            component: Parent,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Veliler',
                path: 'parents',
                end_point: 'enrollments/parents',
                isExport: true,
            },
        },
        {
            name: 'enrollments.reports.index',
            path: 'reports',
            component: Report,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Raporlar',
                path: 'reports',
                end_point: 'enrollments/reports',
                isExport: true,
            },
        },
    ]
}
