<style lang="scss">
.el-tab-pane {
    min-height: 600px;
}

.status {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    cursor: pointer;
}

label {
    margin-bottom: 0;
}
</style>

<template>
    <vue-scroll class="page-element-form" v-loading="itemLoading">
        <div class="loading-container">
            <div v-if="form.id">
                <div class="card-box">
                    <header class="card-box-header">
                        <div class="title">
                            #{{ form.id }}
                            <div>
                                {{ $d(new Date(form.created_at), 'long', 'tr-TR') }}
                            </div>
                        </div>
                        <div class="actions">
                            <Buttons :order="form" />
                        </div>
                    </header>
                </div>

                <el-row :gutter="24">
                    <el-col :span="16">
                        <Customer :order="form" />
                    </el-col>
                    <el-col :span="8">
                        <Statuses v-model="form.status" :url="$route.meta.end_point + '/' + form.id" :order="form"/>
                    </el-col>
                </el-row>

                <el-row :gutter="24">
                    <el-col :span="16">
                        <Products :source="$route.meta.end_point + '/' + form.id" :order="form"/>

                        <div class="card-box">
                            <section class="card-box-tabs">
                                <el-tabs :tab-position="'top'">
                                    <el-tab-pane label="Muhasebe">
                                        <section class="card-box-content">
                                            <div class="box-list">
                                                <Payments :url="$route.meta.end_point + '/' + form.id" :order="form"/>
                                                <Address :order="form" alias="invoice" key="invoice" />
                                                <Address :order="form" alias="contract" key="contract" />
                                            </div>
                                        </section>
                                    </el-tab-pane>

                                    <el-tab-pane label="Kargo">
                                        <section class="card-box-content">
                                            <div class="box-list">
                                                <div class="box-list-item box-list-item-title">
                                                    <div class="name">
                                                        Kargo Bilgileri
                                                    </div>
                                                    <div class="description d-flex" style="width: auto">
                                                        <el-button icon="el-icon-edit" type="text" class="el-button-link green" @click="modalDeliveryVisible = true"/>
                                                    </div>
                                                </div>
                                                <div class="box-list-item">
                                                    <div class="name">
                                                        Gonderim Tarihi
                                                    </div>
                                                    <div class="description">
                                                        {{ form.delivery_at }}
                                                    </div>
                                                </div>
                                                <div class="box-list-item">
                                                    <div class="name">
                                                        Takip No
                                                    </div>
                                                    <div class="description">
                                                        {{ form.delivery_no }}
                                                    </div>
                                                </div>
                                                <div class="box-list-item">
                                                    <div class="name">
                                                        Kargo Firmasi
                                                    </div>
                                                    <div class="description">
                                                        {{ form.delivery_gate }}
                                                    </div>
                                                </div>
                                                <Address :order="form" alias="delivery" key="delivery" />
                                            </div>
                                        </section>
                                    </el-tab-pane>

                                    <el-tab-pane label="Iade">
                                        <section class="card-box-content">
                                            <div class="box-list">
                                                <OrderReturns :url="$route.meta.end_point + '/' + form.id" :order="form"/>
                                            </div>
                                        </section>
                                    </el-tab-pane>
                                </el-tabs>
                            </section>
                        </div>

                        <Notes :url="'crm/accounts/' + form.account_id" :key="form.account_id"/>
                    </el-col>
                    <el-col :span="8">
                        <Campaigns :campaigns="form.campaigns" :customCampaigns="form.custom_campaigns"/>

                        <div class="card-box">
                            <header class="card-box-header">
                                <div class="title">
                                    Siparis
                                </div>
                                <div class="actions">
                                    <el-tooltip content="Odeme Onayi" effect="dark" placement="bottom">
                                        <el-button icon="el-icon-check" type="text" class="el-button-link yellow" @click="modalPaymentVisible = true"/>
                                    </el-tooltip>
                                    <el-tooltip content="Duzenle" effect="dark" placement="bottom">
                                        <el-button icon="el-icon-edit" type="text" class="el-button-link green" @click="modalOrderVisible = true"/>
                                    </el-tooltip>
                                </div>
                            </header>
                            <section class="card-box-content">
                                <div class="box-list">
                                    <div class="box-list-item">
                                        <div class="name">
                                            Personel
                                        </div>
                                        <div class="description" v-if="form.created_by">
                                            {{ form.created_by.name }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Siparis Kodu
                                        </div>
                                        <div class="description">
                                            {{ form.code }}
                                        </div>
                                    </div>
                                    <div class="box-list-item" v-if="form.offer_id">
                                        <div class="name">
                                            Teklif Bilgileri
                                        </div>
                                        <div class="description">
                                            <span class="route-link" @click="openBlankWindow('/enrollments/offers/edit/' + form.offer_id)">
                                                #{{ form.offer_id }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            O.Tarihi
                                        </div>
                                        <div class="description">
                                            {{ form.created_at }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Yil
                                        </div>
                                        <div class="price" v-if="form.year">
                                            {{ form.year.title }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Odeme Yontemi
                                        </div>
                                        <div class="description">
                                            {{ form.payment_plugin.title }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Odeme Kanali
                                        </div>
                                        <div class="description">
                                            {{ form.payment_gate }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Odeme Onayi
                                        </div>
                                        <div class="description">
                                            {{ form.payment_at }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Adet
                                        </div>
                                        <div class="description">
                                            {{ form.quantity }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Servis Toplami
                                        </div>
                                        <div class="description">
                                            {{ $n(form.vehicle_total, 'currency') }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Urun Toplami
                                        </div>
                                        <div class="description">
                                            {{ $n(form.product_total, 'currency') }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name fluid">
                                            Servis Indirim Toplami
                                        </div>
                                        <div class="description">
                                            {{ $n(form.vehicle_discount_total, 'currency') }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name fluid">
                                            Urun Indirim Toplami
                                        </div>
                                        <div class="description">
                                            {{ $n(form.product_discount_total, 'currency') }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Indirim
                                        </div>
                                        <div class="description">
                                            {{ $n(form.discount_total, 'currency') }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Kargo Ucreti
                                        </div>
                                        <div class="description">
                                            {{ $n(form.shipping_total, 'currency') }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            K.D.V.
                                        </div>
                                        <div class="description">
                                            {{ $n(form.tax_total, 'currency') }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Vade Farki
                                        </div>
                                        <div class="description">
                                            {{ $n(form.installment_total, 'currency') }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Taksit Sayisi
                                        </div>
                                        <div class="description">
                                            {{ form.installment_no }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Toplam
                                        </div>
                                        <div class="description">
                                            {{ $n(form.total, 'currency') }}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </el-col>
                </el-row>

                <el-dialog title="Siparis Bilgileri" :visible.sync="modalOrderVisible" v-loading="modalLoading">
                    <el-form :model="form">

                        <div class="card-box no-shadow">
                            <section class="card-box-content">
                                <el-form-item label="Egitim Yili">
                                    <select-definition label="Egitim Yili" alias="education-years" model="education_year_definition_id" :container="form"/>
                                </el-form-item>

                                <div class="box-list">
                                    <div class="box-list-item box-list-item-title">
                                        Musteri
                                    </div>
                                </div>
                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <el-form-item label="Adi">
                                            <el-input v-model="form.first_name" placeholder="Adi"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Soyadi">
                                            <el-input v-model="form.last_name" placeholder="Soyadi"/>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <el-form-item label="Telefon">
                                            <el-input v-model="form.phone" placeholder="Telefon"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Eposta">
                                            <el-input v-model="form.email" placeholder="Eposta"/>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <div class="box-list">
                                    <div class="box-list-item box-list-item-title">
                                        Odeme Bilgileri
                                    </div>
                                </div>

                                <el-form-item label="Odeme Yontemi">
                                    <el-select v-model="form.payment_plugin_id" value-key="id" placeholder="Odeme Yontemi">
                                        <el-option v-for="item in paymentPlugins" :key="item.id" :label="item.title"
                                                   :value="item.id"/>
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="Odeme Kanali">
                                    <el-select v-model="form.payment_gate" placeholder="Odeme Kanali" clearable>
                                        <el-option v-for="item in paymentGates" :key="item.label" :label="item.label"
                                                   :value="item.label"/>
                                    </el-select>
                                </el-form-item>

                                <div class="box-list">
                                    <div class="box-list-item box-list-item-title">
                                        Fiyat Bilgileri
                                    </div>
                                </div>

                                <el-form-item label="Adet">
                                    <el-input v-model="form.quantity" placeholder="Adet"/>
                                </el-form-item>

                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <el-form-item label="Servis Toplami">
                                            <el-input v-model="form.vehicle_total" placeholder="Servis Ucreti"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Servis Indirimi">
                                            <el-input v-model="form.vehicle_discount_total" placeholder="Servis Indirimi"/>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <el-form-item label="Urun Toplami">
                                            <el-input v-model="form.product_total" placeholder="Urun Toplami"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Urun Indirimi">
                                            <el-input v-model="form.product_discount_total" placeholder="Urun Indirimi"/>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <el-form-item label="Vade Farki">
                                            <el-input v-model="form.installment_total" placeholder="Vade Farki"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="Taksit Sayisi">
                                            <el-input v-model="form.installment_no" placeholder="Taksit Sayisi"/>
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-form-item label="Toplam Indirim">
                                    <el-input v-model="form.discount_total" placeholder="Toplam Indirim"/>
                                </el-form-item>

                                <el-form-item label="Kargo Ucreti">
                                    <el-input v-model="form.shipping_total" placeholder="Kargo Ucreti"/>
                                </el-form-item>

                                <el-form-item label="Vergi">
                                    <el-input v-model="form.tax_total" placeholder="Vergi"/>
                                </el-form-item>

                                <el-form-item label="Toplam">
                                    <el-input v-model="form.total" placeholder="Toplam"/>
                                </el-form-item>
                            </section>
                        </div>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="success" class="fluid" @click="updateItem">
                            <i class="el-icon-check"></i> Guncelle
                        </el-button>
                    </div>
                </el-dialog>

                <el-dialog title="Kargo Bilgileri" :visible.sync="modalDeliveryVisible" v-loading="modalLoading">
                    <el-form :model="form">
                        <el-form-item label="Firma">
                            <el-input v-model="form.delivery_gate" placeholder="Firma"/>
                        </el-form-item>

                        <el-form-item label="Takip No">
                            <el-input v-model="form.delivery_no" placeholder="Takip No"/>
                        </el-form-item>

                        <el-form-item label="Gonderim Tarihi">
                            <el-date-picker v-model="form.delivery_at" type="datetime" placeholder="Tarih Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>

                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="success" @click="updateItem" class="fluid">
                            <i class="el-icon-check"></i> Guncelle
                        </el-button>
                    </div>
                </el-dialog>

                <el-dialog title="Odeme Onayi" :visible.sync="modalPaymentVisible" v-loading="modalLoading">
                    <el-form :model="form">
                        <el-form-item label="Odeme Tarihi">
                            <el-date-picker v-model="form.payment_at" type="datetime" placeholder="Tarih Seçiniz" value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="success" @click="updateItem" class="fluid">
                            <i class="el-icon-check"></i> Guncelle
                        </el-button>
                    </div>
                </el-dialog>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
import Customer from "./_edit/Customer";
import Statuses from "./_edit/Statuses";
import Buttons from "./_edit/Buttons";
import Campaigns from "./_edit/Campaigns";
import Products from"./_edit/Products";
import OrderReturns from "./_edit/OrderReturns";
import Payments from "./_edit/Payments";
import Address from "./_edit/Address";
import SelectDefinition from "../../../../components/SelectDefinition";
import Notes from "../../../../components/Notes";
export default {
    name: 'OrderEdit',
    components: {
        Notes,
        SelectDefinition,
        Address,
        Payments,
        OrderReturns,
        Products,
        Campaigns,
        Buttons,
        Statuses,
        Customer,
    },
    data() {
        return {
            itemLoading: true,
            modalLoading: false,
            form: {
                //
            },

            modalOrderVisible : false,
            modalPaymentVisible : false,
            modalDeliveryVisible : false,

            paymentPlugins : [],
            paymentGates: [
                {
                    label : 'Türkiye İş Bankası A.Ş.',
                },
                {
                    label : 'Garanti Bankasi A.Ş.',
                },
            ]
        }
    },

    methods: {
        loadItem() {
            this.itemLoading = true;

            this.$http
                .get(this.$route.meta.end_point + '/' + this.$route.params.id)
                .then(response => {
                    this.itemLoading = false;
                    this.form = response.body.item;
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        updateItem() {

            this.itemLoading = true;
            this.modalLoading = true;

            this.$http
                .put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                .then(response => {
                    this.itemLoading = false;
                    this.modalLoading = false;
                    this.modalOrderVisible = false;
                    this.modalDeliveryVisible = false;
                    this.modalPaymentVisible = false;

                    this.form.year = response.body.item.year;

                    this.apiSuccessMessage(response);
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.modalLoading = false;

                    this.apiErrorMessage(e);
                });
        },

        getPaymentPlugins() {

            this.itemLoading = true;

            this.$http
                .get('tools/plugins/payments')
                .then(response => {
                    this.itemLoading = false;
                    this.paymentPlugins = response.body.rows;
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },
    },

    mounted() {
        this.loadItem();
        this.getPaymentPlugins();
    }
}
</script>
