<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading">
            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <div class="actions">
                        <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                            <el-button @click="$router.go(-1)" type="text" icon="el-icon-back"
                                       class="el-button-link grey"/>
                        </el-tooltip>
                    </div>
                </header>
                <section class="card-box-content padding-20">
                    <el-form :label-position="'top'" ref="form" :model="form">

                        <el-row>
                            <el-col :span="11" v-for="item in items" :key="item.name"
                                    style="cursor: pointer; margin-right: 20px; margin-bottom: 20px">
                                <el-card>
                                    <div style="padding: 14px;" @click="storeItem(item.name)">
                                        <div>{{ item.title }}</div>
                                        <div>{{ item.description }}</div>
                                    </div>
                                </el-card>
                            </el-col>
                        </el-row>

                    </el-form>
                </section>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
    export default {
        name: 'ElementForm',
        props: ['source'],
        data() {
            return {
                itemLoading: true,
                endPoint: null,
                items: [],
                form: {
                    //
                }
            }
        },

        methods: {
            getItems() {

                this.itemLoading = true;

                this.$http
                    .get(this.endPoint + '/create')
                    .then(response => {
                        this.itemLoading = false;
                        this.items = response.body.items;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            storeItem(name) {

                this.itemLoading = true;

                this.form.name = name

                this.$http
                    .post(this.endPoint, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                        this.$router.push('edit/' + response.body.item.id);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            }
        },

        mounted() {

            this.endPoint = this.$route.meta.end_point + this.$route.params.type;

            this.getItems();
        }
    }
</script>