<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="pageLoading">
            <div v-if="form.id">
                <div class="card-box">
                    <header class="card-box-header">
                        <div class="title d-flex" style="align-items: center">
                            <div class="mr-10">
                                <avatar :img="form.avatar" type="student" class="ui image mini" />
                            </div>
                            <div>
                                {{ form.first_name }} {{ form.last_name }}
                            </div>
                        </div>
                        <div class="actions">
                            <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                                <el-button @click="$router.go(-1)" type="text" icon="el-icon-back" class="el-button-link grey"/>
                            </el-tooltip>
                        </div>
                    </header>
                </div>
                <el-row :gutter="24" >
                    <el-col :span="16">
                        <QuizReport :source="'school/students/' + form.id" />
                        <MonthlyReport :source="'school/students/' + form.id" />
                    </el-col>
                    <el-col :span="8">
                        <Notes :url="'crm/accounts/' + form.account_id" :key="form.account_id"/>
                    </el-col>
                </el-row>
            </div>
        </div>
    </vue-scroll>
</template>

<script>
    import QuizReport from "../../../../School/Students/_parts/QuizReport";
    import MonthlyReport from "../../../../School/Students/_parts/MonthlyReport";
    import Notes from "../../../../../../components/Notes";

    export default {
        name: 'ElementForm',
        components: {Notes, MonthlyReport, QuizReport},
        data() {
            return {
                pageLoading: true,
                form: {
                    //
                }
            }
        },

        methods: {
            loadItem() {

                this.pageLoading = true;

                this.$http
                    .get(this.$route.meta.end_point + '/' + this.$route.params.id)
                    .then(response => {
                        this.pageLoading = false;
                        this.form = response.body.item
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.pageLoading = true;

                this.$http
                    .put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },
        },

        mounted() {
            this.loadItem()
        }
    }
</script>