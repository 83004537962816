<template>
    <div v-loading="itemLoading">

        <div class="card-box">
            <header class="card-box-header">
                <div class="title">
                    {{ title }}
                </div>
                <div class="actions">
                    <el-popover placement="bottom-end" width="400" :title="title">
                        <template slot="reference">
                            <el-button icon="el-icon-plus" type="text" class="el-button-link primary" />
                        </template>
                        <div class="app-card-pop" v-loading="itemLoading">
                            <div class="item-title">
                                <el-select
                                        @change="store"
                                        v-model="form.type_id"
                                        filterable
                                        remote
                                        reserve-keyword
                                        :placeholder="title"
                                        :remote-method="search"
                                        :loading="searchLoading">
                                    <el-option v-for="item in searchItems" :key="item.id" :label="item.title"
                                               :value="item.id"/>
                                </el-select>
                            </div>
                        </div>
                    </el-popover>
                </div>
            </header>
            <section class="card-box-content">
                <div class="box-list">
                    <div class="box-list-item" v-for="item in items" :key="item.id" v-if="item.type === type">
                        <div class="name fluid">
                            {{ item.type_item.title }}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Items',
        props: ['cardId', 'title', 'type', 'alias', 'searchPath'],
        data() {
            return {
                itemLoading: true,
                searchLoading: false,
                form: {
                    //
                },
                items: [],
                searchItems: [],
            }
        },

        methods: {
            search(query) {
                this.searchLoading = true;

                this.$http
                    .get(this.searchPath, {
                        params: {
                            q: query,
                            alias : this.alias
                        }
                    })
                    .then(response => {
                        this.searchLoading = false;
                        this.searchItems = response.body.items;
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.searchLoading = false;

                        this.apiErrorMessage(e);
                    });
            },

            index() {

                this.itemLoading = true;

                this.$http
                    .get('cards/cards/' + this.cardId + '/items')
                    .then(response => {
                        this.itemLoading = false;
                        this.items = response.body.items
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            store() {
                this.itemLoading = true;

                this.form.fake_url = 'v1/cards/cards/' + this.cardId;
                this.form.type = this.type;

                this.$http
                    .post('cards/cards/' + this.cardId + '/items', this.form)
                    .then(response => {
                        this.itemLoading = false;

                        this.items.push(response.body.item);

                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;

                        this.apiErrorMessage(e);
                    });
            },

            destroy(id) {
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                    confirmButtonText: 'Tamam',
                    cancelButtonText: 'Iptal Et',
                    type: 'warning'
                })
                    .then(() => {
                        this.$http
                            .delete('cards/cards/' +this.cardId + '/items/' + id)
                            .then(response => {
                                this.itemLoading = false;
                                this.apiSuccessMessage(response);

                                this.index();
                            })
                            .catch((e) => {
                                this.itemLoading = false;

                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            },
        },

        mounted() {
            this.index()
        }
    }
</script>
