<template>
	<vue-scroll class="page-element-form">
		<div class="loading-container" v-loading="pageLoading">
			<el-form :label-position="'top'" ref="form" :model="form">
				<div class="card-box">
					<header class="card-box-header">
						<div class="title">
							{{ $route.meta.title }}
						</div>
						<div class="actions">
							<crud-buttons :store-item="store" :update-item="update" />
						</div>
					</header>
					<section class="card-box-content">
						<div class="box-list">
							<div class="box-list-item box-list-item-title">
								<div class="name">Metin</div>
							</div>
						</div>
						<div class="sub-title-container">
							<el-form-item label="Başlık">
								<el-input v-model="form.title" placeholder="Başlık" />
							</el-form-item>

							<el-form-item label="Açıklama">
								<el-input v-model="form.description" placeholder="Açıklama" rows="3" type="textarea" />
							</el-form-item>

							<el-form-item label="Sıralama">
								<el-input-number v-model="form.sort" placeholder="Sıralama" />
							</el-form-item>
						</div>
						<div class="box-list">
							<div class="box-list-item box-list-item-title">
								<div class="name">Genel</div>
							</div>
						</div>
						<div class="sub-title-container">
							<el-form-item label="İndirim Tipi">
								<lv-select :container="form" model="type" :options="campaignTypes" />
							</el-form-item>

							<el-form-item label="Kod">
								<el-input placeholder="Kod" v-model="code" class="input-with-select" readonly="">
									<el-button slot="append" icon="el-icon-search" type="success"
											   @click="generateCode" />
								</el-input>
							</el-form-item>
						</div>
						<div class="box-list">
							<div class="box-list-item box-list-item-title">
								<div class="name">Fiyat</div>
							</div>
						</div>
						<div class="sub-title-container">
							<el-form-item label="İndirim Orani">
								<el-row :gutter="24">
									<el-col :span="4">
										<lv-select :container="form" model="discount_type" :options="discountTypes" />
									</el-col>
									<el-col :span="20">
										<el-input v-model="form.discount" placeholder="Oran" />
									</el-col>
								</el-row>
							</el-form-item>

							<el-form-item label="Ekstra İndirim Orani">
								<el-row :gutter="24">
									<el-col :span="4">
										<lv-select :container="form" model="discount_type2" :options="discountTypes" />
									</el-col>
									<el-col :span="20">
										<el-input v-model="form.discount2" placeholder="Oran" />
									</el-col>
								</el-row>
							</el-form-item>
						</div>

						<div class="box-list">
							<div class="box-list-item box-list-item-title">
								<div class="name">Ozel Durumlar</div>
							</div>
						</div>
						<div class="sub-title-container">
							<el-form-item label="Ozel Durum">
								<lv-select :container="form" model="special_process" :options="specialItems" />
							</el-form-item>
						</div>

						<div class="box-list">
							<div class="box-list-item box-list-item-title">
								<div class="name">Genel Kisitlamalar</div>
							</div>
						</div>
						<div class="sub-title-container">
							<el-row :gutter="24">
								<el-col :span="12">
									<el-form-item label="Egitim Yili">
										<select-definition label="Egitim Yili" alias="education-years"
														   model="education_year_definition_id" :container="form" />
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="Egitim Donemi">
										<select-definition label="Egitim Donemi" alias="education-periods"
														   model="education_period_definition_id" :container="form" />
									</el-form-item>
								</el-col>
							</el-row>

							<el-row :gutter="24">
								<el-col :span="12">
									<el-form-item label="Baslangic Tarihi">
										<el-date-picker v-model="form.start_at" type="datetime"
														placeholder="Tarih Seçiniz"
														value-format="yyyy-MM-dd HH:mm:ss" />
									</el-form-item>
								</el-col>

								<el-col :span="12">
									<el-form-item label="Bitis Tarihi">
										<el-date-picker v-model="form.end_at" type="datetime"
														placeholder="Tarih Seçiniz"
														value-format="yyyy-MM-dd HH:mm:ss" />
									</el-form-item>
								</el-col>
							</el-row>

							<el-form-item label="Minimum Sepet Tutarı">
								<el-input v-model="form.minimum_price" placeholder="Minimum Sepet Tutarı" />
							</el-form-item>

							<el-form-item label="Minimum Baglilik">
								<el-input v-model="form.loyalty_year" placeholder="Minimum Baglilik" />
							</el-form-item>

							<el-row :gutter="24">
								<el-col :span="12">
									<el-form-item label="Kullanim Limiti">
										<el-input v-model="form.max_usage_count" placeholder="Kullanim Limiti" />
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="Kullanilan">
										<el-input v-model="form.current_usage_count" placeholder="Kullanilan"
												  disabled />
									</el-form-item>
								</el-col>
							</el-row>
						</div>

						<div class="box-list">
							<div class="box-list-item box-list-item-title">
								<div class="name">Kosula Bagli Kisitlamalar</div>
							</div>
						</div>
						<div class="sub-title-container">
							<el-form-item>
								<el-switch v-model="form.is_active" active-text="Aktif" />
							</el-form-item>

							<el-form-item>
								<el-switch v-model="form.is_single"
										   active-text="Bunun dışında başka indirim varsa kullanma" />
							</el-form-item>

							<el-form-item>
								<el-switch v-model="form.is_only_offer" active-text="Teklif de Kullan" />
							</el-form-item>

							<el-form-item>
								<el-switch v-model="form.is_only_basket" active-text="Sepet de Kullan" />
							</el-form-item>

							<el-form-item>
								<el-switch v-model="form.is_only_vehicles"
										   active-text="Sadece Servis Ücretlerinde Kullan" />
							</el-form-item>

							<el-form-item>
								<el-switch v-model="form.is_only_classrooms"
										   active-text="Sadece Sınıf Ücretlerinde Kullan" />
							</el-form-item>

							<el-form-item>
								<el-switch v-model="form.is_only_package_price"
										   active-text="Sadece Sınıf Eğitim Ücretlerinde Kullan" />
							</el-form-item>

							<el-form-item>
								<el-switch v-model="form.is_only_sibling"
										   active-text="Birden Fazla Kardesi Olan Ogrencilerde Kullan" />
							</el-form-item>
						</div>
					</section>
				</div>

				<div class="card-box" v-if="form.id">
					<header class="card-box-header">
						<div class="title">
							Iliskilere Bagli Kisitlamalar
						</div>
					</header>
					<section class="card-box-content card-box-tabs padded small">
						<el-tabs :tab-position="'top'">
							<el-tab-pane label="Odeme Yontemi">
								<el-form-item>
									<el-switch v-model="form.is_payment" active-text="Etkinlestir" />
								</el-form-item>
								<el-transfer
										v-if="form.is_payment"
										filterable
										:filter-method="filterMethod"
										filter-placeholder="Odeme Yontemlerin de de Ara"
										:titles="['Tüm Odeme Yontemleri', 'Seçili Olanlar']"
										v-model="form.payments"
										:props="{key: 'id', label: 'title'}"
										:data="payments" />
							</el-tab-pane>
							<el-tab-pane label="Kurum">
								<el-form-item>
									<el-switch v-model="form.is_company" active-text="Etkinlestir" />
								</el-form-item>
								<el-transfer
										v-if="form.is_company"
										filterable
										:filter-method="filterMethod"
										filter-placeholder="Sirketler de Ara"
										:titles="['Tüm Sirketler', 'Seçili Olanlar']"
										v-model="form.companies"
										:props="{key: 'id', label: 'title'}"
										:data="companies" />
							</el-tab-pane>
							<el-tab-pane label="Meslek">
								<el-form-item>
									<el-switch v-model="form.is_job" active-text="Etkinlestir" />
								</el-form-item>
								<el-transfer
										v-if="form.is_job"
										filterable
										:filter-method="filterMethod"
										filter-placeholder="Meslekler de Ara"
										:titles="['Tüm Meslekler', 'Seçili Olanlar']"
										v-model="form.jobs"
										:props="{key: 'id', label: 'title'}"
										:data="jobs" />
							</el-tab-pane>
							<el-tab-pane label="Rol">
								<el-form-item>
									<el-switch v-model="form.is_role" active-text="Etkinlestir" />
								</el-form-item>
								<el-transfer
										v-if="form.is_role"
										filterable
										:filter-method="filterMethod"
										filter-placeholder="Roller de Ara"
										:titles="['Tüm Roller', 'Seçili Olanlar']"
										v-model="form.roles"
										:props="{key: 'id', label: 'title'}"
										:data="roles" />
							</el-tab-pane>
							<el-tab-pane label="Sinif">
								<el-form-item>
									<el-switch v-model="form.is_class" active-text="Etkinlestir" />
								</el-form-item>
								<el-transfer
										v-if="form.is_class"
										filterable
										:filter-method="filterMethod"
										filter-placeholder="Sinfilar da Ara"
										:titles="['Tüm Siniflar', 'Seçili Olanlar']"
										v-model="form.classes"
										:props="{key: 'id', label: 'title'}"
										:data="classes" />
							</el-tab-pane>
							<el-tab-pane label="Kategori">
								<el-form-item>
									<el-switch v-model="form.is_category" active-text="Etkinlestir" />
								</el-form-item>

								<el-transfer
										v-if="form.is_category"
										filterable
										:filter-method="filterMethod"
										filter-placeholder="Kategoriler de Ara"
										:titles="['Tüm Kategoriler', 'Seçili Olanlar']"
										v-model="form.categories"
										:props="{key: 'id', label: 'nest_title'}"
										:data="categories" />
							</el-tab-pane>
							<el-tab-pane label="Urun">
								<el-form-item>
									<el-switch v-model="form.is_product" active-text="Etkinlestir" />
								</el-form-item>

								<el-form-item label="Minimum Sepet Urun Adeti">
									<el-input v-model="form.min_product" placeholder="Minimum Sepet Urun Adeti" />
								</el-form-item>

								<el-transfer
										v-if="form.is_product"
										filterable
										:filter-method="filterMethod"
										filter-placeholder="Ürünlerde Ara"
										:titles="['Tüm ürünler', 'Seçili Olanlar']"
										v-model="form.products"
										:props="{key: 'id', label: 'title'}"
										:data="products" />
							</el-tab-pane>
						</el-tabs>

					</section>
				</div>
			</el-form>
		</div>
	</vue-scroll>
</template>

<script>
    export default {
        name: 'ElementForm',
        data() {
            return {
                pageLoading: true,
                form: {
                    //
                },

                code: null,

                campaignTypes: [
                    {
                        value: 2,
                        label: 'Genel'
                    },
                    {
                        value: 4,
                        label: 'Ozel'
                    },
                    {
                        value: 3,
                        label: 'Kupon'
                    }
                ],
                discountTypes: [
                    {
                        value: 1,
                        label: 'Fiyat - TL'
                    },
                    {
                        value: 2,
                        label: 'Yüzde - %'
                    },
                ],
                specialItems: [
                    {
                        value: 'summer',
                        label: 'Yaz / Kis Donemi Paketi'
                    },
                ],
                payments: [],
                companies: [],
                roles: [],
                products: [],
                categories: [],
                jobs: [],
                classes: [],
            }
        },

        mounted() {
            this.payments = this.$store.state.ui.payments;
            this.companies = this.$store.state.ui.companies;
            this.roles = this.$store.state.ui.roles;
            this.jobs = this.$store.state.ui.definitions.jobs;
            this.classes = this.$store.state.ui.definitions.classes;

            this.getProducts();
            this.getCategories();

            if (this.$route.params.id) {
                this.show()
            } else {
                this.pageLoading = false;
            }
        },

        methods: {
            getProducts() {
                this.pageLoading = true;

                this.$http
                    .get('tools/search/products')
                    .then(response => {
                        this.pageLoading = false;
                        this.products = response.body.items
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getCategories() {
                this.pageLoading = true;

                this.$http
                    .get('tools/search/categories')
                    .then(response => {
                        this.pageLoading = false;
                        this.categories = response.body.items;
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            show() {
                this.pageLoading = true;

                this
                    .$http
                    .get(this.$route.meta.end_point + '/' + this.$route.params.id, {
                        params: this.serverParams
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.form = response.body.item;
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            store() {
                this.pageLoading = true;

                this.$http
                    .post(this.$route.meta.end_point, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.apiSuccessMessage(response);
                        this.$router.push('edit/' + response.body.item.id);
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            update() {
                this.pageLoading = true;

                this.$http
                    .put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.pageLoading = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            generateCode() {
                this.code = (Math.random().toString(36).substring(2, 8)).toUpperCase();
                this.form.code = this.code;
            },

            filterMethod(query, item) {
                return item.title.toLowerCase().indexOf(query.toLowerCase()) > -1;
            },
        }
    }
</script>

<style lang="scss">
	.sub-title-container {
		padding : 10px 20px !important;
	}
</style>
