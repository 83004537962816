<template>
    <vue-scroll class="page-element-form" v-loading="itemLoading">
        <div class="loading-container">
            <div v-if="form.id">

                <div class="card-box">
                    <header class="card-box-header">
                        <div class="title">
                            #{{ form.id }}
                            <div>
                                {{ $d(new Date(form.created_at), 'long', 'tr-TR') }}
                            </div>
                        </div>
                        <div class="actions">
                            <el-tooltip content="Geri Don" effect="dark" placement="bottom">
                                <el-button @click="$router.go(-1)" type="text" icon="el-icon-back" class="el-button-link grey"/>
                            </el-tooltip>

                            <el-tooltip content="Olustur" effect="dark" placement="bottom" v-if="$route.params.id">
                                <el-button @click="createButton($route.meta.end_point)" type="text" icon="el-icon-plus" class="el-button-link primary"/>
                            </el-tooltip>
                        </div>
                    </header>
                </div>

                <el-row :gutter="24">
                    <el-col :span="form.student ? 12 : 24">
                        <div class="card-box">
                            <header class="card-box-header">
                                <div class="title">
                                    Musteri Bilgileri
                                </div>
                            </header>
                            <section class="card-box-content" >
                                <div class="box-list">
                                    <div class="box-list-item box-list-item-editable box-list-item-media">
                                        <div class="box-list-item-media-item">
                                            <div class="media">
                                                <img src="@/resources/images/customer.png" alt="avatar">
                                            </div>
                                            <div class="text fluid">
                                                <div class="name">
                                                    <span class="route-link"
                                                          @click="openBlankWindow('/crm/users/edit/' + form.customer_id)">
                                                        {{ form.first_name }} {{ form.last_name }}
                                                    </span>
                                                </div>
                                                <div class="email" v-if="form.customer">
                                                    <SingleDraft :userId="form.customer.id" :receiver="form.customer.email" alias="email"/>
                                                </div>
                                                <div class="phone" v-if="form.customer && form.customer.address">
                                                    <SingleDraft :userId="form.customer.id" :receiver="form.customer.address.phone" alias="sms"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="card-box" v-if="form.student">
                            <header class="card-box-header">
                                <div class="title">
                                    Ogrenci Bilgileri
                                </div>
                            </header>
                            <section class="card-box-content">
                                <div class="box-list">
                                    <div class="box-list-item box-list-item-editable box-list-item-media">
                                        <div class="box-list-item-media-item">
                                            <div class="media">
                                                <img src="@/resources/images/student.png" alt="avatar">
                                            </div>
                                            <div class="text fluid">
                                                <div class="name">
                                                    <span class="route-link"
                                                          @click="openBlankWindow('/school/students/edit/' + form.student.id)">
                                                        {{ form.student.full_name }}
                                                        <span v-if="form.student.gender">
                                                            ({{ form.student.gender.title }})
                                                        </span>
                                                    </span>
                                                </div>
                                                <div class="email">
                                                    {{ form.student.identification_no }}
                                                </div>
                                                <div class="email">
                                                    {{ form.student.birthday }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </el-col>
                </el-row>

                <el-form :label-position="'top'" ref="form" :model="form">
                    <el-row :gutter="24">
                        <el-col :span="16">

                            <Products :offer="form" />

                            <div class="card-box">
                                <header class="card-box-header">
                                    <div class="title">
                                        Odeme Takvimi
                                    </div>
                                </header>
                                <section class="card-box-content">
                                    <div class="box-list">
                                        <div class="box-list-item" v-for="item in form.plains">
                                            <div class="name fluid">
                                                {{ item.id }}. Taksit
                                                [{{ item.payment_at }}]
                                            </div>
                                            <div class="price">{{ $n(item.price, 'currency') }}</div>
                                        </div>
                                    </div>
                                </section>
                            </div>

                            <Notes :url="'crm/accounts/' + form.account_id" :key="form.account_id"/>
                        </el-col>
                        <el-col :span="8">
                            <div class="ui icon buttons large fluid" style="margin-bottom: 30px">
                                <el-tooltip content="Teklif Formunu Yazdir" effect="dark" placement="bottom">
                                    <div class="ui button" @click="viewPrint">
                                        <i class="icon el-icon-printer"></i>
                                    </div>
                                </el-tooltip>

                                <el-tooltip content="Siparis Olustur" effect="dark" placement="bottom">
                                    <div class="ui button green" @click="createOrder">
                                        <i class="icon el-icon-sell"></i>
                                    </div>
                                </el-tooltip>
                            </div>

                            <div
                                    @click="modalStatusVisible = true"
                                    class="status card-base card-shadow--medium widget small-widget margin-bottom-30"
                                    :style="'background-color: ' + form.status.bg_color + '; color: ' + form.status.text_color + ';cursor:pointer'"
                            >
                                <div class="widget-header">
                                    <div class="flex justify-center align-center">
                                        <div class="widget-info box grow text-truncate animated fadeInLeft">
                                            <div class="widget-title text-truncate mb-10">Teklif Durumu</div>
                                            <h2 class="m-0 text-truncate fs-22">{{ form.status.title }}</h2>
                                        </div>
                                        <div class="widget-icon-box ml-20 animated fadeInRight">
                                            <i :class="'widget-icon accent-text fs-30 ' + (form.status.icon ? form.status.icon : 'mdi mdi-label-outline')"
                                               :style="'color:' + form.status.text_color "></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Campaigns :campaigns="form.campaigns" :customCampaigns="form.custom_campaigns"/>

                            <div class="card-box">
                                <header class="card-box-header">
                                    <div class="title">
                                        Teklif
                                    </div>
                                </header>
                                <section class="card-box-content">
                                    <div class="box-list">
                                        <div class="box-list-item">
                                            <div class="name fluid">
                                                Gecerlilik Tarihi
                                            </div>
                                            <div class="price fluid text-right">
                                                {{ $d(new Date(form.expiry_at), 'short', 'tr-TR') }}
                                            </div>
                                        </div>
                                        <div class="box-list-item">
                                            <div class="name">
                                                Yil
                                            </div>
                                            <div class="price" v-if="form.year">
                                                {{ form.year.title }}
                                            </div>
                                        </div>
                                        <div class="box-list-item">
                                            <div class="name">
                                                Donem
                                            </div>
                                            <div class="price" v-if="form.period">
                                                {{ form.period.title }}
                                            </div>
                                        </div>
                                        <div class="box-list-item">
                                            <div class="name">
                                                O.Yontemi
                                            </div>
                                            <div class="price fluid text-right" v-if="form.payment">
                                                {{ form.payment.title }}
                                            </div>
                                        </div>
                                        <div class="box-list-item">
                                            <div class="name fluid">
                                                Ilk Odeme Tarihi
                                            </div>
                                            <div class="price fluid text-right">
                                                {{ $d(new Date(form.payment_start_at), 'short', 'tr-TR') }}
                                            </div>
                                        </div>
                                        <div class="box-list-item">
                                            <div class="name fluid">
                                                Taksit Sayisi
                                            </div>
                                            <div class="price">
                                                {{ form.payment_plain }}
                                            </div>
                                        </div>
                                        <div class="box-list-item">
                                            <div class="name fluid">
                                                Urun Toplamı
                                            </div>
                                            <div class="price">
                                                {{ $n(form.product_total, 'currency') }}
                                            </div>
                                        </div>
                                        <div class="box-list-item">
                                            <div class="name fluid">
                                                Ürün İndirim Toplamı
                                            </div>
                                            <div class="price">
                                                {{ $n(form.product_discount_total, 'currency') }}
                                            </div>
                                        </div>
                                        <div class="box-list-item">
                                            <div class="name fluid">
                                                Indirim Toplamı
                                            </div>
                                            <div class="price">
                                                {{ $n(form.discount_total, 'currency') }}
                                            </div>
                                        </div>
                                        <div class="box-list-item">
                                            <div class="name fluid">
                                                Toplam
                                            </div>
                                            <div class="price text-right"
                                                 style="font-size: 16px; font-weight: 600; color: #5C50B9;">
                                                {{ $n(form.total, 'currency') }}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </el-col>
                    </el-row>
                </el-form>

                <el-dialog title="Durum Değiştir" :visible.sync="modalStatusVisible" v-loading="modalStatusLoading">
                    <el-form :model="form">
                        <el-form-item label="Durum">
                            <el-select v-model="form.status_id" value-key="id" placeholder="Durum">
                                <el-option v-for="item in statuses" :key="item.id" :label="item.title"
                                           :value="item.id"/>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">

                        <el-button type="success" size="medium" @click="updateItem" style="width: 100%">
                            <i class="mdi mdi-check"></i> Guncelle
                        </el-button>
                    </span>
                </el-dialog>
            </div>
        </div>

        <div style="height: 400px"></div>
    </vue-scroll>
</template>

<script>
    import Campaigns from "./_parts/Campaigns";
    import Products from "./_parts/Products";
    import Notes from "../../../../components/Notes";
    import SingleDraft from "../../../../components/SingleDraft";

    export default {
        name: 'OfferEdit',
        components: {
            SingleDraft,
            Notes,
            Products,
            Campaigns,
        },
        data() {
            return {
                itemLoading: true,
                modalStatusVisible: false,
                modalStatusLoading: true,
                statuses: [],
                form: {
                    student: null,
                    customer: null,
                }
            }
        },

        methods: {
            loadItem() {

                this.itemLoading = true;

                this.$http
                    .get(this.$route.meta.end_point + '/' + this.$route.params.id)
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body.item;
                    });
            },

            updateItem() {

                this.itemLoading = true;
                this.modalStatusLoading = true;

                this.$http.put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.modalStatusLoading = false;
                        this.modalStatusVisible = false;
                        this.apiSuccessMessage(response);

                        this.loadItem();
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.modalStatusLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            viewPrint() {
                return window.open(this.appConfig.frontPath + '/prints/offers/' + this.form.crypt_id, '_blank');
            },

            createOrder() {
                return this.$router.push('/sales/orders/create?offer_id=' + this.form.id);
            },

            getStatuses() {
                this.itemLoading = true;
                this.$http.get('system/statuses?alias=offers').then(response => {
                    this.itemLoading = false;
                    this.statuses = response.body.rows;
                });
            },
        },

        mounted() {
            this.loadItem();
            this.getStatuses();
        }
    }
</script>
