import layouts from '../config/layout'

import MasterContainer from '../app/Container/Master'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Dt'

import Role from '../app/Pages/Crm/Roles/Role'
import Company from '../app/Pages/Crm/Company'
import Address from '../app/Pages/Crm/Address'

import UserCreate from '../app/Pages/Crm/Users/Create'
import UserEdit from '../app/Pages/Crm/Users/Edit'

export default {
    path: '/crm',
    name: 'crm',
    component: MasterContainer,
    meta: {
        auth: true,
        layout: layouts.navLeft,
        title: 'Crm',
    },
    children: [
        {
            path: 'roles',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Roller',
            },
            children: [
                {
                    name: 'crm.roles.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Roller',
                        path: 'roles',
                        end_point: 'crm/roles',
                    },
                },
                {
                    name: 'crm.roles.create',
                    path: 'create',
                    component: Role,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Rol Olustur',
                        path: 'roles',
                        end_point: 'crm/roles',
                    }
                },
                {
                    name: 'crm.roles.edit',
                    path: 'edit/:id',
                    component: Role,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Rol Bilgileri',
                        path: 'roles',
                        end_point: 'crm/roles',
                    }
                }
            ]
        },
        {
            path: 'companies',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Sirketler',
            },
            children: [
                {
                    name: 'crm.companies.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Sirketler',
                        path: 'companies',
                        end_point: 'crm/companies',
                    },
                },
                {
                    name: 'crm.companies.create',
                    path: 'create',
                    component: Company,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Sirket Olustur',
                        path: 'companies',
                        end_point: 'crm/companies',
                    }
                },
                {
                    name: 'crm.companies.edit',
                    path: 'edit/:id',
                    component: Company,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Sirket Bilgileri',
                        path: 'companies',
                        end_point: 'crm/companies',
                    }
                }
            ]
        },
        {
            path: 'addresses',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Kullanici Adresleri',
            },
            children: [
                {
                    name: 'crm.addresses.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Kullanici Adresleri',
                        path: 'addresses',
                        end_point: 'crm/addresses',
                    },
                },
                {
                    name: 'crm.addresses.edit',
                    path: 'edit/:id',
                    component: Address,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Kullanici Adres Bilgileri',
                        path: 'addresses',
                        end_point: 'crm/addresses',
                    }
                }
            ]
        },
        {
            path: 'users',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Veliler',
            },
            children: [
                {
                    name: 'crm.users.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Veliler',
                        path: 'users',
                        end_point: 'crm/users',
                    },
                },
                {
                    name: 'crm.users.create',
                    path: 'create',
                    component: UserCreate,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Veli Olustur',
                        path: 'users',
                        end_point: 'crm/users',
                    }
                },
                {
                    name: 'crm.users.edit',
                    path: 'edit/:id',
                    component: UserEdit,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Veli Bilgileri',
                        path: 'users',
                        end_point: 'crm/users',
                    }
                },
            ]
        },
    ]
}
