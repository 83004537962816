import layouts from '../config/layout'

import MasterContainer from '../app/Container/Master'
import TeacherContainer from '../app/Container/Teacher'
import DatatableContainer from '../app/Container/Dt'

import Dashboard from '../app/Pages/Platform/Teacher/Dashboard'
import ContentCreate from '../app/Pages/Platform/Teacher/Contents/Create'
import ContentEdit from '../app/Pages/Platform/Teacher/Contents/Edit'
import GalleryForm from '../app/Pages/Platform/Teacher/Galleries/Form'
import ClassroomIndex from '../app/Pages/Platform/Teacher/Classrooms/Index'
import StudentShow from '../app/Pages/Platform/Teacher/Classrooms/students/Show'

export default {
    path: '/teacher',
    name: 'teacher',
    component: TeacherContainer,
    meta: {
        auth: true,
        layout: layouts.contenOnly,
        title: 'Teacher Platform',
    },
    children: [
        {
            path: 'dashboard',
            name: 'teacher.dashboard.index',
            component: Dashboard,
            meta: {
                auth: true,
                layout: layouts.contenOnly,
                title: 'Dashboard',
            },
        },
        {
            path: 'videos',
            component: MasterContainer,
            meta: {
                auth: true,
                layout: layouts.contenOnly,
                title: 'Videolar',
            },
            children: [
                {
                    name: 'platform.teacher.videos.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Videolar',
                        path: 'videos',
                        end_point: 'platform/teacher/videos',
                        queryAppend: [
                            {
                                field: 'products',
                                element: 'teacher.classroomIds'
                            }
                        ],
                    },
                },
                {
                    name: 'platform.teacher.videos.create',
                    path: 'create',
                    component: ContentCreate,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Video Ekle',
                        path: 'videos',
                        end_point: 'platform/teacher/videos',
                        fieldTitle: 'Videolar',
                    }
                },
                {
                    name: 'platform.teacher.videos.edit',
                    path: 'edit/:id',
                    component: ContentEdit,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Video Bilgileri',
                        path: 'videos',
                        end_point: 'platform/teacher/videos',
                        fieldTitle: 'Videolar',
                    }
                }
            ]
        },
        {
            path: 'sounds',
            component: MasterContainer,
            meta: {
                auth: true,
                layout: layouts.contenOnly,
                title: 'Sesler',
            },
            children: [
                {
                    name: 'platform.teacher.sounds.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Sesler',
                        path: 'sounds',
                        end_point: 'platform/teacher/sounds',
                        queryAppend: [
                            {
                                field: 'products',
                                element: 'teacher.classroomIds'
                            }
                        ],
                    },
                },
                {
                    name: 'platform.teacher.sounds.create',
                    path: 'create',
                    component: ContentCreate,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Ses Ekle',
                        path: 'sounds',
                        end_point: 'platform/teacher/sounds',
                        fieldTitle: 'Sesler',
                    }
                },
                {
                    name: 'platform.teacher.sounds.edit',
                    path: 'edit/:id',
                    component: ContentEdit,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Ses Bilgileri',
                        path: 'sounds',
                        end_point: 'platform/teacher/sounds',
                        fieldTitle: 'Sesler',
                    }
                }
            ]
        },
        {
            path: 'readings',
            component: MasterContainer,
            meta: {
                auth: true,
                layout: layouts.contenOnly,
                title: 'Okuma Parcalari',
            },
            children: [
                {
                    name: 'platform.teacher.readings.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Okuma Parcalari',
                        path: 'readings',
                        end_point: 'platform/teacher/readings',
                        queryAppend: [
                            {
                                field: 'products',
                                element: 'teacher.classroomIds'
                            }
                        ],
                    },
                },
                {
                    name: 'platform.teacher.readings.create',
                    path: 'create',
                    component: ContentCreate,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Okuma Parcasi Ekle',
                        path: 'readings',
                        end_point: 'platform/teacher/readings',
                        fieldTitle: 'Okuma Parcalari',
                    }
                },
                {
                    name: 'platform.teacher.readings.edit',
                    path: 'edit/:id',
                    component: ContentEdit,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Okuma Parcasi Bilgileri',
                        path: 'readings',
                        end_point: 'platform/teacher/readings',
                        fieldTitle: 'Okuma Parcalari',
                    }
                }
            ]
        },
        {
            path: 'games',
            component: MasterContainer,
            meta: {
                auth: true,
                layout: layouts.contenOnly,
                title: 'Oyunlar',
            },
            children: [
                {
                    name: 'platform.teacher.games.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Oyunlar',
                        path: 'games',
                        end_point: 'platform/teacher/games',
                        queryAppend: [
                            {
                                field: 'products',
                                element: 'teacher.classroomIds'
                            }
                        ],
                    },
                },
                {
                    name: 'platform.teacher.games.create',
                    path: 'create',
                    component: ContentCreate,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Oyun Ekle',
                        path: 'games',
                        end_point: 'platform/teacher/games',
                        fieldTitle: 'Oyunlar',
                    }
                },
                {
                    name: 'platform.teacher.games.edit',
                    path: 'edit/:id',
                    component: ContentEdit,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Oyun Bilgileri',
                        path: 'games',
                        end_point: 'platform/teacher/games',
                        fieldTitle: 'Oyunlar',
                    }
                }
            ]
        },
        {
            path: 'homeworks',
            component: MasterContainer,
            meta: {
                auth: true,
                layout: layouts.contenOnly,
                title: 'Odevler',
            },
            children: [
                {
                    name: 'platform.teacher.homeworks.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Odevler',
                        path: 'homeworks',
                        end_point: 'platform/teacher/homeworks',
                        queryAppend: [
                            {
                                field: 'products',
                                element: 'teacher.classroomIds'
                            }
                        ],
                    },
                },
                {
                    name: 'platform.teacher.homeworks.create',
                    path: 'create',
                    component: ContentCreate,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Odev Ekle',
                        path: 'homeworks',
                        end_point: 'platform/teacher/homeworks',
                        fieldTitle: 'Odevler',
                    }
                },
                {
                    name: 'platform.teacher.homeworks.edit',
                    path: 'edit/:id',
                    component: ContentEdit,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Odev Bilgileri',
                        path: 'homeworks',
                        end_point: 'platform/teacher/homeworks',
                        fieldTitle: 'Odevler',
                    }
                }
            ]
        },
        {
            path: 'newsletters',
            component: MasterContainer,
            meta: {
                auth: true,
                layout: layouts.contenOnly,
                title: 'Bilgilendirme Mektuplari',
            },
            children: [
                {
                    name: 'platform.teacher.newsletters.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Bilgilendirme Mektuplari',
                        path: 'newsletters',
                        end_point: 'platform/teacher/newsletters',
                        queryAppend: [
                            {
                                field: 'products',
                                element: 'teacher.classroomIds'
                            }
                        ],
                    },
                },
                {
                    name: 'platform.teacher.newsletters.create',
                    path: 'create',
                    component: ContentCreate,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Mektup Ekle',
                        path: 'newsletters',
                        end_point: 'platform/teacher/newsletters',
                        fieldTitle: 'Bilgilendirme Mektuplari',
                    }
                },
                {
                    name: 'platform.teacher.newsletters.edit',
                    path: 'edit/:id',
                    component: ContentEdit,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Mektup Bilgileri',
                        path: 'newsletters',
                        end_point: 'platform/teacher/newsletters',
                        fieldTitle: 'Bilgilendirme Mektuplari',
                    }
                }
            ]
        },
        {
            path: 'galleries',
            component: MasterContainer,
            meta: {
                auth: true,
                layout: layouts.contenOnly,
                title: 'Galeriler',
            },
            children: [
                {
                    name: 'platform.teacher.galleries.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Galeriler',
                        path: 'galleries',
                        end_point: 'platform/teacher/galleries',
                        queryAppend: [
                            {
                                field: 'products',
                                element: 'teacher.classroomIds'
                            }
                        ],
                    },
                },
                {
                    name: 'platform.teacher.galleries.create',
                    path: 'create',
                    component: GalleryForm,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Galeri Ekle',
                        path: 'galleries',
                        end_point: 'platform/teacher/galleries',
                    }
                },
                {
                    name: 'platform.teacher.galleries.edit',
                    path: 'edit/:id',
                    component: GalleryForm,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Galeri Bilgileri',
                        path: 'galleries',
                        end_point: 'platform/teacher/galleries',
                    }
                }
            ]
        },
        {
            path: 'classrooms',
            component: MasterContainer,
            meta: {
                auth: true,
                layout: layouts.contenOnly,
                title: 'Siniflar',
            },
            children: [
                {
                    name: 'platform.teacher.classrooms.index',
                    path: '',
                    component: ClassroomIndex,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Siniflar',
                        path: 'classrooms',
                        end_point: 'platform/teacher/classrooms',
                        queryAppend: [
                            {
                                field: 'products',
                                element: 'teacher.classroomIds'
                            }
                        ],
                    },
                },
                {
                    name: 'platform.teacher.classrooms.students.show',
                    path: 'students/show/:id',
                    component: StudentShow,
                    meta: {
                        auth: true,
                        layout: layouts.contenOnly,
                        title: 'Ogrenci Bilgileri',
                        path: 'students',
                        end_point: 'platform/teacher/students',
                        queryAppend: [
                            {
                                field: 'products',
                                element: 'teacher.classroomIds'
                            }
                        ],
                    },
                },
            ]
        }
    ]
}