<template>
	<el-select v-model="container[model]" placeholder="Seçiminiz" :multiple="multiple" clearable filterable
			   @change="handleChange">
		<el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id" />
	</el-select>
</template>

<script>
    export default {
        name: 'TitleIdSelect',
        props: [
            'container',
            'model',
            'label',
            'multiple',
            'options',
            'change'
        ],
		data() {
			return {
			    items : [],
			}
		},
        mounted() {
            //
        },
        methods: {
            handleChange(e) {
                if (!this.change) {
                    return false;
                }

                this.change(e)
            }
        }
    };
</script>
