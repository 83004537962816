import layouts from '../config/layout'

import MasterContainer from '../app/Container/Master'

import Classroom from '../app/Pages/Reports/Classroom'
import Vehicle from '../app/Pages/Reports/Vehicle'
import Student from '../app/Pages/Reports/Student'
import MarketingStudent from '../app/Pages/Reports/MarketingStudent'
import Offer from '../app/Pages/Reports/Offer'
import Order from '../app/Pages/Reports/Order'
import Sale from '../app/Pages/Reports/Sale'
import Staff from '../app/Pages/Reports/Staff'

export default {
    path: '/reports',
    name: 'reports',
    component: MasterContainer,
    meta: {
        auth: true,
        layout: layouts.navLeft,
        title: 'Raporlar',
    },
    children: [
        {
            path: 'classrooms',
            name: 'reports.classrooms.index',
            component: Classroom,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Sinif Raporlari',
            },
        },
        {
            path: 'vehicles',
            name: 'reports.vehicles.index',
            component: Vehicle,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Servis Raporlari',
                end_point: 'reports/vehicles',
            },
        },
        {
            path: 'students',
            name: 'reports.students.index',
            component: Student,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Ogrenci Raporlari',
                end_point: 'reports/students',
            },
        },
        {
            path: 'marketing-students',
            name: 'reports.marketing_students.index',
            component: MarketingStudent,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Potansiyel Ogrenci Raporlari',
            },
        },
        {
            path: 'offers',
            name: 'reports.offers.index',
            component: Offer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Teklif Raporlari',
            },
        },
        {
            path: 'orders',
            name: 'reports.orders.index',
            component: Order,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Sipariş Raporları',
                end_point: 'reports/orders',
            },
        },
        {
            path: 'sales',
            name: 'reports.sales.index',
            component: Sale,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Satış Raporlari',
                end_point: 'reports/sales',
            },
        },
        {
            path: 'staffs',
            name: 'reports.staffs.index',
            component: Staff,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Personel Raporlari',
            },
        },
    ]
}
