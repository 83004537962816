<template>
    <div class="item">
        <el-button type="success" class="mr-10" @click="visible = true">
            Görüşme Başlat
        </el-button>
        <el-dialog title="Görüşme Başlat" :visible.sync="visible" width="40%" append-to-body>
            <el-form :label-position="'top'" ref="form" :model="form" @submit.native.prevent="store">
                <el-form-item label="Görüşme Konusu">
                    <select-definition placeholder="Görüşme Konusu" alias="interview-subjects" model="subject_definition_id" :container="form"/>
                </el-form-item>
                <el-form-item class="mb-0-i" label="Telefon Numarasi">
                    <el-input v-model="form.phone" placeholder="Telefon Numarasi" v-mask="'0 (###) ### ## ##'"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" :loading="loading" :disabled="loading" @click="store" class="fluid">
                    Kaydet
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'NewUser',
    data() {
        return {
            visible: false,
            loading: false,
            form: {
                phone: '',
            }
        };
    },
    methods: {
        store() {
            if (this.lodash.size(this.form.phone) !== 17) {
                return this.$notify.error({
                    title: 'Upss!',
                    message: 'Telefon numarasi alaninin doldurulmasi zorunludur!',
                    position: 'top-right'
                });
            }

            this.loading = true

            this.$http
                .post('enrollments/interviews', this.form)
                .then(response => {
                    this.loading = false;
                    this.visible = false;
                    this.form.phone = ''

                    this.$router.push('/enrollments/interviews?id=' + response.body.item.id)
                })
                .catch((e) => {
                    this.loading = false;
                    this.apiErrorMessage(e);
                });
        }
    }
}
</script>
