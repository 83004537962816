<template>
    <div class="order-page loading-container" v-loading="pageLoading">
        <template v-if="offer.offer_id">
            <div class="ui three item menu small mb-20">
                <div class="item font-weight-600" v-for="tab in ui.tabs" :key="tab.value"
                     :class="{active : tab.value === ui.activeTab}">
                    {{ tab.label }}
                </div>
            </div>
            <div class="my-flex mb-20" :style="{'justify-content' : ui.activeTab === 1 ? 'end' : 'space-between'}">
                <div class="ui labeled icon button default fw-500" @click="handleTabBack" v-if="ui.activeTab !== 1">
                    <i class="el-icon-arrow-left icon"></i> Geri Don
                </div>

                <div class="ui right labeled icon button green fw-500" @click="handleTabNext" v-if="ui.activeTab !== 3">
                    <i class="el-icon-arrow-right icon"></i> Devam Et
                </div>

                <div class="ui right labeled icon button green fw-500" @click="handlePay" v-if="ui.activeTab === 3">
                    <i class="el-icon-arrow-right icon"></i> Ödeme Yap
                </div>
            </div>
            <el-form :label-position="'top'" ref="form" :model="form">
                <div class="tab-body">
                    <div class="tab-item" title="Müşteri Bilgileri" v-show="ui.activeTab === 1">
                        <div class="card-box">
                            <header class="card-box-header">
                                <div class="title">Veli Bilgileri</div>
                                <div class="actions">
                                    <el-button @click="createParentAddress">Veli Ekle</el-button>
                                </div>
                            </header>
                            <section class="card-box-content">
                                <el-tabs type="border-card" v-model="ui.activeParentTab" closable @tab-remove="removeParent">
                                    <el-tab-pane label="Veli" :name="'parent'">
                                        <el-row :gutter="24">
                                            <el-col :span="8">
                                                <el-form-item label="Veli Adı" class="is-required" >
                                                    <el-input v-model="offer.customer.address.first_name" placeholder="Veli Adı"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Veli Soy Adı" class="is-required">
                                                    <el-input v-model="offer.customer.address.last_name" placeholder="Veli Soy Adı"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Yakınlık Derecesi" class="is-required">
                                                    <el-select v-model="offer.customer.address.title" filterable allow-create default-first-option placeholder="Yakınlık Derecesi">
                                                        <el-option v-for="item in ui.addressTitles" :key="item.label" :label="item.label" :value="item.label"/>
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Telefon" class="is-required">
                                                    <el-input v-model="offer.customer.address.phone" placeholder="Telefon Numarasi" v-mask="'0 (###) ### ## ##'"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="E-Posta" class="is-required">
                                                    <el-input v-model="offer.customer.address.email" placeholder="E-Posta"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="T.C. Kimlik Numarası" class="is-required">
                                                    <el-input v-model="offer.customer.address.identification_no" placeholder="T.C. Kimlik Numarası" v-mask="'###########'"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Kurum">
                                                    <ti-select :container="offer.customer.address" model="company_id" :options="$store.state.ui.companies"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Meslek">
                                                    <select-definition label="Meslek" alias="jobs" model="job_definition_id" :container="offer.customer.address"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="İş yeri">
                                                    <el-input v-model="offer.customer.address.company_name" placeholder="İş yeri"/>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Eğitim Durumu">
                                                    <select-definition label="Eğitim Durumu" alias="educations" model="education_definition_id" :container="offer.customer.address"/>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        <div class="box-list">
                                            <div class="box-list-item box-list-item-title font-weight-500">
                                                Adres Bilgileri
                                            </div>
                                        </div>
                                        <div class="pt-20">
                                            <zone-box :container="offer.customer.address"></zone-box>

                                            <el-form-item label="Adres" class="is-required">
                                                <el-input v-model="offer.customer.address.address_1" placeholder="Adres"/>
                                            </el-form-item>

                                            <el-row :gutter="24">
                                                <el-col :span="12">
                                                    <el-form-item label="Vergi Numarasi" class="mb-0-i">
                                                        <el-input v-model="offer.customer.address.tax_no" placeholder="Vergi Numarasi"/>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="12">
                                                    <el-form-item label="Vergi Dairesi" class="mb-0-i">
                                                        <el-input v-model="offer.customer.address.tax_center" placeholder="Vergi Dairesi"/>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </el-tab-pane>
                                    <template v-for="(address, index) in offer.addresses">
                                        <el-tab-pane :label="address.title" :name="'address-' + index">
                                            <el-row :gutter="24">
                                                <el-col :span="8">
                                                    <el-form-item label="Veli Adı" class="is-required">
                                                        <el-input v-model="address.first_name" placeholder="Veli Adı"/>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="Veli Soy Adı" class="is-required">
                                                        <el-input v-model="address.last_name" placeholder="Veli Soy Adı"/>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="Yakınlık Derecesi" class="is-required">
                                                        <el-select v-model="address.title" filterable allow-create
                                                                   default-first-option placeholder="Yakınlık Derecesi">
                                                            <el-option v-for="item in ui.addressTitles" :key="item.label"
                                                                       :label="item.label" :value="item.label"/>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="Telefon" class="is-required mb-0-i">
                                                        <el-input v-model="address.phone" placeholder="Telefon"
                                                                  v-mask="'0 (###) ### ## ##'"/>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="E-Posta" class="mb-0-i">
                                                        <el-input v-model="address.email" placeholder="E-Posta"/>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="T.C. Kimlik Numarası">
                                                        <el-input v-model="address.identification_no" placeholder="T.C. Kimlik Numarası" v-mask="'###########'"/>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="Kurum">
                                                        <ti-select :container="address" model="company_id" :options="$store.state.ui.companies"/>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="Meslek">
                                                        <select-definition label="Meslek" alias="jobs" model="job_definition_id" :container="address"/>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="İş yeri">
                                                        <el-input v-model="address.company_name" placeholder="İş yeri"/>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="8">
                                                    <el-form-item label="Eğitim Durumu">
                                                        <select-definition label="Eğitim Durumu" alias="educations" model="education_definition_id" :container="address"/>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                        </el-tab-pane>
                                    </template>
                                </el-tabs>
                            </section>
                        </div>
                    </div>
                    <div class="tab-item" title="Öğrenci Bilgileri" v-show="ui.activeTab === 2">
                        <div class="card-box">
                            <header class="card-box-header">
                                <div class="title">Öğrenci Bilgileri</div>
                            </header>
                            <section class="card-box-content p-20">
                                <el-form-item label="T.C. Kimlik Numarası">
                                    <el-input v-model="offer.student.identification_no" placeholder="T.C. Kimlik Numarası" v-mask="'###########'"/>
                                </el-form-item>
                                <el-row :gutter="24">
                                    <el-col :span="8">
                                        <el-form-item label="Öğrenci Adı">
                                            <el-input v-model="offer.student.first_name" placeholder="Öğrenci Adı"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Öğrenci Soy Adı">
                                            <el-input v-model="offer.student.last_name" placeholder="Öğrenci Soy Adı"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Doğum Tarihi">
                                            <el-date-picker v-model="offer.student.birthday" type="date" placeholder="Tarih Seçiniz" value-format="yyyy-MM-dd" format="dd/MM/yyyy"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Sınıfı">
                                            <select-definition label="Sinif" alias="classes" model="education_class_definition_id" :container="offer.student"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Okulu">
                                            <select-definition label="Okul" alias="schools" model="school_definition_id" :container="offer.student"/>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="Cinsiyet">
                                            <select-definition label="Cinsiyet" alias="genders" model="gender_definition_id" :container="offer.student"/>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-form-item label="Kan Grubu">
                                    <select-definition label="Kan Grubu" alias="blood-groups" model="blood_group_definition_id" :container="offer.student"/>
                                </el-form-item>
                                <el-form-item label="Saglik Problemleri">
                                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="Saglik Problemleri" v-model="offer.student.health_problems"/>
                                </el-form-item>
                                <el-form-item label="Surekli Kullandigi Ilaclar">
                                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="Surekli Kullandigi Ilaclar" v-model="offer.student.using_pills"/>
                                </el-form-item>
                                <el-form-item label="Alerjisi">
                                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="Alerjisi" v-model="offer.student.allergy"/>
                                </el-form-item>
                                <el-form-item class="mb-0-i">
                                    <el-switch v-model="offer.student.is_share" active-text="Fotoğraf İzni"/>
                                </el-form-item>
                            </section>
                        </div>
                    </div>
                    <div class="tab-item" title="Ödeme" v-show="ui.activeTab === 3">
                        <div class="card-box">
                            <header class="card-box-header">
                                <div class="title">Ödeme</div>
                            </header>
                            <section class="card-box-content p-20">

                                <el-row :gutter="24">
                                    <template v-for="item in offer.payments">
                                        <el-col :span="8">
                                            <div class="ui button fw-500 fluid mb-20-i" :class="{blue : item.id === payment.id}" @click="handleSelectPayment(item)">
                                                {{ item.title }}
                                            </div>
                                        </el-col>
                                    </template>
                                </el-row>

                                <div class="card-box p-20 mb-0" style="border-top: 4px solid #2185D0">
                                    <div v-if="payment.name === 'Cash' || payment.name === 'Voucher'">
                                        <div class="ui message info text-center">
                                            Herhangi bir islem yapmaniza gerek yok direk siparisi tamamlaya bilirsiniz
                                        </div>
                                    </div>

                                    <div v-if="payment.name === 'Eft' || payment.name === 'Kmh' || payment.name === 'KmhContinued'">
                                        <div class="box-list">
                                            <div v-for="(row, key) in payment.rows" :class="{'box-list-item' : true, 'box-list-item-clickable' : true, 'bg-success' : row.selected}" @click="selectedPaymentRow(key)">
                                                <div class="media margin-right-20" style="width: 100px">
                                                    <img :src="appConfig.frontPath + '/' + row.image" alt="" style="width: 100px">
                                                </div>
                                                <div class="text fluid">
                                                    <div class="text-item">
                                                        <div class="name fs-20">{{ row.title }}</div>
                                                    </div>
                                                    <div class="text-item">
                                                        <div>Sube</div>
                                                        <div>{{ row.branch }}</div>
                                                    </div>
                                                    <div class="text-item">
                                                        <div>IBAN</div>
                                                        <div>{{ row.iban }}</div>
                                                    </div>
                                                    <div class="text-item">
                                                        <div>Hesap No</div>
                                                        <div>{{ row.account }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="payment.name === 'Payu' || payment.name === 'Paytr' || payment.name === 'IPara'">
                                        <el-form-item label="Kart Üzerindeki İsim">
                                            <el-input v-model="form.cc.owner" placeholder="Kart Üzerindeki İsim" />
                                        </el-form-item>

                                        <el-form-item label="Kart Numarası">
                                            <el-input v-model="form.cc.number" placeholder="Kart Numarası" v-mask="'#### #### #### ####'" />
                                        </el-form-item>

                                        <el-row :gutter="24">
                                            <el-col :span="8">
                                                <el-form-item label="Ay">
                                                    <el-input v-model="form.cc.month" placeholder="Ay" v-mask="'##'" />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Yil">
                                                    <el-input v-model="form.cc.year" placeholder="Yil" v-mask="'####'" />
                                                </el-form-item>
                                            </el-col>
                                            <el-col :span="8">
                                                <el-form-item label="Guvenlik Kodu">
                                                    <el-input v-model="form.cc.cvv" placeholder="Guvenlik Kodu" v-mask="'###'" />
                                                </el-form-item>
                                            </el-col>
                                        </el-row>

                                        <el-row :gutter="24">
                                            <el-col :span="12">
                                                <el-radio v-model="form.installment_type" label="single" @change="getInstallments('single')">
                                                    Tek Cekim
                                                </el-radio>
                                            </el-col>
                                            <el-col :span="12">
                                                <el-radio v-model="form.installment_type" label="multi" @change="getInstallments('multi')">
                                                    Taksitli Cekim
                                                </el-radio>
                                            </el-col>
                                        </el-row>

                                        <el-form-item v-if="installments" class="margin-top-20">
                                            <el-form-item>
                                                <el-select v-model="form.installment_no" value-key="id" placeholder="Taksit Secimi">
                                                    <el-option
                                                        v-for="item in installments"
                                                        :key="item.id"
                                                        :label="item.title"
                                                        :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-form-item>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </el-form>
        </template>

        <el-dialog title="3D Dogrulama" :visible.sync="payFormDialogVisible" append-to-body class="card-dialog" width="60%">
            <iframe frameborder="0" v-if="payFormDialogVisible" style="width: 100%; height: 500px" :src="payFormSrc"></iframe>
        </el-dialog>
    </div>
</template>

<script>
import BankInstallment from "@/components/BankInstallment.vue";

export default {
    name: 'OrderCreate',
    components: {BankInstallment},
    data() {
        return {
            pageLoading: false,
            ui: {
                activeParentTab : 'parent',
                activeTab: 1,
                tabs: [
                    {
                        value: 1,
                        label: 'Veli Bilgileri'
                    },
                    {
                        value: 2,
                        label: 'Öğrenci Bilgileri'
                    },
                    {
                        value: 3,
                        label: 'Ödeme'
                    }
                ],
                addressTitles: [
                    {
                        label: 'Anne',
                    },
                    {
                        label: 'Baba',
                    },
                    {
                        label: '3. Şahıs',
                    },
                ],
            },
            offer: {
                id: null,
            },
            form: {
                offer_id : null,
                account_id : null,
                customer_id : null,
                student_id : null,
                payment_plugin_id : null,
                delivery_plugin_id : null,
                delivery_address_id : null,
                invoice_address_id : null,
                contract_address_id : null,

                //
                bank_name : null,
                installment_no : 1,
                installment_type : 'single',
                cc : {
                    owner : null,
                    number : null,
                    month : null,
                    year : null,
                    cvv : null,
                }
            },
            payment : {
                //
            },

            installments : null,
            payFormSrc : null,
            payFormDialogVisible : false
        }
    },

    mounted() {
        this.getOffer()
    },

    methods: {
        getOffer() {
            this.pageLoading = true

            this.$http
                .get('sales/offers/' + this.$route.query.offer_id + '?process=order')
                .then(response => {
                    this.pageLoading = false;
                    this.offer = response.body.data;
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        handleTabBack() {
            let key = this.ui.activeTab - 1;

            if (!key) {
                return;
            }

            this.ui.activeTab = key;
        },

        handleTabNext() {
            let key = this.ui.activeTab + 1;

            if (key > 3) {
                return;
            }

            switch (this.ui.activeTab) {
                case 1 :
                    this.updateCustomer();
                    break;
                case 2 :
                    this.updateStudent();
                    break;
            }
        },

        updateCustomer() {
            this.pageLoading = true

            this.$http
                .post('enrollments/orders', {
                    process : 'updateCustomer',
                    customer : this.offer.customer,
                    addresses : this.offer.addresses,
                })
                .then(response => {
                    this.pageLoading = false
                    this.ui.activeTab = 2
                })
                .catch((e) => {
                    this.pageLoading = false
                    this.apiErrorMessage(e);
                })
        },

        updateStudent() {
            this.pageLoading = true

            this.$http
                .post('enrollments/orders', {
                    process : 'updateStudent',
                    student : this.offer.student,
                })
                .then(response => {
                    this.pageLoading = false
                    this.ui.activeTab = 3
                })
                .catch((e) => {
                    this.pageLoading = false
                    this.apiErrorMessage(e);
                })
        },

        createParentAddress() {
            this.offer.addresses.push({
                'title': 'Yeni Iletisim Bilgisi',
                country_zone_id : 1,
                city_zone_id : null,
                town_zone_id : null,
                district_zone_id : null,
                neighborhood_zone_id : null,
            })
        },

        removeParent(index) {
            if (index === 'parent') {
                return this.$notify.error({
                    title: 'Upss!',
                    message: 'Gecerli veliyi silemezsiniz!',
                    position: 'top-right'
                });
            }

            this
                .$confirm('Verileriniz kalici olarak silinecek.', 'Uyari', {
                    confirmButtonText: 'Onayliyorum',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {
                    let key = index.replace('address-', '')

                    this.offer.addresses.splice(key, 1);
                })
                .catch(() => {
                    return false;
                });
        },

        handleSelectPayment(item) {
            this.form.payment_plugin_id = item.id;
            this.payment = item;
        },

        selectedPaymentRow(rowKey) {

            let rows = [];

            this.payment.rows.forEach(function (row) {
                row.selected = false;
                rows.push(row);
            });

            this.form.bank_name = rows[rowKey].title;

            rows[rowKey].selected = true;

            this.payment.rows = rows;
        },

        getInstallments(type) {

            this.installments = null;
            this.form.installment_no = 1;
            this.form.installment_type = 'single';

            if (type === 'single') {
                return;
            }

            if (!this.form.cc.owner) {
                return this.$notify.error({
                    title: 'Upss!',
                    message: 'Devam edebilmek icin Kart Üzerindeki İsim alanini doldurmaniz gerekiyor!',
                    position: 'top-right'
                });
            }

            if (!this.form.cc.number) {
                return this.$notify.error({
                    title: 'Upss!',
                    message: 'Devam edebilmek icin Kart Numarası alanini doldurmaniz gerekiyor!',
                    position: 'top-right'
                });
            }

            if (!this.form.cc.month) {
                return this.$notify.error({
                    title: 'Upss!',
                    message: 'Devam edebilmek icin Ay alanini doldurmaniz gerekiyor!',
                    position: 'top-right'
                });
            }

            if (!this.form.cc.year) {
                return this.$notify.error({
                    title: 'Upss!',
                    message: 'Devam edebilmek icin Yil alanini doldurmaniz gerekiyor!',
                    position: 'top-right'
                });
            }

            if (!this.form.cc.cvv) {
                return this.$notify.error({
                    title: 'Upss!',
                    message: 'Devam edebilmek icin Guvenlik Kodu alanini doldurmaniz gerekiyor!',
                    position: 'top-right'
                });
            }

            this.pageLoading = true;

            this.$http.post(this.$route.meta.end_point, {
                process : 'installments',
                offer_id : this.offer.offer_id,
                payment : this.payment.name,
                installment_no : this.form.installment_no,
                cc : this.form.cc,
                delivery_address_id : this.offer.customer.address.id,
                invoice_address_id : this.offer.customer.address.id,
                contract_address_id : this.offer.customer.address.id,
            })
                .then(response => {
                    this.pageLoading = false;
                    this.installments = response.body.items

                    this.form.installment_no = 2;
                    this.form.installment_type = 'multi';
                })
                .catch((e) => {
                    this.pageLoading = false;
                    this.apiErrorMessage(e);

                    this.installments = null;
                    this.form.installment_no = 1;
                    this.form.installment_type = 'single';
                });
        },

        handlePay() {
            this.pageLoading = true;

            this.$http.post(this.$route.meta.end_point, {
                offer_id : this.offer.offer_id,
                delivery_address_id : this.offer.customer.address.id,
                invoice_address_id : this.offer.customer.address.id,
                contract_address_id : this.offer.customer.address.id,

                bank_name : this.form.bank_name,
                payment : this.payment.name,
                installment_no : this.form.installment_no,
                installment_type : this.form.installment_type,
                cc : this.form.cc,
            })
                .then(response => {
                    this.pageLoading = false;

                    if (response.body.url) {
                        this.payFormSrc = response.body.url;
                        this.payFormDialogVisible = true;
                    } else {
                        return this.$router.push('edit/' + response.body.item.id);
                    }
                })
                .catch((e) => {
                    this.pageLoading = false;

                    if (e.body.error) {
                        this.$notify.error({
                            title: 'Upss!',
                            message: e.body.error,
                            position: 'top-right'
                        });
                    }

                    this.apiErrorMessage(e);
                });
        }
    }
}
</script>
