<template>
    <section v-loading="itemLoading" class="loading-container">
        <div class="modern-items">
            <template v-for="item in items">
                <div class="item" :key="item.id"@click="openBlankWindow('/enrollments/interviews?id=' + item.id)">
                    <div class="content">
                        <div class="customer-name">{{ item.user }}</div>
                        <div class="metas">
                            <div class="meta">
                                {{ item.subject }}
                            </div>
                            <div class="meta">
                                {{ item.channel }}
                            </div>
                            <div class="meta">
                                {{ item.created }}
                            </div>
                            <div class="meta">
                                {{ item.created_at }}
                            </div>
                        </div>
                    </div>
                    <div class="info">
                        <div class="ui label" :style="{background: item.status.bg_color, color: item.status.text_color}">
                            {{ item.status.title }}
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>
<script>
export default {
    name: 'Interviews',
    props: ['source', 'customer'],

    data() {
        return {
            itemLoading: true,
            items: [],
        }
    },

    mounted() {
        this.index();
    },

    methods: {
        index() {
            this.itemLoading = true;

            this.$http.get(this.source, {
                params: {
                    fake_url: this.source,
                    user_id : this.customer.id
                }
            }).then(response => {
                this.items = response.body.data.rows;
                this.itemLoading = false;
            });
        }
    }
}
</script>
