<template>
    <div class="page-vue-good-table scrollable only-y">
        <page-header />
        <div class="card-box">
            <header class="card-box-header">
                <div class="title">
                    {{ $route.meta.title }}
                </div>
            </header>
            <section class="card-box-content padding-20">
                <div class="ui message warning">
                    Yapım aşamasında. Sistemden almak istediğiniz düzenli veriler için lütfen yazilim@remzihoca.com'a mail atınız.
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import PageHeader from "../../../components/PageHeader";
    export default {
        components: {PageHeader}
    }
</script>