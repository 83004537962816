<template>
    <img v-bind:src="src ? src : image(basic)" alt="avatar" v-if="basic">
</template>
<script>
    export default {
        name: 'avatar',
        props: ['img', 'type'],
        data() {
            return {
                src: null,
                basic: null,
            };
        },

        methods: {
            initStudent() {
                this.basic = 'student';
            },

            initCustomer() {
                this.basic = 'customer';
            },

            initStaff() {
                this.basic = 'staff';
            },

            image(pet) {
                let images = require.context('../resources/images/', false, /\.png$/);

                return images('./' + pet + '.png')
            }
        },

        mounted() {

            if (this.type === 'student') {
                this.initStudent();
            }

            if (this.type === 'customer') {
                this.initCustomer();
            }

            if (this.type === 'staff') {
                this.initStaff();
            }

            if (this.img) {
                this.src = this.appConfig.frontPath + '/' + this.img;
                this.basic = true;
            }
        }
    };
</script>

