<template>
    <div v-loading="itemLoading">
        <div class="card-box">
            <header class="card-box-header">
                <div class="title">
                    Donemler
                </div>
                <div class="actions">
                    <el-button icon="el-icon-plus" type="text" class="el-button-link primary" @click="createItem"/>
                </div>
            </header>
            <section class="card-box-content">
                <div class="box-list">
                    <div class="box-list-item" v-for="item in items" :key="item.id">
                        <div class="fluid margin-right-20">
                            <div class="my-flex">
                                <div class="name fluid">
                                    <div>{{ item.education_year.title }}</div>
                                    <div>{{ item.education_period.title }}</div>
                                </div>
                                <div class="fluid">
                                    <el-tooltip content="Kalan" effect="dark" placement="bottom">
                                        <span>
                                            {{ item.current_stock }}
                                        </span>
                                    </el-tooltip>
                                    /
                                    <el-tooltip content="Kullanilan" effect="dark" placement="bottom">
                                        <span>
                                            {{ item.stock_total - item.current_stock }}
                                        </span>
                                    </el-tooltip>
                                    /
                                    <el-tooltip content="Kontenjan" effect="dark" placement="bottom">
                                        <span>
                                            {{ item.stock_total }}
                                        </span>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex" style="justify-content: end">
                            <el-button icon="el-icon-edit" type="text" class="el-button-link green" @click="editItem(item)"/>
                            <el-button icon="el-icon-delete" type="text" class="el-button-link red" @click="destroyItem(item.id)"/>
                            <el-tooltip content="Promosyonlari musterilere tekrardan dagit" placement="bottom">
                                <el-button icon="el-icon-share" type="text" class="el-button-link blue" @click="sharePromotionProducts(item.id)"/>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <el-dialog title="Donem Bilgileri" :visible.sync="modalCreateVisible" v-loading="itemLoading" width="50%"
                   append-to-body>
            <el-form :label-position="'top'" ref="form" :model="form">

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Egitim Yili">
                            <select-definition label="Egitim Yili" alias="education-years"
                                               model="education_year_definition_id" :container="form"/>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="Egitim Donemi">
                            <select-definition label="Egitim Donemi" alias="education-periods"
                                               model="education_period_definition_id" :container="form"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Kontenjan">
                    <el-input v-model="form.stock_total" placeholder="Kontenjan"/>
                </el-form-item>

                <el-form-item label="Vergi Sinifi">
                    <select-definition label="Vergi Sinifi" alias="tax-classes" model="tax_class_definition_id"
                                       :container="form"/>
                </el-form-item>

                <el-form-item label="Fiyat">
                    <el-input v-model="form.price" placeholder="Fiyat"/>
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Ders Birim Fiyati">
                            <el-input v-model="form.q_price" placeholder="Ders Birim Fiyati"/>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="Eğitim Fiyati">
                            <el-input v-model="form.package_price" placeholder="Eğitim Fiyati"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Toplam Ders Saati">
                    <el-input v-model="form.total_lesson_time" placeholder="Toplam Ders Saati"/>
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Egitim Baslangic Tarihi">
                            <el-date-picker v-model="form.start_at" type="date" placeholder="Egitim Baslangic Tarihi"
                                            value-format="yyyy-MM-dd"/>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="Egitim Bitis Tarihi">
                            <el-date-picker v-model="form.end_at" type="date" placeholder="Egitim Bitis Tarihi"
                                            value-format="yyyy-MM-dd"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <div class="ui button labeled green icon fluid" @click="storeItem" v-if="!form.id">
                    <i class="icon mdi mdi-check"></i> Kaydet
                </div>
                <div class="ui button labeled green icon fluid" @click="updateItem" v-if="form.id">
                    <i class="icon mdi mdi-check"></i> Guncelle
                </div>
            </div>
        </el-dialog>

        <el-dialog title="Donem Bilgileri" :visible.sync="modalDetailVisible" v-loading="itemLoading" width="95%"
                   :key="form.id"
                   class="classroom-modal-detail">
            <el-form :label-position="'top'" ref="form" :model="form" v-if="form.id">
                <el-row :gutter="24">
                    <el-col :span="8">
                        <div class="card-box">
                            <header class="card-box-header has-buttons">
                                <div class="title">
                                    Egitim
                                </div>
                                <div class="actions">
                                    <el-button icon="el-icon-edit" type="text" class="el-button-link green"
                                               @click="modalCreateVisible = true"/>
                                </div>
                            </header>
                            <section class="card-box-content">
                                <div class="box-list">
                                    <div class="box-list-item">
                                        <div class="name">
                                            Egitim Yili
                                        </div>
                                        <div>
                                            {{ form.education_year.title }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Egitim Donemi
                                        </div>
                                        <div>
                                            {{ form.education_period.title }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Kontenjan
                                        </div>
                                        <div>
                                            {{ form.stock_total }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Vergi Sinifi
                                        </div>
                                        <div>
                                            {{ form.tax_class.title }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Fiyat
                                        </div>
                                        <div>
                                            {{ $n(form.price, 'currency') }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Ders Birim Fiyati
                                        </div>
                                        <div>
                                            {{ $n(form.q_price, 'currency') }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Eğitim Fiyati
                                        </div>
                                        <div>
                                            {{ $n(form.package_price, 'currency') }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Toplam Ders Saati
                                        </div>
                                        <div>
                                            {{ form.total_lesson_time }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Egitim Baslangic Tarihi
                                        </div>
                                        <div>
                                            {{ form.start_at }}
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            Egitim Bitis Tarihi
                                        </div>
                                        <div>
                                            {{ form.end_at }}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </el-col>
                    <el-col :span="16">
                        <div class="card-box no-shadow">
                            <section class="card-box-tabs">
                                <el-row :gutter="24">
                                    <el-col :span="12">
                                        <Students :product="form" :key="form.id"/>
                                    </el-col>
                                    <el-col :span="12">
                                        <Teachers :product="form" :key="form.id"/>
                                    </el-col>
                                </el-row>

                                <el-tabs :tab-position="'top'">
                                    <el-tab-pane label="Videos">
                                        <PeriodContent :product="form" title="Videolar" fieldTitle="Videolar" fieldAlias="videos" key="videos"/>
                                    </el-tab-pane>
                                    <el-tab-pane label="Readings">
                                        <PeriodContent :product="form" title="Okuma Parcalari" fieldTitle="Okuma Parcalari" fieldAlias="readings" key="readings"/>
                                    </el-tab-pane>
                                    <el-tab-pane label="Listenings">
                                        <PeriodContent :product="form" title="Listenings" fieldTitle="Listenings" fieldAlias="sounds" key="sounds"/>
                                    </el-tab-pane>
                                    <el-tab-pane label="Games">
                                        <PeriodContent :product="form" title="Oyunlar" fieldTitle="Oyunlar" fieldAlias="games" key="games"/>
                                    </el-tab-pane>
                                    <el-tab-pane label="Bilgilendirme Mektuplari">
                                        <PeriodContent :product="form" title="Bilgilendirme Mektuplari" fieldTitle="Mektuplar" fieldAlias="newsletters" key="newsletters2"/>
                                    </el-tab-pane>
                                    <el-tab-pane label="Galeri">
                                        <PeriodGallery :source="endPoint + '/' + form.id"/>
                                    </el-tab-pane>
                                </el-tabs>
                            </section>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import SelectDefinition from "../../../../../../components/SelectDefinition";
    import Students from "./Students";
    import Teachers from "./Teachers";
    import PeriodContent from "./PeriodContent";
    import PeriodGallery from "./PeriodGallery";

    export default {
        name: 'Periods',
        components: {
            PeriodGallery,
            PeriodContent,
            Students,
            Teachers,
            SelectDefinition

        },
        props: ['itemId'],
        data() {
            return {
                itemLoading: false,
                endPoint: null,
                items: [],
                form: {
                    content_id: this.itemId
                },

                modalCreateVisible: false,
                modalDetailVisible: false,
            }
        },

        methods: {
            index() {
                this.itemLoading = true;

                this.$http
                    .get(this.endPoint, {
                        perPage: 100
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.items = response.body.rows
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            createItem() {
                this.form = {
                    content_id: this.itemId
                };

                this.modalCreateVisible = true;
            },

            storeItem() {
                this.itemLoading = true;

                this.$http
                    .post(this.endPoint, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.modalCreateVisible = false;

                        this.apiSuccessMessage(response);
                        this.index();
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            editItem(item) {
                this.form = item;
                this.modalDetailVisible = true;
            },

            updateItem() {
                this.itemLoading = true;

                this.$http
                    .put(this.endPoint + '/' + this.form.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                        this.modalCreateVisible = false;
                        this.modalDetailVisible = false;

                        this.index();
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {

                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http
                        .delete(this.endPoint + '/' + id)
                        .then(response => {
                            this.itemLoading = false;

                            this.index();

                            this.$message({
                                type: 'success',
                                message: 'Delete completed'
                            });
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });

                    this.itemLoading = false;
                });
            },

            sharePromotionProducts(id) {
                this.itemLoading = true;

                this.$confirm('Bu urune sahip olan ve muhasebe programina gonderilmemis tum siparislere promosyonlar tekrardan dagitilacak', 'Uyari', {
                    confirmButtonText: 'Onayliyorum',
                    cancelButtonText: 'Vazgec',
                    type: 'warning'
                }).then(() => {

                    this.itemLoading = true;

                    this.$http
                        .put(this.endPoint + '/' + id, {process : 'promotion'})
                        .then(response => {
                            this.itemLoading = false;
                            this.apiSuccessMessage(response);
                        })
                        .catch((e) => {
                            this.itemLoading = false;
                            this.apiErrorMessage(e);
                        });

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Iptal edildi!'
                    });

                    this.itemLoading = false;
                });
            }
        },

        mounted() {

            this.endPoint = 'school/contents/' + this.itemId + '/periods';

            this.index();
        },
    }
</script>