<template>
    <div>
        <div class="card-box">
            <header class="card-box-header">
                <div class="title">
                    Musteri Bilgileri
                </div>
            </header>
            <section class="card-box-content">
                <div class="box-list">
                    <div class="box-list-item box-list-item-editable box-list-item-media">
                        <div class="box-list-item-media-item">
                            <div class="media">
                                <img src="@/resources/images/customer.png" alt="avatar">
                            </div>
                            <div class="text fluid">
                                <div class="name">
                                    <span class="route-link" @click="openBlankWindow('/crm/users/edit/' + order.customer_id)">
                                        {{ order.first_name }} {{ order.last_name }}
                                    </span>
                                </div>
                                <div class="email">
                                    <SingleDraft :userId="order.customer_id" :receiver="order.email" alias="email"/>
                                </div>
                                <div class="phone">
                                    <SingleDraft :userId="order.customer_id" :receiver="order.phone" alias="sms"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="card-box" v-if="order.student">
            <header class="card-box-header">
                <div class="title">
                    Ogrenci Bilgileri
                </div>
            </header>
            <section class="card-box-content">
                <div class="box-list">
                    <div class="box-list-item box-list-item-editable box-list-item-media">
                        <div class="box-list-item-media-item">
                            <div class="media">
                                <img src="@/resources/images/student.png" alt="avatar">
                            </div>
                            <div class="text fluid">
                                <div class="name">
                                    <span class="route-link" @click="openBlankWindow('/school/students/edit/' + order.student.id)">
                                        {{ order.student.full_name }}
                                    </span>
                                </div>
                                <div>
                                    {{ order.student.identification_no }}
                                </div>
                                <div>
                                    {{ order.student.birthday }}
                                    {{ $d(new Date(order.student.birthday), 'sort', 'tr-TR') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import SingleDraft from "../../../../../components/SingleDraft";

    export default {
        name: 'Customer',
        components: {SingleDraft},
        props: ['order'],
        data() {
            return {
                //
            }
        },

        methods: {
           //
        },

        mounted() {
            //
        }
    }
</script>