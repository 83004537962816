<template>
    <div class="page-vue-good-table scrollable only-y">
        <page-header>
            <div class="actions">
                <div class="counter" v-if="pagination">
                    <span>{{ pagination.total }}</span> sonuc bulundu.
                </div>
                <el-tooltip content="Excel Çıktısı Al">
                    <el-button icon="el-icon-download" size="small" type="warning" @click="handleExport(1)"/>
                </el-tooltip>
                <el-tooltip content="Yeni Gorusme Ekle">
                    <el-button icon="el-icon-plus" size="small" type="primary" @click="create"/>
                </el-tooltip>
                <el-tooltip content="Filtrele">
                    <el-button icon="el-icon-search" size="small" type="success" @click="getIndex(1)"/>
                </el-tooltip>
            </div>
        </page-header>

        <el-form :label-position="'top'" ref="filterForm" :model="filterForm" @submit.native.prevent="getIndex(1)">
            <div class="card-box card-global-filter">
                <el-row :gutter="24">
                    <el-col :span="6">
                        <el-form-item>
                            <el-input v-model="filterForm.customer_name" placeholder="Veli Adı-Soyadı"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item >
                            <select-definition placeholder="Görüşme Nedeni" alias="interview-subjects" model="subject_definition_id" :container="filterForm"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item>
                            <select-status placeholder="Görüşme Durumu" alias="interviews" model="status_id" :container="filterForm"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item>
                            <el-select v-model="filterForm.created_by_id" placeholder="Danışman" clearable filterable class="fluid">
                                <el-option v-for="item in staffs" :key="item.id" :label="item.title" :value="item.id"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item class="m-0">
                            <select-definition alias="education-years" model="education_year_definition_id" :container="filterForm" placeholder="Eğitim Yılı"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item class="m-0">
                            <select-definition alias="classes" model="class_definition_id" :container="filterForm" placeholder="Öğrencinin Sınıfı"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item class="m-0">
                            <short-daterange-picker :container="filterForm" model="date_range" placeholder="Gorusme Zamani"/>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <button type="submit" style="display: none"></button>
        </el-form>

        <div class="loading-container" v-loading="pageLoading">
            <div class="modern-items">
                <template v-for="item in items">
                    <div class="item" :key="item.id" @click="handleShowCard(item.id)">
                        <div class="content">
                            <div class="customer-name">{{ item.user }}</div>
                            <div class="metas">
                                <div class="meta">
                                    {{ item.subject }}
                                </div>
                                <div class="meta">
                                    {{ item.channel }}
                                </div>
                                <div class="meta">
                                    {{ item.created }}
                                </div>
                                <div class="meta">
                                    {{ item.created_at }}
                                </div>
                            </div>
                        </div>
                        <div class="info">
                            <div class="ui label" :style="{background: item.status.bg_color, color: item.status.text_color}">
                                {{ item.status.title }}
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div v-if="pagination && pagination.total > pagination.limit" class="text-right margin-bottom-20">
            <el-pagination
                background
                :currentPage="filterForm.page"
                :pageCount="pagination.last_page"
                :pagerCount="5"
                :small="false"
                @current-change="getIndex"
            />
        </div>

        <el-dialog title="Yeni Görüşme Olustur" :visible.sync="dialogVisible" append-to-body class="card-dialog" width="40%">
            <el-form :label-position="'top'" :model="form" class="p-20">
                <el-form-item label="Gorusme Konusu">
                    <select-definition placeholder="Görüşme Konusu" alias="interview-subjects" model="subject_definition_id" :container="form"/>
                </el-form-item>

                <el-form-item label="Musteri Telefon Numarası" class="m-0">
                    <el-input v-model="form.phone" placeholder="Telefon Numarasi" v-mask="'0 (###) ### ## ##'"/>
                </el-form-item>
            </el-form>

            <div slot="footer">
                <el-button type="success" class="fluid" @click="store" :loading="dialogLoading">
                    Kaydet
                </el-button>
            </div>
        </el-dialog>

        <interview-show :id="cardId" :close="handleCloseCard" :staffs="staffs" :key="cardId" />
    </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import SearchUser from "@/components/search-user";
import InterviewShow from "@/app/Pages/Enrollments/Interviews/Show";

export default {
    name: 'InterviewIndex',
    components: {
        InterviewShow,
        SearchUser,
        PageHeader
    },

    data() {
        return {
            pageLoading: true,
            filterForm: {
                page: 1
            },
            staffs: [],

            pagination: null,
            items: [],

            dialogVisible : false,
            dialogLoading : false,
            form : {
                //
            },

            cardId : null,
        }
    },
    mounted() {
        this.pageLoading = false;

        this.getStaffs()
        this.getIndex(1)

        if (this.$route.query.card_id) {
            this.create()
        }

        if (this.$route.query.id) {
            this.handleShowCard(this.$route.query.id)
        }
    },
    methods: {
        getStaffs() {
            this.itemLoading = false;

            this.$http
                .get('crm/users', {
                    params: {
                        filters: [
                            {
                                relation: 'roles',
                                inputs: [
                                    {
                                        name: 'role_id',
                                        field: 'role_id',
                                        type: 'multiSelect',
                                        value: [
                                            9
                                        ]
                                    }
                                ]
                            },
                        ],
                    }
                })
                .then(response => {
                    this.itemLoading = false;

                    this.staffs.push({
                        id: -1,
                        title: 'System User',
                    })

                    _.forEach(response.body.rows, (item) => {
                        this.staffs.push({
                            id: item.id,
                            title: item.name,
                        })
                    })
                });
        },

        getIndex(page, process) {
            this.pageLoading = true;

            if (page) {
                this.filterForm.page = page
            }

            this.filterForm.move_created_by_id = 0
            this.filterForm.process = process

            this.$store.commit('setCardDialogVisible', false)
            this.cardId = null

            this.$http
                .get('enrollments/interviews', {
                    params: this.filterForm
                })
                .then(response => {
                    this.pageLoading = false;

                    if (response.data.data.link) {
                        this.openBlankWindow(response.data.data.link)
                    } else {
                        this.pagination = response.data.data.pagination
                        this.items = response.data.data.rows
                    }
                })
                .catch((e) => {
                    this.pageLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        create() {
            this.form = {
                //
            }

            this.dialogVisible = true
        },

        store() {

            this.dialogLoading = true

            this.$http
                .post(this.$route.meta.end_point, this.form)
                .then(response => {
                    this.apiSuccessMessage(response);

                    this.dialogLoading = false;
                    this.dialogVisible = false;

                    this.handleShowCard(response.body.item.id)
                })
                .catch((e) => {
                    this.dialogLoading = false;
                    this.apiErrorMessage(e);
                });

        },

        handleShowCard(cardId) {
            this.$store.commit('setCardDialogVisible', true)
            this.cardId = cardId
        },

        handleCloseCard() {
            this.$store.commit('setCardDialogVisible', false)
            this.cardId = null

            this.getIndex(this.filterForm.page)
        },
        handleExport (page) {
            this
                .$confirm('Excel çıktısı oluşturulmasını onaylıyor musunuz?', 'Warning', {
                    confirmButtonText: 'Evet',
                    cancelButtonText: 'İptal',
                    type: 'warning'
                })
                .then(() => {
                    this.getIndex(1, 'export')
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'İndirme işlemi iptal edildi!'
                    });

                    this.pageLoading = false;
                });
        }
    }
}
</script>
