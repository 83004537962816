<template>
	<el-select v-model="container[model]" value-key="id" filterable remote reserve-keyword :placeholder="placeholder ? placeholder : 'Musteri'" :remote-method="search" @change="handleSelected" :loading="loading">
        <el-option v-for="item in customers" :key="item.id" :label="item.title" :value="item.id" >
            <span style="float: left">
                {{ item.title }} <b v-if="item.phone">({{item.phone}})</b>
            </span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.students_format }}</span>
        </el-option>
	</el-select>
</template>
<script>
    export default {
        name: 'SearchUser',
        props: ['container', 'model', 'change', 'customer', 'placeholder'],
        data() {
            return {
                loading: false,
                customers: []
            };
        },

        mounted() {
            if (this.container[this.model]) {
                this.findCustomer(this.container[this.model]);
            }
        },

        methods: {
            handleSelected(e) {
                if (this.change) {
                    return this.change(e);
                }

                if (this.customer) {
                    return this.handleGetCustomer(e)
                }
            },

            handleGetCustomer(e) {
                console.log(e, this.customers);
            },

            search(query) {
                this.loading = true;

                this
                    .$http
                    .get('tools/search/users?q=' + query)
                    .then(response => {
                        this.loading = false;
                        this.customers = response.body.items;
                    })
                    .catch((e) => {
                        this.loading = false;
                        this.apiErrorMessage(e);
                    });
            },

            findCustomer(id) {
                this.loading = true;

                this.$http
                    .get('crm/users/' + id)
                    .then(response => {
                        this.loading = false;
                        let item = response.body.item;

                        item.title = item.name;

                        this.customers.push(item);
                    });
            },
        },
    };
</script>

