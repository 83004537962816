import layouts from '../config/layout'

import MasterContainer from '../app/Container/Master'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Dt'

import EducationBook from '../app/Pages/Platform/EducationBook'
import Homework from '../app/Pages/Platform/Homework'
import Newsletter from '../app/Pages/Platform/Homework'

import Document from '../app/Pages/Platform/Contents/Document'
import Video from '../app/Pages/Platform/Contents/Video'
import Sound from '../app/Pages/Platform/Contents/Sound'
import Reading from '../app/Pages/Platform/Contents/Reading'
import Game from '../app/Pages/Platform/Contents/Game'
import Word from '../app/Pages/Platform/Contents/Word'
import ContentCategory from '../app/Pages/Platform/Contents/Categories'

import Book from '../app/Pages/Platform/Books/Book'

export default {
    path: '/platform',
    name: 'platform',
    component: MasterContainer,
    meta: {
        auth: true,
        layout: layouts.navLeft,
        title: 'Platform',
    },
    children: [
        {
            path: 'education-books',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Egitim Kitaplari',
            },
            children: [
                {
                    name: 'platform.education_books.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Egitim Kitaplari',
                        path: 'education-books',
                        end_point: 'platform/education-books',
                    },
                },
                {
                    name: 'platform.education_books.create',
                    path: 'create',
                    component: EducationBook,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Egitim Kitabi Ekle',
                        path: 'education-books',
                        end_point: 'platform/education-books',
                    }
                },
                {
                    name: 'platform.education_books.edit',
                    path: 'edit/:id',
                    component: EducationBook,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Egitim Bilgileri',
                        path: 'education-books',
                        end_point: 'platform/education-books',
                    }
                }
            ]
        },
        {
            path: 'homeworks',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Odevler',
            },
            children: [
                {
                    name: 'platform.homeworks.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Odevler',
                        path: 'homeworks',
                        end_point: 'platform/homeworks',
                    },
                },
                {
                    name: 'platform.homeworks.create',
                    path: 'create',
                    component: Homework,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Odev Ekle',
                        path: 'homeworks',
                        end_point: 'platform/homeworks',
                    }
                },
                {
                    name: 'platform.homeworks.edit',
                    path: 'edit/:id',
                    component: Homework,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Odev Bilgileri',
                        path: 'homeworks',
                        end_point: 'platform/homeworks',
                    }
                }
            ]
        },
        {
            path: 'newsletters',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Bilgilendirme Mektuplari',
            },
            children: [
                {
                    name: 'platform.newsletters.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Bilgilendirme Mektuplari',
                        path: 'newsletters',
                        end_point: 'platform/newsletters',
                    },
                },
                {
                    name: 'platform.newsletters.create',
                    path: 'create',
                    component: Newsletter,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Bilgilendirme Mektubu Ekle',
                        path: 'newsletters',
                        end_point: 'platform/newsletters',
                    }
                },
                {
                    name: 'platform.newsletters.edit',
                    path: 'edit/:id',
                    component: Newsletter,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Bilgilendirme Mektubu Bilgileri',
                        path: 'newsletters',
                        end_point: 'platform/newsletters',
                    }
                }
            ]
        },
        {
            path: 'contents',
            component: MasterContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Icerikler',
            },
            children: [
                {
                    path: 'documents',
                    component: PageContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Dokumanlar',
                    },
                    children: [
                        {
                            name: 'platform.contents.documents.index',
                            path: '',
                            component: DatatableContainer,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Dokumanlar',
                                path: 'documents',
                                end_point: 'platform/contents/documents'
                            },
                        },
                        {
                            name: 'platform.contents.documents.create',
                            path: 'create',
                            component: Document,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Dokuman Olustur',
                                path: 'documents',
                                end_point: 'platform/contents/documents'
                            }
                        },
                        {
                            name: 'platform.contents.documents.edit',
                            path: 'edit/:id',
                            component: Document,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Dokuman Bilgileri',
                                path: 'documents',
                                end_point: 'platform/contents/documents'
                            }
                        }
                    ]
                },
                {
                    path: 'videos',
                    component: PageContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Videolar',
                    },
                    children: [
                        {
                            name: 'platform.contents.videos.index',
                            path: '',
                            component: DatatableContainer,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Videolar',
                                path: 'videos',
                                end_point: 'platform/contents/videos'
                            },
                        },
                        {
                            name: 'platform.contents.videos.create',
                            path: 'create',
                            component: Video,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Video Olustur',
                                path: 'videos',
                                end_point: 'platform/contents/videos'
                            }
                        },
                        {
                            name: 'platform.contents.videos.edit',
                            path: 'edit/:id',
                            component: Video,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Video Bilgileri',
                                path: 'videos',
                                end_point: 'platform/contents/videos'
                            }
                        }
                    ]
                },
                {
                    path: 'sounds',
                    component: PageContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Sesler',
                    },
                    children: [
                        {
                            name: 'platform.contents.sounds.index',
                            path: '',
                            component: DatatableContainer,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Sesler',
                                path: 'sounds',
                                end_point: 'platform/contents/sounds'
                            },
                        },
                        {
                            name: 'platform.contents.sounds.create',
                            path: 'create',
                            component: Sound,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Ses Olustur',
                                path: 'sounds',
                                end_point: 'platform/contents/sounds'
                            }
                        },
                        {
                            name: 'platform.contents.sounds.edit',
                            path: 'edit/:id',
                            component: Sound,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Ses Bilgileri',
                                path: 'sounds',
                                end_point: 'platform/contents/sounds'
                            }
                        }
                    ]
                },
                {
                    path: 'readings',
                    component: PageContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Okuma Parcalari',
                    },
                    children: [
                        {
                            name: 'platform.contents.readings.index',
                            path: '',
                            component: DatatableContainer,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Okuma Parcalari',
                                path: 'readings',
                                end_point: 'platform/contents/readings'
                            },
                        },
                        {
                            name: 'platform.contents.readings.create',
                            path: 'create',
                            component: Reading,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Okuma Parcasi Olustur',
                                path: 'readings',
                                end_point: 'platform/contents/readings'
                            }
                        },
                        {
                            name: 'platform.contents.readings.edit',
                            path: 'edit/:id',
                            component: Reading,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Okuma Parcasi Bilgileri',
                                path: 'readings',
                                end_point: 'platform/contents/readings'
                            }
                        }
                    ]
                },
                {
                    path: 'games',
                    component: PageContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Oyunlar',
                    },
                    children: [
                        {
                            name: 'platform.contents.games.index',
                            path: '',
                            component: DatatableContainer,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Oyunlar',
                                path: 'games',
                                end_point: 'platform/contents/games'
                            },
                        },
                        {
                            name: 'platform.contents.games.create',
                            path: 'create',
                            component: Game,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Oyun Olustur',
                                path: 'games',
                                end_point: 'platform/contents/games'
                            }
                        },
                        {
                            name: 'platform.contents.games.edit',
                            path: 'edit/:id',
                            component: Game,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Oyun Bilgileri',
                                path: 'games',
                                end_point: 'platform/contents/games'
                            }
                        }
                    ]
                },
                {
                    path: 'words',
                    component: PageContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Kelimeler',
                    },
                    children: [
                        {
                            name: 'platform.contents.words.index',
                            path: '',
                            component: DatatableContainer,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Kelimeler',
                                path: 'words',
                                end_point: 'platform/contents/words'
                            },
                        },
                        {
                            name: 'platform.contents.words.create',
                            path: 'create',
                            component: Word,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Kelime Olustur',
                                path: 'words',
                                end_point: 'platform/contents/words'
                            }
                        },
                        {
                            name: 'platform.contents.words.edit',
                            path: 'edit/:id',
                            component: Word,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Kelime Bilgileri',
                                path: 'words',
                                end_point: 'platform/contents/words'
                            }
                        }
                    ]
                },
                {
                    path: 'categories',
                    component: PageContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Etiketler',
                    },
                    children: [
                        {
                            name: 'platform.contents.categories.index',
                            path: '',
                            component: DatatableContainer,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Etiketler',
                                path: 'categories',
                                end_point: 'cms/categories/platform'
                            },
                        },
                        {
                            name: 'platform.contents.categories.create',
                            path: 'create',
                            component: ContentCategory,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Etiket Olustur',
                                path: 'categories',
                                end_point: 'cms/categories/platform'
                            }
                        },
                        {
                            name: 'platform.contents.categories.edit',
                            path: 'edit/:id',
                            component: ContentCategory,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Etiket Bilgileri',
                                path: 'categories',
                                end_point: 'cms/categories/platform'
                            }
                        }
                    ]
                },
            ]
        },
        {
            path: 'books',
            component: MasterContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Kitaplar',
            },
            children: [
                {
                    path: 'contents',
                    component: PageContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Kitaplar',
                    },
                    children: [
                        {
                            name: 'platform.books.contents.index',
                            path: '',
                            component: DatatableContainer,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Kitaplar',
                                path: 'contents',
                                end_point: 'platform/books/contents'
                            },
                        },
                        {
                            name: 'platform.books.contents.create',
                            path: 'create',
                            component: Book,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Kitap Olustur',
                                path: 'contents',
                                end_point: 'platform/books/contents'
                            }
                        },
                        {
                            name: 'platform.books.contents.edit',
                            path: 'edit/:id',
                            component: Book,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Kitap Bilgileri',
                                path: 'contents',
                                end_point: 'platform/books/contents'
                            }
                        }
                    ]
                },
                {
                    path: 'categories',
                    component: PageContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Kategoriler',
                    },
                    children: [
                        {
                            name: 'platform.books.categories.index',
                            path: '',
                            component: DatatableContainer,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Kategoriler',
                                path: 'categories',
                                end_point: 'platform/books/categories'
                            },
                        },
                        {
                            name: 'platform.books.categories.create',
                            path: 'create',
                            component: ContentCategory,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Kategori Olustur',
                                path: 'categories',
                                end_point: 'platform/books/categories'
                            }
                        },
                        {
                            name: 'platform.books.categories.edit',
                            path: 'edit/:id',
                            component: ContentCategory,
                            meta: {
                                auth: true,
                                layout: layouts.navLeft,
                                title: 'Kategori Bilgileri',
                                path: 'categories',
                                end_point: 'platform/books/categories'
                            }
                        }
                    ]
                }
            ]
        },
    ]
}
