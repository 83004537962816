<template>
    <vue-scroll class="page-element-form">
        <div class="loading-container" v-loading="itemLoading">
            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        {{ $route.meta.title }}
                    </div>
                    <crud-buttons :store-item="storeItem" :update-item="updateItem" />
                </header>
                <section class="card-box-content padding-20">
                    <el-form :label-position="'top'" ref="form" :model="form">

                        <div v-if="!$route.params.id">
                            <el-row>
                                <el-col :span="12" v-for="item in items" :key="item.name" style="cursor: pointer">
                                    <el-card>
                                        <div style="padding: 14px;" @click="storeItem(item.name)">
                                            <div>{{ item.title }}</div>
                                            <div>{{ item.description }}</div>
                                        </div>
                                    </el-card>
                                </el-col>
                            </el-row>
                        </div>

                        <div v-if="$route.params.id">
                            <el-form-item label="Başlık">
                                <el-input v-model="form.title" placeholder="Başlık"/>
                            </el-form-item>

                            <el-row :gutter="24">
                                <el-col :span="6">
                                    <el-form-item label="Favicon">
                                        <file-browser v-model="form.favicon"/>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="6">
                                    <el-form-item label="Logo">
                                        <file-browser v-model="form.logo"/>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="6">
                                    <el-form-item label="Footer Logo">
                                        <file-browser v-model="form.footer_logo"/>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="6">
                                    <el-form-item label="Giris Logosu">
                                        <file-browser v-model="form.auth_logo"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-form-item label="Html Meta">
                                <codemirror v-model="form.html_meta" :options="htmlMetaOptions"/>
                            </el-form-item>

                            <div class="margin-top-20 margin-bottom-20">
                                <h4>Styles</h4>

                                <el-form-item label="Style Links">
                                    <el-input v-model="form.style_links" placeholder="Style Links"/>
                                </el-form-item>

                                <el-form-item label="Style Code">
                                    <codemirror v-model="form.style_code" :options="styleOptions"/>
                                </el-form-item>
                            </div>

                            <div class="margin-bottom-20">
                                <h4>Javascript</h4>

                                <el-form-item label="Js Scripts">
                                    <el-input v-model="form.js_scripts" placeholder="Js Scripts"/>
                                </el-form-item>

                                <el-form-item label="Js Code">
                                    <codemirror v-model="form.js_code" :options="jsOptions"/>
                                </el-form-item>
                            </div>
                        </div>

                    </el-form>
                </section>
            </div>
        </div>
    </vue-scroll>
</template>

<script>

    import FileBrowser from "../../../components/FileBrowser";

    export default {
        name: 'ElementForm',
        props: ['source'],
        components: {
            FileBrowser,
        },
        data() {
            return {
                itemLoading: true,
                items : [],
                form: {
                    logo: null,
                    footer_logo: null,
                    auth_logo: null,
                    favicon: null,
                    html_meta: '',
                    style_code: '',
                    js_code: '',
                },
                htmlMetaOptions: {
                    mode: 'text/html',
                    tabSize: 4,
                    styleActiveLine: true,
                    lineNumbers: true,
                    autoCloseTags: true,
                    line: true,
                    theme: 'eclipse'
                },
                styleOptions: {
                    mode: 'text/css',
                    tabSize: 4,
                    styleActiveLine: true,
                    lineNumbers: true,
                    autoCloseTags: true,
                    line: true,
                    theme: 'eclipse'
                },
                jsOptions: {
                    mode: 'text/javascript',
                    tabSize: 4,
                    styleActiveLine: true,
                    lineNumbers: true,
                    autoCloseTags: true,
                    line: true,
                    theme: 'eclipse'
                }
            }
        },

        methods: {
            loadItem() {

                this.itemLoading = true;

                this.$http
                    .get(this.$route.meta.end_point + '/' + this.$route.params.id, {
                        params: this.serverParams
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.form = response.body.item
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            createItem() {

                this.itemLoading = true;

                this.$http
                    .get(this.$route.meta.end_point + '/create', {
                        params: this.serverParams
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.items = response.body.themes
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            storeItem() {

                this.itemLoading = true;

                this.$http
                    .post(this.$route.meta.end_point, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                        this.$router.push('edit/' + response.body.item.id);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            updateItem() {

                this.itemLoading = true;

                this.$http
                    .put(this.$route.meta.end_point + '/' + this.$route.params.id, this.form)
                    .then(response => {
                        this.itemLoading = false;
                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            destroyItem(id) {
                this.itemLoading = true;

                this.$confirm('This will permanently delete the file. Continue?', 'Uyari', {
                    confirmButtonText: 'Tamam',
                    cancelButtonText: 'Iptal Et',
                    type: 'warning'
                })
                    .then(() => {
                        this.$http
                            .delete(this.$route.meta.end_point + '/' + id)
                            .then(response => {
                                this.itemLoading = false;

                                this.$router.push('/');

                                this.apiSuccessMessage(response)
                            })
                            .catch((e) => {
                                this.itemLoading = false;
                                this.apiErrorMessage(e);
                            });
                    })
                    .catch(() => {
                        this.itemLoading = false;
                    });
            },
        },

        mounted() {
            if (this.$route.params.id) {
                this.loadItem()
            } else {
                this.createItem()
            }
        }
    }
</script>