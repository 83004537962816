<style lang="scss" scoped>
.payments {
    .payment {
        margin-bottom    : 30px;
        text-align       : center;
        padding          : 40px;
        border           : 2px solid transparent;
        cursor           : pointer;
        position         : relative;
        background-color : #f9f9f9;
        border-radius    : 0.1875rem;
        display          : inline-block;
        overflow         : hidden;
        width            : 100%;

        .payment-icon {
            text-align : center;
            cursor     : pointer;
            font-size  : 44px;
        }

        .payment-text {
            padding : 10px 0;
        }

        &:hover {
            border-color : #00b289;

            .payment-icon {
                color : #00b289;
            }

            .payment-text {
                color : #00b289;
            }
        }
    }
}

.payment-icon-Payu:before {
    content : "\F19C";
}

.payment-icon-Paytr:before {
    content : "\F19C";
}

.payment-icon-Eft:before {
    content : "\F070";
}

.payment-icon-Cash:before {
    content : "\F116";
}

.payment-icon-Kmh:before {
    content : "\F0F3";
}

.payment-icon-Voucher:before {
    content : "\F21A";
}

.payment-icon-KmhContinued:before {
    content : "\F0F0";
}

.payment-icon-Pos:before {
    content : "\F19B";
}

iframe {
    border : none !important;
}
</style>

<template>
    <vue-scroll class="page-element-form" v-loading="itemLoading">
        <div class="loading-container">
            <el-form :label-position="'top'" ref="form" :model="form" v-if="offer">
                <el-row :gutter="24">
                    <el-col :span="16">
                        <div class="steps">
                            <div class="step" v-if="steps.info">
                                <Products :offer="offer" />

                                <div class="text-right">
                                    <div class="ui button right labeled  icon blue" @click="nextStep('address')">
                                        <i class="icon el-icon-arrow-right"></i>
                                        Devam Et
                                    </div>
                                </div>
                            </div>
                            <div class="step" v-if="steps.address">
                                <div class="ui icon message yellow">
                                    <i class="icon mdi mdi-alert"></i>
                                    <div class="content">
                                        <div class="header" style="margin: 0; height: auto">
                                            Onemli!
                                        </div>
                                        <p>
                                            Kredi Karti ile odemeler de addres bilgilerinin tamaminin dolu olmasi
                                            zorunludur.
                                        </p>
                                    </div>
                                </div>

                                <el-row :gutter="24">
                                    <el-col :span="24">
                                        <div class="card-box">
                                            <header class="card-box-header">
                                                <div class="title">
                                                    Teslimat Adresi
                                                </div>
                                                <div class="actions">
                                                    <el-button icon="el-icon-edit" type="text"
                                                               class="el-button-link green"
                                                               v-if="form.delivery_address_id"
                                                               @click="openBlankWindow('/crm/addresses/edit/' + form.delivery_address_id)" />
                                                </div>
                                            </header>
                                            <section class="card-box-content">
                                                <div class="box-list">
                                                    <div class="box-list-item box-list-item-title">
                                                        <el-form-item style="margin: 0" class="fluid">
                                                            <el-select v-model="deliveryAddress" value-key="id"
                                                                       placeholder="Teslimat Adresi" clearable
                                                                       @change="form.delivery_address_id = deliveryAddress.id">
                                                                <el-option v-for="item in addresses" :key="item.id"
                                                                           :label="item.title" :value="item" />
                                                            </el-select>
                                                        </el-form-item>
                                                    </div>

                                                    <div v-if="deliveryAddress" class="fluid">
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                T.C. No
                                                            </div>
                                                            <div class="description">
                                                                {{ deliveryAddress.identification_no }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Ad Soyad
                                                            </div>
                                                            <div class="description">
                                                                {{ deliveryAddress.first_name }} {{
                                                                    deliveryAddress.last_name
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                E-Mail
                                                            </div>
                                                            <div class="description">
                                                                {{ deliveryAddress.email }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Telefon
                                                            </div>
                                                            <div class="description">
                                                                {{ deliveryAddress.phone }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Adres
                                                            </div>
                                                            <div class="description">
                                                                {{ deliveryAddress.address_1 }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Adres 2
                                                            </div>
                                                            <div class="description">
                                                                {{ deliveryAddress.address_2 }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Mahalle
                                                            </div>
                                                            <div class="description">
                                                                <span v-if="deliveryAddress.neighborhood">
                                                                    {{ deliveryAddress.neighborhood.title }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Semt
                                                            </div>
                                                            <div class="description">
                                                                <span v-if="deliveryAddress.district">
                                                                    {{ deliveryAddress.district.title }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Ilce
                                                            </div>
                                                            <div class="description">
                                                                <span v-if="deliveryAddress.town">
                                                                    {{ deliveryAddress.town.title }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Il
                                                            </div>
                                                            <div class="description">
                                                                <span v-if="deliveryAddress.city">
                                                                    {{ deliveryAddress.city.title }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </el-col>
                                    <el-col :span="24">
                                        <div class="card-box">
                                            <header class="card-box-header">
                                                <div class="title">
                                                    Fatura Adresi
                                                </div>
                                                <div class="actions">
                                                    <el-button icon="el-icon-edit" type="text"
                                                               class="el-button-link green"
                                                               v-if="form.invoice_address_id"
                                                               @click="openBlankWindow('/crm/addresses/edit/' + form.invoice_address_id)" />
                                                </div>
                                            </header>
                                            <section class="card-box-content">
                                                <div class="box-list">
                                                    <div class="box-list-item box-list-item-title">
                                                        <el-form-item style="margin: 0" class="fluid">
                                                            <el-select v-model="invoiceAddress" value-key="id"
                                                                       placeholder="Fatura Adresi" clearable
                                                                       @change="form.invoice_address_id = invoiceAddress.id">
                                                                <el-option v-for="item in addresses" :key="item.id"
                                                                           :label="item.title" :value="item" />
                                                            </el-select>
                                                        </el-form-item>
                                                    </div>

                                                    <div v-if="invoiceAddress" class="fluid">
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                T.C. No
                                                            </div>
                                                            <div class="description">
                                                                {{ invoiceAddress.identification_no }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Ad Soyad
                                                            </div>
                                                            <div class="description">
                                                                {{ invoiceAddress.first_name }} {{
                                                                    invoiceAddress.last_name
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                E-Mail
                                                            </div>
                                                            <div class="description">
                                                                {{ invoiceAddress.email }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Telefon
                                                            </div>
                                                            <div class="description">
                                                                {{ invoiceAddress.phone }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Adres
                                                            </div>
                                                            <div class="description">
                                                                {{ invoiceAddress.address_1 }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Adres 2
                                                            </div>
                                                            <div class="description">
                                                                {{ invoiceAddress.address_2 }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Mahalle
                                                            </div>
                                                            <div class="description">
                                                                <span v-if="invoiceAddress.neighborhood">
                                                                    {{ invoiceAddress.neighborhood.title }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Semt
                                                            </div>
                                                            <div class="description">
                                                                <span v-if="invoiceAddress.district">
                                                                    {{ invoiceAddress.district.title }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Ilce
                                                            </div>
                                                            <div class="description">
                                                                <span v-if="invoiceAddress.town">
                                                                    {{ invoiceAddress.town.title }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Il
                                                            </div>
                                                            <div class="description">
                                                                <span v-if="invoiceAddress.city">
                                                                    {{ invoiceAddress.city.title }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </section>
                                        </div>
                                    </el-col>
                                    <el-col :span="24">
                                        <div class="card-box">
                                            <header class="card-box-header">
                                                <div class="title">
                                                    Sozlesme Adresi
                                                </div>
                                                <div class="actions">
                                                    <el-button icon="el-icon-edit" type="text"
                                                               class="el-button-link green"
                                                               v-if="form.contract_address_id"
                                                               @click="openBlankWindow('/crm/addresses/edit/' + form.contract_address_id)" />
                                                </div>
                                            </header>
                                            <section class="card-box-content">
                                                <div class="box-list">
                                                    <div class="box-list-item box-list-item-title">
                                                        <el-form-item style="margin: 0" class="fluid">
                                                            <el-select v-model="contractAddress" value-key="id"
                                                                       placeholder="Fatura Adresi" clearable
                                                                       @change="form.contract_address_id = contractAddress.id">
                                                                <el-option v-for="item in addresses" :key="item.id"
                                                                           :label="item.title" :value="item" />
                                                            </el-select>
                                                        </el-form-item>
                                                    </div>

                                                    <div v-if="contractAddress" class="fluid">
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                T.C. No
                                                            </div>
                                                            <div class="description">
                                                                {{ contractAddress.identification_no }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Ad Soyad
                                                            </div>
                                                            <div class="description">
                                                                {{ contractAddress.first_name }} {{
                                                                    contractAddress.last_name
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                E-Mail
                                                            </div>
                                                            <div class="description">
                                                                {{ contractAddress.email }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Telefon
                                                            </div>
                                                            <div class="description">
                                                                {{ contractAddress.phone }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Adres
                                                            </div>
                                                            <div class="description">
                                                                {{ contractAddress.address_1 }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Adres 2
                                                            </div>
                                                            <div class="description">
                                                                {{ contractAddress.address_2 }}
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Mahalle
                                                            </div>
                                                            <div class="description">
                                                                <span v-if="contractAddress.neighborhood">
                                                                    {{ contractAddress.neighborhood.title }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Semt
                                                            </div>
                                                            <div class="description">
                                                                <span v-if="contractAddress.district">
                                                                    {{ contractAddress.district.title }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Ilce
                                                            </div>
                                                            <div class="description">
                                                                <span v-if="contractAddress.town">
                                                                    {{ contractAddress.town.title }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="box-list-item">
                                                            <div class="name">
                                                                Il
                                                            </div>
                                                            <div class="description">
                                                                <span v-if="contractAddress.city">
                                                                    {{ contractAddress.city.title }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </section>
                                        </div>
                                    </el-col>
                                </el-row>

                                <div style="display: flex; justify-content: space-between">
                                    <div class="ui button labeled icon " @click="nextStep('info')">
                                        <i class="icon el-icon-arrow-left"></i>
                                        Geri Don
                                    </div>
                                    <div class="ui button right labeled  icon blue" @click="nextStep('payment')">
                                        <i class="icon el-icon-arrow-right"></i>
                                        Devam Et
                                    </div>
                                </div>
                            </div>
                            <div class="step" v-if="steps.payment">

                                <div v-if="!selectedPaymentMethod">
                                    <div class="card-box">
                                        <section class="card-box-content padding-20">
                                            <div class="payments">
                                                <el-row :gutter="24">
                                                    <el-col :span="12"
                                                            v-for="(orderPayment, orderPaymentKey) in order.payments"
                                                            :key="orderPaymentKey">
                                                        <div class="payment"
                                                             @click="paymentStep(orderPaymentKey, orderPayment)">
                                                            <div class="payment-icon">
                                                                <i :class="'mdi payment-icon-' + orderPayment.name"></i>
                                                            </div>
                                                            <div class="payment-text">
                                                                {{ orderPayment.title }}
                                                            </div>
                                                        </div>
                                                    </el-col>
                                                </el-row>
                                            </div>
                                        </section>
                                    </div>

                                    <div style="display: flex; justify-content: space-between">
                                        <div class="ui button labeled icon " @click="nextStep('address')">
                                            <i class="icon el-icon-arrow-left"></i>
                                            Geri Don
                                        </div>
                                    </div>
                                </div>

                                <div class="pay" v-if="selectedPaymentMethod">
                                    <div class="card-box">
                                        <header class="card-box-header">
                                            <div class="title">
                                                {{ selectedPaymentMethod.title }}
                                            </div>
                                        </header>
                                        <section class="card-box-content">
                                            <div
                                                v-if="selectedPaymentMethod.name === 'Cash' || selectedPaymentMethod.name === 'Voucher'"
                                                class="padding-20">
                                                <div class="ui message warning large text-center">
                                                    <div class="card-box-content-empty-icon">
                                                        <i class="el-icon-warning fs-60"></i>
                                                    </div>
                                                    <p>
                                                        Herhangi bir islem yapmaniza gerek yok direk siparisi tamamlaya
                                                        bilirsiniz
                                                    </p>
                                                </div>
                                            </div>

                                            <div
                                                v-if="selectedPaymentMethod.name === 'Eft' || selectedPaymentMethod.name === 'Kmh' || selectedPaymentMethod.name === 'KmhContinued'">
                                                <div class="padding-20">
                                                    <div class="ui icon message yellow">
                                                        <i class="icon el-icon-warning"></i>
                                                        <div class="content">
                                                            <p v-html="selectedPaymentMethod.alert"></p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="box-list">
                                                    <div
                                                        v-for="(row, key) in selectedPaymentMethod.rows"
                                                        :class="{'box-list-item' : true, 'box-list-item-clickable' : true, 'bg-success' : row.selected}"
                                                        @click="selectedPaymentRow(selectedPaymentMethod.name, key)"
                                                    >
                                                        <div class="media margin-right-20" style="width: 100px">
                                                            <img :src="appConfig.frontPath + '/' + row.image" alt="">
                                                        </div>
                                                        <div class="text fluid">
                                                            <div class="text-item">
                                                                <div class="name fs-20">
                                                                    {{ row.title }}
                                                                </div>
                                                            </div>
                                                            <div class="text-item">
                                                                <div>
                                                                    Sube
                                                                </div>
                                                                <div>
                                                                    {{ row.branch }}
                                                                </div>
                                                            </div>
                                                            <div class="text-item">
                                                                <div>
                                                                    IBAN
                                                                </div>
                                                                <div>
                                                                    {{ row.iban }}
                                                                </div>
                                                            </div>
                                                            <div class="text-item">
                                                                <div>
                                                                    Hesap No
                                                                </div>
                                                                <div>
                                                                    {{ row.account }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                v-if="selectedPaymentMethod.name === 'Payu' || selectedPaymentMethod.name === 'Paytr'">
                                                <div class="padding-20">
                                                    <el-form-item>
                                                        <el-input v-model="form.cc.owner"
                                                                  placeholder="Kart Üzerindeki İsim" />
                                                    </el-form-item>

                                                    <el-form-item>
                                                        <el-input v-model="form.cc.number" placeholder="Kart Numarası"
                                                                  v-mask="'#### #### #### ####'" />
                                                    </el-form-item>

                                                    <el-row :gutter="24">
                                                        <el-col :span="12">
                                                            <el-form-item>
                                                                <el-input v-model="form.cc.month" placeholder="Ay"
                                                                          v-mask="'##'" />
                                                            </el-form-item>
                                                        </el-col>
                                                        <el-col :span="12">
                                                            <el-form-item>
                                                                <el-input v-model="form.cc.year" placeholder="Yil"
                                                                          v-mask="'####'" />
                                                            </el-form-item>
                                                        </el-col>
                                                    </el-row>

                                                    <el-form-item>
                                                        <el-input v-model="form.cc.cvv" placeholder="Guvenlik Kodu"
                                                                  v-mask="'###'" />
                                                    </el-form-item>

                                                    <el-row :gutter="24">
                                                        <el-col :span="12">
                                                            <el-radio v-model="form.installment_type" label="single"
                                                                      @change="getInstallments('single')">Tek Cekim
                                                            </el-radio>
                                                        </el-col>
                                                        <el-col :span="12">
                                                            <el-radio v-model="form.installment_type" label="multi"
                                                                      @change="getInstallments('multi')">Taksitli Cekim
                                                            </el-radio>
                                                        </el-col>
                                                    </el-row>

                                                    <el-form-item v-if="installments" class="margin-top-20">
                                                        <el-form-item>
                                                            <template>
                                                                <el-select v-model="form.installment_no" value-key="id"
                                                                           placeholder="Taksit Secimi">
                                                                    <el-option
                                                                        v-for="(item, key) in installments"
                                                                        :key="item.id"
                                                                        :label="item.title"
                                                                        :value="item.id">
                                                                    </el-option>
                                                                </el-select>
                                                            </template>
                                                        </el-form-item>
                                                    </el-form-item>
                                                </div>
                                                <div class="padding-20">
                                                    <BankInstallment :container="form" :total="offer.total"
                                                                     :key="offer.total" />
                                                </div>
                                            </div>

                                            <div v-if="selectedPaymentMethod.name === 'Pos'" class="padding-20">
                                                <BankInstallment :container="form" :total="offer.total"
                                                                 :key="offer.total" />
                                            </div>
                                        </section>
                                    </div>
                                    <div style="display: flex; justify-content: space-between">
                                        <div class="ui button labeled icon " @click="selectedPaymentMethod = null">
                                            <i class="icon el-icon-arrow-left"></i>
                                            Geri Don
                                        </div>
                                        <div class="ui button labeled icon green" @click="storeItem">
                                            <i class="icon el-icon-check"></i> Siparis Olustur
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <Info :offer="offer" />
                    </el-col>
                </el-row>
            </el-form>
        </div>

        <el-dialog title="Payu Odeme Kanali" :visible.sync="paymentModalVisible" v-if="paymentUrl">
            <iframe id="PayuIframe" :src="paymentUrl" style="width: 100%; min-height: 600px"
                    v-if="paymentModalVisible"></iframe>
        </el-dialog>

        <div id="CreditCardServiceResponse" style="display: none">
            <div v-html="PaymentDataForm"></div>
        </div>
    </vue-scroll>
</template>

<script>
import Info from "./_create/Info";
import Products from "../Offers/_parts/Products";
import BankInstallment from "../../../../components/BankInstallment";

export default {
    name: 'ElementForm',
    components: {BankInstallment, Products, Info},
    data() {
        return {
            itemLoading: true,
            paymentUrl: null,
            paymentModalVisible: null,
            selectedPaymentMethod: null,
            deliveryAddress: null,
            invoiceAddress: null,
            contractAddress: null,
            steps: {
                info: true,
                address: null,
                payment: null,
                success: null,
            },
            payment: {
                Payu: null,
                Eft: null,
                Cash: null,
                Voucher: null,
                Kmh: null,
                KmhContinued: null,
            },
            offer: null,
            order: {
                payments: []
            },
            addresses: [],
            installments: null,
            deliveryModalVisible: null,
            invoiceModalVisible: null,
            form: {
                offer_id: null,
                delivery_address_id: null,
                invoice_address_id: null,
                contract_address_id: null,
                payment: null,
                installment_no: 1,
                installment_type: null,
                bank_name: null,
                cc: {
                    owner: null,
                    number: null,
                    month: null,
                    year: null,
                    cvv: null,
                },

            },

            PaymentDataForm: '',
        }
    },

    methods: {

        loadOffer() {

            this.itemLoading = true;

            this.$http
                .get('sales/offers/' + this.$route.query.offer_id)
                .then(response => {
                    this.itemLoading = false;
                    this.offer = response.body.item;
                    this.form.offer_id = this.offer.id;

                    this.getAddresses();
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        loadOrder() {

            this.itemLoading = true;

            this.$http
                .get(this.$route.meta.end_point + '/create')
                .then(response => {
                    this.itemLoading = false;
                    this.order = response.body;

                    this.getAddresses();
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        getAddresses() {

            this.itemLoading = true;

            this.$http
                .get('crm/accounts/' + this.offer.account_id + '/addresses')
                .then(response => {
                    this.itemLoading = false;
                    this.addresses = response.body.rows;
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        storeItem() {
            this.itemLoading = true;

            let form = this.form;
            form.process = null;

            this.$http.post(this.$route.meta.end_point, form)
                .then(response => {

                    this.apiSuccessMessage(response);

                    if (response.body.form) {

                        this.PaymentDataForm = response.body.form;

                        setTimeout(() => {
                            $('#CreditCardServiceResponse form').submit();
                        }, 2000)

                    } else {

                        this.itemLoading = false;

                        if (response.body.payment) {

                            this.itemLoading = true;

                            this.paymentUrl = response.body.redirect.url;
                            this.paymentModalVisible = true;
                        }

                        return this.$router.push('edit/' + response.body.item.id);
                    }
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);
                });
        },

        nextStep(key) {

            if (key === 'payment') {

                if (!this.form.delivery_address_id) {
                    return this.$notify.error({
                        title: 'Upss!',
                        message: 'Teslimat adresi alaninin doldurulmasi zorunludur',
                        position: 'top-right'
                    });
                }

                if (!this.form.invoice_address_id) {
                    return this.$notify.error({
                        title: 'Upss!',
                        message: 'Fatura adresi alaninin doldurulmasi zorunludur',
                        position: 'top-right'
                    });
                }

                if (!this.form.contract_address_id) {
                    return this.$notify.error({
                        title: 'Upss!',
                        message: 'Sozlesme adresi alaninin doldurulmasi zorunludur',
                        position: 'top-right'
                    });
                }
            }

            this.steps = {
                info: null,
                address: null,
                payment: null,
                success: null,
            };

            this.steps[key] = true;
        },

        paymentStep(key, item) {
            this.payment = {
                Payu: null,
                Eft: null,
                Cash: null,
                Voucher: null,
                Kmh: null,
            };

            this.form.payment = key;

            this.payment[key] = true;

            this.selectedPaymentMethod = item;
        },

        selectedPaymentRow(PaymentKey, rowKey) {

            let rows = [];

            this.order.payments[PaymentKey].rows.forEach(function (row) {

                row.selected = false;

                rows.push(row);
            });

            this.form.bank_name = rows[rowKey].title;

            rows[rowKey].selected = true;

            this.order.payments[PaymentKey].rows = rows;
        },

        getInstallments(type) {

            if (type === 'single') {
                this.installments = null;
                this.form.installment_no = 1;
                this.form.installment_type = 'single';

                return;
            }

            this.itemLoading = true;

            let form = this.form;
            form.process = 'installments';

            this.$http.post(this.$route.meta.end_point, form)
                .then(response => {
                    this.itemLoading = false;
                    this.apiSuccessMessage(response);

                    this.installments = response.body.items
                })
                .catch((e) => {
                    this.itemLoading = false;
                    this.apiErrorMessage(e);

                    this.installments = null;
                    this.form.installment_no = 1;
                    this.form.installment_type = 'single';
                });
        }
    },

    mounted() {
        if (this.$route.query.error) {
            this.$notify.error({
                title: 'Upss!',
                message: this.$route.query.error,
                position: 'top-right'
            });
        }

        this.loadOffer();
        this.loadOrder();
    },
}
</script>