<template>
    <vue-scroll>
        <div class="loading-container" v-loading="pageLoading">
            <div class="card-box">
                <header class="card-box-header has-buttons">
                    <div class="title">
                        Ogrenci Raporlari
                    </div>
                    <div class="actions">
                        <el-button icon="el-icon-download" type="text" class="el-button-link grey d-flex"
                                   @click="exportVisible = true" v-if="report"/>
                        <el-button icon="el-icon-search" type="text" class="el-button-link blue d-flex"
                                   @click="filterVisible = true"/>
                    </div>
                </header>
            </div>

            <div v-if="report">
                <el-row :gutter="24">
                    <el-col :span="12">
                        <div class="card-box">
                            <div class="card-box-content">
                                <div class="box-list">
                                    <div class="box-list-item">
                                        <div class="media">
                                            <i class="mdi mdi-school fs-38 fw-500"></i>
                                        </div>
                                        <div class="text">
                                            <div class="total text-right fs-24 fw-500">
                                                {{ report.total }}
                                            </div>
                                            <div class="description">
                                                Toplam
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="media">
                                            <i class="mdi mdi-gender-female fs-38 fw-500"></i>
                                        </div>
                                        <div class="text">
                                            <div class="total text-right fs-24 fw-500">
                                                {{ report.gender.woman }}
                                            </div>
                                            <div class="description">
                                                Kadin
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="media">
                                            <i class="mdi mdi-gender-male fs-38 fw-500"></i>
                                        </div>
                                        <div class="text">
                                            <div class="total text-right fs-24 fw-500">
                                                {{ report.gender.man }}
                                            </div>
                                            <div class="description">
                                                Erkek
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="card-box">
                            <div class="card-box-content has-canvas canvas-center" style="height: 218px">
                                <highcharts :options="genderChart" style="height: 180px;"/>
                            </div>
                        </div>
                    </el-col>
                </el-row>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <div class="card-box">
                            <div class="card-box-content">
                                <div class="box-list">
                                    <div class="box-list-item">
                                        <div class="media">
                                            <i class="mdi mdi-school fs-38 fw-500"></i>
                                        </div>
                                        <div class="text">
                                            <div class="total text-right fs-24 fw-500">
                                                {{ report.loyalty.new }}
                                            </div>
                                            <div class="description fluid">
                                                Yeni Baslayan
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="media">
                                            <i class="mdi mdi-school fs-38 fw-500"></i>
                                        </div>
                                        <div class="text">
                                            <div class="total text-right fs-24 fw-500">
                                                {{ report.loyalty.one }}
                                            </div>
                                            <div class="description fluid">
                                                1 Yillik
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="media">
                                            <i class="mdi mdi-school fs-38 fw-500"></i>
                                        </div>
                                        <div class="text">
                                            <div class="total text-right fs-24 fw-500">
                                                {{ report.loyalty.two }}
                                            </div>
                                            <div class="description fluid">
                                                2 Yillik
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="media">
                                            <i class="mdi mdi-school fs-38 fw-500"></i>
                                        </div>
                                        <div class="text">
                                            <div class="total text-right fs-24 fw-500">
                                                {{ report.loyalty.more }}
                                            </div>
                                            <div class="description fluid">
                                                3 ve Uzeri
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="card-box">
                            <div class="card-box-content has-canvas canvas-center" style="height: 291px">
                                <highcharts :options="loyaltyChart" style="height: 271px;"/>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>

        <el-dialog title="Filtrele" :visible.sync="filterVisible" v-loading="pageLoading" width="50%">
            <el-form :label-position="'top'" ref="form" :model="filterForm">

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Egitim Yili">
                            <select-definition label="Egitim Yili" alias="education-years"
                                               model="education_year_definition_id" :container="filterForm"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Egitim Donemi">
                            <select-definition label="Egitim Donemi" alias="education-periods"
                                               model="education_period_definition_id" :container="filterForm"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Siniflar">
                    <select-definition label="Siniflar" alias="classes" model="class_definition_id"
                                       :container="filterForm"/>
                </el-form-item>

                <el-form-item v-for="category in categories" :key="category.id" v-if="category" :label="category.title">
                    <el-select v-model="filterForm.categories[category.id]" :placeholder="category.title + ' secin'"
                               clearable>
                        <el-option
                                v-for="item in category.items"
                                v-if="item"
                                :key="item.id"
                                :label="item.title"
                                :value="item.id"
                        />
                    </el-select>
                </el-form-item>

                <el-form-item label="Dogum Yili">
                    <el-date-picker v-model="filterForm.birth_year" type="year" placeholder="Dogum Yili"/>
                </el-form-item>

                <el-form-item label="Baglilik">
                    <el-select v-model="filterForm.loyalty_year" placeholder="Baglilik" clearable>
                        <el-option v-for="item in loyalties" :key="item.value" :label="item.label" :value="item.value"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="Kan Grubu">
                    <select-definition label="Kan Grubu" alias="blood-groups" model="blood_definition_id"
                                       :container="filterForm"/>
                </el-form-item>

                <el-form-item label="Okullar">
                    <select-definition label="Okullar" alias="schools" model="school_definition_id"
                                       :container="filterForm"/>
                </el-form-item>

                <el-form-item label="Kullandigi Kampanya">
                    <el-select v-model="filterForm.campaign_id" placeholder="Kullandigi Kampanya" clearable>
                        <el-option v-for="item in campaigns" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="Kurumlar">
                    <el-select v-model="filterForm.company_id" placeholder="Kurumlar" clearable>
                        <el-option v-for="item in companies" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="Roller">
                    <el-select v-model="filterForm.role_id" placeholder="Kurumlar" clearable>
                        <el-option v-for="item in roles" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>

                <el-form-item label="Velinin Meslegi">
                    <select-definition label="Meslek" alias="jobs" model="job_definition_id" :container="filterForm"/>
                </el-form-item>

                <el-form-item label="Velinin Egitim Durumu">
                    <select-definition label="Meslek" alias="educations" model="education_definition_id"
                                       :container="filterForm"/>
                </el-form-item>

                <div class="ui button labeled icon green fluid" @click="handleSearch">
                    <i class="el-icon-search icon"></i> Ara
                </div>
            </el-form>
        </el-dialog>
        <el-dialog title="Disa Aktar" :visible.sync="exportVisible" v-loading="pageLoading" width="50%">
            <div class="card-box m-0">
                <div class="card-box-content">
                    <div class="box-list">
                        <div class="box-list-item cursor-pointer" @click="handleExport('query')">
                            <div class="name">Sorgu Listesi</div>
                        </div>
                        <div class="box-list-item cursor-pointer"  @click="handleExport('sublies')">
                            <div class="name">Kardesi Olanlar</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-box margin-top-20" v-if="exportData">
                <div class="card-box-content padding-20">
                    <div class="ui message">
                        {{ exportData.success }}
                    </div>

                    <div class="ui button icon labeled fluid green fw-500" @click="openBlankWindow(exportData.link)">
                        <i class="icon el-icon-download"></i> Indir
                    </div>
                </div>
            </div>
        </el-dialog>
    </vue-scroll>
</template>

<script>
    import SelectDefinition from "../../../components/SelectDefinition";

    export default {
        name: 'Student',
        components: {SelectDefinition},
        data() {
            return {
                pageLoading: true,
                filterVisible: false,
                exportVisible: false,
                filterForm: {
                    categories: []
                },
                categories: [],
                campaigns: [],
                roles: [],
                companies: [],
                loyalties: [
                    {
                        label: 'Yeni Baslayanlar',
                        value: 1
                    },
                    {
                        label: '1 Yil',
                        value: 2
                    },
                    {
                        label: '2 Yil',
                        value: 3
                    },
                    {
                        label: '3 Yil ve Uzeri',
                        value: 4
                    }
                ],
                genderChart: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                    },
                    title: {
                        text: ''
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    series: null
                },
                loyaltyChart: {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie',
                    },
                    title: {
                        text: ''
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    series: null
                },
                options: {
                    segmentShowStroke: false,
                    responsive: false,
                },

                report: null,
                exportData : null
            }
        },

        methods: {
            getCategories() {
                this.pageLoading = true;

                this.$http
                    .get('tools/search/categories', {
                        params: {
                            alias: 'classrooms'
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;

                        _.forEach(response.body.items, (item) => {

                            if (!item) {
                                return;
                            }

                            if (item.parent_id) {
                                this.categories[item.parent_id]['items'][item.id] = item;
                            } else {
                                item.items = [];
                                item.selected = null;
                                this.categories[item.id] = item;
                            }
                        });
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            getCampaigns() {
                this.pageLoading = true;

                this.$http
                    .get('marketing/campaigns', {
                        params: {
                            perPage: 100,
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.campaigns = response.body.rows
                    });
            },

            getRoles() {
                this.pageLoading = true;

                this.$http
                    .get('crm/roles', {
                        params: {
                            perPage: 100,
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.roles = response.body.rows
                    });
            },

            getCompanies() {
                this.pageLoading = true;

                this.$http
                    .get('crm/companies', {
                        params: {
                            perPage: 100,
                        }
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.companies = response.body.rows
                    });
            },

            handleSearch() {

                this.pageLoading = true;
                this.filterForm.export = null;
                this.exportData = null;

                this.$http
                    .get(this.$route.meta.end_point, {
                        params: this.filterForm
                    })
                    .then(response => {
                        this.pageLoading = false;
                        this.filterVisible = false;

                        let report = response.body;

                        this.genderChart.series = [{
                            colorByPoint: true,
                            data: [
                                {
                                    name: 'Kadin',
                                    y: report.gender.woman,
                                    color: "#e03997"
                                },
                                {
                                    name: 'Erkek',
                                    y: report.gender.man,
                                    color: "#4f91cd"
                                }
                            ]
                        }];

                        this.loyaltyChart.series = [{
                            colorByPoint: true,
                            data: [
                                {
                                    name: 'Yeni Baslayan',
                                    y: report.loyalty.new,
                                    color: "#f2711c"
                                },
                                {
                                    name: '1 Yillik',
                                    y: report.loyalty.one,
                                    color: "#b5cc18"
                                },
                                {
                                    name: '2 Yillik',
                                    y: report.loyalty.two,
                                    color: "#a333c8"
                                },
                                {
                                    name: '3 Yil ve Uzeri',
                                    y: report.loyalty.more,
                                    color: "#2a41e8"
                                }
                            ]
                        }];

                        this.report = report;
                    })
                    .catch((e) => {
                        this.pageLoading = false;

                        this.apiErrorMessage(e);
                    });
            },

            handleExport(exportType) {
                this.pageLoading = true;

                this.filterForm.export = exportType;

                this.$http
                    .get(this.$route.meta.end_point, {
                        params: this.filterForm
                    })
                    .then(response => {
                        this.pageLoading = false;

                        this.exportData = response.body;
                    })
                    .catch((e) => {
                        this.pageLoading = false;

                        this.apiErrorMessage(e);
                    });
            }
        },

        mounted() {
            this.pageLoading = false;

            this.getCategories();
            this.getCampaigns();
            this.getRoles();
            this.getCompanies();
        }
    }
</script>