<style scoped lang="scss">
    .counter .name {
        line-height: normal !important;
    }
</style>

<template>
    <vue-scroll>
        <div class="loading-container" v-loading="pageLoading">
            <div v-if="user">
                <div class="card-box">
                    <header class="card-box-header">
                        <div class="title">
                            {{ user.name }}
                            <span v-if="user.gender">({{ user.gender.title }})</span>
                        </div>
                        <div class="actions">
                            <el-button icon="el-icon-edit" type="text" class="el-button-link green"
                                       @click="editDialogVisible = true"/>
                        </div>
                    </header>
                    <section class="card-box-content">
                        <div class="information-header">
                            <div class="media">
                                <avatar :img="user.avatar" type="customer"/>
                            </div>
                            <div class="text">
                                <div class="email fs-14">
                                    {{ user.email }}
                                </div>
                                <div class="email fs-14" v-if="user.address">
                                   <span v-if="user.address">
                                       {{ user.address.phone }}
                                   </span>
                                </div>
                                <div class="date  fs-14" v-if="user.birthday">
                                    {{ user.birthday }}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <el-row :gutter="24" v-if="counter" class="counter">
                    <el-col :span="12">
                        <div class="card-box">
                            <div class="card-box-header">
                                <div class="title">
                                    Teklifler
                                </div>
                            </div>
                            <div class="card-box-content">
                                <div class="box-list" v-if="counter.offer">
                                    <div class="box-list-item" >
                                        <div class="name">
                                            <div class="number">{{ counter.offer.negative }}</div>
                                            <div class="text fs-11">Iptal Edilen</div>
                                        </div>
                                        <div class="icon">
                                            <i class="el-icon-minus fs-45 text-red"></i>
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            <div class="number">{{ counter.offer.positive }}</div>
                                            <div class="text fs-11">Tamamlanan</div>
                                        </div>
                                        <div class="icon">
                                            <i class="el-icon-plus fs-45 text-green"></i>
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            <div class="number">{{ counter.offer.total }}</div>
                                            <div class="text fs-11">Toplam</div>
                                        </div>
                                        <div class="icon">
                                            <i class="mdi mdi-history fs-45 text-blue"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="card-box">
                            <div class="card-box-header">
                                <div class="title">
                                    Siparisler
                                </div>
                            </div>
                            <div class="card-box-content">
                                <div class="box-list" v-if="counter.order">
                                    <div class="box-list-item">
                                        <div class="name">
                                            <div class="number">{{ counter.order.negative }}</div>
                                            <div class="text fs-11">Iptal Edilen</div>
                                        </div>
                                        <div class="icon">
                                            <i class="el-icon-minus fs-45 text-red"></i>
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            <div class="number">{{ counter.order.positive }}</div>
                                            <div class="text fs-11">Tamamlanan</div>
                                        </div>
                                        <div class="icon">
                                            <i class="el-icon-plus fs-45 text-green"></i>
                                        </div>
                                    </div>
                                    <div class="box-list-item">
                                        <div class="name">
                                            <div class="number">{{ counter.order.total }}</div>
                                            <div class="text fs-11">Toplam</div>
                                        </div>
                                        <div class="icon">
                                            <i class="mdi mdi-history fs-45 text-blue"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>

                <div class="card-box">
                    <section class="card-box-tabs">
                        <el-tabs :tab-position="'top'">
                            <el-tab-pane label="Teklifler">
                                <Offers :source="$route.meta.end_point + '/' + $route.params.id" :customer="user"/>
                            </el-tab-pane>
                            <el-tab-pane label="Siparisler">
                                <Orders :source="$route.meta.end_point + '/' + $route.params.id" :customer="user"/>
                            </el-tab-pane>
                        </el-tabs>
                    </section>
                </div>
            </div>

            <el-dialog :title="form.name" :visible.sync="editDialogVisible" v-loading="pageLoading" width="85%">
                <el-form :label-position="'top'" ref="form" :model="form">
                    <el-row :gutter="24">
                        <el-col :span="18">
                            <el-form-item label="Kullanici Adi">
                                <el-input v-model="form.name" placeholder="Kullanici Adi"/>
                            </el-form-item>

                            <el-form-item label="Eposta Adresi">
                                <el-input v-model="form.email" placeholder="Eposta Adresi"/>
                            </el-form-item>

                            <el-form-item label="Sifre">
                                <el-input v-model="form.password" placeholder="Sifre" type="password"/>
                            </el-form-item>
                        </el-col>

                        <el-col :span="6">
                            <el-form-item label="Fotograf">
                                <file-browser v-model="form.avatar"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="success" size="medium" class="fluid" @click="update">
                        <i class="mdi mdi-check"></i> Guncelle
                    </el-button>
                </div>
            </el-dialog>
        </div>
    </vue-scroll>
</template>

<script>
    import {mapState} from 'vuex';
    import Offers from "./Offers";
    import Orders from "./Orders";
    import FileBrowser from "../../../components/FileBrowser";

    export default {
        name: 'Index',
        components: {
            FileBrowser,
            Offers,
            Orders
        },
        computed: mapState([
            'user',
        ]),
        data() {
            return {
                pageLoading: true,
                editDialogVisible: false,
                form: {
                    //
                },

                counter: {
                    //
                }
            }
        },

        methods: {
            index() {
                this.pageLoading = true;

                this.$http
                    .get('account/user')
                    .then(response => {
                        this.$store.commit('setUser', response.body.item);

                        this.counter = response.body.counter;

                        this.pageLoading = false;

                        this.form = {
                            id: this.user.id,
                            name: this.user.name,
                            email: this.user.email,
                            avatar: this.user.avatar,
                            password: null,
                        }
                    })
                    .catch(error => {
                        this.pageLoading = false;

                        this.apiErrorMessage(error);
                    })
            },

            update() {
                this.pageLoading = true;

                this.$http
                    .put('crm/users/' + this.form.id, this.form)
                    .then(response => {

                        this.editDialogVisible = false;

                        this.$store.commit('setUser', response.body.item);

                        if (this.form.password) {
                            return window.location = 'https://minidil.com/giris';
                        } else {
                            this.pageLoading = false;
                        }

                        this.apiSuccessMessage(response);
                    })
                    .catch((e) => {
                        this.pageLoading = false;
                        this.apiErrorMessage(e);
                    });
            }
        },

        mounted() {
            this.pageLoading = false;

            this.index();
        }
    }
</script>