<template>
    <vue-scroll>
        <div class="loading-container" v-loading="pageLoading">
            Parent
        </div>
    </vue-scroll>
</template>

<script>
    export default {
        name: 'PageItem',
        data() {
            return {
                pageLoading: true,
                //
            }
        },

        methods: {
            //
        },

        mounted() {
            this.pageLoading = false;
        }
    }
</script>
