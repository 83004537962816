import layouts from '../config/layout'

import MasterContainer from '../app/Container/Master'
import PageContainer from '../app/Container/Page'
import DatatableContainer from '../app/Container/Dt'

import Comment from '../app/Pages/Cms/Comment'
import Category from '../app/Pages/Cms/Category'
import Content from '../app/Pages/Cms/Contents/Show'

export default {
    path: '/cms',
    name: 'cms',
    component: MasterContainer,
    meta: {
        auth: true,
        layout: layouts.navLeft,
        title: 'Cms',
    },
    children: [
        {
            path: 'comments',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Yorumlar',
            },
            children: [
                {
                    name: 'cms.comments.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Yorumlar',
                        path: 'comments',
                        end_point: 'cms/comments',
                    },
                },
                {
                    name: 'cms.comments.edit',
                    path: 'edit/:id',
                    component: Comment,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Yorum Bilgileri',
                        path: 'comments',
                        end_point: 'cms/comments',
                    },
                }
            ]
        },
        {
            path: 'categories/:type',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Kategoriler',
            },
            children: [
                {
                    name: 'cms.categories.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Kategoriler',
                        path: 'categories',
                        end_point: 'cms/categories',
                        is_link: true,
                    },
                },
                {
                    name: 'cms.categories.create',
                    path: 'create',
                    component: Category,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Kategori Olustur',
                        path: 'categories',
                        end_point: 'cms/categories/',
                    },
                },
                {
                    name: 'cms.categories.edit',
                    path: 'edit/:id',
                    component: Category,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Kategori Bilgileri',
                        path: 'categories',
                        end_point: 'cms/categories/',
                    },
                }
            ]
        },
        {
            path: 'contents/:type',
            component: PageContainer,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                title: 'Icerikler',
            },
            children: [
                {
                    name: 'cms.contents.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Icerikler',
                        path: 'contents',
                        end_point: 'cms/contents',
                        is_link: true,
                    },
                },
                {
                    name: 'cms.contents.create',
                    path: 'create',
                    component: Content,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Icerik Olustur',
                        path: 'contents',
                        end_point: 'cms/contents/',
                    },
                },
                {
                    name: 'cms.contents.edit',
                    path: 'edit/:id',
                    component: Content,
                    meta: {
                        auth: true,
                        layout: layouts.navLeft,
                        title: 'Icerik Bilgileri',
                        path: 'contents',
                        end_point: 'cms/contents/',
                    },
                }
            ]
        },
    ]
}
