<template>
    <div class="page-vue-good-table scrollable only-y">
        <page-header />

        <div class="card-box">
            <header class="card-box-header">
                <div class="title">
                    {{ $route.meta.title }}
                </div>
            </header>
            <section class="card-box-content">
                <file-manager :settings="fileManagerSettings" />
            </section>
        </div>
    </div>
</template>

<script>
    import PageHeader from "../../../components/PageHeader";

    export default {
        name: 'ElementForm',
        components: {PageHeader},
        data() {
            return {
                //
            }
        }
    }
</script>