<style lang="scss">
    .f-button-right {
        height: 40px;
        min-width: 40px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .no-border-radius {
        border-radius: 0;

        input {
            border-radius: 0;
            border-left-color: transparent !important;
        }
    }

    .no-border-radius-right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .popover-content {
        .items {
            margin-top: 20px;

            .item {
                cursor: pointer;
                font-weight: 600;
                padding: 10px 10px;
                border-top: 1px solid rgba(92, 80, 185, 0.2);
                border-bottom: 1px solid rgba(92, 80, 185, 0.2);

                &:hover {
                    background: rgba(92, 80, 185, 0.2);
                    color: #5C50B9;
                }

                &:first-child {
                    border-top: 0;
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }
    }

    .event-box {
        .card-box-header {
            .title {
                font-size: 15px;
            }

            .actions {
                font-size: 14px;
            }
        }

        .card-box-content {

            display: flex;
            align-items: center;

            .media {
                margin-right: 20px;

                i {
                    height: 60px;
                    width: 60px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 25px;
                }
            }

            .text {
                font-size: 14px;
            }
        }
    }
</style>

<template>
    <div class="scrollable">
        <div v-loading="itemLoading" style="margin-bottom: 100px">
            <div class="card-box">
                <header class="card-box-header">
                    <div class="title">
                        <div v-if="pagination">
                            {{ pagination.total }} etkinlik
                        </div>
                    </div>
                    <el-form class="actions" :label-position="'top'" ref="form" :model="filterForm">
                        <el-popover trigger="click" placement="bottom-end" width="550">
                            <el-tooltip content="Tarih Araligi" placement="top" slot="reference">
                                <el-button type="text" class="el-button-link green" icon="el-icon-date"
                                           style="display: flex !important;"/>
                            </el-tooltip>
                            <div class="popover-content">
                                <div class="d-flex">
                                    <el-date-picker v-model="range.startAt" type="date" placeholder="Baslangic"
                                                    value-format="yyyy-MM-dd" class="no-border-radius-right"/>
                                    <el-date-picker v-model="range.endAt" type="date" placeholder="Bitis"
                                                    value-format="yyyy-MM-dd" class="no-border-radius"/>
                                    <el-button type="text" class="el-button-link green f-button-right"
                                               icon="el-icon-check" @click="setDate('range')"/>
                                </div>
                                <div class="items">
                                    <div class="item" v-for="date in dates" :key="date.value"
                                         @click="setDate(date.value)"
                                         :class="form.date_type === date.value ? 'is-active' : ''">
                                        {{ date.label }}
                                    </div>
                                </div>
                            </div>
                        </el-popover>
                        <el-dropdown class="ml-10" @command="handleStatusCommand">
                            <el-tooltip content="Durumlar" placement="top">
                                <el-button type="text" class="el-button-link primary" icon="el-icon-menu"
                                           style="display: flex !important;"/>
                            </el-tooltip>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="status in statuses" :key="status.id" :command="status.id">
                                    <i :class="status.icon"></i>
                                    {{ status.title }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-form>
                </header>
            </div>

            <div class="loading-container">
                <div v-if="pagination && pagination.total > 0" class="text-right margin-bottom-20">
                    <el-pagination
                            :currentPage="pagination.current_page"
                            :pageCount="pagination.last_page"
                            :pagerCount="5"
                            :small="false"
                            @current-change="onPagerClick"
                    />
                </div>

                <el-row :gutter="24">
                    <el-col :span="12" v-for="item in events" :key="item.id">
                        <div class="card-box event-box">
                            <header class="card-box-header" :class="item.color">
                                <div class="title">
                                    {{ item.title }}
                                </div>
                                <div class="actions">
                                    {{ item.start_at_format }} <i class="el-icon-time" style="margin: 2px 0 0 10px"></i>
                                </div>
                            </header>
                            <div class="card-box-content padding-20">
                                <div class="media" v-if="item.status">
                                    <el-tooltip :content="item.status.title" placement="top">
                                        <i :class="item.status.icon"
                                           :style="{'background-color' : item.status.bg_color, color : item.status.text_color}"></i>
                                    </el-tooltip>
                                </div>
                                <div class="text">
                                    <vue-scroll>
                                        <div style="max-height: 60px">
                                            {{ item.description }}
                                        </div>
                                    </vue-scroll>
                                </div>
                            </div>
                            <div class="card-box-footer">
                                <div style="display: flex" class="fluid">
                                    <el-button
                                            icon="el-icon-view"
                                            type="text"
                                            class="el-button-link grey fluid"
                                            @click="openBlankWindow('/cards/marketing/pipelines/' + item.card.stage.card_pipeline_id + '/' + item.card_id)"
                                    >
                                        Ilgili Kart'a Git
                                    </el-button>
                                    <el-button icon="el-icon-edit" type="text" class="el-button-link primary fluid"
                                               @click="editItem(item)">
                                        Duzenle
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>

        <el-dialog v-loading="itemLoading" title="Duzenle" :visible.sync="eventModalVisible">
            <el-form :model="form">
                <el-form-item label="Etkinlik Tipi">
                    <template>
                        <el-select v-model="form.status_id" value-key="id" placeholder="Etkinlik Tipi">
                            <el-option v-for="(item,key) in statuses" :key="item.id" :label="item.title"
                                       :value="item.id"/>
                        </el-select>
                    </template>
                </el-form-item>

                <el-form-item label="Baslik">
                    <el-input v-model="form.title" placeholder="Ad"/>
                </el-form-item>

                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="Baslangic Tarihi">
                            <el-date-picker v-model="form.start_at" type="datetime" placeholder="Tarih Seçiniz"
                                            value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Bitis Tarihi">
                            <el-date-picker v-model="form.end_at" type="datetime" placeholder="Tarih Seçiniz"
                                            value-format="yyyy-MM-dd HH:mm:ss"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="Notunuz">
                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="Notunuz..."
                              v-model="form.description"/>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="updateItem(this.form)" class="fluid">
                    <i class="mdi mdi-check"></i> Guncelle
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

    const startTime = ' 00:00:00';
    const endTime = ' 23:59:59';

    export default {
        name: 'Events',

        props: ['source'],
        data() {
            return {
                itemLoading: true,
                eventModalVisible: false,
                filterForm: {
                    status_id: null,
                    date_type: null,
                    start_at: null,
                    end_at: null,
                    page: 1
                },
                form: {
                    //
                },
                staffs: [],
                statuses: [],
                events: [],
                pagination: null,
                range: {
                    startAt: null,
                    endAt: null,
                },
                dates:
                    [
                        {
                            label: 'Dun',
                            value: 'yesterday',
                        },
                        {
                            label: 'Bu gun',
                            value: 'today',
                        },
                        {
                            label: 'Yarin',
                            value: 'tomorrow',
                        },
                    ],
            }
        },

        methods: {

            getStaffs() {
                this.itemLoading = false;

                this.$http
                    .get('crm/users', {
                        params: {
                            filters: [
                                {
                                    relation: 'roles',
                                    inputs: [
                                        {
                                            name: 'role_id',
                                            value: [
                                                9
                                            ]
                                        }
                                    ]
                                }
                            ],
                        }
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.staffs = response.body.rows;
                    });
            },

            getStatuses() {
                this.itemLoading = true;
                this.$http.get('system/statuses?alias=events').then(response => {
                    this.itemLoading = false;
                    this.statuses = response.body.rows;
                });
            },

            getEvents() {
                this.itemLoading = true;

                this.$http
                    .get(this.source, {
                        params: this.filterForm
                    })
                    .then(response => {
                        this.itemLoading = false;
                        this.events = response.body.rows;
                        this.pagination = response.body.pagination
                    })
                    .catch((e) => {
                        this.itemLoading = false;
                        this.apiErrorMessage(e);
                    });
            },

            yesterday() {
                let date = new Date();

                date.setDate(date.getDate() - 1);

                let dd = date.getDate();
                let mm = date.getMonth() + 1;
                let yyyy = date.getFullYear();

                if (dd < 10) {
                    dd = '0' + dd;
                }

                if (mm < 10) {
                    mm = '0' + mm;
                }

                return yyyy + '-' + mm + '-' + dd;
            },

            today() {
                let today = new Date();
                let dd = today.getDate();
                let mm = today.getMonth() + 1;
                let yyyy = today.getFullYear();

                if (dd < 10) {
                    dd = '0' + dd;
                }
                if (mm < 10) {
                    mm = '0' + mm;
                }

                return yyyy + '-' + mm + '-' + dd;
            },

            tomorrow() {
                let date = new Date();

                date.setDate(date.getDate() + 1);

                let dd = date.getDate();
                let mm = date.getMonth() + 1;
                let yyyy = date.getFullYear();

                if (dd < 10) {
                    dd = '0' + dd;
                }

                if (mm < 10) {
                    mm = '0' + mm;
                }

                return yyyy + '-' + mm + '-' + dd;
            },

            changeStatus(id) {
                this.filterForm.status_id = id;
                this.filterForm.page = 1;

                this.getEvents();
            },

            setDate(type) {
                let startAt = null;
                let endAt = null;

                if (type === 'tomorrow') {
                    startAt = this.tomorrow();
                    endAt = this.tomorrow();
                }

                if (type === 'today') {
                    startAt = this.today();
                    endAt = this.today();
                }

                if (type === 'yesterday') {
                    startAt = this.yesterday();
                    endAt = this.yesterday();
                }

                if (type === 'range') {
                    startAt = this.range.startAt;
                    endAt = this.range.endAt;
                }

                this.filterForm.start_at = startAt + startTime;
                this.filterForm.end_at = endAt + endTime;
                this.filterForm.date_type = type;
                this.filterForm.page = 1;


                this.getEvents();
            },

            onPagerClick(page) {
                this.filterForm.page = page;

                this.getEvents();
            },

            editItem(item) {
                this.eventModalVisible = true;
                this.form = item;
            },

            updateItem(form) {

                this.$http
                    .put(this.source + '/' + form.id, form)
                    .then(response => {
                        this.apiSuccessMessage(response);
                        this.form = response.body.item
                    })
                    .catch((e) => {
                        this.apiErrorMessage(e);
                    });
            },

            handleStatusCommand(command) {
                this.changeStatus(command)
            }
        },

        mounted() {
            //this.getStaffs();
            this.getStatuses();
            this.setDate('today');
        }
    }
</script>