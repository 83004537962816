<template>
    <div class="page-vue-good-table scrollable only-y">
        <page-header>
            <el-form :label-position="'top'" ref="filterForm" :model="filterForm">
                <el-form-item class="m-0">
                    <short-daterange-picker :container="filterForm" model="date_range" :change="index"/>
                </el-form-item>
            </el-form>
        </page-header>

        <div class="loading-container" v-loading="pageLoading">
            <template v-for="staff in staffs">
                <div class="card-box"  :key="staff.id">
                    <header class="card-box-header">
                        <div class="title">{{ staff.name }}</div>
                    </header>
                    <section class="card-box-content">
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <div class="card-box mb-0">
                                    <div class="card-box-content">
                                        <div class="box-list">
                                            <div class="box-list-item box-list-item-title">
                                                <div class="name fluid">Etkinlik Performansı</div>
                                            </div>
                                            <template v-for="event in staff.events">
                                                <div class="box-list-item" :key="event.id">
                                                    <div class="name fluid">{{ event.name }}</div>
                                                    <div class="description">{{ event.count }}</div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-box mb-0">
                                    <div class="card-box-content">
                                        <div class="box-list">
                                            <div class="box-list-item box-list-item-title">
                                                <div class="name fluid">Görüşme Performansı</div>
                                            </div>
                                            <template v-for="interview in staff.interviews">
                                                <div class="box-list-item" :key="interview.id">
                                                    <div class="name fluid">{{ interview.name }}</div>
                                                    <div class="description">{{ interview.count }}</div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div class="card-box mb-0">
                                    <div class="card-box-content">
                                        <div class="box-list">
                                            <div class="box-list-item box-list-item-title">
                                                <div class="name fluid">Teklif Performansı</div>
                                            </div>
                                            <div>
                                                <template v-for="offer in staff.offers">
                                                    <div class="box-list-item" :key="offer.id">
                                                        <div class="name fluid">{{ offer.name }}</div>
                                                        <div class="description">
                                                            {{ offer.count }} / {{ $n(offer.total, 'currency') }}
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-box mb-0">
                                    <div class="card-box-content">
                                        <div class="box-list">
                                            <div class="box-list-item box-list-item-title">
                                                <div class="name fluid">Sipariş Performansı</div>
                                            </div>
                                            <template v-for="order in staff.orders">
                                                <div class="box-list-item" :key="order.id">
                                                    <div class="name fluid">{{ order.name }}</div>
                                                    <div class="description">
                                                        {{ order.count }} / {{ $n(order.total, 'currency') }}
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </section>
                </div>
            </template>

            <el-row :gutter="24">
                <el-col :span="12">
                    <div class="card-box">
                        <header class="card-box-header">
                            <div class="title">Data Kaynağı</div>
                        </header>
                        <template v-for="item in marketings">
                            <section class="card-box-content" :key="item.id">
                                <div class="box-list">
                                    <div class="box-list-item ">
                                        <div class="name">{{ item.name }}</div>
                                        <div class="description fw-600">{{ item.value }}</div>
                                    </div>
                                </div>
                            </section>
                        </template>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="card-box">
                        <header class="card-box-header">
                            <div class="title">Sınıf Satış Raporu</div>
                        </header>
                        <template v-for="item in classrooms">
                            <section class="card-box-content" :key="item.id">
                                <div class="box-list">
                                    <div class="box-list-item ">
                                        <div class="name">{{ item.name }}</div>
                                        <div class="description fw-600">{{ item.value }}</div>
                                    </div>
                                </div>
                            </section>
                        </template>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";

export default {
    name: 'ReportIndex',
    components: {PageHeader},
    data() {
        return {
            pageLoading: false,
            filterForm: {
                //
            },

            staffs : [],
            marketings : [],
            classrooms : [],
        }
    },

    mounted() {
        this.index()
    },

    methods: {
        index() {
            this.pageLoading = true

            this.$http
                .get('enrollments/reports', {
                    params: this.filterForm
                })
                .then(response => {
                    this.pageLoading = false;
                    this.staffs = response.data.data.staffs
                    this.marketings = response.data.data.marketings
                    this.classrooms = response.data.data.classrooms
                })
                .catch((e) => {
                    this.pageLoading = false;
                    this.apiErrorMessage(e);
                });
        }
    }
}
</script>
