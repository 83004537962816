<template>
    <el-form :label-position="'top'" ref="form" :model="form" v-loading="pageLoading">
        <h5 class="card-box-sub-title">Ogrenci</h5>
        <div class="padding-20">
            <el-row :gutter="24">
                <el-col :span="20">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="Adi">
                                <el-input v-model="form.first_name" placeholder="Adi"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Soyadi">
                                <el-input v-model="form.last_name" placeholder="Soyadi"/>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="T.C. Kimlik No" prop="identification_no" class="mb-0-i">
                        <el-input v-model="form.identification_no" placeholder="T.C. Kimlik No" v-mask="'###########'"/>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="Fotograf" class="mb-0-i">
                        <file-browser v-model="form.avatar" :small="true"/>
                    </el-form-item>
                    <div class="text-center" style="margin-top: -35px">
                        <el-rate v-model="form.loyalty_year > 3 ? 3 : form.loyalty_year" :max="3" disabled />
                    </div>
                </el-col>
            </el-row>

            <hr />

            <el-row :gutter="24">
                <el-col :span="6">
                    <el-form-item label="Cinsiyet">
                        <select-definition label="Cinsiyet" alias="genders" model="gender_definition_id" :container="form"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="Dogum Tarihi">
                        <el-date-picker v-model="form.birthday" type="date" placeholder="Tarih Seçiniz" value-format="yyyy-MM-dd"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="Dogum Yeri Ulke">
                        <el-select v-model="form.birthplace_country_zone_id" value-key="id" placeholder="Ulke" @change="selectedCountry" clearable filterable>
                            <el-option v-for="item in getZonesByParentId(0)" :key="item.id" :label="item.title" :value="item.id"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="Dogum Yeri Sehir" :key="form.birthplace_country_zone_id">
                        <el-select v-model="form.birthplace_city_zone_id" value-key="id" placeholder="Sehir" clearable filterable>
                            <el-option v-for="item in getZonesByParentId(form.birthplace_country_zone_id)" :key="item.id" :label="item.title" :value="item.id"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>

        <h5 class="card-box-sub-title">Saglik Bilgileri</h5>
        <div class="padding-20">
            <el-form-item label="Kan Grubu">
                <select-definition label="Kan Grubu" alias="blood-groups" model="blood_group_definition_id" :container="form"/>
            </el-form-item>

            <el-form-item label="Saglik Problemleri">
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="Saglik Problemleri"
                          v-model="form.health_problems"/>
            </el-form-item>

            <el-form-item label="Surekli Kullandigi Ilaclar">
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}"
                          placeholder="Surekli Kullandigi Ilaclar" v-model="form.using_pills"/>
            </el-form-item>

            <el-form-item label="Alerjisi">
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="Alerjisi"
                          v-model="form.allergy"/>
            </el-form-item>

            <el-form-item>
                <el-switch v-model="form.is_share" active-text="Kisisel Bilgilerini Paylas?"/>
            </el-form-item>
        </div>

        <h5 class="card-box-sub-title">Okul Bilgileri</h5>
        <div class="padding-20">
            <el-form-item label="Okul">
                <select-definition label="Okul" alias="schools" model="school_definition_id" :container="form"/>
            </el-form-item>

            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="Egitim Yili" class="fluid">
                        <select-definition alias="education-years" model="education_year_definition_id" :container="form"/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Sinif" class="fluid">
                        <select-definition label="Sinif" alias="classes" model="education_class_definition_id" :container="form"/>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
    </el-form>
</template>

<script>
import SelectDefinition from "./SelectDefinition";
import FileBrowser from "./FileBrowser";

export default {
    name: 'Student',
    components: {
        SelectDefinition,
        FileBrowser,
    },
    props: [
        'form'
    ],
    data() {
        return {
            pageLoading: true,
        }
    },

    mounted() {
        this.pageLoading = false;
    },

    methods: {
        selectedCountry() {

            if (!this.form.birthplace_country_zone_id) {
                return null;
            }

            this.form.birthplace_city_zone_id = null;
        },

        getZonesByParentId(parentId) {

            if (parentId === undefined) {
                return [];
            }

            return this.$store.getters.ui.zones[parentId] ? this.$store.getters.ui.zones[parentId] : []
        },
    }
}
</script>
